import { ALL_CURRENCIES, CLASS, CLASS_TYPES, CLASS_LABELS } from '../../../constants';
import { getCurrencyDetail } from '../../../shared/utility';

const unEditableFields = ['currency', 'expiryDate'];

export const returnPassFields = (classType, data = null) => {
  let result = {
    name: {
      label: 'Name',
      type: 'text',
      placeholder: 'Pass name',
      value: data ? data.name : null,
      validation: {
        required: true
      },
      valid: !!data,
      touched: false,
      errorText: null,
      dataCy: 'name'
    },
    description: {
      label: 'Description',
      type: 'text',
      placeholder: 'Pass description',
      value: data ? data.description : null,
      validation: {
        required: true
      },
      valid: !!data,
      touched: false,
      errorText: null,
      dataCy: 'description'
    },
    explainer: {
      label: 'Explainer',
      type: 'text',
      placeholder: 'Pass explainer (additional information about how the pass works)',
      value: data ? data.explainer : null,
      validation: {
        required: false
      },
      valid: true,
      touched: false,
      errorText: null,
      dataCy: 'explainer'
    },
    places: {
      label: 'Places',
      type: 'text',
      placeholder: 'Places',
      value: data && data.places ? data.places : null,
      validation: {
        required: true,
        isNumeric: true
      },
      valid: !!data,
      touched: false,
      errorText: null,
      dataCy: 'places'
    },
    duration: {
      label: 'Duration (in minutes)',
      type: 'text',
      placeholder: 'Enter duration',
      value: data && data.duration ? data.duration : null,
      validation: {
        required: true,
        isNumericDecimal: true
      },
      valid: !!data,
      touched: false,
      errorText: null,
      dataCy: 'duration'
    },
    classCount: {
      label: 'Classes included',
      type: 'text',
      placeholder: 'Classes included',
      value: data ? data.classCount : null,
      validation: {
        required: true,
        isNumeric: true
      },
      valid: !!data,
      touched: false,
      errorText: null,
      dataCy: 'classCount'
    },
    forClass: {
      label: 'Select Class Type - where videos will appear',
      type: 'select',
      options: CLASS_TYPES.map((value) => ({
        label: CLASS_LABELS[value],
        value
      })),
      value: data && data.forClass ? { label: CLASS_LABELS[data.forClass], value: data.forClass } : null,
      validation: {
        required: true
      },
      valid: true,
      touched: false,
      errorText: null,
      dataCy: 'forClass'
    },
    currency: {
      label: 'Currency',
      type: 'select',
      options: ALL_CURRENCIES,
      placeholder: 'Select',
      value: getCurrencyDetail(data && data.currency),
      validation: {
        required: true
      },
      valid: true,
      touched: false,
      errorText: null,
      dataCy: 'currency'
    },
    cost: {
      label: 'Price',
      type: 'text',
      placeholder: 'Pass price',
      value: data ? data.cost : null,
      validation: {
        required: true,
        isNumericDecimal: true
      },
      valid: !!data,
      touched: false,
      errorText: null,
      dataCy: 'cost'
    },
    expiryDate: {
      label: 'Optional - set date to hide for purchase by customers',
      type: 'date',
      value: data ? data.expiryDate : null,
      validation: {
        required: false,
        isDate: true
      },
      valid: true,
      touched: false,
      errorText: null,
      dataCy: 'expiryDate'
    },
    displayOrder: {
      label: 'Display order',
      type: 'text',
      placeholder: 'Display order',
      value: data ? data.displayOrder : null,
      validation: {
        required: true,
        isNumericWithLimit: true
      },
      valid: !!data,
      touched: false,
      errorText: null,
      dataCy: 'displayOrder'
    },
    videos: {
      label: 'Videos',
      type: 'videoPicker',
      value: data ? data.videos : [],
      validation: {
        required: false
      },
      valid: true,
      touched: false,
      errorText: null,
      dataCy: 'videos'
    },
    oneOff: {
      label: 'Available only once per user',
      type: 'checkbox',
      value: data ? data.oneOff : false,
      validation: {
        required: true
      },
      valid: true,
      touched: false,
      errorText: null,
      dataCy: 'oneOff'
    },
    active: {
      label: 'Active',
      type: 'checkbox',
      value: data ? data.active : true,
      validation: {
        required: false
      },
      valid: true,
      touched: false,
      errorText: null,
      dataCy: 'active'
    },
    enforceVideoOrder: {
      label: 'Enforce video order',
      type: 'checkbox',
      value: data ? data.enforceVideoOrder : false,
      validation: {
        required: false
      },
      valid: true,
      touched: false,
      errorText: null,
      dataCy: 'enforceVideoOrder'
    }
  };

  if (classType !== CLASS.PERSONAL_TRAINING) {
    delete result.places;
    delete result.duration;
  }

  if (classType === CLASS.VIDEO) {
    delete result.classCount;
    delete result.oneOff;
  }

  if (classType !== CLASS.VIDEO) {
    delete result.videos;
    delete result.explainer;
    delete result.forClass;
    delete result.enforceVideoOrder;
  }

  if (data !== null && unEditableFields) {
    unEditableFields.forEach((elem) => delete result[elem]);
  }

  return result;
};
