import React from 'react';
import { List } from 'grommet';
import SingleFAQ from './SingleFAQ/SingleFAQ';

const ListFAQ = ({ data, setShowAddEditFAQ, setSelectedFAQ }) => {
  if (data && data.length > 0) {
    return (
      <List>
        {data.map((element) => (
          <SingleFAQ
            key={element._id}
            singleFAQ={element}
            editFAQ={(element) => {
              setSelectedFAQ(element);
              setShowAddEditFAQ(true);
            }}
          />
        ))}
      </List>
    );
  }
  return <h3>No FAQ</h3>;
};

export default ListFAQ;
