import * as moment from 'moment';

export const returnFormFields = (fieldsArray, data = null) => {
  let result = {
    name: {
      label: 'Class name',
      type: 'text',
      placeholder: 'Enter class name',
      value: data ? data.name : null,
      validation: {
        required: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    description: {
      label: 'Description',
      type: 'text',
      placeholder: 'Enter description',
      value: data ? (data.defaultDescription ? data.defaultDescription : data.description) : null,
      validation: {
        required: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    location: {
      label: 'Class location',
      type: 'text',
      placeholder: 'Enter class location',
      value: data ? data.location : null,
      validation: {
        required: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    limit: {
      label: 'Limit',
      type: 'text',
      placeholder: 'Enter limit',
      value: data ? (data.defaultLimit ? data.defaultLimit : data.limit) : null,
      validation: {
        required: true,
        isNumeric: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    classTime: {
      label: 'Time',
      type: 'time',
      placeholder: 'Enter time',
      value: data && data.classTime ? moment(data.classTime, 'hh:mm A') : '',
      validation: {
        required: true,
        isTime: true
      },
      valid: data && data.classTime !== null,
      touched: data && data.classTime !== null,
      errorText: 'Select time'
    },
    classDate: {
      label: 'Date',
      type: 'date',
      placeholder: 'Enter date',
      value: data && data.classDate ? moment(data.classDate, 'DD/MM/YYYY') : '',
      validation: {
        required: true,
        isDate: true
      },
      valid: data && data.classDate !== null,
      touched: data && data.classDate !== null,
      errorText: 'Select date'
    },
    duration: {
      label: 'Duration (in minutes)',
      type: 'text',
      placeholder: 'Enter duration',
      value: data ? (data.defaultDuration ? data.defaultDuration : data.duration) : null,
      validation: {
        required: true,
        isNumericDecimal: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    minimalTimeCancellationInHours: {
      label: 'Minimal cancellation time (in hours)',
      type: 'text',
      placeholder: 'Enter time',
      value: data
        ? data.defaultMinimalTimeCancellationInHours
          ? data.defaultMinimalTimeCancellationInHours
          : data.minimalTimeCancellationInHours
        : null,
      validation: {
        required: true,
        isNumericDecimal: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    private: {
      label: 'Private',
      type: 'select',
      options: ['yes', 'no'],
      placeholder: 'Select',
      value: data ? (data.private ? 'yes' : 'no') : 'no',
      validation: {
        required: true
      },
      valid: true,
      touched: false,
      errorText: null
    },
    cost: {
      label: 'Cost',
      type: 'text',
      placeholder: 'Enter cost',
      value: data && data.cost ? data.cost : null,
      validation: {
        required: true,
        isNumericDecimal: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    }
  };

  for (const property in result) {
    if (!fieldsArray.includes(property)) {
      delete result[property];
    }
  }

  return result;
};
