import React, { useEffect, useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';

function StripeSCAPayment({ clientSecret, onError = () => {}, onSuccess = () => {} }) {
  const [confirming, setConfirming] = useState(false);
  const stripe = useStripe();

  useEffect(() => {
    const scaRedirect = async () => {
      setConfirming(true);

      const result = await stripe.confirmCardPayment(clientSecret, {});
      if (result.error) {
        onError(result.error?.message);
      } else {
        onSuccess();
      }

      setConfirming(false);
    };
    if (stripe && !confirming && clientSecret?.length) {
      scaRedirect();
    }
  }, [clientSecret, stripe, onError, onSuccess, confirming]);

  return <></>;
}

export default StripeSCAPayment;
