import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import List from 'grommet/components/List';
import Notification from 'grommet/components/Notification';
import SingleTrainer from './SingleTrainer/SingleTrainer';
import classes from './ManageTrainers.module.scss';
import Filter from '../../../../components/Filter';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import Loading from '../../../../components/Loading/Loading';
import { delayFetchFunctions, fetchTrainers } from '../../../../apiFunctions/apiFunctions';
import { useQuery } from 'react-query';
import withAdminDashboard from '../../../../store/hoc/withAdminDashboard';

const ManageTrainers = ({ selectedGym, history }) => {
  const [filteredTrainers, setFilteredTrainers] = useState([]);

  const {
    data: trainersList,
    isLoading: isTrainersListLoading,
    error: isTrainersListError
  } = useQuery(['trainersList', selectedGym._id], () => fetchTrainers(selectedGym._id));

  useEffect(() => {
    if (trainersList) {
      setFilteredTrainers(trainersList);
    }
  }, [trainersList]);

  const renderTrainersList = () => {
    if (!isTrainersListLoading && isTrainersListError) {
      return <Notification message={isTrainersListError} status="critical" />;
    }

    if (filteredTrainers.length > 0) {
      return filteredTrainers.map((trainer) => (
        <SingleTrainer
          key={trainer._id}
          singleTrainer={trainer}
          refreshData={() => delayFetchFunctions([['trainersList', selectedGym._id]], 0)}
        />
      ));
    }

    if (filteredTrainers.length === 0) {
      return <h3>No trainers</h3>;
    }

    return <Loading />;
  };

  const onSearchChanged = (value) => {
    const filteredArray = trainersList.filter((elem) => {
      return elem.name.toLowerCase().includes(value) || elem.last_name.toLowerCase().includes(value);
    });
    setFilteredTrainers(filteredArray);
  };

  return (
    <div data-cy={'trainers'}>
      <div className={classes.header}>
        <Filter title={'Filter by name'} onFilter={onSearchChanged} placeHolder={'Trainer name'} />
        <CustomButton
          label="Add new"
          secondary={true}
          onClick={() => history.push(`/gym/${selectedGym._id}/trainers/add`)}
        />
      </div>
      <List>{renderTrainersList()}</List>
    </div>
  );
};

export default withRouter(withAdminDashboard(ManageTrainers));
