import { useState, useEffect } from 'react';

const useCameraMedia = () => {
  const [mediaStream, setMediaStream] = useState(null);

  useEffect(() => {
    async function enableVideoStream() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: {
            width: { ideal: 1280 },
            height: { ideal: 720 }
          }
        });
        setMediaStream(stream);
      } catch (err) {
        // eslint-disable-next-line
        console.error('Error occurred trying to set video and audio streams', err, err.message);
      }
    }

    if (!mediaStream) {
      enableVideoStream();
    } else {
      return function cleanup() {
        mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
      };
    }
  }, [mediaStream]);

  return mediaStream;
};

export default useCameraMedia;
