import React, { useState } from 'react';
import axios from '../../../axios-global';
import Modal from 'react-modal';

import CustomButton from '../../../components/CustomButton/CustomButton';
import Select from 'grommet/components/Select';
import NumberInput from 'grommet/components/NumberInput';

import SignUpSummary from './SignUpSummary';
import ClientProfilePaymentMethodSelection from '../ClientProfilePaymentMethodSelection';
import HeaderWithBorder from '../../UI/HeaderWithBorder/HeaderWithBorder';
import { getCurrencySymbol, getIntegerSign } from '../../../shared/utility';
import './ClientProfileSignUpClient.scss';
import CheckBox from 'grommet/components/CheckBox';
import CircleInformation from 'grommet/components/icons/base/CircleInformation';

export default function ClientProfileSignUpClient(props) {
  const [addMembershipModalState, setAddMembershipModalState] = useState(false);
  const [changeMembershipModalState, setChangeMembershipModalState] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('cash');
  const [plan, setPlan] = useState({});
  const [paidPeriodInMonths, setPaidPeriodInMonths] = useState(1);
  const [isChecked, setIsChecked] = useState(false);

  let lastPaymentMessage =
    props.selectedClient.paymentHistory && props.selectedClient.paymentHistory.length > 0
      ? `The last payment method used for this client was ${props.selectedClient.paymentHistory[0].method}`
      : null;

  let plansList = [];
  if (props.plansList && props.plansList.length) {
    plansList = props.plansList.map((elem) => {
      return {
        label: elem.name,
        value: elem
      };
    });
  }

  const selectPlan = (event) => {
    setPlan({ label: event.option.label, value: event.option.value });
  };

  const updatePaidPeriodInMonths = (event) => {
    setPaidPeriodInMonths(event.target.value.replace(',', '.').split('.')[0]);
  };

  const signUpClient = async () => {
    if (!isAlreadySubscribed && !plan.value) {
      return props.onFailedSave({
        response: { data: { message: 'Please select a plan' } }
      });
    }

    let url;

    if (props.selectedClient.currentMembershipPlan) {
      url = '/membership-plan/payment';
    } else {
      url = `/membership-plan/subscribe/${plan.value._id}`;
    }

    try {
      const respond = await axios.post(url, {
        method: paymentMethod,
        userId: props.selectedClient._id,
        paidPeriodInMonths: Number(paidPeriodInMonths),
        alignToFirstDayOfMonth: isChecked
      });
      props.onSuccessfulSave(respond);
      props.fetchSingleClient();
    } catch (error) {
      props.onFailedSave(error);
    }
  };

  const changeMembershipPlan = async () => {
    if (!plan.value) {
      props.onFailedSave({ message: 'No plan selected' });
      return;
    }

    try {
      const response = await axios.patch('/users/plan', {
        membershipPlanId: plan.value._id,
        userId: props.selectedClient._id,
        method: paymentMethod
      });

      props.onSuccessfulSave(response);
      props.fetchSingleClient();
    } catch (error) {
      props.onFailedSave(error);
    }

    setChangeMembershipModalState(false);
  };

  const handleModalClose = () => {
    setAddMembershipModalState(false);
    setChangeMembershipModalState(false);
  };

  const isAlreadySubscribed = props.selectedClient.currentMembershipPlan
    ? props.selectedClient.currentMembershipPlan
    : false;
  const currentPlan = plansList.find((plan) => {
    if (plan.value && isAlreadySubscribed) {
      return plan.value._id === isAlreadySubscribed.ssPlanId._id;
    }
    return false;
  });

  const joiningFeePaid = props.selectedClient.joiningFeePaid;
  let priceDiff;

  if (plan.value && currentPlan) {
    priceDiff = plan.value.price - currentPlan.value.price;
  }

  const checkBoxChangeHandler = (checkboxEvent) => {
    setIsChecked(checkboxEvent.target.checked);
  };

  return (
    <>
      <Modal
        appElement={document.getElementById('root')}
        ariaHideApp={false}
        isOpen={addMembershipModalState}
        onRequestClose={handleModalClose}
        className="ss-modal client-profile-add-membership__modal">
        {!isAlreadySubscribed && (
          <div style={{ marginBottom: '20px' }} data-cy="choosePlan">
            <HeaderWithBorder header={'Choose Plan'} />

            <Select options={plansList} value={plan.label} onChange={selectPlan} />
          </div>
        )}
        <div style={{ marginBottom: '20px' }} data-cy="choosePaymentMethod">
          <HeaderWithBorder header={'Choose Payment Method'} />

          <ClientProfilePaymentMethodSelection onMethodSelected={setPaymentMethod} />
        </div>

        <div style={{ marginBottom: '20px' }}>
          <HeaderWithBorder header={'Specify the number of months'} />

          <NumberInput value={paidPeriodInMonths} step={1} onChange={updatePaidPeriodInMonths} />
        </div>

        <div>
          <CheckBox
            checked={isChecked}
            onChange={(checkboxEvent) => checkBoxChangeHandler(checkboxEvent)}
            label={'Align payment to first of the month'}
          />
        </div>

        {!isAlreadySubscribed && (
          <>
            <HeaderWithBorder header={'Plan Summary'} />
            <SignUpSummary plan={plan.value} paidPeriodInMonths={paidPeriodInMonths} joiningFeePaid={joiningFeePaid} />
          </>
        )}

        <div className="ss-modal__buttons-container">
          <CustomButton box label="OK" secondary={true} onClick={signUpClient} data-cy="okBtn" />

          <CustomButton box label="Cancel" primary onClick={handleModalClose} />
        </div>
      </Modal>

      <Modal
        appElement={document.getElementById('root')}
        ariaHideApp={false}
        isOpen={changeMembershipModalState}
        onRequestClose={handleModalClose}
        className="ss-modal client-profile-add-membership__modal">
        <div style={{ marginBottom: '20px' }} data-cy="choosePlan">
          <HeaderWithBorder header={'Choose Plan'} forId="choose-plan" />

          <Select
            id="choose-plan"
            options={plansList.filter((plan) => {
              if (isAlreadySubscribed && isAlreadySubscribed.ssPlanId && plan && plan.value) {
                return isAlreadySubscribed.ssPlanId._id !== plan.value._id;
              }

              return true;
            })}
            value={plan.label}
            onChange={selectPlan}
          />
        </div>

        <div style={{ marginBottom: '20px' }}>
          {lastPaymentMessage && (
            <div className="info">
              <CircleInformation /> {lastPaymentMessage}
            </div>
          )}

          <HeaderWithBorder header={'Choose Payment Method'} />
          <ClientProfilePaymentMethodSelection onMethodSelected={setPaymentMethod} />
        </div>

        {plan.value ? (
          <div className="membership-diff-container">
            <HeaderWithBorder header={'Change Summary'} />
            <div>{`Before: ${currentPlan ? currentPlan.label : ''}`}</div>
            <div>
              {`After: ${plan.label} ${
                priceDiff !== 0
                  ? `(${getIntegerSign(priceDiff)}${getCurrencySymbol(plan.value.currency)}${Math.abs(priceDiff)} p/m)`
                  : ''
              }`}
            </div>
          </div>
        ) : null}

        <div className="ss-modal__buttons-container">
          <CustomButton label="OK" secondary onClick={changeMembershipPlan} data-cy="okBtn" />

          <CustomButton label="Cancel" primary onClick={handleModalClose} />
        </div>
      </Modal>

      <div className="client-profile__smaller-buttons-container">
        {props.isAdmin && isAlreadySubscribed && plansList.length > 1 ? (
          <CustomButton
            className="client-profile__button"
            label={'Change Gym Membership'}
            secondary={true}
            onClick={() => setChangeMembershipModalState(true)}
            data-cy="changeGymMembershipBtn"
          />
        ) : null}

        {props.isAdmin && (
          <CustomButton
            className="client-profile__button"
            label={isAlreadySubscribed ? 'Add Membership Payment' : 'Add Gym Membership'}
            secondary={true}
            onClick={() => setAddMembershipModalState(true)}
            data-cy="addGymMembershipBtn"
          />
        )}
      </div>
    </>
  );
}
