import React, { useState } from 'react';
import Label from 'grommet/components/Label';
import FormField from 'grommet/components/FormField';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import Loading from '../../../../components/Loading/Loading';
import classes from './SearchClients.module.scss';
import { useMutation } from 'react-query';
import { searchUsersByName } from '../../../../apiFunctions/apiFunctions';
import Notification from 'grommet/components/Notification';
import ClientsList from './ClientsList/ClientsList';

const SearchClients = () => {
  const [name, setName] = useState('');
  const [searchData, setSearchData] = useState(null);
  const [showError, setShowError] = useState(false);

  const mutateSearch = useMutation(() => searchUsersByName(name), {
    onSuccess: (data) => setSearchData(data),
    onError: () => setShowError(true)
  });

  const search = (
    <div>
      <Label>Please enter name or last name</Label>
      <FormField>
        <input value={name} onChange={(e) => setName(e.target.value)} />
      </FormField>
      <CustomButton primary label="Search" onClick={mutateSearch.mutate} />
    </div>
  );

  const errorNotification = showError && (
    <Notification message="Something went wrong. Please try again" status="critical" />
  );
  return (
    <div className={classes.root}>
      {search}
      {errorNotification}
      {mutateSearch.isLoading && <Loading />}
      <ClientsList searchData={searchData} />
    </div>
  );
};

export default SearchClients;
