import React from 'react';
import moment from 'moment';
import RedirectToUserDetails from '../../../../ActionButtons/RedirectToUserDetails/RedirectToUserDetails';
import classes from './ClientsList.module.scss';

const ClientsList = ({ searchData }) => {
  const header = ['Name', 'Last name', 'Date of birth', 'Gym', ''];

  if (searchData === null) {
    return null;
  }
  if (searchData && searchData.length === 0) {
    return <h3>No clients</h3>;
  }
  const renderHeader = (
    <div className={[classes.grid, classes.header].join(' ')}>
      {header.map((elm, i) => (
        <div key={i}>{elm}</div>
      ))}
    </div>
  );
  const singleClient = ({ name, gymId, userId, lastName, gymName, dateOfBirth }) => (
    <div className={[classes.grid, classes.singleClient].join(' ')} key={userId}>
      <div className={classes.name}>{name}</div>
      <div className={classes.name}>{lastName}</div>
      <div>
        <div className={classes.subheader}>DATE OF BIRTH</div>
        {dateOfBirth ? moment(dateOfBirth).format('DD/MM/YYYY') : null}{' '}
      </div>
      <div>
        <div className={classes.subheader}>GYM</div>
        {gymName}
      </div>
      <div className={classes.icon}>
        <RedirectToUserDetails gymId={gymId} clientId={userId} />
      </div>
    </div>
  );

  const renderList = searchData.map((elm) => singleClient(elm));
  return (
    <div>
      {renderHeader}
      {renderList}
    </div>
  );
};

export default ClientsList;
