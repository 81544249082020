import React from 'react';

import classes from './Card.module.scss';

const Card = (props) => {
  return (
    <div className={classes.card}>
      <div className={classes.card__label}>{props.label}</div>

      <div className={classes.card__body}>{props.body}</div>
    </div>
  );
};
export default Card;
