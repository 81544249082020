import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  plansList: null,
  plansListLoading: false,
  plansListError: null,

  selectedPlan: null,
  selectedPlanLoading: false,
  selectedPlanError: null,

  sourcesList: null,
  sourcesListLoading: false,
  sourcesListError: null,

  currentStatus: null,
  currentStatusLoading: false,
  currentStatusError: null,

  upcomingClasses: [],
  upcomingClassesLoading: false,
  upcomingClassesError: null,

  upcomingPts: [],
  upcomingPtsLoading: false,
  upcomingPtsError: null,

  updateUserDetailsLoading: false,
  updateUserDetailsError: null,

  videoProducts: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // plans list
    case actionTypes.CLIENT_FETCH_PLANS_START:
      return updateObject(state, {
        plansListLoading: true
      });
    case actionTypes.CLIENT_FETCH_PLANS_FAILED:
      return updateObject(state, {
        plansListLoading: false,
        plansListError: action.error
      });
    case actionTypes.CLIENT_FETCH_PLANS_SUCCESS:
      return updateObject(state, {
        plansListLoading: false,
        plansList: action.plansList
      });

    // sources list
    case actionTypes.CLIENT_FETCH_SOURCES_START:
      return updateObject(state, {
        sourcesListLoading: true
      });
    case actionTypes.CLIENT_FETCH_SOURCES_FAILED:
      return updateObject(state, {
        sourcesListLoading: false,
        sourcesListError: action.error
      });
    case actionTypes.CLIENT_FETCH_SOURCES_SUCCESS:
      return updateObject(state, {
        sourcesListLoading: false,
        sourcesList: action.sourcesList
      });

    // current subscribtion status
    case actionTypes.CLIENT_SUBSCRIPTION_STATUS_START:
      return updateObject(state, {
        currentStatusLoading: true
      });
    case actionTypes.CLIENT_SUBSCRIPTION_STATUS_FAILED:
      return updateObject(state, {
        currentStatusLoading: false,
        currentStatusError: action.error
      });
    case actionTypes.CLIENT_SUBSCRIPTION_STATUS_SUCCESS:
      return updateObject(state, {
        currentStatusLoading: false,
        currentStatus: action.currentStatus
      });

    // upcoming classes
    case actionTypes.CLIENT_FETCH_UPCOMING_CLASSES_START:
      return updateObject(state, {
        upcomingClassesLoading: true
      });
    case actionTypes.CLIENT_FETCH_UPCOMING_CLASSES_FAILED:
      return updateObject(state, {
        upcomingClassesLoading: false,
        upcomingClassesError: action.error
      });
    case actionTypes.CLIENT_FETCH_UPCOMING_CLASSES_SUCCESS:
      return updateObject(state, {
        upcomingClassesLoading: false,
        upcomingClasses: action.upcomingClasses
      });

    // PTs
    case actionTypes.CLIENT_FETCH_UPCOMING_PTS_START:
      return updateObject(state, {
        upcomingPtsLoading: true
      });
    case actionTypes.CLIENT_FETCH_UPCOMING_PTS_FAILED:
      return updateObject(state, {
        upcomingPtsLoading: false,
        upcomingPtsError: action.error
      });
    case actionTypes.CLIENT_FETCH_UPCOMING_PTS_SUCCESS:
      return updateObject(state, {
        upcomingPtsLoading: false,
        upcomingPts: action.upcomingPts
      });

    case actionTypes.CLIENT_FETCH_WAIT_LIST_START:
      return updateObject(state, {
        waitListLoading: true
      });
    case actionTypes.CLIENT_FETCH_WAIT_LIST_FAILED:
      return updateObject(state, {
        waitListLoading: false,
        waitListError: action.error
      });
    case actionTypes.CLIENT_FETCH_WAIT_LIST_SUCCESS:
      return updateObject(state, {
        waitListLoading: false,
        waitList: action.waitList
      });

    case actionTypes.CLIENT_UPDATE_DETAILS_START:
      return updateObject(state, {
        updateUserDetailsLoading: true
      });

    case actionTypes.CLIENT_UPDATE_DETAILS_SUCCESS:
      return updateObject(state, {
        updateUserDetailsLoading: false
      });

    case actionTypes.CLIENT_UPDATE_DETAILS_FAILED:
      return updateObject(state, {
        updateUserDetailsLoading: false,
        updateUserDetailsError: action.error
      });

    case actionTypes.UPDATE_VIDEO_PRODUCTS:
      return updateObject(state, {
        videoProducts: action.videoProducts
      });

    default:
      return state;
  }
};

export default reducer;
