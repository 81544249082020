import React from 'react';
import { Button } from 'grommet';
import Loading from '../Loading/Loading';
import classes from './CustomButton.module.scss';

const CustomButton = ({
  isLoading,
  label = null,
  primary = false,
  secondary = false,
  onClick = () => {},
  fullWidth = false,
  style = {},
  className = '',
  dataCy = 'btn',
  disabled = false,
  icon = null,
  position = null
}) => {
  if (isLoading) {
    return <Loading data-cy={dataCy} centered />;
  }
  let returnStyles = fullWidth ? { ...style, ...fullWidthStyle } : { ...style };

  if (icon !== null) {
    if (disabled === true) {
      return (
        <div className={classes.root} style={positionStyle(position)}>
          <Button
            label={label}
            className={className}
            style={{ ...style, ...disabledStyle }}
            data-cy={dataCy}
            icon={icon}
          />
        </div>
      );
    }
    return (
      <div className={classes.root} style={positionStyle(position)}>
        <Button label={label} className={className} style={style} onClick={onClick} data-cy={dataCy} icon={icon} />
      </div>
    );
  }
  if (disabled === true) {
    return (
      <div className={classes.root} style={positionStyle(position)}>
        <Button
          primary={primary}
          style={{ ...returnStyles, ...disabledStyle }}
          className={className}
          label={label}
          secondary={secondary}
          data-cy={dataCy}
        />
      </div>
    );
  }

  return (
    <div className={classes.root} style={positionStyle(position)}>
      <Button
        primary={primary}
        className={className}
        style={returnStyles}
        label={label}
        secondary={secondary}
        onClick={onClick}
        data-cy={dataCy}
      />
    </div>
  );
};

export default CustomButton;

const fullWidthStyle = {
  margin: '15px 0px',
  display: 'block',
  minWidth: '100%'
};

const disabledStyle = {
  cursor: 'not-allowed'
};
const positionStyle = (position = null) => {
  if (position === 'right') {
    return { margin: '0 0 0 auto' };
  }
  if (position === 'center') {
    return { margin: '0 auto' };
  }
  if (position === 'bottom') {
    return { margin: 'auto auto 0 0' };
  }
  return;
};
