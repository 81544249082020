import { ALL_CLASS_TYPES_WITH_DETAILS, GYM_SETTINGS } from '../constants';

export const returnClassConfigFieldValue = (classConfigArray, classType, fieldName) => {
  /**
     *   fieldNames:
     "      allowedToBuyPasses
     "      trainerRequired
     "      singleDayTimetable
     "      showClassCost
     */
  const classConfig = returnClassConfig(classConfigArray, classType);
  return classConfig !== {} ? classConfig[fieldName] : false;
};

export const returnClassConfigForSelectedClass = (gymsList, singleClass) => {
  const gymId = singleClass.gymId;
  return returnClassConfig(
    gymsList.filter((elem) => elem._id === gymId),
    singleClass.__t
  );
};

export const returnClassConfig = (classConfigArray, classType) => {
  const defaultConfig = {
    allowedToBuyPasses: false,
    trainerRequired: false,
    singleDayTimetable: false,
    showClassCost: false,
    seventhDayAvailabilityHour: 0,
    consecutiveBookingsEnabled: false,
    consecutiveBookingsCoolOff: 30,
    classType,
    schedule: true,
    showAddNew: true,
    showHistory: true,
    showUpcoming: true
  };
  let result = defaultConfig;
  if (classConfigArray !== undefined) {
    result = { ...result, ...classConfigArray.find((elem) => elem.classType === classType) };
    if (result === undefined) {
      result = defaultConfig;
    }
  }

  if (classType === 'induction') {
    result.allowedToBuyPasses = false;
  }
  result.schedule = classType !== 'personalTraining';
  result.template = classType !== 'personalTraining';

  if (classType === 'video') {
    result.allowedToBuyPasses = true;
    result.schedule = false;
    result.singleDayTimetable = false;
    result.showAddNew = false;
    result.showHistory = false;
    result.showUpcoming = false;
  }

  return result;
};

export const returnClientDetails = (userId, clientsList) => clientsList.find((elem) => elem._id === userId);

export const returnClientsListWithSamePTPasses = (clientsList, duration = 60, places = 1, userIdForFirstClient) =>
  clientsList.filter((elem) =>
    elem.status === 'active' && userIdForFirstClient !== elem._id && elem.PTclassesLeft.length > 0
      ? elem.PTclassesLeft.filter(
          (item) => item.duration === duration && item.places === places && item.classesLeft > 0
        ).length > 0
      : false
  );

export const returnGymsPerActiveClassType = (gymList, classType) => gymList.filter((gym) => gym.settings[classType]);

export const returnAvailableClassTypesInGym = (gymSettings) =>
  ALL_CLASS_TYPES_WITH_DETAILS().filter((elem) => gymSettings[elem.type]);

export const returnGymSettingsFromGymsList = (gymId = '', gymsArray = []) => {
  const gym = gymsArray.find((elem) => elem._id === gymId);
  return gym ? gym.settings : GYM_SETTINGS;
};
