import React, { useState } from 'react';
import { Notification } from 'grommet';
import Loading from '../../../components/Loading/Loading';
import { returnCouponFields } from './returnCouponFields';
import { useMutation } from 'react-query';
import { addCoupon, delayFetchFunctions, editCoupon } from '../../../apiFunctions/apiFunctions';
import { updateObject, checkValidity, returnErrorTextForField, returnErrorFromResponse } from '../../../shared/utility';
import FormCreator from '../../../components/UI/FormCreator/FormCreator';
import classes from './AddEditCoupon.module.scss';
import ProductSelector from '../../../components/Selectors/ProductSelector/ProductSelector';
import CustomToast from '../../../components/CustomToast/CustomToast';
import Select from 'grommet/components/Select';
import Label from 'grommet/components/Label';
import CustomButton from '../../../components/CustomButton/CustomButton';
const AddEditCoupon = ({ selectedCoupon = null, selectedGym, onClose, membershipPlans }) => {
  const [formIsValid, setFormIsValid] = useState(false);
  const [formValidated, setFormValidated] = useState(false);
  const [formFields, setFormFields] = useState(returnCouponFields(selectedCoupon));
  const [toast, setToast] = useState(null);
  const [selectedDuration, setSelectdDuration] = useState('repeating');
  const isNewCoupon = selectedCoupon === null || selectedCoupon === undefined;
  const [selectedProductType, setSelectedProductType] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const mutateCoupon = useMutation(
    (formData) => (isNewCoupon ? addCoupon(selectedGym._id, formData) : editCoupon(selectedCoupon._id, formData)),
    {
      onSuccess: () => {
        setToast({
          status: 'ok',
          msg: isNewCoupon ? 'Successfully created' : 'Successfully updated',
          show: true
        });
        delayFetchFunctions([['coupons']], 0);
        setTimeout(() => onClose(), 1500);
      },
      onError: (err) => {
        setToast({
          status: 'critical',
          msg: returnErrorFromResponse(err),
          show: true
        });
      }
    }
  );

  const valueChangedHandler = (newValue, identifier) => {
    const updated = updateObject(formFields, {
      [identifier]: updateObject(formFields[identifier], {
        value: newValue,
        valid: checkValidity(newValue, formFields[identifier].validation),
        errorText: returnErrorTextForField(formFields[identifier].validation),
        touched: true
      })
    });
    setFormFields(updated);
    setFormValidated(false);
  };

  const checkFormValidity = () => {
    setFormValidated(true);

    for (let input in formFields) {
      if (!formFields[input].valid) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        return setFormIsValid(false);
      }
    }
    setFormIsValid(true);
    saveCoupon();
  };

  const saveCoupon = () => {
    let formData = {};
    if (isNewCoupon) {
      formData = {
        currency: formFields.currency.value.value,
        oneOff: formFields.oneOff.value === 'yes'
      };
      if (formFields.oneOff.value === 'no' && selectedDuration) {
        formData.duration = selectedDuration;
      }
    }
    formData.productIds = selectedProduct;

    if (selectedProductType && selectedProductType.type) {
      formData.productType = selectedProductType.type;
    }

    const fieldsToSkip = ['currency', 'oneOff'];
    for (let input in formFields) {
      if (!fieldsToSkip.includes(input)) {
        let value = formFields[input].value;
        formData[input] = typeof value === 'string' ? value.trim() : value;
      }
    }

    mutateCoupon.mutate(formData);
  };

  const formWarning = !formIsValid && formValidated && (
    <Notification message="Form is not filled in correctly" status="warning" className="ss-top-notification" />
  );

  const button = mutateCoupon.isLoading ? (
    <Loading className={classes.spinner} />
  ) : (
    <CustomButton
      className={classes.saveBtn}
      label={selectedCoupon ? 'Save' : 'Add'}
      secondary={true}
      onClick={checkFormValidity}
    />
  );

  const onChange = (event) => {
    setSelectdDuration(event.option);
  };

  return (
    <div>
      <div className={classes.scheduledList}>
        <CustomButton label={'Coupons list'} secondary={true} onClick={onClose} />
      </div>
      <div>
        <CustomToast toast={toast} />
        {formWarning}
        <FormCreator formData={formFields} valueChanged={valueChangedHandler} />
        {formFields && formFields.oneOff && formFields.oneOff.value === 'no' ? (
          <div className={classes.bottomSpace}>
            <Label>Duration</Label>
            <Select value={selectedDuration} options={['repeating', 'forever']} onChange={onChange} />
          </div>
        ) : null}
        <ProductSelector
          gymId={selectedGym._id}
          selectedCoupon={selectedCoupon}
          onProductTypeSelection={setSelectedProductType}
          onProductSelection={setSelectedProduct}
          selectedCouponType={selectedCoupon ? selectedCoupon.couponType : formFields.couponType.value}
          membershipPlans={membershipPlans}
        />
        {button}
      </div>
    </div>
  );
};

export default AddEditCoupon;
