import React from 'react';
import { Tab, Tabs } from 'grommet';
import ProfileTextWithLabel from '../../UI/ProfileTextWithLabel/ProfileTextWithLabel';
import ProfilePaymentHistory from '../../UI/ProfilePaymentHistory/ProfilePaymentHistory';
import { PASS_PAYMENT_TYPE, SUBSCRIPTION_PAYMENT_TYPE } from '../../../../src/constants';
const ClientProfilePaymentHistoryTabs = (props) => {
  const dataSubscriptionPayments = props.paymentHistory.filter((elem) => SUBSCRIPTION_PAYMENT_TYPE.includes(elem.type));

  const dataOneOffPayments = props.paymentHistory.filter(
    (elem) => !PASS_PAYMENT_TYPE.includes(elem.type) && !SUBSCRIPTION_PAYMENT_TYPE.includes(elem.type)
  );

  const dataPassPayments = props.paymentHistory.filter((elem) => PASS_PAYMENT_TYPE.includes(elem.type));

  return (
    <>
      <Tabs justify="start">
        <Tab title="Subscription">
          {dataSubscriptionPayments.length === 0 && 'No detail available'}
          {dataSubscriptionPayments.map((passElem) => (
            <ProfileTextWithLabel
              paymentsInfo
              key={passElem.date}
              label={passElem.type}
              text={<ProfilePaymentHistory lastPaymentInfo={passElem} />}
            />
          ))}
        </Tab>
        <Tab title="Passes">
          {dataPassPayments.length === 0 && 'No detail available'}
          {dataPassPayments.map((passElem) => (
            <ProfileTextWithLabel
              paymentsInfo
              key={passElem.date}
              label={passElem.type}
              text={<ProfilePaymentHistory lastPaymentInfo={passElem} />}
            />
          ))}
        </Tab>
        <Tab title="OneOff">
          {dataOneOffPayments.length === 0 && 'No detail available'}
          {dataOneOffPayments.map((passElem) => (
            <ProfileTextWithLabel
              paymentsInfo
              key={passElem.date}
              label={passElem.type}
              text={<ProfilePaymentHistory lastPaymentInfo={passElem} />}
            />
          ))}
        </Tab>
      </Tabs>
    </>
  );
};
export default ClientProfilePaymentHistoryTabs;
