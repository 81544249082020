import React from 'react';
import { connect } from 'react-redux';
import * as actions from '../actions';
import { returnArrayOfClassesDetailInSelectedGym } from '../../shared/classFunctions';
import { isFullAccessAdminOrTrainer, returnGymsByAccess } from '../../shared/userFunctions';
import { GYM_SETTINGS } from '../../constants';

export default (Component) => {
  const mapStateToProps = (state) => {
    return {
      globalQrReader: state.admin.globalQrReader,
      locations: state.location.locations,
      loading: state.location.loading,
      userData: state.auth.userData,

      gymDataLoading: state.admin.gymDataLoading,
      gymDataError: state.admin.gymDataError,
      selectedGym: state.admin.selectedGym,
      classesConfig:
        state.admin.selectedGym && state.admin.selectedGym.settings && state.admin.selectedGym.settings.classConfig
          ? state.admin.selectedGym.settings.classConfig
          : [],
      selectedGymSettings:
        state.admin.selectedGym && state.admin.selectedGym.settings ? state.admin.selectedGym.settings : GYM_SETTINGS,

      plansListLoading: state.admin.plansListLoading,
      plansListError: state.admin.plansListError,
      plansList: state.admin.plansList,

      gymUsersListLoading: state.admin.gymUsersListLoading,
      gymUsersListError: state.admin.gymUsersListError,
      allClientsList: state.admin.allClientsList,
      clientsList: state.admin.clientsList,

      gymsUsersFullData: state.admin.gymsUsersFullData,
      gymsUsersErrorData: state.admin.gymsUsersErrorData,
      gymsUsersFullDataLoading: state.admin.gymsUsersFullDataLoading,
      gymsUsersFullDataError: state.admin.gymsUsersFullDataError,

      adminsList: state.admin.adminsList,
      adminsListLoading: state.admin.adminsListLoading,
      adminsListError: state.admin.adminsListError,

      deletedAdmin: state.admin.deletedAdmin,
      deletedAdminLoading: state.admin.deletedAdminLoading,
      deletedAdminError: state.admin.deletedAdminError,

      trainersListLoading: state.admin.trainersListLoading,
      trainersListError: state.admin.trainersListError,
      trainersList: state.admin.trainersList,
      trainersFromAllGyms: state.admin.trainersFromAllGyms,

      selectedClient: state.admin.selectedClient,

      classesTemplatesListLoading: state.admin.classesTemplatesListLoading,
      classesTemplatesListError: state.admin.classesTemplatesListError,
      classesTemplatesList: state.admin.classesTemplatesList,

      upcomingPts: state.admin.upcomingPts,
      upcomingPtsLoading: state.admin.upcomingPtsLoading,
      upcomingPtsError: state.admin.upcomingPtsError,

      usersAllUpcomingClasses: state.client.usersAllUpcomingClasses,
      usersAllUpcomingClassesLoading: state.client.usersAllUpcomingClassesLoading,
      usersAllUpcomingClassesError: state.client.usersAllUpcomingClassesError,

      isAdmin: state.auth.userData && state.auth.userData.role === 'admin',
      isTrainer: state.auth.userData && state.auth.userData.role === 'trainer',
      isFullAccessUserData:
        state.auth.userData && state.auth.userData.role !== 'user'
          ? isFullAccessAdminOrTrainer(state.auth.userData)
          : false,

      classesDetailsInSelectedGym: returnArrayOfClassesDetailInSelectedGym(
        state.admin.selectedGym ? state.admin.selectedGym.settings : {}
      ),
      userGyms: returnGymsByAccess(state.location.locations, state.auth.userData)
    };
  };

  const mapDispatchToProps = (dispatch) => {
    return {
      onFetchLocations: () => dispatch(actions.fetchLocations()),
      onFetchGymDetails: (gymId) => dispatch(actions.fetchGymDetails(gymId)),
      onFetchGymAdmins: (gymId) => dispatch(actions.getAdmins(gymId)),
      onFetchPlansList: (gymId) => dispatch(actions.fetchPlansList(gymId)),
      onFetchGymUsersList: (gymId) => dispatch(actions.fetchGymUsersList(gymId)),
      onFetchTrainersList: (gymId) => dispatch(actions.fetchTrainersList(gymId)),
      onFetchClassesTemplatesList: (gymId) => dispatch(actions.fetchClassesTemplatesList(gymId)),
      onAdminFetchUpcomingPts: (gymId, params = null) => dispatch(actions.adminFetchUpcomingPts(gymId, params)),
      onFetchGymUsersFullData: (gymId) => dispatch(actions.fetchGymUsersFullData(gymId))
    };
  };

  class Wrapper extends React.Component {
    render() {
      return <Component {...this.props} />;
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Wrapper);
};
