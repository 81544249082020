import React, { useState, useEffect } from 'react';
import { Notification } from 'grommet';
import classes from './manageFAQ.module.scss';
import { withRouter } from 'react-router-dom';
import { fetchFAQs } from '../../apiFunctions/apiFunctions';
import { useQuery } from 'react-query';
import AddEditFAQ from './AddEditFAQ/AddEditFAQ';
import withUser from '../../store/hoc/withUser';
import Loading from '../../components/Loading/Loading';
import ListFAQ from './ListFAQ/ListFAQ';
import UploadFAQ from './UploadFAQ/UploadFAQ';
import { returnAvailableClassTypesInGym } from '../../shared/gymFunctions';
import CustomCheckboxes from '../../components/CustomCheckboxes/CustomCheckboxes';

const ManageFAQ = ({ isAdmin = false, selectedGym, selectedGymId, isUser }) => {
  const [showAddEditFaq, setShowAddEditFAQ] = useState(false);
  const [selectedFAQ, setSelectedFAQ] = useState(null);
  const [filters, setFilters] = useState([]);
  const [filteredData, setFilteredData] = useState(null);
  const [questionTypes, setQuestionTypes] = useState([]);
  const gymId = selectedGymId ? selectedGymId : selectedGym._id;
  const { data, isLoading, error } = useQuery('faq', () => fetchFAQs(gymId));

  useEffect(
    () => {
      setFilteredData(data);
      const questionTypesData = returnAvailableClassTypesInGym(selectedGym.settings).map(({ title, name }) => ({
        label: title,
        value: name.toLowerCase()
      }));
      if (!isUser) {
        questionTypesData.unshift({ label: 'Non customer', value: 'non customer' });
      }
      questionTypesData.unshift({ label: 'General', value: 'general' });
      setFilters(questionTypesData.map((elm) => elm.value));
      setQuestionTypes(questionTypesData);
    }, // eslint-disable-next-line
    [data]
  );

  useEffect(
    () => {
      if (data !== undefined && data.length > 0) {
        setFilteredData(data.filter((elm) => filters.includes(elm.questionType)));
      }
    }, // eslint-disable-next-line
    [filters]
  );

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <Notification message="Something went wrong. Please try again" status="critical" />;
  }

  if (showAddEditFaq) {
    return <AddEditFAQ selectedGym={selectedGym} selectedFAQ={selectedFAQ} onClose={() => setShowAddEditFAQ(false)} />;
  }

  return (
    <div data-cy="faq" className={classes.root}>
      <UploadFAQ isAdmin={isAdmin} setShowAddEditFAQ={setShowAddEditFAQ} setSelectedFAQ={setSelectedFAQ} />
      <h4>Filters:</h4>
      <CustomCheckboxes
        checkboxes={questionTypes}
        defaultCheckedAll
        onChange={setFilters}
        className={classes.checkboxes}
      />
      <ListFAQ data={filteredData} setShowAddEditFAQ={setShowAddEditFAQ} setSelectedFAQ={setSelectedFAQ} />
    </div>
  );
};

export default withRouter(withUser(ManageFAQ));
