import React from 'react';
import { Select, Label } from 'grommet';
import classes from './TemplateSelector.module.scss';
import { returnProductName } from '../../../shared/classFunctions';
import { useQuery } from 'react-query';
import { fetchTemplates } from '../../../apiFunctions/apiFunctions';
import Notification from 'grommet/components/Notification';
import Loading from '../../Loading/Loading';

const TemplateSelector = ({
  selectedTemplate,
  onSelectTemplate,
  classType,
  gymId,
  setStatus,
  isValid = null,
  fetchData = true
}) => {
  const { data, isLoading, error } = useQuery(
    ['class-template', classType, gymId],
    () => fetchTemplates(gymId, classType),
    {
      enabled: fetchData
    }
  );

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Notification message="Something went wrong. Please try again" status="critical" />;
  }

  if (data && data.length === 0) {
    return <div className={classes.bottomSpace}>Please add at least one class template first.</div>;
  }

  if (data && data.length > 0) {
    setStatus('ok');
    const classesTemplates = data.map((elem) => {
      return {
        ...elem,
        label: `${elem.name} - type: ${returnProductName(elem.classType)}`,
        value: elem
      };
    });

    return (
      <div className={classes.bottomSpace}>
        <Label>Session template</Label>
        <Select
          options={classesTemplates}
          value={selectedTemplate ? selectedTemplate.name : null}
          onChange={(...args) => onSelectTemplate(args[0].value)}
        />
        {isValid !== null && !isValid && <div className={classes.error}>Please select a template</div>}
      </div>
    );
  }
};

export default TemplateSelector;
