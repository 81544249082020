import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Modal from 'react-modal';
import GoogleApplePayButton from './GoogleApplePayButton';
import axios from '../../../axios-global';
import classes from './GoogleApplePayModal.module.scss';
import Loading from '../../../components/Loading/Loading';

const GoogleApplePayModal = ({ history, productType = 'class', product, selectedClass, classType, sourcesList }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentIntentResponse, setPaymentIntentResponse] = useState(null);

  const onWebPayHandler = async () => {
    setLoading(true);
    const response =
      productType === 'pass'
        ? await axios.post(`/payments/webPay/${product._id}`, {
            type: 'product'
          })
        : await axios.post(`/payments/webPay/${selectedClass._id}`, {
            type: 'class'
          });

    if (response) {
      setLoading(true);
      setPaymentIntentResponse(response);
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
    onWebPayHandler().then(() => {
      // eslint-disable-next-line
      setLoading(false);
    });
  };

  const content = (
    <div>
      <span
        style={{
          fontSize: '1.3em',
          paddingBottom: '10px',
          textAlign: 'center',
          display: 'block'
        }}>
        Choose payment method
      </span>
      <>
        <GoogleApplePayButton
          product={product}
          productType={productType}
          initWebPayment={paymentIntentResponse}
          selectedClass={selectedClass}
          classType={classType}
          closeModal={() => setShowModal(false)}
        />
        <CustomButton
          dataCy="addPaymentMethod"
          label="Add payment method"
          secondary
          className={classes.btn}
          onClick={() => history.push(`/gym/payment-methods`)}
        />
        <CustomButton className={classes.btn} label="Cancel" onClick={() => setShowModal(false)} />
      </>
    </div>
  );

  return (
    <div className={classes.root}>
      <Modal
        isOpen={showModal}
        className="ss-modal"
        onRequestClose={() => setShowModal(false)}
        style={{ padding: '30px 0px' }}>
        <div className="grommet" style={{ marginLeft: '35px !important' }}>
          {loading ? <Loading className={classes.spinningIcon} /> : <div>{content}</div>}
        </div>
      </Modal>
      {sourcesList && sourcesList.length > 0 ? (
        <CustomButton secondary label={'Pay'} onClick={handleOpenModal} />
      ) : (
        <CustomButton label="Add card" secondary onClick={() => history.push(`/gym/payment-methods`)} />
      )}
    </div>
  );
};

export default withRouter(GoogleApplePayModal);
