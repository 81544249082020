import React, { useState } from 'react';
import withAdminDashboard from '../../../../store/hoc/withAdminDashboard';
import ClientSelector from '../../../../components/Selectors/ClientSelector/ClientSelector';
import { useMutation } from 'react-query';
import { delayFetchFunctions, joinClass } from '../../../../apiFunctions/apiFunctions';
import { returnErrorFromResponse } from '../../../../shared/utility';
import CustomToast from '../../../../components/CustomToast/CustomToast';
import withGymsUsersFullData from '../../../../store/hoc/withGymsUsersFullData';
import { returnClientsListWithSamePTPasses } from '../../../../shared/gymFunctions';

const AddAttendee = ({
  singleClass: { _id, __t, duration, places },
  isLimitReached,
  gymsUsersFullData,
  activeAttendanceList = []
}) => {
  const [toast, setToast] = useState(null);
  let clients =
    gymsUsersFullData && gymsUsersFullData.length > 0
      ? gymsUsersFullData.filter((elem) => elem.status === 'active')
      : [];
  if (activeAttendanceList.length > 0) {
    clients = clients.filter(
      (client) => activeAttendanceList.filter((attendance) => attendance.user._id !== client._id).length > 0
    );
  }
  if (__t === 'personalTraining') {
    clients = returnClientsListWithSamePTPasses(clients, duration, places);
  }

  const mutateCreateAttendance = useMutation((user) => joinClass(_id, user._id), {
    onSuccess: () => {
      delayFetchFunctions(['singleClass'], 0);
      setToast({
        msg: `Client successfully added.`,
        status: 'ok',
        show: true
      });
    },
    onError: (err) => {
      setToast({
        msg: returnErrorFromResponse(err),
        status: 'critical',
        show: true
      });
    }
  });

  if (isLimitReached) {
    return null;
  }

  return (
    <div data-cy="clientList">
      <CustomToast toast={toast} />
      <h4 className="ss-list-header">ADD ATTENDEE</h4>
      <ClientSelector
        users={clients}
        setSelectedClient={mutateCreateAttendance.mutate}
        isValid={true}
        showLabel={false}
      />
    </div>
  );
};

export default withGymsUsersFullData(withAdminDashboard(AddAttendee));
