import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from '../../../../axios-global';
import * as actions from '../../../../store/actions';
import Notification from 'grommet/components/Notification';
import FormCreator from '../../../../components/UI/FormCreator/FormCreator';
import WhiteBackground from '../../../../hoc/WhiteBackground/WhiteBackground';
import { updateObject, checkValidity, returnErrorTextForField } from '../../../../shared/utility';
import { ALL_CURRENCIES } from '../../../../constants';
import classes from './AdminAddGym.module.scss';
import CustomButton from '../../../../components/CustomButton/CustomButton';

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchLocations: () => dispatch(actions.fetchLocations())
  };
};

class AdminAddGym extends Component {
  state = {
    currentStep: 0,
    formIsValid: false,
    formValidated: false,
    loading: false,
    error: null,
    newGym: {
      name: {
        label: 'Name',
        type: 'text',
        placeholder: 'Gym name',
        value: '',
        validation: {
          required: true,
          restrictSpecialCharacter: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      urlName: {
        label: 'Gym URL',
        type: 'text',
        placeholder: 'Gym URL name',
        value: '',
        validation: {
          required: true,
          restrictSpecialCharacterWithSpace: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      address: {
        label: 'Address',
        type: 'text',
        placeholder: 'Gym address',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      city: {
        label: 'City',
        type: 'text',
        placeholder: 'Gym city',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      postcode: {
        label: 'Postcode',
        type: 'text',
        placeholder: 'Gym postcode',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      currency: {
        label: 'Currency',
        type: 'select',
        options: ALL_CURRENCIES,
        placeholder: 'Select',
        value: ALL_CURRENCIES[0],
        validation: {
          required: true
        },
        valid: true,
        touched: false,
        errorText: null
      },
      termsUrl: {
        label: 'Terms URL',
        type: 'text',
        placeholder: 'Terms URL',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      }
    }
  };

  valueChangedHandler = (newValue, identifier) => {
    const updatedNewGym = updateObject(this.state.newGym, {
      [identifier]: updateObject(this.state.newGym[identifier], {
        value: newValue,
        valid: checkValidity(newValue, this.state.newGym[identifier].validation),
        errorText: returnErrorTextForField(this.state.newGym[identifier].validation),
        touched: true
      })
    });
    return this.setState({
      newGym: updatedNewGym,
      formValidated: false
    });
  };

  checkFormValidity = () => {
    this.setState({ formValidated: true });
    for (let input in this.state.newGym) {
      if (!this.state.newGym[input].valid) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        return this.setState({ formIsValid: false });
      }
    }

    this.setState({ formIsValid: true });

    this.saveNewGym();
  };

  saveNewGym = () => {
    this.setState({ loading: true });
    let gymData = {};

    for (let input in this.state.newGym) {
      gymData[input] = this.state.newGym[input].value;
    }
    gymData.currency = gymData.currency.value;

    axios
      .post('/gym/create', gymData)
      .then((response) => {
        this.setState({ loading: false });
        this.props.onFetchLocations();
        this.props.history.push(`/gym/${response.data._id}/settings`);
      })
      .catch((err) => {
        this.setState({ loading: false });
        this.setState({ error: err.message });
      });
  };

  render() {
    let formWarning = null;
    if (!this.state.formIsValid && this.state.formValidated) {
      formWarning = (
        <Notification message="Form is not filled in correctly" status="warning" className={classes.notification} />
      );
    }

    let error = null;
    if (this.state.error) {
      error = <Notification message={this.state.error} status="critical" className={classes.notification} />;
    }

    return (
      <WhiteBackground>
        <div className={classes.root}>
          <div className={classes.dashboardContainer}>
            {error}
            {formWarning}
            <FormCreator formData={this.state.newGym} valueChanged={this.valueChangedHandler} />
            <CustomButton
              isLoading={this.state.loading}
              label={'Next'}
              secondary={true}
              onClick={this.checkFormValidity}
            />
          </div>
        </div>
      </WhiteBackground>
    );
  }
}

export default connect(null, mapDispatchToProps)(AdminAddGym);
