import 'babel-polyfill';
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';

// imports needed to inject header to api requests
import axios, { setAuthHeader } from './axios-global';
import { authLogout, setGlobalError500, setGlobalNetworkError, setUnauthorizedError } from './store/actions/index';

// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import 'react-perfect-scrollbar/dist/css/styles.min.css';
import 'gridlex/dist/gridlex.css';
import './scss/grommet/grommet-supersonic/index.scss';
import './scss/supersonic/index.scss';

import SsApp from './SsApp';

// subscribing to store to add header to api requests
store.subscribe(() => setAuthHeader(store.getState().auth.token));

// interceptor listening to auth errors
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response === undefined) {
      store.dispatch(setGlobalNetworkError());
    }

    if (error.response && error.response.status === 401) {
      store.dispatch(authLogout());
      store.dispatch(setUnauthorizedError(true));
    }

    if (error.response && error.response.status === 500) {
      store.dispatch(setGlobalError500(true));
    }
    return Promise.reject(error);
  }
);

document.addEventListener('DOMContentLoaded', function () {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <SsApp />
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  );

  // registerServiceWorker();
  unregister();
});
