import React, { useState, useEffect } from 'react';
import classes from './SingleDay.module.scss';
import withUser from '../../../../store/hoc/withUser';
import DefaultTimetable from './DefaultTimetable';
import Loading from '../../../../components/Loading/Loading';
import Notification from 'grommet/components/Notification';
import Heading from 'grommet/components/Heading';
import CalendarHeader from '../../../../components/CalendarHeader/CalendarHeader';

const DefaultSingleDayTimetable = ({
  classesList,
  classesListLoading,
  classesListError,
  classesDetails,
  classConfig,
  showAllGymsData
}) => {
  const [currentDay, setCurrentDay] = useState(0);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);

  useEffect(() => {
    if (classesList && classesList.length > 0) {
      setCurrentDay(0);
      setHasPrevious(false);
      setHasNext(classesList.length > 1);
    }
  }, [classesList]);

  if (classesList === undefined || classesListLoading) {
    return <Loading />;
  }

  if (classesListError) {
    return <Notification message="Something went wrong. Please try again." status="critical" />;
  }

  const changeDay = (value) => {
    setCurrentDay(currentDay + value);
    setHasPrevious(currentDay + value >= 1);
    setHasNext(classesList.length - 1 > currentDay + value);
  };

  return (
    <>
      {classesList && !!classesList.length ? (
        <div data-testid="default-single-day-timetable" className={classes.root}>
          {classesList[currentDay] && (
            <CalendarHeader
              startDate={classesList[currentDay]._id}
              daysJumpValue={1}
              hasNext={hasNext}
              hasPrevious={hasPrevious}
              onLeftArrow={() => changeDay(-1)}
              onRightArrow={() => changeDay(1)}
            />
          )}
          {classesList[currentDay] && (
            <DefaultTimetable
              classesList={classesList[currentDay].classes}
              classesListLoading={classesListLoading}
              classesListError={classesListError}
              classType={classesDetails.type}
              classesDetails={classesDetails}
              classConfig={classConfig}
              showAllGymsData={showAllGymsData}
            />
          )}
        </div>
      ) : (
        <Heading tag="h3">No bookings</Heading>
      )}
    </>
  );
};

export default withUser(DefaultSingleDayTimetable);
