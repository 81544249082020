import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import utilsReducer from './reducers/utils';
import authReducer from './reducers/auth';
import locationReducer from './reducers/locations';
import adminReducer from './reducers/admin';
import clientReducer from './reducers/client';

const composeEnhancers =
  process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null || compose;

const rootReducer = combineReducers({
  utils: utilsReducer,
  auth: authReducer,
  location: locationReducer,
  admin: adminReducer,
  client: clientReducer
});

export const store = createStore(
  rootReducer,
  composeEnhancers ? composeEnhancers(applyMiddleware(thunk)) : applyMiddleware(thunk)
);
