import React, { useEffect, useState } from 'react';
import DateTime from 'grommet/components/DateTime';
import moment from 'moment';
import classes from './DateRangePicker.module.scss';

const DateRangePicker = ({
  startDate = null,
  endDate = null,
  onChange = () => {},
  onlyPast = false,
  onlyFuture = false
}) => {
  const [dateFrom, setDateFrom] = useState(startDate);
  const [isDateFromValid, setIsDateFromValid] = useState(true);
  const [dateTo, setDateTo] = useState(endDate);
  const [isDateToValid, setIsDateToValid] = useState(true);
  const [areBothDateValid, setAreBothDateValid] = useState(true);

  useEffect(
    () => {
      const isDateFromCorrect = checkIsDateValid(dateFrom);
      const isDateToCorrect = checkIsDateValid(dateTo);
      const areBothDatesCorrect =
        dateFrom && dateTo ? moment(dateFrom, 'YYYY-MM-DD').isSameOrBefore(moment(dateTo, 'YYYY-MM-DD')) : true;

      if (dateFrom && dateTo && isDateFromCorrect && isDateToCorrect && areBothDatesCorrect) {
        onChange({ dateFrom, dateTo });
      }
      setIsDateFromValid(isDateFromCorrect);
      setIsDateToValid(isDateToCorrect);
      setAreBothDateValid(areBothDatesCorrect);
    },
    //eslint-disable-next-line
    [dateFrom, dateTo]
  );

  const checkIsDateValid = (value) =>
    value
      ? onlyPast
        ? moment(value, 'YYYY-MM-DD').isSameOrBefore(moment())
        : onlyFuture
        ? moment(value, 'YYYY-MM-DD').isSameOrAfter(moment())
        : true
      : true;

  return (
    <div>
      <div className={classes.root}>
        <div>
          <h3>From:</h3>
          <DateTime format="YYYY-MM-DD" onChange={(value) => setDateFrom(value)} value={dateFrom} />
          {!isDateFromValid ? (
            <div className={classes.errorMsg}>Date has to be {onlyPast ? 'in the past' : 'in the future'}</div>
          ) : null}
        </div>
        <div>
          <h3>To:</h3>
          <DateTime format="YYYY-MM-DD" onChange={(value) => setDateTo(value)} value={dateTo} />{' '}
          {!isDateToValid ? (
            <div className={classes.errorMsg}>Date has to be {onlyPast ? 'in the past' : 'in the future'}</div>
          ) : null}
        </div>
      </div>
      <div className={[classes.errorMsg, classes.mainError].join(' ')}>
        {!areBothDateValid ? 'Date "From" has to be before date "To"' : ''}
      </div>
    </div>
  );
};

export default DateRangePicker;
