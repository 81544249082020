import React from 'react';
import { withRouter } from 'react-router-dom';
import classes from './ClassPage.module.scss';

import Loading from '../../components/Loading/Loading';

import { Notification } from 'grommet';
import { useQuery } from 'react-query';
import { fetchSingleClass } from '../../apiFunctions/apiFunctions';
import ClassDetails from './Components/ClassDetails/ClassDetails';
import withAdminDashboard from '../../store/hoc/withAdminDashboard';
import { returnClassConfig } from '../../shared/gymFunctions';
import ClassActionButtons from './Components/ClassActionButtons';
import ClassStatus from './Components/ClassStatus';
import ClassAttendees from './Components/ClassAttendees/ClassAttendees';

const ClassPage = ({ match, isAdmin, userData, selectedGym }) => {
  const classId = match.params.classId;
  const { data, isLoading, error } = useQuery(['singleClass', classId], () => fetchSingleClass(classId));

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <Notification message="Something went wrong." status="critical" />;
  }

  const classConfig = returnClassConfig(selectedGym.settings.classConfig, data.__t);

  return (
    <>
      <div className={classes.flex}>
        <ClassDetails singleClass={data} classConfig={classConfig} />
        <ClassActionButtons singleClass={data} userData={userData} isAdmin={isAdmin} classConfig={classConfig} />
      </div>
      <ClassStatus singleClass={data} />
      <ClassAttendees singleClass={data} />
    </>
  );
};

export default withRouter(withAdminDashboard(ClassPage));
