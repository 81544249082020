import React, { useState, useEffect } from 'react';
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import Toast from 'grommet/components/Toast';
import { returnClassPrice } from '../../../shared/classFunctions';
import withUser from '../../../store/hoc/withUser';
import Loading from '../../../components/Loading/Loading';
import { delayFetchFunctions, payForClass, payForPass } from '../../../apiFunctions/apiFunctions';
import { useMutation } from 'react-query';
import { returnErrorFromResponse } from '../../../shared/utility';

const GoogleApplePayButton = ({
  product,
  productType,
  initWebPayment,
  selectedClass,
  userData,
  classType,
  closeModal
}) => {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [toast, setToast] = useState({ show: false, msg: '', status: 'ok' });
  const [error, setError] = useState(null);
  const mutatePayForClass = useMutation(
    (stripePaymentId) => payForClass(selectedClass._id, stripePaymentId, 'webPay'),
    {
      onSuccess: () => {
        setToast({
          status: 'ok',
          msg: 'Successfully paid',
          show: true
        });
        delayFetchFunctions([
          ['upcomingClasses', selectedClass.__t],
          ['clientUpcomingClasses', selectedClass.__t],
          ['userData']
        ]);
      },
      onError: (err) => {
        setToast({
          status: 'critical',
          msg: returnErrorFromResponse(err),
          show: true
        });
      }
    }
  );

  const mutatePayForPass = useMutation(
    (stripeOrderId) => payForPass(product._id, stripeOrderId, 'webPay', product.currency),
    {
      onSuccess: () => {
        setToast({
          status: 'ok',
          msg: 'Successfully paid',
          show: true
        });
        delayFetchFunctions([['userData'], ['passes', classType]]);
        setTimeout(() => closeModal(), 1500);
      },
      onError: (err) => {
        setToast({
          status: 'critical',
          msg: returnErrorFromResponse(err),
          show: true
        });
      }
    }
  );

  useEffect(() => {
    setError(null);
    const label = productType === 'pass' ? product.name : selectedClass.__t;
    const amount = productType === 'pass' ? parseInt(product.cost) * 100 : returnClassPrice(selectedClass, userData);
    if (stripe && (product || selectedClass)) {
      try {
        const pr = stripe.paymentRequest({
          country: 'GB',
          currency: 'gbp',
          total: {
            label: label,
            amount: amount
          },
          requestPayerName: true,
          requestPayerEmail: true
        });

        pr.canMakePayment().then((result) => {
          if (result) {
            setPaymentRequest(pr);
          } else {
            setPaymentRequest(false);
          }
        });
      } catch (error) {
        setError(error);
      }
    }
  }, [stripe, product, productType, selectedClass, userData]);

  if ((initWebPayment === null || paymentRequest === null || mutatePayForClass.isLoading) && !error) {
    return <Loading />;
  }

  if (paymentRequest && initWebPayment) {
    paymentRequest.on('paymentmethod', function (ev) {
      // Confirm the PaymentIntent without handling potential next actions (yet).
      stripe
        .confirmCardPayment(
          initWebPayment.data.clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        )
        .then(function (confirmResult) {
          if (confirmResult.error) {
            // Report to the browser that the payment failed, prompting it to re-show the payment interface, or show an error message and close the payment interface.
            ev.complete('fail');
            setToast({
              show: true,
              msg: 'Something went wrong',
              status: 'critical'
            });
          } else {
            // Report to the browser that the confirmation was successful, prompting
            // it to close the browser payment method collection interface.
            ev.complete('success');
            if (confirmResult.paymentIntent.status === 'requires_action') {
              stripe.confirmCardPayment(initWebPayment.data.clientSecret).then(function (result) {
                if (result.error) {
                  setToast({
                    show: true,
                    msg: 'Something went wrong',
                    status: 'critical'
                  });
                } else {
                  // The payment has succeeded. Recording payment in backend system
                  if (productType === 'pass') {
                    mutatePayForPass.mutate(confirmResult.paymentIntent.id);
                  } else {
                    mutatePayForClass.mutate(confirmResult.paymentIntent.id);
                  }
                }
              });
            } else {
              // The payment has succeeded. Recording payment in backend system
              if (productType === 'pass') {
                mutatePayForPass.mutate(confirmResult.paymentIntent.id);
              } else {
                mutatePayForClass.mutate(confirmResult.paymentIntent.id);
              }
            }
          }
        });
    });
    return (
      <div style={{ margin: '10px 0' }}>
        {toast.show && (
          <Toast status={toast.status} onClose={() => setToast({ show: false })}>
            {toast.msg}
          </Toast>
        )}

        <div style={{ width: '100%' }}>
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </div>
      </div>
    );
  }

  if (!paymentRequest) {
    return null;
  }
};

export default withUser(GoogleApplePayButton);
