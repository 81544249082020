import React, { useState } from 'react';
import classes from '../ClassPage.module.scss';
import CancelAttendanceOrClass from '../../ActionButtons/ClassActionButtons/CancelAttandanceOrClass/CancelAttendanceOrClass';
import ActivateClass from '../../ActionButtons/ActivateClass/ActivateClass';
import ExportToCSV from '../../AdminDashboard/ExportToCSV/ExportToCSV';
import Modal from 'react-modal';
import AddEditSingleClass from '../../AddEditSingleClass/AddEditSingleClass';
import CustomButton from '../../../components/CustomButton/CustomButton';
import MoveClientsBetweenClasses from '../../ActionButtons/MoveClientsBetweenClasses/MoveClientsBetweenClasses';

const ClassActionButtons = ({ isAdmin, classConfig, userData, singleClass }) => {
  const isTrainer = userData.role === 'trainer';
  const [showModal, setShowModal] = useState(false);
  return (
    <div>
      <Modal isOpen={showModal} className="ss-modal" onRequestClose={() => setShowModal(false)}>
        <div className="grommet">
          <AddEditSingleClass
            classType={singleClass.__t}
            classConfig={classConfig}
            selectedClass={singleClass}
            onClose={() => setShowModal(false)}
          />
        </div>
      </Modal>
      <div>
        <div className={classes.flex}>
          <CustomButton label="Edit" onClick={() => setShowModal(true)} dataCy="editBtn" />

          {(isAdmin || isTrainer) && (
            <CancelAttendanceOrClass
              cancelType="singleClass"
              type={'cancelClassButton'}
              selectedClass={singleClass}
              user={userData}
              attendance={singleClass.attendanceList}
            />
          )}
          {(isAdmin || isTrainer) && !singleClass.active && <ActivateClass classId={singleClass._id} />}
        </div>
        <ExportToCSV data={singleClass} type={'classDetail'} gymName={singleClass.gymId.name} fullWidth />
        <MoveClientsBetweenClasses isAdmin currentClass={singleClass} />
      </div>
    </div>
  );
};

export default ClassActionButtons;
