import React, { useState } from 'react';

import { Notification } from 'grommet';
import { useQuery } from 'react-query';
import { fetchPasses, fetchVideoPasses } from '../../apiFunctions/apiFunctions';
import { returnClassesDetails, returnProductTypeName } from '../../shared/classFunctions';
import withUser from '../../store/hoc/withUser';
import Title from '../../components/UI/Title/Title';
import AddEditPass from './AddEditPass/AddEditPass';
import Loading from '../../components/Loading/Loading';
import TablePassesView from './ShowPasses/TablePassesView/TablePassesView';
import SinglePassView from './ShowPasses/SinglePassesView/SinglePassesView';
import { CLASS } from '../../constants';
import Heading from 'grommet/components/Heading';
import { store } from '../../store';
import { updateVideoProducts } from '../../store/actions/client';
import classes from './ManagePasses.module.scss';
import CustomButton from '../../components/CustomButton/CustomButton';

const ManagePasses = ({ classType, userData, selectedGym, isAdmin, isUser, noTitle = false }) => {
  const [showAddEditPass, setShowAddEditPass] = useState(false);
  const [selectedPass, setSelectedPass] = useState(null);
  const gymId = userData.role === 'user' ? userData.gymId : selectedGym._id;
  const { data, isLoading, error } = useQuery(['passes', classType], () => fetchPasses(classType, gymId), {
    enabled: classType !== CLASS.INDUCTION // no class passes for inductions
  });
  const {
    data: videoPasses,
    isLoading: videoIsLoading,
    error: videoError
  } = useQuery(['videoPasses', classType], () => fetchVideoPasses({ gymId }), {
    enabled: classType !== CLASS.VIDEO,
    onSuccess: (data) => {
      store.dispatch(updateVideoProducts(data));
    }
  });
  const classesDetails = returnClassesDetails(classType);

  if (isLoading || videoIsLoading) {
    return <Loading size={'medium'} />;
  }
  if (error || videoError) {
    return <Notification message={error.message} status="critical" />;
  }

  const videoPassesForClass = videoPasses ? videoPasses.filter(({ forClass }) => forClass === classType) : [];

  const addNewPassButton = isAdmin && (
    <CustomButton
      dataCy="add_btn"
      position={'right'}
      label={'Add'}
      secondary={true}
      onClick={() => {
        setSelectedPass(null);
        setShowAddEditPass(true);
      }}
    />
  );
  const title = isUser && !noTitle && (
    <Title
      header={classesDetails.title + ' Passes'}
      subHeader={`Please purchase your ${
        returnProductTypeName(classesDetails.productType).toLowerCase() || 'passes'
      } below${
        classesDetails.type === CLASS.PERSONAL_TRAINING ? ' and contact your trainer to book your session.' : '.'
      }`}
    />
  );

  const displayPasses = () => {
    if ((!data || (data && !data.length)) && !videoPassesForClass.length) {
      return <h3>No passes</h3>;
    }

    if (isAdmin) {
      return (
        <TablePassesView
          data={data}
          classType={classType}
          setShowAddEditPass={setShowAddEditPass}
          setSelectedPass={setSelectedPass}
        />
      );
    }

    return (
      <div>
        {!!videoPassesForClass.length && (
          <div className={classes.vodStyles}>
            <Heading tag="h3">Video on Demand Passes</Heading>
            <SinglePassView data={videoPassesForClass} classType={classType} />
          </div>
        )}
        {![CLASS.VIDEO, CLASS.INDUCTION].includes(classType) && !!data.length && (
          <>
            <SinglePassView data={data} classType={classType} />
          </>
        )}
      </div>
    );
  };

  if (showAddEditPass) {
    return <AddEditPass classType={classType} selectedPass={selectedPass} onClose={() => setShowAddEditPass(false)} />;
  }

  return (
    <div data-cy={classType + '_passes'}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {title}
        {addNewPassButton}
      </div>
      {displayPasses()}
    </div>
  );
};

export default withUser(ManagePasses);
