import React, { useState } from 'react';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Notification from 'grommet/components/Notification';
import classes from './ManageMembershipPlans.module.scss';
import { getCurrencySymbol } from '../../../shared/utility';
import Icons from '../../../components/UI/Icons/Icons';
import Modal from 'react-modal';
import AddEditMembershipPlan from '../../../containers/AdminDashboard/ManageMembershipPlans/AddEditMembershipPlan/AddEditMembershipPlan';
import Loading from '../../../components/Loading/Loading';
import { withRouter } from 'react-router-dom';

const ManageMembershipPlans = ({ loading, error, plansList, match, history }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <Notification message={error} status="critical" />;
  }

  const renderData = () => {
    const header = ['Name', 'Price', 'Active', 'Classes', 'Display Order', 'Action'];
    const item = (plan) => (
      <div key={plan._id} className={[classes.grid, classes.item].join(' ')}>
        <div className={classes.name}>{plan.name}</div>
        <div>
          <span className={classes.label}>Price:</span>
          {getCurrencySymbol(plan.currency)} {plan.price}
        </div>
        <div>
          <span className={classes.label}>Active:</span>
          {plan.active ? 'Yes' : 'No'}
        </div>
        <div>
          <span className={classes.label}>Name:</span>
          {plan.unlimited ? 'Unlimited' : plan.classesIncluded ? plan.classesIncluded : '-'}
        </div>
        <div>
          <span className={classes.label}>Display order:</span>
          {plan.displayOrder}
        </div>
        <div className={classes.action}>
          <Icons
            edit
            editClick={() => {
              setSelectedPlan(plan);
              setShowModal(true);
            }}
          />
        </div>
      </div>
    );

    return (
      <div className={classes.root}>
        <div className={['table-header', classes.grid, classes.header].join(' ')}>
          {header.map((elm) => (
            <div key={elm}>{elm}</div>
          ))}
        </div>
        <div className={classes.dataContainer}>{plansList.map((elem) => item(elem))}</div>
      </div>
    );
  };

  return (
    <div data-cy={'membership_plans'}>
      <Modal onRequestClose={() => setShowModal(false)} isOpen={showModal} className="ss-modal ss-modal--auto">
        <div className="grommet">
          <AddEditMembershipPlan editedPlan={selectedPlan} />
        </div>
      </Modal>
      <CustomButton
        label="Add"
        secondary={true}
        onClick={() => history.push(`${match.url}/add`)}
        className={classes.addButton}
      />
      {plansList.length ? renderData() : <h4>No plans</h4>}
    </div>
  );
};
export default withRouter(ManageMembershipPlans);
