import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useQuery } from 'react-query';
import { withRouter } from 'react-router-dom';
import Loading from '../../../../components/Loading/Loading';
import Notification from 'grommet/components/Notification';
import Heading from 'grommet/components/Heading';
import { fetchVideoById } from '../../../../apiFunctions/apiFunctions';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import { getVideoKey } from '../../../../constants';
import withUser from '../../../../store/hoc/withUser';
import classes from './Viewer.module.scss';

const Viewer = ({ videoId, history, userData }) => {
  const [watched, setWatched] = useState(false);
  const {
    data: video,
    isLoading,
    error
  } = useQuery(videoId, () => fetchVideoById(videoId), {
    enabled: !!videoId && !!videoId.length
  });

  useEffect(() => {
    if (!videoId || !videoId.length) return;

    const userId = userData?._id || '';
    const USER_VOD = getVideoKey(userId);
    const persisted = localStorage.getItem(USER_VOD);
    const watchedVideos = persisted ? JSON.parse(persisted) : {};

    if (watched && !watchedVideos[videoId]) {
      return localStorage.setItem(USER_VOD, JSON.stringify({ ...watchedVideos, [videoId]: {} }));
    }

    if (watchedVideos[videoId]) {
      setWatched(true);
    }
  }, [userData?._id, videoId, watched]);

  if (error || !videoId || !videoId.length) {
    return <Notification message="Something went wrong. Please try again" status="critical" />;
  }
  if (isLoading || !video) {
    return <Loading />;
  }

  const HLSFiles = video.media && video.media.find((media) => media.type === 'HLS');
  const sources = HLSFiles.playlistUrl ? [HLSFiles.playlistUrl] : [];
  let length;

  if (video.videoConfig) {
    const lengthConfig = video.videoConfig.find((config) => config.name === 'Length');
    if (lengthConfig && lengthConfig.options && lengthConfig.options.length) {
      length = lengthConfig.options[0];
    }
  }

  const onVideoWatched = () => {
    setWatched(true);
    history.goBack();
  };

  return (
    <>
      <VideoPlayer sources={sources} onVideoComplete={onVideoWatched} />
      <div className={classes.titleBar}>
        <Heading tag="h2">{video.title || ''}</Heading>
        <div className={classes.dateLength}>
          <Heading tag="h6">{moment(video.updatedDate).format('LL') || ''}</Heading>
          <Heading tag="h6">{length || ''}</Heading>
        </div>
      </div>
    </>
  );
};

export default withUser(withRouter(Viewer));
