import React from 'react';

import Heading from 'grommet/components/Heading';
import Paragraph from 'grommet/components/Paragraph';

export default () => (
  <>
    <h1>PRIVACY POLICY</h1>
    <Paragraph>
      motive8 Group ("we" and "us") respect the privacy of our users ("you"), therefore have developed the following
      Privacy Policy to show our commitment to protecting your privacy. If you have any questions about this Privacy
      Policy please do not hesitate to contact us on 0800 028 0198.
    </Paragraph>

    <Heading tag="h3">1. INTRODUCTION AND DEFINITIONS</Heading>
    <Paragraph>
      Motive8 are strongly committed to protecting personal data. This privacy statement describes why and how we
      collect and use personal data and provides information about individuals’ rights. It applies to personal data
      provided to us, both by individuals themselves or by others. We may use personal data provided to us for any of
      the purposes described in this privacy statement or as otherwise stated at the point of collection.
    </Paragraph>
    <Paragraph>
      Personal data is any information relating to an identified or identifiable living person. Motive8 processes
      personal data for numerous purposes, and the means of collection, lawful basis of processing, use, disclosure, and
      retention periods for each purpose may differ.
    </Paragraph>
    <Paragraph>
      When collecting and using personal data, our policy is to be transparent about why and how we process personal
      data.
    </Paragraph>

    <Heading tag="h3">2. SECURITY</Heading>
    <Paragraph>
      We take the security of all the data we hold very seriously and are registered with the ICO as a data controller.
      We have a framework of policies, procedures and training in place covering data protection, confidentiality and
      security and regularly review the appropriateness of the measures we have in place to keep the data we hold
      secure.
    </Paragraph>

    <b>WHEN AND HOW WE SHARE PERSONAL DATA AND LOCATIONS OF PROCESSING</b>
    <Paragraph>
      We will only share personal data with others when we are legally permitted to do so. When we share data with
      others, we put contractual arrangements and security mechanisms in place to protect the data and to comply with
      our data protection, confidentiality and security standards.
    </Paragraph>

    <b>PERSONAL DATA HELD BY US MAY BE TRANSFERRED TO</b>
    <ul>
      <li>
        Estate Management firms who contract us to perform inductions at their on-site gyms for private residential
        facilities. Only data for their specific site they are contracted to manage would be shared.
      </li>
      <li>
        Estate Management firms who have an agreement with us to host a booking platform to manage their gym bookings.
        Only data for their specific site they are contracted to manage would be shared.
      </li>
      <li>
        Corporate clients who contract us to perform fitness or wellbeing related services at their offices. Only data
        related to their employees would be shared.
      </li>
      <li>
        Third party organisations that provide applications/functionality, data processing or IT services to us
        <ul>
          <li>
            {' '}
            We use third parties to support us in providing our services and to help provide, run and manage our
            internal IT systems. For example, providers of information technology, cloud based software as a service
            providers, website hosting and management, data back-up, security and storage services. The servers powering
            and facilitating that cloud infrastructure are located in secure data centers in the UK.
          </li>
        </ul>
      </li>
      <li>Third party organisations that otherwise assist us in providing goods, services or information</li>
      <li>Auditors and other professional advisers</li>
      <li>
        Law enforcement or other government and regulatory agencies or to other third parties as required by, and in
        accordance with, applicable law or regulation
      </li>
    </ul>

    <b>CHANGES TO THIS PRIVACY</b>
    <Paragraph>
      We recognise that transparency is an ongoing responsibility so we will keep this privacy statement under regular
      review.
    </Paragraph>
    <Paragraph>This privacy statement was last updated on 27 July 2020.</Paragraph>

    <b>DATA CONTROLLER AND CONTACT INFORMATION</b>
    <Paragraph>
      The data controller is motive8 Ltd under registration no. Z3142378 and with its registration address at 65 High
      Street, Teddington, TW11 8HA.
    </Paragraph>
    <Paragraph>
      If you have any questions about this privacy statement or how and why we process personal data, please contact us
      at:
    </Paragraph>
    <Paragraph>
      Data Protection Officer
      <br />
      Motive8 Ltd
      <br />
      First Floor
      <br />
      65 High Street
      <br />
      Teddington
      <br />
      TW11 8HA
      <br />
      Email: dataprotection@m8group.co.uk
      <br />
      Phone: 020 8481 9700
      <br />
    </Paragraph>

    <b>INDIVIDUALS'S RIGHTS AND HOW TO EXERCISE THEM</b>
    <Paragraph>
      Individuals have certain rights over their personal data and data controllers are responsible for fulfilling these
      rights. Where we decide how and why personal data is processed, we are a data controller and include further
      information about the rights that individuals have and how to exercise them below.
    </Paragraph>
    <ul>
      <li>
        Access to personal data
        <ul>
          <li>
            You have a right of access to personal data held by us as a data controller. This right may be exercised by
            emailing us at dataprotection@m8group.co.uk We may charge for a request for access in accordance with
            applicable law. We will aim to respond to any requests for information promptly, and in any event within the
            legally required time limits (currently 40 days).
          </li>
        </ul>
      </li>
      <li>
        Amendment of personal data
        <ul>
          <li>
            To update personal data submitted to us, you may email us at dataprotection@m8group.co.uk or, where
            applicable by amending the personal details held on relevant applications with which you registered.
          </li>
          <li>
            When practically possible, once we are informed that any personal data processed by us is no longer
            accurate, we will make corrections (where appropriate) based on your updated information.
          </li>
        </ul>
      </li>
      <li>
        Withdrawal of consent
        <ul>
          <li>
            Where we process personal data based on consent, individuals have a right to withdraw consent at any time.
            To withdraw consent to our processing of your personal data please email us at dataprotection@m8group.co.uk
            or, to stop receiving an email from a motive8 marketing list, please click on the unsubscribe link in the
            relevant email received from
          </li>
        </ul>
      </li>
      <li>
        Other data subject rights
        <ul>
          <li>
            This privacy statement is intended to provide information about what personal data we collect about you and
            how it is used. As well as rights of access and amendment referred to above, individuals may have other
            rights in relation to the personal data we hold, such as a right to erasure/deletion, to restrict or object
            to our processing of personal data and the right to data portability. Some of these rights will only be
            available from 25 May 2018.
          </li>
          <li>If you wish to exercise any of these rights, please send an email to dataprotection@m8group.co.uk</li>
        </ul>
      </li>
      <li>
        Complaints
        <ul>
          <li>
            We hope that you won’t ever need to, but if you do want to complain about our use of personal data, please
            send an email with the details of your complaint to dataprotection@m8group.co.uk We will look into and
            respond to any complaints we receive.
          </li>
          <li>
            {' '}
            You also have the right to lodge a complaint with the Information Commissioner's Office (“ICO”) (the UK data
            protection regulator). For further information on your rights and how to complain to the ICO, please refer
            to the ICO website,{' '}
            <a href="https://ico.org.uk/for-the-public/raising-concerns/">
              https://ico.org.uk/for-the-public/raising-concerns/
            </a>
          </li>
        </ul>
      </li>
    </ul>

    <Heading tag="h3">3. INFORMATION WE COLLECT FROM YOU</Heading>
    <ol>
      <li>We will collect and process the following Personal Data (and other data):</li>

      <ul style={{ marginBottom: 0 }}>
        <li>
          <strong>Information you give us.</strong> This is information about you that you give us by filling in forms
          on the Website (including health questionnaires which may include sensitive personal data) or by corresponding
          with us by phone, e-mail or otherwise. It includes information you provide when you register to use the
          Website, subscribe to our service, search for a product, place an order on the Website, participate in
          discussion boards or other social media functions on our site, enter a competition, promotion or survey, click
          and use links provided throughout, use and engage with the website in any way and when you report a problem
          with the Website. The information you give us may include your name, address, e-mail address and phone number,
          financial and credit card information, personal description and photograph;
        </li>
        <li>
          <strong>Information we collect about you.</strong> With regard to each of your visits to the Website we will
          automatically collect the following information:
          <br />- technical information, including the Internet protocol (IP) address used connect your computer or
          alternative device to the Internet, your login information, browser type and version, time zone setting,
          browser plug-in types and versions, operating system and platform; and
          <br />- information about your visit, including the full Uniform Resource Locators (“URL”), clickstream to,
          through and from the Website (including date and time), products you viewed or searched for’ page response
          times, download errors, length of visits to certain pages, page interaction information (such as scrolling,
          clicks, and mouse-overs), methods used to browse away from the page, and any phone number used to call our
          customer service number.
        </li>
      </ul>

      <li>
        <strong>Information we receive from other sources.</strong> This is information we receive about you if you use
        any of the other websites we operate or the other services we provide. We are working closely with third parties
        (including, for example, business partners, sub-contractors in payment and delivery services). We will notify
        you when we receive information about you from them and the purposes for which we intend to use that
        information.
      </li>
    </ol>

    <Heading tag="h3">4. USES MADE OF THE INFORMATION</Heading>
    <ol>
      <li>We use information held about you in the following ways:</li>

      <ul style={{ marginBottom: 0 }}>
        <li>
          <strong>Information you give to us.</strong> We will use this information:
          <br />- to carry out our obligations arising under the Terms of Use;
          <br />- to provide you with information about other goods and services that are similar to those that you have
          already purchased, enquired about or otherwise engaged with;
          <br />- to notify you about changes to the Website; and
          <br />- to ensure that content from the Website is presented in the most effective manner for you and for your
          computer or internet enabled device.
        </li>
      </ul>

      <li>
        <strong>Information we collect about you.</strong> We will use this information:
      </li>

      <ul style={{ marginBottom: 0 }}>
        <li>
          to administer the Website and for internal operations, including troubleshooting, data analysis, testing,
          research, statistical and survey purposes;
        </li>
        <li>
          to improve the Website to ensure that content is presented in the most effective manner for you and for your
          computer or internet enabled device;
        </li>
        <li>to allow you to participate in interactive features of our service, when you choose to do</li>
        <li>as part of our efforts to keep the Website safe and secure;</li>
        <li>
          to measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant
          advertising to you;
        </li>
        <li>
          to make suggestions and recommendations to you and other users of the Website about goods or services that may
          interest you or them.
        </li>
      </ul>

      <li>
        <strong>Information we receive from other sources.</strong> We will combine this information with information
        you give to us and information we collect about you. We will use this information and the combined information
        for the purposes set out above (depending on the types of information we receive).
      </li>
    </ol>

    <Heading tag="h3">5. DISCLOSURE OF YOUR INFORMATION</Heading>
    <ol>
      <li>
        We will disclose your personal information and Personal Data to third parties (including companies in our
        group):
      </li>

      <ul style={{ marginBottom: 0 }}>
        <li>to give effect to clause 3.1 above;</li>
        <li>
          in the event that we sell or buy any business or assets, in which case we will disclose your Personal Data to
          the prospective seller or buyer of such business or assets.
        </li>
        <li>
          if we are acquired by a third party, in which case Personal Data held by it about its customers will be one of
          the transferred assets.
        </li>
        <li>
          if we are under a duty to disclose or share your Personal Data in order to comply with any legal obligation,
          or in order to enforce or apply Terms of Use and other agreements; or to protect the rights, property, or
          safety of the Website, our customers, or others. This includes exchanging information with other companies and
          organisations for the purposes of fraud protection and credit risk reduction.
        </li>
      </ul>
    </ol>

    <Heading tag="h3">6. WHERE WE STORE YOUR PERSONAL DATA</Heading>
    <ol>
      <li>
        By submitting your Personal Data, you agree to this transfer, storing or processing. We will take all steps
        reasonably necessary to ensure that your Personal Data is treated securely and in accordance with this privacy
        policy.
      </li>
      <li>
        All information you provide to us is stored on our secure servers. Any payment transactions will be encrypted
        using SSL technology. Where we have given you (or where you have chosen) a password which enables you to access
        certain parts of the Website, you are responsible for keeping this password confidential. We ask you not to
        share a password with anyone.
      </li>
      <li>
        Unfortunately, the transmission of information via the internet is not completely secure. Although we will do
        our best to protect your Personal Data, we cannot guarantee the security of your Personal Data transmitted to
        the Website; any transmission is at your own risk. Once we have received your information, we will use strict
        procedures and security features to try to prevent unauthorised access.
      </li>
    </ol>

    <Heading tag="h3">7. YOUR RIGHTS</Heading>
    <ol>
      <li>
        You have the right to ask us not to process your Personal Data for marketing purposes. We will usually inform
        you (before collecting your Personal Data) if we intend to use your Personal Data for such purposes or if we
        intend to disclose your information to any third party for such purposes. You can exercise your right to prevent
        such processing by checking certain boxes in the account area where you can control your Personal Data options
        and where required on the forms we use to collect your Personal Data. You can also exercise the right at any
        time by contacting us using the contact details set out in the Terms of Use.
      </li>
      <li>
        The Website may from time to time, contain links to and from the sites of our partner networks, advertisers and
        affiliates. If you follow a link to any of these websites, please note that these websites have their own
        privacy policies and that we do not accept any responsibility or liability for these policies. Please check
        these policies before you submit any Personal Data to these websites.
      </li>
      <li>
        Please refer to www.dataprotection.gov.uk for further information about your rights under the Act. You have the
        right to receive a copy of the information we hold about you by sending a request in writing (with a fee of £10)
        to us using the contact details set out in the terms of use.
      </li>
    </ol>

    <Heading tag="h3">8. COOKIES</Heading>
    <ol>
      <li>
        The Website uses cookies to function and distinguish you from other users of the Website. This helps us to
        provide you with a good experience when you browse the Website and also allows us to improve. For detailed
        information on the cookies we use and the purposes for which we use them see our Cookie Policy;
        https://bookings.m8group.co.uk/cookie-policy
      </li>
      <li>
        For more information about Cookies and how they are used please refer to
        https://ico.org.uk/for-the-public/online/cookies/
      </li>
    </ol>

    <Heading tag="h3">9. CHANGES TO OUR PRIVACY POLICY</Heading>
    <ol>
      <li>
        Any changes we make to our privacy policy in the future will be posted on this page and, where appropriate,
        notified to you by e-mail. Please check back frequently to see any updates or changes to our privacy policy.
      </li>
    </ol>

    <br />
    <h1>DATA PROTECTION POLICY</h1>

    <Heading tag="h3">1. INTRODUCTION AND DEFINITIONS</Heading>
    <ol>
      <li>
        This is the Data Protection Policy for motive8 North Limited (company number 07487095) of First Floor, 65 High
        Street, Teddington, Middlesex, TW11 8HA.
      </li>
      <li>The nominated Data Protection Officer is Jenny Cromack.</li>
      <li>
        ‘’Customer’’. Any person who signs up for the services offered by motive8 North such as exercise classes,
        personal training, gym membership in addition to voluntarily signing up for online offers and newsletters
        through the website.
      </li>
      <li>‘’Website’’. The website is bookings.m8group.co.uk.</li>
    </ol>

    <Heading tag="h3">2. DATA PROTECTION STATEMENT</Heading>
    <ol>
      <li>
        To provide you with the correct advice and support in relation to our facilities and the services we offer, we
        need to hold and process certain personal data relating to you, including your identity, contact details and
        sensitive information relating to your health.
      </li>
      <li>
        By joining our gym,or signing up for any of our services including personal training, classes, boot camps,you
        consent to allowing us to process this information.
      </li>
    </ol>

    <Heading tag="h3">3. TERMS OF USE – WEBSITE</Heading>
    <ol>
      <li>The term “Website” means the website accessible from this URL, being bookings.m8group.co.uk</li>
      <li>Other defined terms shall have the meanings given to them in the Terms of Use.</li>
      <li>
        The terms “Data Controller” and “Personal Data” shall have the meanings given to them in the Data Protection Act
        1998 (the “Act”).
      </li>
      <li>
        For the purposes of the Act, the Data Controller shall be motive8 North Limited (company number 07487095) of
        First Floor, 65 High Street, Teddlington, Middlesex, TW11 8HA
      </li>
      <li>
        The privacy policy (together with our terms of and any other documents referred to in it) sets out the basis on
        which any Personal Data we collect from you, or that you provide to us, will be processed by us. Our privacy
        policy can be found at https://bookings.m8group.co.uk/terms-conditions
      </li>
      <li>
        Please read the following carefully to understand our views and practices regarding your Personal Data and how
        we will treat it. By visiting the Website, you are accepting and consenting to the practices described in this
        policy. If you have any questions at all about this privacy policy or the Website, please email
        jenny.cromack@m8group.co.uk.
      </li>
      <li>Our nominated representative for the purpose of the Act is Ms Jenny Cromack.</li>
    </ol>

    <Heading tag="h3">4. INFORMATION WE COLLECT FROM YOU</Heading>
    <ol>
      <li>We will collect and process the following Personal Data (and other data):</li>

      <ul style={{ marginBottom: 0 }}>
        <li>
          Information you give us. This is information about you that you give us by filling in forms in our gym or on
          the Website (including health questionnaires which may include sensitive personal data) or by corresponding
          with us by phone, e-mail or otherwise. It includes information you provide when you register to use the
          Website, subscribe to our services, place an order on the Website, participate in discussion boards or other
          social media functions on our site, enter a competition, promotion or survey, click and use links provided
          throughout, use and engage with the website in any way and when you report a problem with the Website. The
          information you give us may include your name, address, e-mail address and phone number, financial and credit
          card information, personal description and photograph;
        </li>
        <li>
          Information we collect about you. With regard to each of your visits to the Website we will automatically
          collect the following information:
          <br /> - technical information, including the Internet protocol (IP) address used to connect your computer or
          alternative device to the Internet, your login information, browser type and version, time zone setting,
          browser plug-in types and versions, operating system and platform; and
          <br /> - information about your visit, including the full Uniform Resource (“URL”), clickstream to, through
          and from the Website (including date and time), products you viewed or searched for’ page response times,
          download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks,
          and mouse-overs), methods used to browse away from the page, and any phone number used to call our customer
          service number.
        </li>
        <li>
          Information we receive from other sources. This is information we receive about you if you use any of the
          other websites we operate or the other services we provide. We are working closely with third parties
          (including, for example, business partners, sub-contractors in payment and delivery services). We will notify
          you when we receive information about you from them and the purposes for which we intend to use that
          information.
        </li>
      </ul>
    </ol>

    <Heading tag="h3">5. USES MADE OF THE INFORMATION</Heading>
    <ol>
      <li>We use information held about you in the following ways:</li>
      <ul style={{ marginBottom: 0 }}>
        <li>
          Information you give to us. We will use this information:
          <br />- to carry out our obligations arising under the Terms of Use;
          <br />- to provide you with information about other goods and services that are similar to those that you have
          already purchased, enquired about or otherwise engaged with;
          <br />- to notify you about changes to the Website; and
          <br />- to ensure that content from the Website is presented in the most effective manner for you and for your
          computer or internet enabled device.
        </li>
      </ul>
      <li>Information we collect about you. We will use this information:</li>
      <ul style={{ marginBottom: 0 }}>
        <li>to supply our gym membership services including classes, personal training and gym memberships;</li>
        <li>
          to administer the Website and for internal operations, including troubleshooting, data analysis, testing,
          research, statistical and survey purposes;
        </li>
        to improve the Website to ensure that content is presented in the most effective manner for you and for your
        computer or internet enabled device;
        <li>to allow you to participate in interactive features of our service, when you choose to do so;</li>
        as part of our efforts to keep the Website safe and secure;
        <li>
          to measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant
          advertising to you;
        </li>
        <li>
          to make suggestions and recommendations to you and other users of the Website about goods or services that may
          interest you or them.
        </li>
      </ul>

      <li>
        Information we receive from other sources. We will combine this information with information you give to us and
        information we collect about you. We will use this information and the combined information for the purposes set
        out above (depending on the types of information we receive).
      </li>
    </ol>

    <Heading tag="h3">6. DISCLOSURE OF YOUR INFORMATION</Heading>
    <ol>
      <li>
        We will disclose your personal information and Personal Data to third parties (including companies in our
        group):
      </li>
      <ul style={{ marginBottom: 0 }}>
        <li>to give effect to clause 3.1 above;</li>
        <li>
          in the event that we sell or buy any business or assets, in which case we will disclose your Personal Data to
          the prospective seller or buyer of such business or assets.
        </li>
        <li>
          if we are acquired by a third party, in which case Personal Data held by it about its customers will be one of
          the transferred assets.
        </li>
        <li>
          if we are under a duty to disclose or share your Personal Data in order to comply with any legal obligation,
          or in order to enforce or apply Terms of Use and other agreements; or to protect the rights, property, or
          safety of the Website, our customers, or others. This includes exchanging information with other companies and
          organisations for the purposes of fraud protection and credit risk reduction.
        </li>
      </ul>
    </ol>

    <Heading tag="h3">7. WHERE WE STORE YOUR PERSONAL DATA</Heading>
    <ol>
      <li>
        By submitting your Personal Data, you agree to this transfer, storing or processing. We will take all steps
        reasonably necessary to ensure that your Personal Data is treated securely and in accordance with this privacy
        policy.
      </li>
      <li>
        All information you provide to us is stored on our secure servers. Any payment transactions will be encrypted
        using SSL technology. Where we have given you (or where you have chosen) a password which enables you to access
        certain parts of the Website, you are responsible for keeping this password confidential. We ask you not to
        share a password with anyone. Any paperwork completed when signing up for the services we offer will be stored
        securely.
      </li>
      <li>
        Unfortunately, the transmission of information via the internet is not completely secure. Although we will do
        our best to protect your Personal Data, we cannot guarantee the security of your Personal Data transmitted to
        the Website; any transmission is at your own risk. Once we have received your information, we will use strict
        procedures and security features to try to prevent unauthorised access.
      </li>
    </ol>

    <Heading tag="h3">8. YOUR RIGHTS</Heading>
    <ol>
      <li>
        You have the right to ask us not to process your Personal Data for marketing purposes. We will usually inform
        you (before collecting your Personal Data) if we intend to use your Personal Data for such purposes or if we
        intend to disclose your information to any third party for such purposes. You can exercise your right to prevent
        such processing by checking certain boxes in the account area where you can control your Personal Data options
        and where required on the forms we use to collect your Personal Data. You can also exercise the right at any
        time by contacting us using the contact details set out in the Terms of Use.
      </li>
      <li>
        The Website may from time to time, contain links to and from the sites of our partner networks, advertisers and
        affiliates. If you follow a link to any of these websites, please note that these websites have their own
        privacy policies and that we do not accept any responsibility or liability for these policies. Please check
        these policies before you submit any Personal Data to these websites.
      </li>
      <li>
        Please refer to www.dataprotection.gov.uk for further information about your rights under the Act. You have the
        right to receive a copy of the information we hold about you by sending a request in writing (with a fee of £10)
        to us using the contact details set out in the terms of use.
      </li>
    </ol>

    <Heading tag="h3">9. COOKIES</Heading>
    <ol>
      <li>
        The Website uses cookies to function and distinguish you from other users of the Website. This helps us to
        provide you with a good experience when you browse the Website and also allows us to improve. For detailed
        information on the cookies we use and the purposes for which we use them see our Cookie Policy;
        https://bookings.m8group.co.uk/cookie-policy
      </li>
      <li>
        For more information about Cookies and how they are used please refer to
        https://ico.org.uk/for-the-public/online/cookies/.
      </li>
    </ol>

    <Heading tag="h3">10. CHANGES TO OUR DATA PROTECTION POLICY</Heading>
    <ol>
      <li>
        Any changes we make to our privacy policy in the future will be posted on this page and, where appropriate,
        notified to you by e-mail. Please check back frequently to see any updates or changes to our privacy policy.
      </li>
    </ol>

    <Paragraph>
      Version 1.0 <br />
      March 2018
    </Paragraph>
  </>
);
