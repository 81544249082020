import React from 'react';

import NavigationLink from '../../../components/Navigation/NavigationLink/NavigationLink';

import whiteLogo from '../../logo-icons/brand-logo-for-dark-background.png';
import classes from '../../components/Footer/Footer.module.scss';

export default () => (
  <div data-cy="footer" className={classes.footer}>
    <div className="container">
      <div className={classes.footerContainer}>
        <img data-cy="footer_logo" src={whiteLogo} alt="logo" className={classes.footerLogo} />

        <nav className={classes.mobileMenu}>
          <NavigationLink data-cy="footer_contact" link="/contact" light>
            Contact
          </NavigationLink>
          <NavigationLink data-cy="footer_privacy_policy" link="/privacy-policy" light>
            Privacy Policy
          </NavigationLink>
          <NavigationLink data-cy="footer_terms_of_website" link="/terms-of-website" light>
            Terms of Website
          </NavigationLink>
        </nav>
      </div>
    </div>
  </div>
);
