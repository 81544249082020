import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import axios from '../../../../axios-global';
import Toast from 'grommet/components/Toast';

import Notification from 'grommet/components/Notification';
import Heading from 'grommet/components/Heading';

import ThankYouScreen from '../../../../components/ThankYouScreen/ThankYouScreen';
import SingleSource from '../../../../components/UI/SingleSource/SingleSource';

import { CardElement, injectStripe } from 'react-stripe-elements';
import * as actions from '../../../../store/actions/index';
import StripeSCAPayment from '../../../StripeSCAPayment/StripeSCAPayment';
import classes from './ClientPaymentControls.module.scss';
import Loading from '../../../../components/Loading/Loading';
import CustomButton from '../../../../components/CustomButton/CustomButton';

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,
    sourcesList: state.client.sourcesList,
    sourcesListLoading: state.client.sourcesListLoading,
    sourcesListError: state.client.sourcesListError
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchSources: () => dispatch(actions.fetchSources()),
    onFetchSubscriptionStatus: () => dispatch(actions.fetchSubscriptionStatus()),
    onFetchUserDetails: () => dispatch(actions.fetchUserDetails())
  };
};

class ClientPaymentControls extends Component {
  state = {
    showToast: false,
    toastMessage: null,
    status: null,
    paymentLoading: false,
    success: false,
    cardsAvailable: false,
    clientSecret: ''
  };

  componentDidMount() {
    this.props.onFetchSources();
  }

  checkForValidSource = () => {
    this.setState({
      paymentLoading: true
    });
  };

  handleSubmit = async () => {
    this.setState({
      paymentLoading: true
    });

    try {
      if (this.props.sourcesList && this.props.sourcesList.length === 0) {
        const source = await this.handleCreateNewSource();
        await this.handleSetPaymentMethod(source);
        await this.handleCardPayment();
      } else {
        await this.handleCardPayment();
      }
    } catch (err) {
      this.setState({
        showToast: true,
        toastMessage: 'An error occurred whilst trying to process the payment, please try again later',
        status: 'critical'
      });
    } finally {
      this.setState({
        paymentLoading: false
      });
    }
  };

  handleCreateNewSource = async () => {
    return this.props.stripe.createSource({
      type: 'card',
      currency: 'gbp'
    });
  };

  handleSetPaymentMethod = async (source) => {
    await axios.post('/users/me/payment-methods', {
      source: source.source.id,
      method: 'web'
    });

    this.props.onFetchSources();
  };

  handleCardPayment = async () => {
    const resp = await axios.post(`/membership-plan/subscribe/${this.props.membershipPlan._id}`, {
      method: 'web'
    });

    if (resp.data?.status === 'requires_action') {
      return this.setState({
        clientSecret: resp.data?.clientSecret
      });
    }

    if (resp.data.status === 'fail') {
      this.setState({
        showToast: true,
        toastMessage: resp.data.message,
        status: 'critical'
      });
    } else {
      this.setState({
        success: true
      });
    }

    this.props.onFetchUserDetails();
    this.refreshUserStatus();
  };

  sourceSelectForm = () => {
    let form = null;
    if (this.props.sourcesListLoading) {
      form = <Loading />;
      return form;
    }

    if (this.props.sourcesListError) {
      return (form = <Notification message={this.props.sourcesListError} status="warning" />);
    }

    if (this.state.success) {
      return this.renderThankYou();
    }

    let headerText = null;
    if (this.props.sourcesList && this.props.sourcesList.length > 0) {
      headerText = 'Select card';
      form = this.props.sourcesList.map((elem) => (
        <SingleSource
          source={elem}
          key={elem.id}
          closeInvisible={true}
          disabledCheckbox={this.state.paymentLoading || this.props.sourcesList.length === 1}
          selectNewDefaultSource={this.onSelectNewDefaultSource}
          default={elem.default}
        />
      ));
    } else if (this.props.sourcesList && this.props.sourcesList.length === 0) {
      headerText = 'Enter card details';
      form = (
        <label>
          <span className={classes.label}>Card details</span>
          <CardElement id="add_card_payment" disabled={this.state.paymentLoading} className={classes.StripeElement} />
        </label>
      );
    }

    return (
      <>
        <Heading strong={false} tag="h2" style={{ fontSize: '30px' }}>
          {headerText}
        </Heading>

        {form}

        {this.state.paymentLoading ? (
          <Loading />
        ) : (
          <div className={classes.controlButtons}>
            <CustomButton
              className={this.state.clientSecret?.length ? classes.disabled : ''}
              label="Pay"
              secondary={true}
              onClick={this.handleSubmit}
              dataCy="pay_btn"
            />
            <CustomButton
              className={this.state.clientSecret?.length ? classes.disabled : ''}
              label="Cancel"
              onClick={() => this.props.history.push('/gym')}
            />
          </div>
        )}
      </>
    );
  };

  onSelectNewDefaultSource = (sourceId) => {
    this.setState({
      paymentLoading: true
    });

    axios
      .post('/users/me/payment-default', {
        source: sourceId,
        method: 'web'
      })
      .then((resp) => {
        this.setState({
          showToast: true,
          toastMessage: resp.data.message,
          status: 'ok',
          paymentLoading: false
        });

        this.props.onFetchSources();
      })
      .catch((err) => {
        this.setState({
          showToast: true,
          toastMessage:
            err.response.data && err.response.data.message
              ? err.response.data.message
              : 'Something went wrong, try again',
          status: 'critical',
          paymentLoading: false
        });
      });
  };

  renderThankYou = () => {
    return (
      <>
        <ThankYouScreen small header="Subscription successful!" subheader="You're ready to go!" />
        <CustomButton dataCy="dashboard_btn" label="Dashboard" secondary={true} onClick={this.refreshUserStatus} />
      </>
    );
  };

  refreshUserStatus = () => {
    this.props.onFetchSubscriptionStatus();
    this.props.history.push('/gym');
  };

  render() {
    let toast = null;
    if (this.state.showToast) {
      toast = (
        <Toast
          status={this.state.status}
          onClose={() => {
            this.setState({
              showToast: false,
              toastMessage: null,
              status: null
            });
          }}>
          {this.state.toastMessage}
        </Toast>
      );
    }

    return (
      <div className={classes.container}>
        {toast}
        <StripeSCAPayment
          clientSecret={this.state.clientSecret}
          onSuccess={() => {
            this.setState({
              success: true,
              clientSecret: ''
            });
          }}
          onError={(err) => {
            this.setState({
              showToast: true,
              toastMessage: err,
              status: 'critical',
              clientSecret: ''
            });
          }}
        />
        {this.sourceSelectForm()}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectStripe(ClientPaymentControls)));
