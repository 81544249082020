import React from 'react';
import { withRouter } from 'react-router-dom';
import withUser from '../../store/hoc/withUser';
import { returnAllUpcomingClasses } from '../../shared/classFunctions';
import { useQuery } from 'react-query';
import { fetchClasses } from '../../apiFunctions/apiFunctions';
import { returnClassConfig } from '../../shared/gymFunctions';
import DefaultTimetable from '../Timetables/DefaultTimetable/DefaultTimetable/DefaultTimetable';
import moment from 'moment';

const initialDateState = {
  dateFrom: moment().subtract(14, 'd').format('YYYY-MM-DD'),
  dateTo: moment().format('YYYY-MM-DD')
};

const SingleClassTypeHistoryComponent = ({ classesDetails, selectedGym, dateRange = initialDateState }) => {
  const { data, error, isLoading, isFetching } = useQuery(
    ['historyClasses', classesDetails.type, dateRange.dateFrom, dateRange.dateTo, selectedGym._id],
    () => fetchClasses(classesDetails.type, selectedGym._id, dateRange.dateFrom, dateRange.dateTo),
    {
      enabled: dateRange.dateFrom !== null && dateRange.dateTo !== null
    }
  );

  return (
    <DefaultTimetable
      classesList={returnAllUpcomingClasses(data)}
      classesListLoading={isLoading || isFetching}
      classesListError={error}
      classesDetails={classesDetails}
      classType={classesDetails.type}
      classConfig={returnClassConfig(selectedGym.settings.classConfig, classesDetails.type)}
      showAllGymsData={false}
      isHistoryComponent={true}
    />
  );
};

export default withRouter(withUser(SingleClassTypeHistoryComponent));
