import React, { useState } from 'react';
import { getCurrencySymbol, returnErrorFromResponse } from '../../../shared/utility';
import classes from './SingleSchedule.module.scss';
import Icons from '../../../components/UI/Icons/Icons';
import Modal from 'react-modal';
import { Toast } from 'grommet';
import { delayFetchFunctions, editScheduledClass } from '../../../apiFunctions/apiFunctions';
import { useMutation } from 'react-query';
import CustomToast from '../../../components/CustomToast/CustomToast';
import CustomButton from '../../../components/CustomButton/CustomButton';

const ConfirmDeleteDayModal = ({ isShowing, onHideDeleteModal, onConfirm }) => {
  return (
    <Modal onRequestClose={onHideDeleteModal} isOpen={isShowing} className="ss-modal">
      <div className="grommet">
        Do you want to delete the chosen day?
        <div className="ss-modal__buttons-container">
          <CustomButton box label="Yes" secondary onClick={onConfirm} />

          <CustomButton box label="Cancel" primary onClick={onHideDeleteModal} />
        </div>
      </div>
    </Modal>
  );
};

const SingleSchedule = ({ scheduledClass, onRemoveScheduledClass, onUpdateScheduledClass, classConfig }) => {
  const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
  const [selectedDay, setSelectedDay] = useState(null);
  const [toast, setToast] = useState(null);
  const [showConfirmToast, setShowConfirmToast] = useState(false);
  const [showDeleteDayConfirmation, setShowDeleteDayConfirmation] = useState(false);
  const mutateScheduleDeleteDay = useMutation((formData) => editScheduledClass(scheduledClass._id, formData), {
    onSuccess: () => {
      setToast({
        status: 'ok',
        msg: 'Successfully updated',
        show: true
      });
      delayFetchFunctions([['scheduled-class', scheduledClass.classType]], 0);
    },
    onError: (err) => {
      setToast({
        status: 'critical',
        msg: returnErrorFromResponse(err),
        show: true
      });
    }
  });
  const doDeleteDay = () => {
    const updatedScheduledClass = { ...scheduledClass };
    updatedScheduledClass.recurrentOn[selectedDay] = '';
    mutateScheduleDeleteDay.mutate(updatedScheduledClass);
    setShowDeleteDayConfirmation(false);
  };

  const deleteDay = (day) => {
    setSelectedDay(day);
    setShowDeleteDayConfirmation(true);
  };

  const handleClassTemplateDelete = () => {
    if (!showConfirmToast) {
      setShowConfirmToast(true);
      return;
    }

    setShowConfirmToast(false);
    onRemoveScheduledClass(scheduledClass);
  };

  const handleConfirmToastClose = () => {
    setShowConfirmToast(false);
  };

  const renderNotification = () =>
    showConfirmToast && (
      <Toast status="warning" onClose={handleConfirmToastClose}>
        Click once again to confirm the deletion of the class template
      </Toast>
    );

  const renderNameAndDescription = () => {
    const hideFor = ['gymClass', 'swimmingClass', 'tennisClass', 'massageClass'];
    if (!hideFor.includes(scheduledClass.classType)) {
      return (
        <>
          <div className={classes.single_scheduled_class__name}>{scheduledClass.name}</div>
          <div className={classes.single_scheduled_class__description}>{scheduledClass.description}</div>
          <span className={classes.single_scheduled_class__label}>Location:</span> {scheduledClass.location}
        </>
      );
    }
    return null;
  };

  return (
    <>
      <CustomToast toast={toast} />
      {renderNotification()}
      <ConfirmDeleteDayModal
        isShowing={showDeleteDayConfirmation}
        onHideDeleteModal={() => setShowDeleteDayConfirmation(false)}
        onConfirm={doDeleteDay}
      />
      <div className={classes.single_scheduled_class}>
        <div>
          {renderNameAndDescription()}

          <div>
            <span className={classes.single_scheduled_class__label}>Limit:</span> {scheduledClass.limit}
          </div>

          <div>
            <span className={classes.single_scheduled_class__label}>Costs:</span>
            {getCurrencySymbol(scheduledClass.currency)}
            &nbsp;
            {scheduledClass.cost}
          </div>

          {scheduledClass.membershipClassPrice > 0 && (
            <div>
              <span className={classes.single_scheduled_class__label}>Membership Class Price:</span>
              {getCurrencySymbol(scheduledClass.currency)}&nbsp;
              {scheduledClass.membershipClassPrice}
            </div>
          )}

          <div>
            <span className={classes.single_scheduled_class__label}>Duration:</span> {scheduledClass.duration} mins
          </div>

          {classConfig.trainerRequired && scheduledClass.trainerId && (
            <div>
              <span className={classes.single_scheduled_class__label}>Trainer:</span> {scheduledClass.trainerId.name}{' '}
              {scheduledClass.trainerId.last_name}
            </div>
          )}
          <div>
            <span className={classes.single_scheduled_class__label}>Waiting list:</span>
            {scheduledClass.waitListConfig && scheduledClass.waitListConfig.active ? 'yes' : 'no'}
          </div>
        </div>

        <div className={classes.single_schedule_class__schedule}>
          {Object.keys(scheduledClass.recurrentOn).map((day) => {
            return (
              <div key={day} className={classes.single_schedule_class__schedule__item}>
                <div className={classes.single_schedule_class__schedule__item__day}>{WEEKDAYS[day]} :</div>
                <div className={classes.single_schedule_class__schedule__item__times}>
                  {scheduledClass.recurrentOn[day]}
                </div>
                {scheduledClass.recurrentOn[day].length > 0 && <Icons delete deleteClick={() => deleteDay(day)} />}
              </div>
            );
          })}
        </div>

        <div className={classes.single_schedule_class__delete}>
          <div className={classes.single_schedule_class__icon}>
            <Icons edit editClick={() => onUpdateScheduledClass(scheduledClass)} />
          </div>

          <div className={classes.single_schedule_class__icon}>
            <Icons delete deleteClick={() => handleClassTemplateDelete()} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleSchedule;
