import React from 'react';
import { returnUserClassesLeftAmount } from '../../../../shared/userFunctions';
import { returnClassPrice, hasClassStarted } from '../../../../shared/classFunctions';
import { getCurrencySymbol } from '../../../../shared/utility';
import withUser from '../../../../store/hoc/withUser';

const ReturnClassPrice = ({ classType, selectedClass, userData, classConfig }) => {
  const classesLeft = returnUserClassesLeftAmount(classType, userData, selectedClass);
  const userClassPrice = returnClassPrice(selectedClass, userData);
  const currency = getCurrencySymbol(selectedClass.currency);

  let price = 'Free';

  if (userData.role === 'user') {
    const isMembershipUser = userData.customerType === 'full-member';
    const hasUserBookedClass = selectedClass.userAttendanceStatus === 'active';
    const hasClassAlreadyStarted = hasClassStarted(selectedClass, 15);

    if (hasUserBookedClass || hasClassAlreadyStarted) {
      price = null;
    }

    if (classesLeft > 0) {
      price = isMembershipUser ? 'Within plan' : 'Within pass';
    } else {
      price = classConfig.showClassCost ? (
        <>
          {currency} {userClassPrice}
        </>
      ) : null;
    }
  } else {
    price = (
      <>
        {currency} {selectedClass.cost}
      </>
    );
  }

  return <h5 style={{ margin: '0px' }}>{price}</h5>;
};

export default withUser(ReturnClassPrice);
