import React, { useState } from 'react';
import CheckBox from 'grommet/components/CheckBox';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import classes from './StepOne_ClientSelector.module.scss';

const StepOne_ClientSelector = ({ attendanceList, onSelect, nextStep, onCloseModal }) => {
  const [selectedClient, setSelectedClient] = useState(null);
  const clients = attendanceList.filter((elem) => ['active', 'pending', 'attended', 'absent'].includes(elem.status));
  const singleClient = (client) => (
    <div className={classes.singleClient}>
      <CheckBox
        checked={selectedClient && selectedClient._id === client._id}
        onChange={() => onSelectClient(client)}
        label={`${client.name} ${client.last_name}`}
      />
    </div>
  );
  const onSelectClient = (client) => {
    onSelect(client);
    setSelectedClient(client);
  };
  const handleNextBtn = () => {
    nextStep(2);
  };

  const returnClients =
    clients.length > 0 ? <div>{clients.map((elem) => singleClient(elem.user))}</div> : <div>No clients</div>;

  return (
    <>
      <div className={classes.header}>Please select client:</div>
      {returnClients}
      <div className="ss-modal__buttons-container">
        <CustomButton
          secondary
          onClick={() => handleNextBtn()}
          disabled={selectedClient === null}
          label={'Next step'}
        />
        <CustomButton onClick={onCloseModal} label={'Cancel'} />
      </div>
    </>
  );
};

export default StepOne_ClientSelector;
