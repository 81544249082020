import React from 'react';

import SingleTile from './SingleTile/SingleTile';

import classes from './TilesBoard.module.scss';

const TilesBoard = (props) => {
  const onActionHandler = (type) => {
    props.action(type);
  };

  return (
    <div className={classes.tilesContainer}>
      {props.tiles.map((elem, index) => (
        <SingleTile
          title={elem.name ? elem.name : elem.title}
          icon={elem.icon}
          text={elem.text}
          link={elem.link}
          dataCy={elem.dataCy}
          action={onActionHandler}
          actionPayload={elem.actionPayload}
          badgeCount={elem.badgeCount ? elem.badgeCount : null}
          key={index}
        />
      ))}
    </div>
  );
};
export default TilesBoard;
