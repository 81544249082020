import React, { Component } from 'react';

import CustomButton from '../../components/CustomButton/CustomButton';
import Loading from '../../components/Loading/Loading';
import Notification from 'grommet/components/Notification';

import WhiteBackground from '../../hoc/WhiteBackground/WhiteBackground';
import Title from '../../components/UI/Title/Title';
import FormCreator from '../../components/UI/FormCreator/FormCreator';

import axios from '../../axios-global';

import classes from './ForgotPassword.module.scss';

import { updateObject, checkValidity, returnErrorTextForField } from '../../shared/utility';

class ForgotPassword extends Component {
  state = {
    loading: false,
    loginError: false,
    notification: null,
    notificationType: 'ok',
    forgotPasswordForm: {
      email: {
        label: 'E-mail',
        type: 'text',
        placeholder: 'Enter e-mail',
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        touched: false,
        errorText: null
      }
    }
  };

  valueChangedHandler = (newValue, identifier) => {
    const updatedForgotPasswordForm = updateObject(this.state.forgotPasswordForm, {
      [identifier]: updateObject(this.state.forgotPasswordForm[identifier], {
        value: newValue,
        valid: checkValidity(newValue, this.state.forgotPasswordForm[identifier].validation),
        errorText: returnErrorTextForField(this.state.forgotPasswordForm[identifier].validation),
        touched: true
      })
    });
    return this.setState({
      forgotPasswordForm: updatedForgotPasswordForm
    });
  };

  checkFormValidity = () => {
    for (let input in this.state.forgotPasswordForm) {
      if (!this.state.forgotPasswordForm[input].valid) {
        const updatedForgotPasswordForm = updateObject(this.state.forgotPasswordForm, {
          [input]: updateObject(this.state.forgotPasswordForm[input], {
            valid: checkValidity(
              this.state.forgotPasswordForm[input].value,
              this.state.forgotPasswordForm[input].validation
            ),
            errorText: returnErrorTextForField(this.state.forgotPasswordForm[input].validation),
            touched: true
          })
        });
        return this.setState({
          forgotPasswordForm: updatedForgotPasswordForm
        });
      }
    }
    this.sendResetLink();
  };

  sendResetLink = () => {
    this.setState({
      loading: true,
      notification: null,
      notificationType: 'ok'
    });

    axios
      .post(`/users/reset-password`, {
        email: this.state.forgotPasswordForm.email.value.trim(),
        url: window.location.origin
      })
      .then((resp) => {
        this.setState({
          loading: false,
          notification: resp.data.message,
          forgotPasswordForm: {
            email: {
              label: 'E-mail',
              type: 'text',
              placeholder: 'Enter e-mail',
              value: '',
              validation: {
                required: true,
                isEmail: true
              },
              valid: false,
              touched: false,
              errorText: null
            }
          }
        });
        setTimeout(() => {
          this.props.history.push('/login');
        }, 3000);
      })
      .catch((err) => {
        this.setState({
          loading: false,
          notificationType: 'critical',
          notification:
            err.response.data.message && typeof err.response.data.message !== 'object'
              ? err.response.data.message
              : 'Server error'
        });
      });
  };

  render() {
    let button = (
      <CustomButton
        className={classes.button}
        box
        label="Get reset link"
        secondary={true}
        onClick={this.checkFormValidity}
      />
    );
    if (this.state.loading) {
      button = (
        <div className={classes.spinnerContainer}>
          <Loading />
        </div>
      );
    }

    let errorNotification = null;
    if (this.state.notification) {
      errorNotification = (
        <div className={classes.notification}>
          <Notification message={this.state.notification} status={this.state.notificationType} />
        </div>
      );
    }

    return (
      <WhiteBackground>
        <div className={classes.formContainer}>
          <Title header="Enter your e-mail" />

          {errorNotification}

          <FormCreator formData={this.state.forgotPasswordForm} valueChanged={this.valueChangedHandler} />

          {button}
        </div>
      </WhiteBackground>
    );
  }
}

export default ForgotPassword;
