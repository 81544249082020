import React from 'react';
import { withRouter } from 'react-router-dom';
import { Header as GrommetHeader, Menu, Anchor } from 'grommet';
import UserIcon from '../../../brandConfig/assets/svg/user.svg';
import DrawerToggle from '../SideDrawer/DrawerToggle/DrawerToggle';
import Logo from '../../UI/Logo/Logo';
import classes from './header.module.scss';
import MenuNavigation from '../MenuNavigation/MenuNavigation';
import CustomButton from '../../CustomButton/CustomButton';

const Header = ({
  location,
  isAuthenticated,
  userData,
  gym,
  dashboardLink,
  drawerToggleClicked,
  menuItems,
  history
}) => {
  let authSection = (
    <CustomButton
      className={[classes.dashboardIcon, classes.hiddenMobile].join(' ')}
      label="LOGIN"
      onClick={() => history.push('/login')}
      primary={true}
    />
  );
  if (isAuthenticated) {
    authSection = (
      <div className={classes.headerIcons}>
        <MenuNavigation menuItems={menuItems} />

        <Menu
          className={classes.userMenu}
          aria-label="User Menu"
          data-cy={'header_menu_user_icon'}
          dropAlign={{ left: 'left', top: 'bottom' }}
          icon={<img className={classes.userMenuIcon} src={UserIcon} alt="user menu" />}>
          <Anchor href="/user/settings" data-cy={'header_menu_settings'}>
            Settings
          </Anchor>
          {userData &&
            userData.role === 'user' &&
            userData.customerType === 'full-member' &&
            userData.status === 'active' &&
            gym &&
            gym.settings.membershipProducts && (
              <Anchor data-cy={'header_menu_membership'} href="/gym/membership">
                Membership
              </Anchor>
            )}
          {gym && (
            <Anchor data-cy={'header_menu_terms_and_conditions'} href="/gym/terms-and-conditions">
              Terms
            </Anchor>
          )}
          <Anchor data-cy={'header_menu_logout'} href="/logout">
            Logout
          </Anchor>
        </Menu>

        <CustomButton
          label="Dashboard"
          className={classes.dashboardIcon}
          dataCy="dashboard_btn"
          onClick={() => history.push(dashboardLink)}
          primary={true}
        />
      </div>
    );
  }

  if (location.pathname !== '/' && !isAuthenticated && gym) {
    authSection = (
      <>
        <div className={classes.hiddenMobile}>
          <CustomButton
            primary={true}
            style={{ margin: '0 10px' }}
            label="LOGIN"
            onClick={() => history.push('/login')}
          />
          <CustomButton
            style={{ margin: '0 10px' }}
            label="SIGN UP"
            data-cy="btn_go_to_signup"
            onClick={() => history.push(`/gym/${gym._id}/signup`)}
            primary={true}
          />
        </div>
      </>
    );
  }

  return (
    <GrommetHeader float={true}>
      <div className="container container--flexbox">
        <div className={classes.headerMenu}>
          <Logo dark={location.pathname === '/'} isAuthenticated={isAuthenticated} />
          <div className={classes.navbarContainer}>
            <div className={classes.authSection}>{authSection}</div>
            <div className={classes.mobileMenuContainer}>
              <DrawerToggle clicked={drawerToggleClicked} dark={location.pathname !== '/'} />
            </div>
          </div>
        </div>
      </div>
    </GrommetHeader>
  );
};

export default withRouter(Header);
