import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const useTabIndex = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const history = useHistory();

  useEffect(
    () => {
      const changeState = () => setActiveTabIndex(history.location.state?.activeTabIndex || 0);
      window.addEventListener('popstate', changeState);
      return () => window.removeEventListener('popstate', changeState);
    },
    //eslint-disable-next-line
    []
  );

  const onTabChange = (index) => {
    setActiveTabIndex(index);
    history.push({ state: { activeTabIndex: index } });
  };

  return {
    activeTabIndex,
    onTabChange
  };
};

export default useTabIndex;
