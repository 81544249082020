import React from 'react';

import Heading from 'grommet/components/Heading';
import Paragraph from 'grommet/components/Paragraph';

export default () => (
  <>
    <Heading>TERMS AND CONDITIONS – USE OF WEBSITE</Heading>

    <Heading tag="h3">1. INTRODUCTION</Heading>
    <ol>
      <li>
        These terms and conditions apply to the entire contents of the M8 Group websites and to any correspondence by
        e-mail between M8 Group/motive8 and you. All references in these terms and conditions: to the “Website” refer to
        this website; to “we”, “us” and “our” refer to M8 Group/motive8 Limited; to “you” and “your” refer to you, the
        user; to “Content” refer to the text, graphics, plans, photographs, information and other material displayed
        from time to time on the Website.
      </li>
      <li>M8 Group refers to all motive8 companies including motive8 Ltd, motive8 North Ltd, motive8 CPD Ltd.</li>
      <li>
        Please read these terms and conditions carefully. By using this Website you are deemed to accept these terms and
        conditions whether or not you choose to register with us. We reserve the right to amend these terms and
        conditions from time to time and recommend that you review them regularly.
      </li>
    </ol>

    <Heading tag="h3">2. COPYRIGHT</Heading>
    <ol>
      <li>
        Unless otherwise stated, we own the copyright and other intellectual property rights in all Content and
        expressly reserve all rights in the same. You may print and download extracts from this Website for your own
        non-commercial use provided that you do not modify any of the Content, you do not use any graphics or
        photographs separately from their accompanying text and you do not remove any copyright or trade mark
        notification from such extracts.
      </li>
      <li>
        All other reproduction or use of extracts of content is strictly prohibited. In particular, no part of this
        Website may be reproduced or stored in any other website or included in any public or private electronic
        retrieval system or service without our prior written permission.
      </li>
    </ol>

    <Heading tag="h3">3. YOUR ACCESS AND USE</Heading>

    <ol>
      <li>
        We endeavour to ensure that this Website is accessible 24 hours a day. However, we will not be liable for any
        losses, expenses, costs or liabilities that you, your business or any other person may suffer if this Website is
        unavailable at any time or for any period or your access to the Website is interrupted, restricted or delayed
        for any reason.
      </li>
      <li>
        Access to this Website may be suspended temporarily and without notice in the case of system failure,
        maintenance or repair or for any reasons beyond our control.
      </li>
      <li>
        Other than personally identifiable information which is covered under our Privacy Policy, any material or
        information that you transmit or post to this Website or e-mail to us (your “Non-Personal Information”) will be
        considered non-confidential and non-proprietary. We will have no obligation to you with respect to your
        Non-Personal Information. By sending such non-personal Information to us you agree that we are free to copy,
        disclose, distribute, incorporate and otherwise use all Non-Personal Information for any and all commercial or
        non-commercial purposes.
      </li>
      <li>
        By using this Website you agree not to attempt to access our systems and you agree not to post on or transmit to
        or from it any material:
        <ol>
          <li>
            that is threatening, defamatory, obscene, indecent, offensive, discriminatory, inflammatory, blasphemous,
            criminal, in breach of confidence or privacy or a third party’s rights or which may otherwise cause
            annoyance or inconvenience; or
          </li>
          <li>
            which is technically harmful including, without limitation, containing computer viruses, logic bombs, Trojan
            horses, worms, harmful components, corrupted data or other malicious software or harmful data.
          </li>
        </ol>
      </li>
      <li>
        If you breach any of the terms of these terms and conditions, your permission to use this Website automatically
        terminates and you must immediately destroy any downloaded or printed extracts from this website that you have
        within your possession or control.
      </li>
      <li>
        We will fully co-operate with any law enforcement authorities or court order requesting or directing us to
        disclose the identity of or to locate anyone posting any material onto the website in breach of point 4 above.
      </li>
    </ol>

    <Heading tag="h3">4. LINKING TO WEBSITE</Heading>

    <ol>
      <li>
        You may create a link from your own website to the website provided that you obtain our prior written consent
        and provided that you only do so on the basis that you link to, and do not replicate, the home page of this
        Website and you DO NOT:
        <ul>
          <li>create a frame or any other browser or border environment around this website;</li>
          <li>in any way imply that we endorse any products or services other than our own;</li>
          <li>misrepresent your relationship with us or present any other false information about us;</li>
          <li>use any of our content or trademarks without our express written permission;</li>
          <li>
            link from a website that is not owned by you; or (vi) display any content on your website that is
            distasteful, offensive or controversial, infringes any intellectual property rights or other rights of any
            other person or otherwise does not comply with all applicable laws and regulations.
          </li>
        </ul>
      </li>
      <li>
        We expressly reserve the right to revoke any right we grant to you to link to our Website should you breach any
        of these terms and conditions. By linking to the Website, you indemnify us for any losses, damages, liabilities,
        costs or expenses that we, or any of our group companies, may suffer or incur due to your breach of any of the
        terms set out in point 1 above.
      </li>
    </ol>

    <Heading tag="h3">5. DISCLAIMER</Heading>
    <ol>
      <li>
        While we endeavour to ensure that the Content is correct and up-to-date, it is provided “as is” and we give no
        representation, warranty or guarantee as to its accuracy, completeness or adequacy. Accordingly, to the maximum
        extent permitted by law, we exclude all express and implied warranties, representations and conditions with
        respect to the Content
      </li>
      <li>
        The Website may from time to time contain links to third party websites which we provide solely for your
        convenience. We have no control over, and make no endorsement of, the content or availability of these third
        party websites and you use and rely on the same at your own risk.
      </li>
      <li>
        You acknowledge and agree that any reliance you place on the Content and the content of any linked third party
        sites is at your own risk. To the maximum extent permitted by law, we (and our group companies) exclude all
        liability for any direct or indirect losses, loss of profits or other consequential loss, damages, costs,
        expenses or liabilities that you may suffer or incur arising from your use of this Website and/or reliance on
        any Content.
      </li>
    </ol>

    <Heading tag="h3">6. READINESS TO PARTICIPATE</Heading>
    <ol>
      <li>
        All class participants, personal training clients and gym members must complete a PAR-Q (Physical Activity
        Readiness Questionnaire) before commencing any exercise. If there are any positive responses on the PAR-Q then
        your doctors consent maybe required before you can undertake any physical activity.
      </li>
      <li>If the responses to your PAR-Q change then you must inform your instructor.</li>
      <li>If you have any injuries you must inform your instructor before you begin your training session.</li>
      <li>
        Appropriate clothing and footwear must be worn for all training sessions. You may not be allowed to participate
        if you are not wearing the correct attire.
      </li>
      <li>Please ensure you are adequately hydrated before you begin your session.</li>
      <li>
        If you feel sick, dizzy or unwell during your training session you must inform your instructor immediately.
      </li>
    </ol>

    <Heading tag="h3"> 7. GOVERNING LAW</Heading>

    <Paragraph>
      These terms and conditions and your use of the Website will be governed by and construed in accordance with
      English law and you agree that any disputes arising in connection with the same will be subject to the exclusive
      jurisdiction of the English courts.
    </Paragraph>

    <Paragraph>
      This website is operated by the M8 Group. A company registered in England; company registration number 07487095;
      registered address is 65 The High Street, Teddington, TW11 8HA. Please contact us on 0800 028 0198 should need any
      assistance.
    </Paragraph>
  </>
);
