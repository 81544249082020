import React from 'react';

import withAdminDashboard from '../../../store/hoc/withAdminDashboard';
import Modal from 'react-modal';
import BulkTable from './BulkTable';
import Loading from '../../../components/Loading/Loading';
import Notification from 'grommet/components/Notification';

const BulkModal = ({ isLoading, error, data, isOpen, closeModal, updatedData = null }) => {
  const noBulkData = (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <h1>No classes</h1>
    </div>
  );
  let content = (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Loading size="xlarge" />
    </div>
  );

  if (error) {
    content = <Notification message="Something went wrong. Please try again" status="critical" />;
  }

  if (!isLoading) {
    content =
      data && data.length > 0 ? (
        <BulkTable data={data} closeModal={closeModal} updatedData={updatedData} classType={data[0].__t} />
      ) : (
        noBulkData
      );
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <div className="grommet">{content}</div>
    </Modal>
  );
};

export default withAdminDashboard(BulkModal);
