import React, { useState } from 'react';
import { Notification } from 'grommet';
import FormCreator from '../../../components/UI/FormCreator/FormCreator';
import moment from 'moment';
import { updateObject, checkValidity, returnErrorTextForField, returnErrorFromResponse } from '../../../shared/utility';
import { withRouter } from 'react-router-dom';
import withAdminDashboard from '../../../store/hoc/withAdminDashboard';
import { returnPassFields } from './returnPassFields';
import { useMutation } from 'react-query';
import { addPass, delayFetchFunctions, editPass } from '../../../apiFunctions/apiFunctions';
import CustomToast from '../../../components/CustomToast/CustomToast';
import Loading from '../../../components/Loading/Loading';
import { CLASS } from '../../../constants';
import CustomButton from '../../../components/CustomButton/CustomButton';

const AddEditPass = ({ classType, selectedPass, selectedGym, onClose }) => {
  const [formIsValid, setFormIsValid] = useState(false);
  const [formValidated, setFormValidated] = useState(false);
  const [formFields, setFormFields] = useState(returnPassFields(classType, selectedPass));
  const [toast, setToast] = useState(null);
  const isNewPass = selectedPass === null || selectedPass === undefined;

  const mutateSavePass = useMutation(
    (formData) => (isNewPass ? addPass(classType, formData) : editPass(selectedPass._id, formData)),
    {
      onSuccess: () => {
        setToast({
          status: 'ok',
          msg: isNewPass ? 'Successfully created' : 'Successfully updated',
          show: true
        });
        delayFetchFunctions([['passes', classType]], 0);
        onClose();
      },
      onError: (err) => {
        setToast({
          status: 'critical',
          msg: returnErrorFromResponse(err),
          show: true
        });
      }
    }
  );

  const valueChangedHandler = (newValue, identifier) => {
    const updatedNewClassPass = updateObject(formFields, {
      [identifier]: updateObject(formFields[identifier], {
        value: newValue,
        valid: checkValidity(newValue, formFields[identifier].validation),
        errorText: returnErrorTextForField(formFields[identifier].validation),
        touched: true
      })
    });
    setFormFields(updatedNewClassPass);
    setFormValidated(false);
  };

  const checkFormValidity = () => {
    setFormValidated(true);

    for (let input in formFields) {
      if (!formFields[input].valid) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        return setFormIsValid(false);
      }
    }
    setFormIsValid(true);
    savePass();
  };

  const savePass = () => {
    const formData = {};
    if (selectedPass === null) {
      formData.gymId = selectedGym._id;
    }
    for (let input in formFields) {
      let value = formFields[input].value;
      if (['date', 'time', 'datetime'].includes(formFields[input].type) && formFields[input].value !== '') {
        value = moment(formFields[input].value, 'DD/MM/YYYY').format('YYYY-MM-DD');
      }
      formData[input] = typeof value === 'string' ? value.trim() : value;
    }

    if (formData.expiryDate) {
      delete formData.expiryDate;
    }

    if (classType === CLASS.VIDEO) {
      formData.classCount = 1;
      formData.oneOff = true;
      formData.forClass = formData.forClass.value;
    }

    if (isNewPass) {
      formData.currency = formData.currency.value;
    }
    mutateSavePass.mutate(formData);
  };

  let formWarning = !formIsValid && formValidated && (
    <Notification message="Form is not filled in correctly" status="warning" className="ss-top-notification" />
  );

  const button = mutateSavePass.isLoading ? (
    <Loading topOffset={'50px'} />
  ) : (
    <CustomButton
      dataCy="add_btn"
      style={{ marginTop: '50px', display: 'block' }}
      label={selectedPass ? 'Save' : 'Add'}
      secondary={true}
      onClick={checkFormValidity}
    />
  );

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
        <CustomButton data-cy="passesList" label={'Passes list'} secondary={true} onClick={onClose} />
      </div>
      <div>
        <CustomToast toast={toast} />
        {formWarning}
        <FormCreator formData={formFields} valueChanged={valueChangedHandler} />
        {button}
      </div>
    </div>
  );
};

export default withRouter(withAdminDashboard(AddEditPass));
