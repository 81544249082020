import React, { useState } from 'react';
import classes from './GymAccess.module.scss';
import CheckBox from 'grommet/components/CheckBox';

const GymAccessSelector = ({
  isFullAccessAdminOrTrainer = false,
  gymsList = [],
  defaultSelectedGyms = {},
  defaultIsFullAccess = false,
  onChange = () => {}
}) => {
  const [fullAccess, setFullAccess] = useState(defaultIsFullAccess);
  const [selectedGyms, setSelectedGyms] = useState(defaultSelectedGyms);
  const [previousSelectedGyms, setPreviousSelectedGyms] = useState(defaultSelectedGyms);
  const [error, setError] = useState(false);

  const handleSelectGym = (gymId) => {
    const newSelection = {
      ...selectedGyms,
      [gymId]: !selectedGyms[gymId]
    };
    const isFullAccessAfterNewSelection = Object.values(newSelection).every((item) => item === true);
    const isError = isFullAccessAfterNewSelection ? false : Object.values(newSelection).every((item) => item === false);
    setSelectedGyms(newSelection);
    if (!isFullAccessAfterNewSelection) {
      setPreviousSelectedGyms(newSelection);
    }
    setFullAccess(isFullAccessAfterNewSelection);
    onChange(isFullAccessAfterNewSelection, newSelection);
    setError(isError);
  };
  const handleFullAccessCheckbox = () => {
    if (fullAccess) {
      onChange(false, previousSelectedGyms);
      setSelectedGyms(previousSelectedGyms);
      setFullAccess(false);
      setError(Object.values(previousSelectedGyms).every((item) => item === false));
    } else {
      const newSelection = gymsList.reduce((obj, item) => ((obj[item._id] = true), obj), {});
      onChange(true, newSelection);
      setSelectedGyms(newSelection);
      setFullAccess(true);
      setError(false);
    }
  };
  const header = <div className={classes.header}>Select gym access</div>;
  const fullAccessCheckbox = isFullAccessAdminOrTrainer && (
    <CheckBox
      label="Full access"
      onClick={handleFullAccessCheckbox}
      className={classes.fullAccess}
      checked={fullAccess}
    />
  );
  const gymsCheckboxes = gymsList.map(({ _id, name }) => (
    <CheckBox label={name} onChange={() => handleSelectGym(_id)} key={_id} checked={selectedGyms[_id]} />
  ));
  const errorNotification = <div className={classes.errorNotification}>{error && 'Please select gym'}</div>;

  return (
    <div className={classes.gymSelector}>
      {header}
      {errorNotification}
      {fullAccessCheckbox}
      {gymsCheckboxes}
    </div>
  );
};

export default GymAccessSelector;
