import React, { useState } from 'react';
import List from 'grommet/components/List';
import ListItem from 'grommet/components/ListItem';
import SearchClients from './SearchClients/SearchClients';
import SearchAdminTrainers from './SearchAdminsTrainers/SearchAdminTrainers';
import FormPreviousIcon from 'grommet/components/icons/base/FormPrevious';
import classes from './DatabaseSearches.module.scss';
import CustomButton from '../../../components/CustomButton/CustomButton';

const DatabaseSearches = () => {
  const [selected, setSelected] = useState(null);
  const databaseSearches = [
    {
      name: 'Client Search',
      type: 'client-search'
    },
    {
      name: 'Trainer Search',
      type: 'trainer-search'
    },
    {
      name: 'Admin Search',
      type: 'admin-search'
    }
  ];

  const goBack = () => {
    return (
      <CustomButton
        icon={<FormPreviousIcon />}
        label="Back"
        className={classes.backBtn}
        onClick={() => setSelected(null)}
      />
    );
  };

  const returnProperSearch = () => {
    if (selected !== null) {
      return (
        <>
          {goBack()}
          {selected === 'client-search' && <SearchClients />}
          {selected === 'trainer-search' && <SearchAdminTrainers role="trainer" />}
          {selected === 'admin-search' && <SearchAdminTrainers role="admin" />}
        </>
      );
    }
    return (
      <div className="report" data-cy={'search'}>
        <List className="report__list">
          {databaseSearches.map((elem, index) => (
            <ListItem className="report__list__item" key={index} onClick={() => setSelected(elem.type)}>
              <span>{elem.name}</span>
            </ListItem>
          ))}
        </List>
      </div>
    );
  };

  return returnProperSearch();
};

export default DatabaseSearches;
