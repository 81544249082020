import React from 'react';
import CustomButton from '../../../components/CustomButton/CustomButton';
import classes from './Hero.module.scss';

export default (props) => (
  <div className={classes.hero}>
    <div className="container">
      <div className={classes.hero__box}>
        <h2 className={classes.box__header}>Sign up</h2>

        <p className={classes.box__additionalInfo}>
          If you would like to book an induction please contact us at inductions@m8group.co.uk or call 0800 028 0198.
        </p>

        {props.isAuthenticated ? (
          <CustomButton label="DASHBOARD" onClick={() => props.history.push(props.dashboardLink)} secondary />
        ) : (
          <CustomButton label="LOGIN" onClick={() => props.history.push(`/login`)} secondary />
        )}
      </div>
    </div>
  </div>
);
