import React, { useState, useEffect } from 'react';
import { Tab, Tabs, Notification } from 'grommet';
import withAdminDashboard from '../../../../store/hoc/withAdminDashboard';
import ExportToCSV from '../../ExportToCSV/ExportToCSV';
import { useQuery } from 'react-query';
import { fetchDebtorsReport } from '../../../../apiFunctions/apiFunctions';
import classes from './DebtorReports.module.scss';
import Loading from '../../../../components/Loading/Loading';
import GymSelector from '../../../../components/Selectors/GymSelector/GymSelector';
import Filter from '../../../../components/Filter';
import DebtorsReportsPayg from './ShowData/DebtorsReportsPAYG';
import DebtorsReportsMembership from './ShowData/DebtorsReportsMembership';
import useTabIndex from '../../../../hooks/useTabIndex';

export const DebtorsReports = () => {
  const [gymSelection, setGymSelection] = useState(null);
  const [type, setType] = useState('payg');
  const [filteredDebtorsReportPayg, setFilteredDebtorsReportPayg] = useState([]);
  const [filteredDebtorsReportMembership, setFilteredDebtorsReportMembership] = useState([]);
  const [clientNameFilter, setClientNameFilter] = useState('');
  const { activeTabIndex, onTabChange } = useTabIndex();

  const { data, isLoading, error } = useQuery(['fetchDebtorsReport', gymSelection], () =>
    fetchDebtorsReport(gymSelection !== null ? gymSelection._id : null)
  );

  useEffect(
    () => {
      if (data && !isLoading && !error) {
        filterDataBySearchText(clientNameFilter);
      }
    }, // eslint-disable-next-line
    [gymSelection, data, isLoading, error]
  );

  const filterDataBySearchText = (value) => {
    if (value === '') {
      setFilteredDebtorsReportPayg(data.unpaidOneOffCharges);
      setFilteredDebtorsReportMembership(data.failedSubscriptionCharges);
    } else {
      setFilteredDebtorsReportPayg(
        data.unpaidOneOffCharges.filter(
          (item) => item.name.toLowerCase().includes(value) || item.lastName.toLowerCase().includes(value)
        )
      );
      setFilteredDebtorsReportMembership(
        data.failedSubscriptionCharges.filter(
          (item) => item.name.toLowerCase().includes(value) || item.lastName.toLowerCase().includes(value)
        )
      );
    }
    setClientNameFilter(value);
  };

  const renderData = () => {
    if (isLoading) {
      return <Loading />;
    }
    if (error) {
      return <Notification message="Something went wrong. Please try again" status="critical" />;
    }

    return type === 'payg' ? (
      <DebtorsReportsPayg data={filteredDebtorsReportPayg} />
    ) : (
      <DebtorsReportsMembership data={filteredDebtorsReportMembership} />
    );
  };

  return (
    <div className={classes.root}>
      <ExportToCSV
        disabled={
          type === 'payg' ? filteredDebtorsReportPayg.length === 0 : filteredDebtorsReportMembership.length === 0
        }
        data={type === 'payg' ? filteredDebtorsReportPayg : filteredDebtorsReportMembership}
        type={type === 'payg' ? 'debtorsReportsPayg' : 'debtorsReportsMembership'}
        gymName={gymSelection !== null ? gymSelection.name : 'All gyms'}
      />

      <div className={classes.filters}>
        <GymSelector
          onSelect={setGymSelection}
          defaultChecked={null}
          refresh
          placeHolder={gymSelection === null ? 'All gyms' : 'Gym name'}
        />
        <Filter title="Filter by name" placeHolder={'Client name'} onFilter={filterDataBySearchText} />
      </div>

      <Tabs justify="start" data-cy="tabPanel" activeIndex={activeTabIndex} onActive={onTabChange}>
        <Tab title="Pay as you go" onClick={() => setType('payg')}>
          {renderData()}
        </Tab>
        <Tab title="Membership" onClick={() => setType('membership')}>
          {renderData()}
        </Tab>
      </Tabs>
    </div>
  );
};

export default withAdminDashboard(DebtorsReports);
