import React, { useState } from 'react';
import { sortDaysAndTme } from '../../../../../shared/date-utility';
import classes from './ShowTrainersShifts.module.scss';
import { firstLetterToUpperCase } from '../../../../../shared/utility';

const ShowTrainersShifts = ({ shifts }) => {
  const [show, setShow] = useState(false);
  const sortedShifts = sortDaysAndTme(shifts);

  const showHideBtn = (
    <div onClick={() => setShow(!show)} className={classes.showHideBtn}>
      {show ? 'Hide' : 'Show'} shifts
    </div>
  );

  const showShifts = () => {
    const singleShift = ({ day, startingFrom, endsAt, _id }) => (
      <div className={classes.shifts} id={_id}>
        <div>{firstLetterToUpperCase(day)}</div>
        <div>{startingFrom}</div>
        <div>{endsAt}</div>
      </div>
    );
    const header = (
      <div className={[classes.shifts, classes.shiftsHeader].join(' ')}>
        <div>Day:</div>
        <div>Starts:</div>
        <div>Ends:</div>
      </div>
    );
    if (show) {
      return (
        <div>
          {header}
          {sortedShifts.map((shift) => singleShift(shift))}
        </div>
      );
    }
    return null;
  };

  if (sortedShifts.length > 0) {
    return (
      <div>
        {showHideBtn}
        {showShifts()}
      </div>
    );
  }
  return null;
};

export default ShowTrainersShifts;
