import React, { useState, useEffect } from 'react';
import classes from './PayrollReports.module.scss';
import withAdminDashboard from '../../../../store/hoc/withAdminDashboard';
import ExportToCSV from '../../ExportToCSV/ExportToCSV';
import Filter from '../../../../components/Filter';
import PayrollReportData from './PayrollReportData';
import ClassTypesCheckboxes, {
  returnSelectedClassTypesAsArray
} from '../../../../components/Selectors/ClassTypesCheckboxes/ClassTypesCheckboxes';
import TrainerSelector from '../../../../components/Selectors/TrainerSelector/TrainerSelector';
import axios from '../../../../axios-global';
import Loading from '../../../../components/Loading/Loading';
import Notification from 'grommet/components/Notification';

export const PayrollReports = ({ selectedGym }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedClassType, setSelectedClassType] = useState([]);
  const [selectedTrainers, setSelectedTrainers] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);

  useEffect(() => {
    const getReport = () => {
      setIsLoading(true);
      const urlParams = {
        fromDate: startDate,
        toDate: endDate,
        trainerIds: selectedTrainers.map((elem) => elem._id),
        classTypes: selectedClassType
      };
      let url = '/users/trainers/timesheet';
      url = `${url}?${new URLSearchParams(urlParams).toString()}`;

      axios
        .get(url)
        .then((response) => {
          setIsLoading(false);
          setIsError(null);
          setFetchedData(() => response.data);
        })
        .catch((err) => {
          setIsLoading(false);
          setIsError(
            err.response.data.errors[0]?.param === 'classTypes'
              ? 'Please select at least one class type'
              : err.response.data.errors[0].msg
          );
        });
    };
    if (selectedTrainers === null) setFetchedData([]);
    if (startDate?.length > 0 && endDate?.length > 0 && selectedTrainers?.length > 0) getReport();
  }, [startDate, endDate, selectedTrainers, selectedClassType]);
  const onCheckboxChange = (checkboxes) => setSelectedClassType(returnSelectedClassTypesAsArray(checkboxes));

  const renderData = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (isError !== null) {
      return <Notification message={isError} status="critical" />;
    }

    if (fetchedData.length === 0) {
      return <h3>No history</h3>;
    }
    return fetchedData.map((elm) => <PayrollReportData key={elm._id} trainer={elm.trainer} data={elm.classes} />);
  };

  return (
    <>
      <ExportToCSV
        data={fetchedData}
        type={'payrollReport'}
        gymName={selectedGym.name}
        disbaled={fetchedData.length === 0}
      />
      <div className={classes.filters}>
        <div>
          <Filter type={'date'} title={'From date'} onFilter={setStartDate} />
          <Filter type={'date'} title={'To date'} onFilter={setEndDate} />
          <div>
            <h4>Trainers</h4>
            <TrainerSelector gymId={selectedGym._id} onSelectTrainer={setSelectedTrainers} showLabel={false} multiple />
          </div>
        </div>
        <div className={classes.classTypes}>
          <ClassTypesCheckboxes onChange={onCheckboxChange} gym={selectedGym} />
        </div>
      </div>
      {renderData()}
    </>
  );
};

export default withAdminDashboard(PayrollReports);
