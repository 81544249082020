import React, { Component } from 'react';
import { withRouter, Route, Switch, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from './store/actions/index';

import { QueryClient, QueryClientProvider } from 'react-query';

import { loadStripe } from '@stripe/stripe-js/pure';
import { Elements } from '@stripe/react-stripe-js';

import { companyDetails } from '../src/brandConfig/companyDetails';
import { isFullAccessAdminOrTrainer, returnGymsByAccess } from './shared/userFunctions';

import CookieBanner from 'react-cookie-banner';
import App from 'grommet/components/App';
import Toast from 'grommet/components/Toast';
import Layout from './hoc/Layout/Layout';
import Homepage from './containers/Homepage/Homepage';
import Contact from './components/Contact/Contact';
import PrivacyPolicy from './containers/PrivacyPolicy/PrivacyPolicy';
import TermsOfWebsite from './containers/TermsOfWebsite/TermsOfWebsite';
import TermsOfService from './containers/TermsOfService/TermsOfService';
import Login from './containers/Login/Login';
import WhiteBackground from './hoc/WhiteBackground/WhiteBackground';
import ForgotPassword from './containers/ForgotPassword/ForgotPassword';
import ResetPassword from './containers/ResetPassword/ResetPassword';
import Logout from './containers/Logout/Logout';
import Signup from './containers/Signup/Signup';
import ClientDashboard from './containers/ClientDashboard/ClientDashboard';
import AdminSelectGym from './components/AdminSelectGym/AdminSelectGym';
import AdminAddGym from './containers/AdminDashboard/ManageGym/AdminAddGym/AdminAddGym';
import LocationComponent from './containers/Location/Location';
import Error404 from './components/Error404/Error404';
import AdminTrainerDashboard from './containers/AdminDashboard/AdminTrainerDashboard';
import ScrollToTop from './components/Utils/ScrollToTop/ScrollToTop';
import TrainerSettings from './containers/UserSettings/Trainer/TrainerSettings';
import UserSettings from './containers/UserSettings/User/UserSettings';
import Loading from './components/Loading/Loading';

export const stripePromise = loadStripe(
  process.env.NODE_ENV === 'development' ? process.env.REACT_APP_STRIPE_KEY_DEV : process.env.REACT_APP_STRIPE_KEY_PROD,
  { apiVersion: '2020-08-27' }
);

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData,
    userDataLoading: state.auth.loading,
    isAuthenticated: state.auth.token !== null,

    locations: state.location.locations,
    loading: state.location.loading,
    cityNames: state.location.cityNames,
    cityNamesLoading: state.location.cityNamesLoading,
    cityNamesError: state.location.cityNamesError,

    showGlobalError500: state.utils.showGlobalError500,
    showGlobalNetworkError: state.utils.showGlobalNetworkError,
    showUnauthorizedError: state.utils.showUnauthorizedError
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchLocations: () => dispatch(actions.fetchLocations()),
    onAuthCheckStatus: () => dispatch(actions.authCheckStatus()),
    onFetchCityNames: () => dispatch(actions.fetchCityNames()),
    onSetGlobalError500: (status) => dispatch(actions.setGlobalError500(status)),
    onSetUnauthorizedError: (status) => dispatch(actions.setUnauthorizedError(status))
  };
};

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchAllOnWindowFocus: false,
      retry: 0,
      enabled: true
    }
  }
});

class SsApp extends Component {
  componentDidMount() {
    this.props.onAuthCheckStatus();
    this.props.onFetchCityNames();
    document.title = companyDetails.name;
  }

  renderCookieBannerLink = () => {
    return (
      <>
        &nbsp;
        <NavLink style={{ display: 'inline', margin: '0' }} to="/privacy-policy">
          &nbsp;Privacy Policy.
        </NavLink>
      </>
    );
  };

  goToLogin = () => {
    localStorage.removeItem('token');
    if (window.location.pathname === '/gym') {
      this.props.history.push('/login');
    }
  };

  render() {
    const mainDivStyles = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '80vh'
    };
    let mainApp = (
      <div style={mainDivStyles}>
        {this.props.showGlobalNetworkError ? (
          <h1 style={{ textAlign: 'center', color: 'red' }}>
            Server unavailable.
            <br /> Please contact support.
          </h1>
        ) : (
          <Loading size="xlarge" />
        )}
      </div>
    );

    let signup = null;
    if (!this.props.isAuthenticated) {
      signup = <Route path="/gym/:gymId/signup" component={Signup} />;
    }

    let logout = null;
    if (this.props.isAuthenticated) {
      logout = <Route path="/logout" component={Logout} />;
    }

    let adminAddGym = null;
    let adminTrainerSelectGym = null;
    let adminTrainerGymDashboard = null;

    let userDataLoading = (
      <>
        <Route
          path="/gym"
          exact
          render={() => (
            <div style={mainDivStyles}>
              <Loading size="xlarge" />
            </div>
          )}
        />
        <Route
          path="/gym/:id"
          exact
          render={() => (
            <div style={mainDivStyles}>
              <Loading size="xlarge" />
            </div>
          )}
        />
      </>
    );

    if (
      (this.props.userData && this.props.userData.role === 'user') ||
      (this.props.userData && this.props.userData.role !== 'user' && this.props.locations)
    ) {
      userDataLoading = null;
    }

    if (this.props.userData && this.props.userData.role !== 'user') {
      if (!this.props.locations) {
        this.props.onFetchLocations();
      } else {
        const isFullAccess = isFullAccessAdminOrTrainer(this.props.userData);
        const gymsList = isFullAccess
          ? this.props.locations
          : returnGymsByAccess(this.props.locations, this.props.userData.gymId);

        adminAddGym =
          this.props.userData.role === 'admin' && isFullAccess ? (
            <Route path="/add-gym" exact component={AdminAddGym} />
          ) : null;

        adminTrainerSelectGym = (
          <Route
            path="/gym"
            exact
            render={() => (
              <AdminSelectGym gymsList={gymsList} adminFullAccess={isFullAccess} history={this.props.history} />
            )}
          />
        );

        adminTrainerGymDashboard = <Route path="/gym/:gymId" component={AdminTrainerDashboard} />;
      }
    }

    let userSettings = null;
    if (this.props.userData && this.props.userData.role) {
      userSettings = (
        <Route
          path={`/user/settings`}
          exact
          render={() =>
            this.props.userData.role === 'user' ? (
              <UserSettings />
            ) : this.props.userData.role === 'trainer' ? (
              <TrainerSettings />
            ) : (
              <h1 style={{ marginTop: '250px', textAlign: 'center' }}>Admin settings are not available for now.</h1>
            )
          }
        />
      );
    }

    let clientDashboard = null;
    if (this.props.userData && this.props.userData.role === 'user') {
      clientDashboard = <Route path="/gym" component={ClientDashboard} />;
    }
    if (
      (!this.props.isAuthenticated && this.props.userData === null) ||
      (this.props.isAuthenticated && this.props.userData !== null) ||
      this.props.cityNamesLoading ||
      !this.props.userDataLoading ||
      !this.props.loading
    ) {
      mainApp = (
        <ScrollToTop>
          <Switch>
            <Route path="/" exact component={Homepage} />
            <Route
              path="/login"
              render={() => (
                <WhiteBackground>
                  <Login />
                </WhiteBackground>
              )}
            />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/reset-password" component={ResetPassword} />

            {signup}
            {logout}

            {adminAddGym}

            {adminTrainerSelectGym}
            {adminTrainerGymDashboard}

            {/* user links */}
            {clientDashboard}

            {userSettings}

            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/terms-of-website" component={TermsOfWebsite} />
            <Route path="/terms-of-service" component={TermsOfService} />
            <Route path="/contact" component={Contact} />
            <Route path="/:city/:urlName" component={LocationComponent} />
            {userDataLoading}
            <Route component={Error404} />
          </Switch>
        </ScrollToTop>
      );
    }

    return (
      <App>
        <Elements stripe={stripePromise}>
          <QueryClientProvider client={queryClient}>
            {this.props.showGlobalError500 && (
              <Toast status="critical" onClose={() => this.props.onSetGlobalError500(false)}>
                Server error, please report this to support.
              </Toast>
            )}
            {this.props.showUnauthorizedError && (
              <Toast status="critical" onClose={() => this.props.onSetUnauthorizedError(false)}>
                {this.goToLogin()}
                Your session expired, please log in again.
              </Toast>
            )}
            <Layout>{mainApp}</Layout>
            <CookieBanner
              data-cy={'cookie_banner'}
              disableStyle={true}
              link={this.renderCookieBannerLink()}
              message={`We use cookies to ensure that we give you the best experience on our website. If you continue without changing your settings, we will assume that you are happy to receive all cookies on the ${companyDetails.name}  website. However, you can change your cookie settings at any time. More details regarding our cookie policy can be found within the `}
              buttonMessage="Close"
              dismissOnScroll={false}
              dismissOnClick={false}
            />
          </QueryClientProvider>
        </Elements>
      </App>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SsApp));
