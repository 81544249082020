import { useState } from 'react';

export const returnArrayAsObject = (arr) =>
  arr.reduce((acc, cur) => {
    acc[cur] = true;
    return acc;
  }, {});

export const returnObjectAsArray = (classTypes) => {
  const keys = Object.keys(classTypes);
  return keys.filter((key) => !!classTypes[key]);
};

const useCustomCheckboxes = (initialState = [], onChange = () => {}) => {
  const [selected, setSelected] = useState(returnArrayAsObject(initialState));
  const [selectedArray, setSelectedArray] = useState([]);

  const onCheckboxCheck = (element) => {
    const newSelection = {
      ...selected,
      [element]: !selected[element]
    };
    const newCheckboxesArray = returnObjectAsArray(newSelection);

    setSelected(newSelection);
    setSelectedArray(newCheckboxesArray);
    onChange(newCheckboxesArray);
  };

  return {
    onCheckboxCheck,
    selected,
    selectedArray
  };
};

export default useCustomCheckboxes;
