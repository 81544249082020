import React, { useState, useEffect } from 'react';
import classes from './ManageWorkingDays.module.scss';
import CustomButton from '../../../../../components/CustomButton/CustomButton';
import DateTime from 'grommet/components/DateTime';
import { daysOfWeek } from '../../../../../constants/timeConstants';
import useWindowSize from '../../../../../hooks/useWindowSize';
import Select from 'grommet/components/Select';
import { firstLetterToUpperCase } from '../../../../../shared/utility';
import CloseIcon from 'grommet/components/icons/base/Close';
import AddIcon from 'grommet/components/icons/base/Add';

const ManageWorkingDays = ({ shifts, isEditTrainer, shiftGenerated, isShiftAreValid }) => {
  const [fields, setFields] = useState([{ day: 'monday', startingFrom: null, endsAt: null }]);
  const [shiftsAreValid, setShiftsAreValid] = useState(true);
  const [initialRender, setInitialRender] = useState(true);
  const [width] = useWindowSize();

  useEffect(
    () => {
      if (isEditTrainer) {
        if (shifts !== undefined && shifts.length !== 0) {
          setFields(shifts);
        } else {
          setFields(fields);
        }
      }
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      setShiftsAreValid(true);
      if (isEditTrainer) {
        validateFields(fields);
      } else {
        if (initialRender === true) {
          setInitialRender(false);
        } else {
          validateFields(fields);
        }
      }
    },
    // eslint-disable-next-line
    [fields]
  );

  useEffect(
    () => {
      handleShiftChange();
    },
    // eslint-disable-next-line
    [shiftsAreValid]
  );

  const validateFields = (fields) => {
    const invalidFields = fields.filter(
      (field) => field.startingFrom === null || field.endsAt === null || field.startingFrom >= field.endsAt
    );

    if (invalidFields !== null && invalidFields.length !== 0) {
      setShiftsAreValid(false);
    } else {
      handleShiftChange();
    }
  };

  const handleSelectChange = (i, event) => {
    const values = [...fields];
    values[i].day = event.value.value;
    setFields(values);
  };

  const handleShiftChange = () => {
    shiftGenerated(fields);
    isShiftAreValid(shiftsAreValid);
  };

  const handleTimeChange = (i, event, key) => {
    const values = [...fields];

    if (key === 'fromTime') {
      values[i].startingFrom = event;
    } else {
      values[i].endsAt = event;
    }

    setFields(values);
  };

  const handleRemove = (i) => {
    const values = [...fields];
    values.splice(i, 1);
    setFields(values);
  };

  const handleAdd = () => {
    const values = [...fields];
    values.push({ day: 'monday', startingFrom: null, endsAt: null });
    setFields(values);
  };

  const renderLabel = (text, idx) => {
    if (width > 700) {
      return idx === 0 && <h4 className={classes.label}>{text}</h4>;
    }
    return <div className={classes.xsLabel}>{text}</div>;
  };
  return (
    <div>
      {fields.map((field, idx) => {
        return (
          <div className={classes.root} key={`${field}-${idx}`}>
            <div>
              {renderLabel('Day', idx)}
              <Select
                options={daysOfWeek}
                value={firstLetterToUpperCase(field.day)}
                onChange={(e) => handleSelectChange(idx, e)}
              />
            </div>

            <div>
              <div>
                {renderLabel('From', idx)}
                <DateTime
                  format="HH:mm"
                  value={field.startingFrom || ''}
                  onChange={(e) => handleTimeChange(idx, e, 'fromTime')}
                />
              </div>
            </div>
            <div>
              {renderLabel('To', idx)}
              <DateTime
                format="HH:mm"
                value={field.endsAt || ''}
                onChange={(e) => handleTimeChange(idx, e, 'toTime')}
              />
            </div>

            <div className={classes.buttonContainer}>
              {fields.length !== 1 ? (
                <CustomButton
                  className={`${idx === 0 ? classes.btnFirst : classes.btn}`}
                  secondary
                  onClick={() => handleRemove(idx)}
                  icon={<CloseIcon />}
                />
              ) : (
                <div></div>
              )}

              {idx === fields.length - 1 && (
                <CustomButton
                  className={`${idx === 0 ? classes.btnFirst : classes.btn}`}
                  primary
                  onClick={() => handleAdd()}
                  icon={<AddIcon />}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ManageWorkingDays;
