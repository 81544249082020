import { ALL_CURRENCIES } from '../../../constants';
import { getCurrencyDetail } from '../../../shared/utility';

const unEditableFields = [];
export const returnTemplateFields = (classType, data = null) => {
  let result = {
    name: {
      label: 'Name',
      type: 'text',
      placeholder: 'Enter name',
      value: data !== null ? data.name : null,
      validation: {
        required: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    defaultDescription: {
      label: 'Description',
      type: 'text',
      placeholder: 'Enter description',
      value: data && data.defaultDescription ? data.defaultDescription : null,
      validation: {
        required: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    currency: {
      label: 'Currency',
      type: 'select',
      options: ALL_CURRENCIES,
      placeholder: 'Select',
      value: getCurrencyDetail(data && data.currency),
      validation: {
        required: true
      },
      valid: true,
      touched: false,
      errorText: null
    },
    defaultCost: {
      label: 'Cost',
      type: 'text',
      placeholder: 'Enter cost',
      value: data && data.defaultCost !== undefined ? data.defaultCost : null,
      validation: {
        required: true,
        isNumericDecimal: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    membershipClassPrice: {
      label: 'Membership Class Price',
      type: 'text',
      placeholder: 'Enter Membership Class price',
      value: data && data.membershipClassPrice !== undefined ? data.membershipClassPrice : null,
      validation: {
        required: true,
        isNumericDecimal: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    defaultLimit: {
      label: 'Capacity',
      type: 'text',
      placeholder: 'Enter capacity',
      value: data && data.defaultLimit ? data.defaultLimit : null,
      validation: {
        required: true,
        isNumeric: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    defaultDuration: {
      label: 'Duration (in minutes)',
      type: 'text',
      placeholder: 'Enter duration',
      value: data && data.defaultDuration ? data.defaultDuration : null,
      validation: {
        required: true,
        isNumericDecimal: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    defaultMinimalTimeCancellationInHours: {
      label: 'Minimal cancellation time (in hours)',
      type: 'text',
      placeholder: 'Enter time',
      value: data && data.defaultMinimalTimeCancellationInHours ? data.defaultMinimalTimeCancellationInHours : null,
      validation: {
        required: true,
        isNumericDecimal: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    active: {
      label: 'Active',
      type: 'select',
      options: ['yes', 'no'],
      placeholder: 'Select',
      value: data && data.active != null ? (data.active ? 'yes' : 'no') : 'yes',
      validation: {
        required: true
      },
      valid: true,
      touched: false,
      errorText: null
    },
    private: {
      label: 'Private',
      type: 'select',
      options: ['yes', 'no'],
      placeholder: 'Select',
      value: data && data.private != null ? (data.private ? 'yes' : 'no') : 'no',
      validation: {
        required: true
      },
      valid: true,
      touched: false,
      errorText: null
    }
  };

  if (data && unEditableFields) {
    unEditableFields.forEach((elem) => delete result[elem]);
  }

  return result;
};
