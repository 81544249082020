import moment from 'moment';
import { returnClassPrice } from '../../../../shared/classFunctions';
import classes from './table.module.scss';
import React from 'react';
import { getCurrencySymbol } from '../../../../shared/utility';

//TODO: Current class is not well presented at value ATTENDEES -> waiting for SS-1497
//TODO: check below function
//TODO: Backend bug to test -
const returnAttendees = ({ joinedUsers, limit }) =>
  joinedUsers !== null && limit !== null
    ? Number(limit) - Number(joinedUsers) > 0
      ? `${joinedUsers}/${limit}`
      : 'Full'
    : '';
export const table = (ssClass = {}, userData = {}) => [
  {
    label: 'Date',
    value: ssClass ? moment(ssClass.classDate, 'YYYY-MM-DD').format('DD/MM') : ''
  },
  {
    label: 'Time',
    value: ssClass ? ssClass.classTime : ''
  },
  {
    label: 'Name',
    value: ssClass ? ssClass.name : ''
  },
  {
    label: 'Price',
    value: ssClass ? `${getCurrencySymbol(ssClass.currency)} ${returnClassPrice(ssClass, userData)}` : ''
  },
  {
    label: 'Duration',
    value: ssClass ? ssClass.duration : ''
  },
  {
    label: 'Attendees',
    value: returnAttendees(ssClass)
  }
];

export const moveClientsTableData = (selectedClass, selectedClient, array, isSelectable = true, onClick = () => {}) => {
  const singleClass = (ssClass) => {
    const classStyles = isSelectable
      ? ssClass && ssClass.isFull
        ? [classes.grid, classes.singleClass, classes.fullClass].join(' ')
        : selectedClass && selectedClass._id === ssClass._id
        ? [classes.grid, classes.singleClass, classes.selectedClass].join(' ')
        : [classes.grid, classes.singleClass].join(' ')
      : [classes.grid, classes.tableHeaderClass].join(' ');

    return (
      <div
        key={ssClass ? ssClass._id : 'singleClass'}
        className={classStyles}
        style={{ gridTemplateColumns: `repeat(${table().length},1fr)` }}
        onClick={() => !ssClass.isFull && onClick(ssClass)}>
        {table(ssClass, selectedClient).map(({ value }, i) => (
          <div key={i}>{value}</div>
        ))}
      </div>
    );
  };

  return <div>{array.map((elem) => singleClass(elem))}</div>;
};

export const moveClientsTableHeaderLabels = () => (
  <div
    className={[classes.grid, classes.tableHeader].join(' ')}
    style={{ gridTemplateColumns: `repeat(${table().length},1fr)` }}>
    {table().map(({ label }, i) => (
      <div key={i}>{label}</div>
    ))}
  </div>
);
