import React from 'react';

import List from 'grommet/components/List';
import ListItem from 'grommet/components/ListItem';

import CurrentStatus from './CurrentStatus/CurrentStatus';
import SingleMembershipPlan from '../../../components/SingleMembershipPlan/SingleMembershipPlan';
import moment from 'moment';

export default (props) => {
  let plans = [];
  if (props.showPlansList && props.availablePlans.length > 0 && !props.currentStatus.active) {
    for (const plan in props.availablePlans) {
      if (props.availablePlans.hasOwnProperty(plan)) {
        plans.push(props.availablePlans[plan]);
      }
    }
  }
  const isActiveMembershipPlan =
    props.userData.currentMembershipPlan &&
    props.userData.currentMembershipPlan.current_period_end &&
    moment(props.userData.currentMembershipPlan.current_period_end).isSameOrAfter(moment());
  const hasUserSignUpMembershipPlan = !!(
    props.userData.currentMembershipPlan && props.userData.currentMembershipPlan.planName
  );

  const displayListOrSpinner = () => {
    if (props.currentStatus && !props.currentStatusLoading) {
      return (
        <div data-cy={'membership'}>
          <List>
            <ListItem
              direction="column"
              align="start"
              separator="horizontal"
              style={hasUserSignUpMembershipPlan ? (isActiveMembershipPlan ? {} : { background: '#fcc9c0' }) : {}}>
              <CurrentStatus
                currentStatus={props.currentStatus}
                openPlansList={props.openPlansList}
                userData={props.userData}
                hasUserSignUpMembershipPlan={hasUserSignUpMembershipPlan}
              />
            </ListItem>

            {plans.map((plan) => (
              <ListItem
                style={{ background: '#f2f2f3' }}
                key={plan._id}
                direction="column"
                align="start"
                separator="horizontal">
                <SingleMembershipPlan
                  plan={plan}
                  key={plan.planId}
                  selectPlan={(plan) => {
                    props.buyPlan(plan);
                  }}
                  joiningFeePaid={props.userData.joiningFeePaid}
                />
              </ListItem>
            ))}
          </List>
        </div>
      );
    }

    return null;
  };

  return displayListOrSpinner();
};
