import React from 'react';

import moment from 'moment';
import Status from 'grommet/components/icons/Status';

import classes from './ProfilePaymentHistory.module.scss';
import { getCurrencySymbol } from '../../../shared/utility';

const ProfilePaymentHistory = ({ lastPaymentInfo }) => {
  return (
    <div>
      <Status className={classes.icon} value={lastPaymentInfo.success ? 'ok' : 'critical'} />
      {moment(lastPaymentInfo.date).format('Do MMM YY h:mm A')}
      {lastPaymentInfo.success
        ? `, ${getCurrencySymbol(lastPaymentInfo.currency)}
        ${lastPaymentInfo.amount}`
        : `, reason: ${lastPaymentInfo.reason}`}
    </div>
  );
};
export default ProfilePaymentHistory;
