import React from 'react';
import List from 'grommet/components/List';
import ListItem from 'grommet/components/ListItem';
import { withRouter } from 'react-router-dom';

export const Reports = ({ history }) => {
  const reportList = [
    {
      reportName: 'Passes Report',
      url: 'passes-reports'
    },
    {
      reportName: 'Attendance Report',
      url: 'class-attendances-reports'
    },
    {
      reportName: 'Recorded Attendances Report',
      url: 'attendances-reports'
    },
    {
      reportName: 'Debtors',
      url: 'debtors-reports'
    },
    {
      reportName: 'Payroll Report',
      url: 'payroll-reports'
    }
  ];

  return (
    <div data-cy="reports">
      <List>
        {reportList.map((report, index) => (
          <ListItem key={index} onClick={() => history.push(`${history.location.pathname}/${report.url}`)}>
            <span>{report.reportName}</span>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default withRouter(Reports);
