import React from 'react';

import withUser from '../../../store/hoc/withUser';
import { withRouter } from 'react-router-dom';
import { returnClassConfig } from '../../../shared/gymFunctions';
import { useQuery } from 'react-query';
import { fetchClasses, fetchVideoPasses } from '../../../apiFunctions/apiFunctions';
import { returnClassesDetails } from '../../../shared/classFunctions';
import UserView from './UsersView/UserView';
import AdminView from './UsersView/AdminView';
import { CLASS, PRODUCT_TYPES } from '../../../constants';
import TrainerView from './UsersView/TrainerView';
import { store } from '../../../store';
import { updateVideoProducts } from '../../../store/actions/client';

const ManageTimetable = ({ userData, userProducts = [], selectedGym, classType }) => {
  const gymId = userData.role === 'user' ? userData.gymId : selectedGym._id;
  const classConfig = returnClassConfig(selectedGym.settings.classConfig, classType);
  const classesDetails = returnClassesDetails(classType);
  const vodEnabled = selectedGym?.settings?.videoOnDemand || false;

  const {
    data: classesList,
    error: classesListError,
    isLoading: classesListLoading,
    isFetching
  } = useQuery(['upcomingClasses', classType], () => fetchClasses(classType, gymId), {
    enabled: classType !== CLASS.VIDEO
  });
  const { data: videoProducts } = useQuery(['videoPasses', classType], () => fetchVideoPasses({ classType, gymId }), {
    enabled: classType !== CLASS.VIDEO && vodEnabled,
    onSuccess: (data) => {
      store.dispatch(updateVideoProducts(data));
    }
  });

  const userVideoProducts = vodEnabled
    ? userProducts.filter(
        (product) => product.__t === PRODUCT_TYPES.VIDEO && product.forClass === classType && !!product?.active
      )
    : undefined;

  if (userData.role === 'user') {
    return (
      <UserView
        classesList={classesList}
        classesListLoading={classesListLoading || isFetching}
        classesListError={classesListError}
        classesDetails={classesDetails}
        classConfig={classConfig}
        userVideoProducts={userVideoProducts}
        videoProducts={videoProducts}
      />
    );
  }

  if (userData.role === 'trainer') {
    return (
      <TrainerView
        classType={classType}
        classesList={classesList}
        classesListLoading={classesListLoading || isFetching}
        classesListError={classesListError}
        classesDetails={classesDetails}
        classConfig={classConfig}
      />
    );
  }

  return (
    <AdminView
      classType={classType}
      classesList={classesList}
      classesListLoading={classesListLoading || isFetching}
      classesListError={classesListError}
      classesDetails={classesDetails}
      classConfig={classConfig}
    />
  );
};

export default withRouter(withUser(ManageTimetable));
