import {
  CLASS_DETAILS,
  GYM_CLASS_DETAILS,
  INDUCTION_CLASS_DETAILS,
  PERSONAL_TRAINING_SESSION_DETAILS,
  SWIMMING_CLASS_DETAILS,
  TENNIS_CLASS_DETAILS,
  MASSAGE_CLASS_DETAILS,
  PRODUCT_TYPES,
  CLASS_TYPES,
  CLASS,
  VIDEO_CLASS_DETAILS
} from '../constants';
import moment from 'moment';

export const countClassAttendees = (attendanceList) =>
  attendanceList && attendanceList.length > 0
    ? attendanceList.filter((elem) => elem.status === 'active' || elem.status === 'attended').length
    : 0;
export const checkAreAnyAttendees = (attendanceList) =>
  attendanceList && attendanceList.length > 0
    ? attendanceList.filter((elem) => ['active', 'pending', 'absent', 'attended'].includes(elem.status)).length > 0
    : false;

export const returnIsFreeClass = (selectedClass) =>
  selectedClass.__t === 'personalTraining' ? false : selectedClass.cost === 0;

export const isFullClass = (ssClass) => ssClass.limit - countClassAttendees(ssClass.attendanceList) === 0;

export const returnProductTypeName = (productType) => {
  if (productType === PRODUCT_TYPES.PERSONAL_TRAINING_PASS) {
    return `Training sessions`;
  } else if (productType === PRODUCT_TYPES.CLASS_PASS) {
    return `Class passes`;
  } else if (productType === PRODUCT_TYPES.GYM_CLASS) {
    return `Gym bookings passes`;
  } else if (productType === PRODUCT_TYPES.SWIMMING_CLASS) {
    return `Swim bookings passes`;
  } else if (productType === PRODUCT_TYPES.TENNIS_CLASS) {
    return `Tennis bookings passes`;
  } else if (productType === PRODUCT_TYPES.MASSAGE_CLASS) {
    return `Massage bookings passes`;
  } else if (productType === PRODUCT_TYPES.INDUCTION) {
    return `Induction passes`;
  } else if (productType === PRODUCT_TYPES.VIDEO) {
    return `Video passes`;
  }
};

export const returnProductName = (productType, attributeName = 'name') => {
  switch (productType) {
    case CLASS_DETAILS.type:
      return CLASS_DETAILS[attributeName];
    case GYM_CLASS_DETAILS.type:
      return GYM_CLASS_DETAILS[attributeName];
    case INDUCTION_CLASS_DETAILS.type:
      return INDUCTION_CLASS_DETAILS[attributeName];
    case PERSONAL_TRAINING_SESSION_DETAILS.type:
      return PERSONAL_TRAINING_SESSION_DETAILS[attributeName];
    case SWIMMING_CLASS_DETAILS.type:
      return SWIMMING_CLASS_DETAILS[attributeName];
    case TENNIS_CLASS_DETAILS.type:
      return TENNIS_CLASS_DETAILS[attributeName];
    case MASSAGE_CLASS_DETAILS.type:
      return MASSAGE_CLASS_DETAILS.name;
    case VIDEO_CLASS_DETAILS.type:
      return VIDEO_CLASS_DETAILS[attributeName];
    default:
      return '';
  }
};

export const returnClassesDetails = (classType) => {
  switch (classType) {
    case CLASS.CLASS:
      return CLASS_DETAILS;
    case CLASS.PERSONAL_TRAINING:
      return PERSONAL_TRAINING_SESSION_DETAILS;
    case CLASS.INDUCTION:
      return INDUCTION_CLASS_DETAILS;
    case CLASS.GYM_CLASS:
      return GYM_CLASS_DETAILS;
    case CLASS.SWIMMING_CLASS:
      return SWIMMING_CLASS_DETAILS;
    case CLASS.TENNIS_CLASS:
      return TENNIS_CLASS_DETAILS;
    case CLASS.MASSAGE_CLASS:
      return MASSAGE_CLASS_DETAILS;
    case CLASS.VIDEO:
      return VIDEO_CLASS_DETAILS;
    default:
      return '';
  }
};

export const classDateAndTime = (selectedClass) =>
  moment(selectedClass.classDate).set({
    hour: moment(selectedClass.classTime, ['h:mm A']).get('hour'),
    minute: moment(selectedClass.classTime, ['h:mm A']).get('minute')
  });

export const returnCancelClassDetails = (selectedClass, userRole) => {
  const messages = {
    clientCancel:
      'Are you sure you wish to cancel? Refund/credit will be issued if applicable. Please see FAQs and terms and conditions for cancellation policy.',
    clientLateCancellation:
      'Are you sure you wish to cancel? As this is a late cancellation no refund/credit will be issued.',
    clientFreeClassCancellation: 'Are you sure you wish to cancel?',
    adminCancelClass: 'Are you sure you wish to cancel? Any booked customers will be notified',
    adminLateCancellation:
      'Are you sure you wish to cancel? As this is a late cancellation, no refund/credit will be issued. Any booked customers will be notified',
    adminCheckTiming: "It's late cancellation. Do you want to charge customers?",
    adminCancelClassNoAttendees: 'Are you sure you wish to cancel?'
  };

  const minimalTimeCancellationInHours = selectedClass.minimalTimeCancellationInHours
    ? selectedClass.minimalTimeCancellationInHours
    : selectedClass.__t === 'class'
    ? 2
    : selectedClass.__t === 'personalTraining'
    ? 24
    : 12;
  const isLateCancellation =
    moment.duration(classDateAndTime(selectedClass).diff(moment())).asHours() < minimalTimeCancellationInHours;

  const isFreeClass = returnIsFreeClass(selectedClass);
  const checkTiming = isFreeClass ? false : isLateCancellation;
  const areAnyAttendees = checkAreAnyAttendees(selectedClass.attendanceList);

  if (userRole === 'user') {
    return {
      isLateCancellation,
      isFreeClass,
      message: isFreeClass
        ? messages.clientFreeClassCancellation
        : isLateCancellation
        ? messages.clientLateCancellation
        : messages.clientCancel
    };
  } else {
    return {
      isLateCancellation,
      isFreeClass,
      checkTiming,
      areAnyAttendees,
      message: isFreeClass
        ? messages.adminCancelClass
        : !areAnyAttendees
        ? messages.adminCancelClassNoAttendees
        : checkTiming
        ? messages.adminCheckTiming
        : isLateCancellation
        ? messages.adminLateCancellation
        : messages.adminCancelClass
    };
  }
};

export const hasClassStarted = (ssClass, addMinutes = 0) =>
  moment() > moment(classDateAndTime(ssClass)).add(addMinutes, 'minutes');

export const returnClassPrice = (ssClass, userData) =>
  userData.customerType === 'full-member'
    ? ssClass.membershipClassPrice
      ? ssClass.membershipClassPrice
      : userData.currentMembershipPlan &&
        userData.currentMembershipPlan.ssPlanId &&
        userData.currentMembershipPlan.ssPlanId.classPrice
      ? userData.currentMembershipPlan.ssPlanId.classPrice
      : ssClass.cost
    : ssClass.cost;

export const returnArrayOfClassesDetailInSelectedGym = (gymSettings) => {
  const result = [];
  CLASS_TYPES.forEach((elem) => {
    if (gymSettings[elem]) {
      result.push(returnClassesDetails(elem));
    }
  });
  return result;
};

export const returnAllUpcomingClasses = (classesList) => {
  let classes = [];
  if (classesList && classesList.length > 0) {
    classesList.forEach((elem) => (classes = Array.concat(classes, elem.classes)));
  }
  return classes;
};

export const returnDayFromClassDateAndTime = (classDateAndTime) =>
  moment(classDateAndTime, 'DD/MM/YYYY h:mm a').format('dddd').toLowerCase();
export const returnTimeFromClassDateAndTime = (classDateAndTime) =>
  moment(classDateAndTime, 'DD/MM/YYYY h:mm a').format('H:mm');

export const checkIfTrainerIsAvailableAtProvidedDateAndTime = (selectedTrainer, classDateAndTime, duration = 60) => {
  if (selectedTrainer && selectedTrainer.shifts.length > 0) {
    const day = moment(classDateAndTime, 'DD/MM/YYYY h:mm a').format('dddd').toLowerCase();
    const startTime = moment(classDateAndTime, 'DD/MM/YYYY h:mm a').format('H:mm');
    const endTime = moment(classDateAndTime, 'DD/MM/YYYY h:mm a').add(duration, 'm').format('H:mm');
    const selectedShifts = selectedTrainer.shifts.filter((elm) => elm.day === day);

    if (selectedShifts.length > 0) {
      for (let i = 0; i < selectedShifts.length; i++) {
        const shiftStartTime = moment(selectedShifts[i].startingFrom, 'H:mm');
        const shiftEndTime = moment(selectedShifts[i].endsAt, 'H:mm');
        const isStartTimeCorrect =
          moment(startTime, 'H:mm').isSameOrAfter(shiftStartTime) && moment(startTime, 'H:mm').isBefore(shiftEndTime);
        const isEndTimeCorrect =
          moment(endTime, 'H:mm').isSameOrAfter(shiftStartTime) && moment(endTime, 'H:mm').isSameOrBefore(shiftEndTime);

        if (isStartTimeCorrect && isEndTimeCorrect) {
          return true;
        }
      }
      return false;
    }
    return false;
  }
  return false;
};

export const checkIfTrainerIsAvailableAtProvidedSchedule = (selectedTrainer, schedule) => {
  let result = [];
  const checkDay = (i) => {
    const classesInDay = schedule.recurrentOn[i].split(', ');
    for (let j = 0; j < classesInDay.length; j++) {
      const dateAndTime = moment()
        .day(i)
        .set({
          hour: moment(classesInDay[j], ['hh:mm A']).get('hour'),
          minute: moment(classesInDay[j], ['hh:mm A']).get('minute'),
          second: 0,
          millisecond: 0
        });

      if (!checkIfTrainerIsAvailableAtProvidedDateAndTime(selectedTrainer, dateAndTime, schedule.duration)) {
        result.push(`${moment(dateAndTime).format('dddd')} ${classesInDay[j]}`);
      }
    }
  };

  if (selectedTrainer && selectedTrainer.shifts.length > 0) {
    for (const key in schedule.recurrentOn) {
      if (schedule.recurrentOn[key] !== '') {
        checkDay(key);
      }
    }
  }
  return result;
};

export const returnCombinedClassesList = (array = []) => {
  let result = [];
  array.map(({ classes }) => {
    result = result.concat(classes);
  });
  return result;
};

export const returnClientAttendanceDetails = (attendanceList, clientId) =>
  attendanceList.filter((elem) => elem.user._id === clientId);
