import React, { memo, useState, useEffect, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import { Draggable } from './Draggable';
import styles from './Reorder.module.scss';

const Reorder = ({ videos, onChange }) => {
  const [listItems, setListItems] = useState([]);

  useEffect(() => {
    if (videos) {
      setListItems(videos);
    }
  }, [videos]);

  const reorder = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = listItems[dragIndex];
      const updated = update(listItems, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard]
        ]
      });
      setListItems(updated);
      onChange(updated);
    },
    [listItems, onChange]
  );

  if (!videos) {
    return null;
  }

  if (!videos.length) {
    return 'No videos selected';
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <div className={styles.dragContainer}>
        <h4>Drag to reorder</h4>
        {listItems.map((item, index) => (
          <Draggable key={item._id} index={index} id={item._id} label={item.title} onChange={reorder} />
        ))}
      </div>
    </DndProvider>
  );
};

export default memo(Reorder);
