import React, { Component } from 'react';
import { connect } from 'react-redux';

import Hero from '../../brandConfig/components/HomePage/Hero';
import HomepageBody from '../../brandConfig/components/HomePage/HomepageBody';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    userData: state.auth.userData,
    selectedGym: state.admin.selectedGym
  };
};

class Homepage extends Component {
  returnDashboardLink = () => {
    if (this.props.userData && this.props.userData.role !== 'user' && this.props.selectedGym) {
      return `/gym/${this.props.selectedGym._id}`;
    }

    return '/gym';
  };

  render() {
    return (
      <>
        <Hero
          isAuthenticated={this.props.isAuthenticated}
          dashboardLink={this.returnDashboardLink()}
          history={this.props.history}
        />
        <HomepageBody />
      </>
    );
  }
}

export default withRouter(connect(mapStateToProps)(Homepage));
