import React, { useState, useEffect } from 'react';
import { Select } from 'grommet';
import withAdminDashboard from '../../../store/hoc/withAdminDashboard';
import { returnGymsPerActiveClassType } from '../../../shared/gymFunctions';
import { returnGymsByAccess } from '../../../shared/userFunctions';
import RefreshIcon from 'grommet/components/icons/base/Refresh';
import classes from './GymSelector.module.scss';

/**
 * onSelect function -> return selected gym details
 */

const mapGymsAsOptions = (array) =>
  array.map((elem) => {
    return {
      label: elem.name,
      id: elem._id,
      gym: elem
    };
  });

const GymSelector = ({
  locations,
  userData,
  onSelect = () => {},
  classType = null,
  filterGymsWithClassType = false,
  defaultChecked = null,
  refresh = false,
  placeHolder = 'Gym name'
}) => {
  const gyms = filterGymsWithClassType
    ? mapGymsAsOptions(returnGymsPerActiveClassType(returnGymsByAccess(locations, userData), classType))
    : mapGymsAsOptions(returnGymsByAccess(locations, userData));
  const [filteredGyms, setFilteredGyms] = useState(gyms);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (defaultChecked !== null) {
      setSelected({
        label: defaultChecked.name,
        id: defaultChecked._id,
        gym: defaultChecked
      });
    }
  }, [defaultChecked]);

  const onSearch = (e) => {
    const searchText = e.target.value.toLowerCase();
    const filteredArray = gyms.filter((elem) => {
      return elem.label.toLowerCase().includes(searchText);
    });
    setFilteredGyms(filteredArray);
  };

  const onChange = (event) => {
    onSelect(event.option.gym);
    setSelected(event.option);
  };
  const onRefresh = () => {
    setSelected(null);
    onSelect(null);
  };

  return (
    <div className={classes.root}>
      <h4>Select gym</h4>
      <div
        className={classes.container}
        style={refresh ? { gridTemplateColumns: '1fr 30px' } : { gridTemplateColumns: '100%' }}>
        <Select
          placeHolder={placeHolder}
          options={filteredGyms}
          value={selected ? selected.label : ''}
          onSearch={onSearch}
          onChange={onChange}
        />
        {refresh && <RefreshIcon onClick={onRefresh} />}
      </div>
    </div>
  );
};

export default withAdminDashboard(GymSelector);
