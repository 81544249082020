import React, { useEffect, useState } from 'react';
import axios from '../../../axios-global';
import classes from './WaitListUserButton.module.scss';
import Toast from 'grommet/components/Toast';
import CustomButton from '../../../components/CustomButton/CustomButton';

const signUpOkToast = {
  show: true,
  msg: 'Thanks you are now on the waiting list. We will email you if a space becomes available.',
  status: 'ok'
};

const signOutOkToast = {
  show: true,
  msg: 'You have left the waiting list.',
  status: 'ok'
};

const failedToast = {
  show: true,
  msg: 'Something went wrong, try again',
  status: 'critical'
};
const initialToastState = { show: false, msg: '', status: 'ok' };
export const WaitListUserButton = ({ selectedClass }) => {
  const [hasUserSignUpWaitList, setHasUserSignUpWaitList] = useState(false);
  const [toast, setToast] = useState(initialToastState);

  useEffect(() => setHasUserSignUpWaitList(selectedClass.hasUserSignUpWaitList), [selectedClass.hasUserSignUpWaitList]);

  const userWaitListActionFunction = (selectedClass) => {
    const action = hasUserSignUpWaitList ? 'signOut' : 'signUp';
    axios
      .post(`/wait-list/class/${selectedClass._id}/${action}`)
      .then(() => {
        setHasUserSignUpWaitList(!hasUserSignUpWaitList);
        setToast(hasUserSignUpWaitList ? signOutOkToast : signUpOkToast);
      })
      .catch(() => setToast(failedToast));
  };

  return (
    <div className={classes.root}>
      {toast.show && (
        <Toast status={toast.status} onClose={() => setToast(initialToastState)}>
          <span data-cy="notiffication_msg">{toast.msg}</span>
        </Toast>
      )}

      <CustomButton
        label={hasUserSignUpWaitList ? 'Leave waiting list' : 'Join waiting list'}
        onClick={() => userWaitListActionFunction(selectedClass)}
      />
    </div>
  );
};
