import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from '../../../../axios-global';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import Notification from 'grommet/components/Notification';
import Loading from '../../../../components/Loading/Loading';
import FormCreator from '../../../../components/UI/FormCreator/FormCreator';
import classes from './EditGymSettings.module.scss';
import Select from 'grommet/components/Select';
import Label from 'grommet/components/Label';
import Toast from 'grommet/components/Toast';
import { checkValidity, returnErrorTextForField, getCurrencyDetail } from '../../../../shared/utility';
import * as actions from '../../../../store/actions';
import ClassConfig from '../ClassConfig/ClassConfig';
import { CLASS_TYPES, CLASS, ALL_CURRENCIES } from '../../../../constants';

const cloneDeep = require('lodash.clonedeep');

const mapStateToProps = (state) => {
  return {
    selectedGym: state.admin.selectedGym
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchGymDetails: (gymId) => dispatch(actions.fetchGymDetails(gymId)),
    onFetchLocations: () => dispatch(actions.fetchLocations())
  };
};

class EditGymSettings extends Component {
  state = {
    classConfig: [],
    enabledProducts: [],
    disabledProducts: [],
    form: {
      currency: {
        label: 'Currency',
        type: 'select',
        options: ALL_CURRENCIES,
        placeholder: 'Select',
        value: getCurrencyDetail(this.props.gym.settings.currency),
        validation: {
          required: true
        },
        valid: true,
        touched: false,
        errorText: null
      },
      qrCode: {
        label: 'QR Code',
        type: 'select',
        options: ['true', 'false'],
        placeholder: 'Is QR Code accessible',
        value: 'false',
        validation: {
          required: true
        },
        valid: true,
        touched: false,
        errorText: null
      },
      newClientsHaveToBeApprovedByAdmin: {
        label: 'Admin approves new clients',
        type: 'select',
        options: ['true', 'false'],
        value: 'false',
        validation: {
          required: true
        },
        valid: true,
        touched: false,
        errorText: null
      },
      faq: {
        label: 'FAQ',
        type: 'select',
        options: ['true', 'false'],
        placeholder: 'Show FAQ?',
        value: 'false',
        validation: {
          required: true
        },
        valid: true,
        touched: false,
        errorText: null
      },
      kitList: {
        label: 'Show kit list',
        type: 'select',
        options: ['true', 'false'],
        value: 'false',
        validation: {
          required: true
        },
        valid: true,
        touched: false,
        errorText: null
      },
      videoOnDemand: {
        label: 'Video on demand',
        type: 'select',
        options: ['true', 'false'],
        value: 'false',
        validation: {
          required: true
        },
        valid: true,
        touched: false,
        errorText: null
      },
      liveStreaming: {
        label: 'Live streaming',
        type: 'select',
        options: ['true', 'false'],
        value: 'false',
        validation: {
          required: true
        },
        valid: true,
        touched: false,
        errorText: null
      },
      minimalUserAge: {
        label: 'Minimal client age',
        type: 'smallNumber',
        placeholder: 'Enter minimal client age',
        value: 18,
        validation: {
          required: true
        },
        valid: true,
        touched: false,
        errorText: null
      },
      membershipProducts: {
        label: 'Membership Products',
        type: 'select',
        options: ['true', 'false'],
        value: 'false',
        validation: {
          required: true
        },
        valid: true,
        touched: false,
        errorText: null
      },
      coupon: {
        label: 'Coupon',
        type: 'select',
        options: ['true', 'false'],
        value: 'false',
        validation: {
          required: true
        },
        valid: true,
        touched: false,
        errorText: null
      },
      staffShifts: {
        label: 'Staff Shifts',
        type: 'select',
        options: ['true', 'false'],
        value: 'false',
        validation: {
          required: true
        },
        valid: true,
        touched: false,
        errorText: null
      }
    },
    toastShow: false,
    toastMessage: null,
    toastStatus: null
  };

  componentDidMount() {
    if (this.props.gym.settings) {
      const copiedForm = cloneDeep(this.state.form);
      this.setState(
        {
          classConfig: this.props.gym.settings.classConfig,
          form: copiedForm
        },
        () => this.assignExistingValues()
      );
    }
  }

  assignExistingValues = () => {
    const formNames = Object.keys(this.props.gym.settings);
    const previousValues = Object.values(this.props.gym.settings);
    const copiedForm = cloneDeep(this.state.form);

    formNames.forEach((element, index) => {
      if (copiedForm.hasOwnProperty(element) && copiedForm[element] !== undefined) {
        copiedForm[element] = {
          ...copiedForm[element],
          value: previousValues[index].toString(),
          valid: true,
          touched: true
        };
      }
    });

    copiedForm.currency.value = getCurrencyDetail(this.props.gym.settings.currency);
    const enabledProducts = [];
    const disabledProducts = [];
    CLASS_TYPES.forEach((classType) => {
      if (this.props.gym.settings[classType]) {
        enabledProducts.push(classType);
      } else {
        disabledProducts.push(classType);
      }
    });
    this.setState({
      form: copiedForm,
      enabledProducts,
      disabledProducts
    });
  };

  renderProductSettings = () => {
    const selected = (event, type) => {
      const enabledProducts = this.state.enabledProducts;
      const disabledProducts = this.state.disabledProducts;
      const index = enabledProducts.indexOf(type);
      if (event.value === 'true') {
        if (index === -1) {
          enabledProducts.push(type);
          disabledProducts.splice(disabledProducts.indexOf(type), 1);
        }
      } else {
        if (index !== -1) {
          disabledProducts.push(type);
          enabledProducts.splice(index, 1);
        }
      }
      this.setState({ enabledProducts, disabledProducts });
    };

    const dropdownLabels = {
      class: 'Classes',
      personalTraining: 'Personal Training Sessions',
      induction: 'Induction',
      gymClass: 'Gym timetable',
      swimmingClass: 'Swim timetable',
      tennisClass: 'Tennis timetable',
      massageClass: 'Massage timetable'
    };

    const classConfig = CLASS_TYPES.filter((type) => type !== CLASS.VIDEO).map((classType) => (
      <div key={classType} className={classes.classConfigContainer}>
        <div>
          <Label>{dropdownLabels[classType]}</Label>
          <Select
            value={this.state.enabledProducts.includes(classType).toString()}
            options={['true', 'false']}
            onChange={(e) => selected(e, classType)}
          />
        </div>
        <div>
          {this.state.enabledProducts.includes(classType) && (
            <ClassConfig
              classType={classType}
              classConfig={this.props.gym.settings.classConfig}
              handleConfigChange={this.handleConfigChange}
            />
          )}
        </div>
      </div>
    ));
    return classConfig;
  };

  valueChangedHandler = (newValue, identifier) => {
    const copiedForm = cloneDeep(this.state.form);

    // update for confirm password
    copiedForm[identifier] = {
      ...copiedForm[identifier],
      value: newValue,
      valid: checkValidity(newValue, this.state.form[identifier].validation),
      errorText: returnErrorTextForField(this.state.form[identifier].validation),
      touched: true
    };

    return this.setState({
      form: copiedForm
    });
  };

  checkFormValidity = () => {
    this.setState({ formValidated: true });

    for (let input in this.state.form) {
      if (!this.state.form[input].valid) {
        const copiedForm = cloneDeep(this.state.form);
        copiedForm[input] = {
          ...copiedForm[input],
          valid: checkValidity(copiedForm[input].value, copiedForm[input].validation),
          errorText: returnErrorTextForField(copiedForm[input].validation),
          touched: true
        };

        if (this.props.gym.settings) {
          document.getElementsByClassName('ss-modal')[0].scrollTop = 0;
        } else {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }

        return this.setState({
          formIsValid: false,
          form: copiedForm
        });
      }
    }
    for (let config in this.state.classConfig) {
      const classConfig = this.state.classConfig[config];
      if (!classConfig.consecutiveBookingsEnabled && classConfig.consecutiveBookingsCoolOff === null) {
        this.setState({
          toastShow: true,
          toastMessage: 'Time between consecutive bookings cannot be blank',
          toastStatus: 'critical'
        });
        return false;
      }
    }
    this.setState({ formIsValid: true });

    this.saveEditedData();
  };
  renderToast = () => {
    if (this.state.toastShow) {
      return (
        <Toast status={this.state.toastStatus} onClose={this.closeToast}>
          {this.state.toastMessage}
        </Toast>
      );
    }
  };
  passErrorMessagesToForm = (error) => {
    if (error.response && error.response.data && error.response.data.errors) {
      const formNames = Object.keys(error.response.data.errors);
      const errorsMessages = Object.values(error.response.data.errors);
      const copiedForm = cloneDeep(this.state.form);

      formNames.forEach((element, index) => {
        if (this.state.form.hasOwnProperty(element) && this.state.form[element] !== undefined) {
          copiedForm[element] = {
            ...copiedForm[element],
            valid: false,
            errorText: errorsMessages[index].message,
            touched: true
          };
        }
      });

      this.setState({
        form: copiedForm
      });
    } else {
      // eslint-disable-next-line
      console.log(error.response);
    }
  };

  saveEditedData = () => {
    const formData = {};
    for (let input in this.state.form) {
      formData[input] = this.state.form[input].value;
    }

    this.state.enabledProducts.forEach((product) => {
      formData[product] = true;
    });
    this.state.disabledProducts.forEach((product) => {
      formData[product] = false;
    });
    formData.currency = formData.currency.value;
    formData.classConfig = this.state.classConfig;
    axios
      .patch(`/gym/settings/${this.props.gym.settings._id}`, formData)
      .then(() => {
        this.setState({ loading: false });
        this.props.onFetchGymDetails(this.props.selectedGym._id);
        this.props.onFetchLocations();
      })
      .catch((error) => {
        this.passErrorMessagesToForm(error);

        this.setState({
          loading: false
        });
      });
  };

  handleConfigChange = (newSingleClassConfig) => {
    let classConfig = this.state.classConfig;
    const index =
      classConfig.length > 0 ? classConfig.findIndex((elem) => elem.classType === newSingleClassConfig.classType) : -1;

    if (index === -1) {
      classConfig.push(newSingleClassConfig);
    } else {
      classConfig[index] = newSingleClassConfig;
    }
    return this.setState({ classConfig });
  };

  render() {
    let formWarning = null;
    if (!this.state.formIsValid && this.state.formValidated) {
      formWarning = (
        <Notification message="Form is not filled in correctly" status="warning" className="ss-top-notification" />
      );
    }

    let button = <CustomButton label={'Save'} secondary={true} onClick={this.checkFormValidity} fullWidth />;
    if (this.state.loading) {
      button = <Loading />;
    }

    let error = null;
    if (this.state.error) {
      error = <Notification message={this.state.error} status="critical" className="ss-top-notification" />;
    }

    return (
      <>
        <div>
          <span>
            {error}
            {formWarning}
            {this.renderToast()}
            <div className={classes.formContainer}>
              <div>{this.renderProductSettings()}</div>
              <FormCreator formData={this.state.form} valueChanged={this.valueChangedHandler} />
            </div>
            {button}
          </span>
        </div>
      </>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditGymSettings);
