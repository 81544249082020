import React from 'react';

import Heading from 'grommet/components/Heading';
import Loading from '../../../components/Loading/Loading';

import classes from './Title.module.scss';

const Title = (props) => {
  let subHeader = null;
  if (props.subHeader) {
    subHeader = (
      <Heading tag="h4" strong={true}>
        {props.subHeader}
      </Heading>
    );
  }

  let header = <Loading />;
  if (props.header) {
    header = <Heading>{props.header}</Heading>;
  }

  return (
    <div className={classes.headerBox} data-cy="headerBox">
      {header}
      {subHeader}
    </div>
  );
};
export default Title;
