/* eslint-disable no-unused-vars */
import React from 'react';
import DefaultSingleDayTimetable from '../../DefaultTimetable/DefaultTimetable/DefaultSingleDayTimetable';
import DefaultTimetable from '../../DefaultTimetable/DefaultTimetable/DefaultTimetable';
import FullDataTimetable from '../../DefaultTimetable/FullDataTimetable/FullDataTimetable';
import { returnAllUpcomingClasses } from '../../../../shared/classFunctions';
import VideoOnDemandPasses from '../../../VideoOnDemandPasses';
import classes from './UserView.module.scss';

const explainer = (classType, inPerson = true) =>
  classType === 'induction' ? (
    <div className={classes.explainer}>
      {`Please select to view your induction through Video On Demand (above)${
        inPerson ? ' or by attending a face to face induction with a motive8 trainer (see below)' : ''
      }`}
    </div>
  ) : null;

const UserView = ({
  classesList,
  classesListLoading,
  classesListError,
  classesDetails,
  classConfig,
  classesListReFetch,
  videoProducts = [],
  userVideoProducts = []
}) => {
  const inPerson = !!classesList?.length;
  const vodAvailable = !!userVideoProducts.length || !!videoProducts.length;

  if (classesList && classConfig.classType === 'class') {
    return (
      <div data-cy={classConfig.classType + '_bookings'}>
        {vodAvailable && (
          <>
            <VideoOnDemandPasses userVideoProducts={userVideoProducts} videoProducts={videoProducts} />
            {explainer(classConfig.classType, inPerson)}
          </>
        )}
        {(inPerson || !vodAvailable) && (
          <FullDataTimetable classesList={classesList} classesDetails={classesDetails} classConfig={classConfig} />
        )}
      </div>
    );
  }

  if (classConfig.singleDayTimetable) {
    return (
      <div data-cy={classConfig.classType + '_bookings'}>
        {vodAvailable && (
          <>
            <VideoOnDemandPasses userVideoProducts={userVideoProducts} videoProducts={videoProducts} />
            {explainer(classConfig.classType, inPerson)}
          </>
        )}
        {(inPerson || !vodAvailable) && (
          <DefaultSingleDayTimetable
            classesList={classesList}
            classesListLoading={classesListLoading}
            classesListError={classesListError}
            onFetchClasses={classesListReFetch}
            classesDetails={classesDetails}
            classConfig={classConfig}
          />
        )}
      </div>
    );
  }

  return (
    <div data-cy={classConfig.classType + '_bookings'}>
      {vodAvailable && (
        <>
          <VideoOnDemandPasses userVideoProducts={userVideoProducts} videoProducts={videoProducts} />
          {explainer(classConfig.classType, inPerson)}
        </>
      )}
      {(inPerson || !vodAvailable) && (
        <DefaultTimetable
          classesList={returnAllUpcomingClasses(classesList)}
          classesListLoading={classesListLoading}
          classesListError={classesListError}
          classesDetails={classesDetails}
          classType={classesDetails.type}
          onFetchClasses={classesListReFetch}
          classConfig={classConfig}
        />
      )}
    </div>
  );
};

export default UserView;
