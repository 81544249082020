import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from '../../axios-global';
import { withRouter } from 'react-router-dom';
import Notification from 'grommet/components/Notification';
import Toast from 'grommet/components/Toast';
import Loading from '../../components/Loading/Loading';

import WhiteBackground from '../../hoc/WhiteBackground/WhiteBackground';
import Title from '../../components/UI/Title/Title';
import StepZero from './StepZero/StepZero';
import StepOne from './StepOne/StepOne';
import StepTwo from './StepTwo/StepTwo';
import StepThird from './StepThird/StepThird';
import { companyDetails } from '../../../src/brandConfig/companyDetails';

import { updateObject, checkValidity, returnErrorTextForField } from '../../shared/utility';

import * as actions from '../../store/actions/index';

import classes from './Signup.module.scss';

const mapDispatchToProps = (dispatch) => {
  return {
    onAuthSuccess: (token) => dispatch(actions.authSuccess(token)),
    onFetchUserDetails: () => dispatch(actions.fetchUserDetails())
  };
};

class Signup extends Component {
  state = {
    loading: true,
    error: false,
    currentStep: 0,
    chosenGymId: '',
    formIsValid: true,
    marketing: false,
    customerType: '',
    registerError: false,
    registerErrorMessage: null,
    registrationLoading: false,
    personalDetails: {
      title: {
        label: 'Title',
        type: 'select',
        options: ['Mr', 'Mrs', 'Miss', 'Ms', 'Dr'],
        placeholder: 'Select title',
        value: 'Mr',
        validation: {
          required: true
        },
        valid: true,
        touched: false,
        errorText: null,
        dataCy: 'sigup_title'
      },
      name: {
        label: 'First name',
        type: 'text',
        placeholder: 'Enter first name',
        value: '',
        dataCy: 'signup_first_name',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      last_name: {
        label: 'Last name',
        type: 'text',
        placeholder: 'Enter last name',
        value: '',
        validation: {
          required: true
        },
        dataCy: 'signup_last_name',
        valid: false,
        touched: false,
        errorText: null
      },
      dayOfBirth: {
        label: 'Day of birth',
        type: 'number',
        placeholder: 'Enter day of birth',
        value: '',
        validation: {
          required: true,
          isDay: true
        },
        dataCy: 'signup_day_of_birth',
        valid: false,
        touched: false,
        errorText: null
      },
      monthOfBirth: {
        label: 'Month of birth',
        type: 'select',
        dataCy: 'sigup_month_of_birth',
        options: [
          {
            value: '01',
            label: 'January'
          },
          {
            value: '02',
            label: 'February'
          },
          {
            value: '03',
            label: 'March'
          },
          {
            value: '04',
            label: 'April'
          },
          {
            value: '05',
            label: 'May'
          },
          {
            value: '06',
            label: 'June'
          },
          {
            value: '07',
            label: 'July'
          },
          {
            value: '08',
            label: 'August'
          },
          {
            value: '09',
            label: 'September'
          },
          {
            value: '10',
            label: 'October'
          },
          {
            value: '11',
            label: 'November'
          },
          {
            value: '12',
            label: 'December'
          }
        ],
        placeholder: 'Select month',
        value: {
          value: '01',
          label: 'January'
        },
        valid: true,
        touched: true
      },
      yearOfBirth: {
        label: 'Year of birth',
        type: 'number',
        placeholder: 'Enter year of birth',
        value: '',
        validation: {
          required: true,
          isNumeric: true,
          isYear: true,
          isOldEnough: true
        },
        dataCy: 'signup_year_of_birth',
        valid: false,
        touched: false,
        errorText: null
      },
      email: {
        label: 'E-mail',
        type: 'text',
        placeholder: 'Enter e-mail',
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        dataCy: 'signup_email',
        valid: false,
        touched: false,
        errorText: null
      },
      phone_number: {
        label: 'Phone number',
        type: 'text',
        placeholder: 'Enter telephone number',
        value: '',
        validation: {
          required: true,
          isUkTelephone: true
        },
        dataCy: 'signup_phone_number',
        valid: false,
        touched: false,
        errorText: null
      },
      address_line1: {
        label: 'Address',
        type: 'text',
        placeholder: 'Enter address',
        value: '',
        validation: {
          required: true
        },
        dataCy: 'signup_address',
        valid: false,
        touched: false,
        errorText: null
      },
      city: {
        label: 'City',
        type: 'text',
        placeholder: 'Enter city',
        value: '',
        validation: {
          required: true
        },
        dataCy: 'signup_city',
        valid: false,
        touched: false,
        errorText: null
      },
      postcode: {
        label: 'Postcode',
        type: 'text',
        placeholder: 'Enter postcode',
        value: '',
        validation: {
          required: true
        },
        dataCy: 'signup_postcode',
        valid: false,
        touched: false,
        errorText: null
      },
      emergency_contact_name: {
        label: 'Emergency contact name',
        type: 'text',
        placeholder: 'Enter emergency contact name',
        value: '',
        validation: {
          required: true
        },
        dataCy: 'signup_contact_name',
        valid: false,
        touched: false,
        errorText: null
      },
      emergency_contact_number: {
        label: 'Emergency contact phone',
        type: 'text',
        placeholder: 'Enter emergency contact phone',
        value: '',
        validation: {
          required: true,
          isNumeric: true
        },
        dataCy: 'signup_contact_phone',
        valid: false,
        touched: false,
        errorText: null
      },
      password: {
        label: 'Password',
        type: 'password',
        placeholder: 'Enter password',
        value: '',
        validation: {
          isValidPassword: true
        },
        dataCy: 'signup_password',
        valid: false,
        touched: false,
        errorText: null
      },
      confirmPassword: {
        label: 'Confirm password',
        type: 'password',
        placeholder: 'Confirm password',
        value: '',
        basePasswordValue: '',
        validation: {
          required: true
        },
        dataCy: 'signup_confirm_password',
        valid: false,
        touched: false,
        errorText: null
      }
    },
    token: null
  };

  componentDidMount() {
    let gym = this.props.match.params.gymId;
    axios
      .get(`/gym/${gym}`)
      .then((resp) => {
        this.setState({
          loading: false,
          chosenGymId: resp.data._id,
          chosenGym: resp.data,
          currentStep: resp.data.settings.membershipProducts ? 0 : 2,
          customerType: resp.data.settings.membershipProducts ? null : 'class-pass-member'
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true
        });
      });
  }

  goToNextStep = (stepToGo) => {
    window.scroll(0, 0);
    this.setState({
      currentStep: stepToGo
    });
  };

  valueChangedHandler = (newValue, identifier) => {
    if (identifier === 'confirmPassword') {
      const updatedPersonalDetails = updateObject(this.state.personalDetails, {
        [identifier]: updateObject(this.state.personalDetails[identifier], {
          value: newValue,
          valid: checkValidity(
            newValue,
            this.state.personalDetails[identifier].validation,
            this.state.chosenGym.settings.minimalUserAge
          ),
          errorText: returnErrorTextForField(
            this.state.personalDetails[identifier].validation,
            this.state.chosenGym.settings.minimalUserAge
          ),
          touched: true,
          basePasswordValue: this.state.personalDetails.password.value
        })
      });
      return this.setState({
        personalDetails: updatedPersonalDetails
      });
    }

    if (identifier === 'monthOfBirth') {
      const updatedPersonalDetails = updateObject(this.state.personalDetails, {
        [identifier]: updateObject(this.state.personalDetails[identifier], {
          value: newValue
        })
      });
      return this.setState({
        personalDetails: updatedPersonalDetails
      });
    }

    const updatedPersonalDetails = updateObject(this.state.personalDetails, {
      [identifier]: updateObject(this.state.personalDetails[identifier], {
        value: newValue,
        valid: checkValidity(
          newValue,
          this.state.personalDetails[identifier].validation,
          this.state.chosenGym.settings.minimalUserAge
        ),
        errorText: returnErrorTextForField(
          this.state.personalDetails[identifier].validation,
          this.state.chosenGym.settings.minimalUserAge
        ),
        touched: true
      })
    });
    return this.setState({
      personalDetails: updatedPersonalDetails
    });
  };

  toggleCheckbox = (checkbox, value) => {
    this.setState({
      [checkbox]: value
    });
  };

  checkFormValidity = () => {
    this.setState({ formIsValid: true });

    for (let input in this.state.personalDetails) {
      if (!this.state.personalDetails[input].valid) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        const updatedPersonalDetails = updateObject(this.state.personalDetails, {
          [input]: updateObject(this.state.personalDetails[input], {
            valid: checkValidity(
              this.state.personalDetails[input].value,
              this.state.personalDetails[input].validation,
              this.state.chosenGym.settings.minimalUserAge
            ),
            errorText: returnErrorTextForField(
              this.state.personalDetails[input].validation,
              this.state.chosenGym.settings.minimalUserAge
            ),
            touched: true
          })
        });
        return this.setState({
          personalDetails: updatedPersonalDetails,
          formIsValid: false
        });
      }
    }

    return this.setState(
      {
        formIsValid: true
      },
      () => {
        if (this.state.formIsValid) {
          this.registerUser();
        }
      }
    );
  };

  registerUser = () => {
    this.setState({
      registerErrorMessage: null,
      registerError: null,
      registrationLoading: true
    });

    const newUserData = {};
    for (let input in this.state.personalDetails) {
      newUserData[input] = this.state.personalDetails[input].value;
    }
    newUserData.gymId = this.state.chosenGymId;
    newUserData.marketing = this.state.marketing;
    newUserData.customerType = this.state.customerType;
    newUserData.email = newUserData.email.trim();

    // removing 0 from day of birth
    let dayOfBirth = this.state.personalDetails.dayOfBirth.value;
    if (dayOfBirth.substr(0, 1) === '0') {
      dayOfBirth = dayOfBirth.substr(1, 1);
    }

    newUserData.dateofbirth = `${this.state.personalDetails.yearOfBirth.value}-${
      this.state.personalDetails.monthOfBirth.value.value
    }-${dayOfBirth < 10 ? '0' + dayOfBirth : dayOfBirth}`;
    delete newUserData.monthOfBirth;
    delete newUserData.dayOfBirth;
    delete newUserData.yearOfBirth;

    axios
      .post('/users', newUserData)
      .then((resposne) => {
        this.setState({
          token: resposne.data.token,
          registrationLoading: false
        });
        this.goToNextStep(3);
      })
      .catch((error) => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        this.passErrorMessagesToForm(error);
        this.setState({
          loading: false
        });
      });
  };
  returnErrorMessage = (response) =>
    response.data && response.data.errors
      ? response.data.errors.map((item) => item.msg)
      : response.data.message
      ? response.data.message
      : '';

  passErrorMessagesToForm = (error) => {
    if (error.response.data && error.response.data.errors) {
      error.response.data.errors.forEach((element) => {
        if (
          this.state.personalDetails.hasOwnProperty(element.param) &&
          this.state.personalDetails[element.param] !== undefined
        ) {
          const updatedPersonalDetails = updateObject(this.state.personalDetails, {
            [element.param]: updateObject(this.state.personalDetails[element.param], {
              valid: false,
              errorText: element.msg,
              touched: true
            })
          });
          this.setState({
            personalDetails: updatedPersonalDetails
          });
        }
      });
    }
    this.setState({
      registerError: true,
      registerErrorMessage: this.returnErrorMessage(error.response),
      registrationLoading: false
    });
  };

  render() {
    let stepToDisplay = (
      <StepZero
        gym={this.props.selectedGym}
        typeSelected={(customerType) => {
          this.goToNextStep(2);
          this.setState({
            customerType
          });
        }}
      />
    );

    let header = (
      <Title
        header="Choose account types"
        subHeader={`Welcome to ${companyDetails.name}. Choose gym membership if you're interested in signing up for a monthly membership, or pay-as-you-go if you wish to attend without commiting to a monthly membership`}
      />
    );

    if (this.state.currentStep === 1) {
      stepToDisplay = (
        <StepOne
          gymSelected={(gymId) => {
            this.goToNextStep(2);
            this.setState({
              chosenGymId: gymId
            });
          }}
        />
      );
      header = <Title header="Register" subHeader="Select one of our gyms" />;
    }

    let formWarning = null;

    if (!this.state.formIsValid && this.state.currentStep === 2) {
      formWarning = (
        <Notification message="Form is not filled in correctly" status="warning" className="ss-top-notification" />
      );
    }

    let registerNotification = null;

    if (this.state.registerError) {
      registerNotification = (
        <Toast
          status="critical"
          onClose={() => {
            this.setState({
              registerError: null
            });
          }}>
          {this.state.registerErrorMessage
            ? this.state.registerErrorMessage
            : 'Registration failed, please check the form and try again.'}
        </Toast>
      );
    }

    if (this.state.currentStep === 2) {
      stepToDisplay = (
        <StepTwo
          personalDetails={this.state.personalDetails}
          valueChangedHandler={this.valueChangedHandler}
          checkFormValidity={this.checkFormValidity}
          marketing={this.state.marketing}
          toggleCheckbox={this.toggleCheckbox}
          registrationLoading={this.state.registrationLoading}
        />
      );
      header = (
        <Title
          header="Personal Details"
          subHeader="Before you sign up, we need to take a few details. By completing this form you agree to the Terms & Conditions of our Website."
        />
      );
    }

    if (this.state.currentStep === 3) {
      stepToDisplay = (
        <StepThird
          dataAccepted={() => {
            this.props.onAuthSuccess(this.state.token);
            this.props.onFetchUserDetails();
            this.props.location.state && this.props.location.state.currentUrl
              ? this.props.history.push(`${this.props.location.state.currentUrl}`)
              : this.props.history.push('/gym');
          }}
          termsUrl={this.state.chosenGym.termsUrl}
          history={this.props.history}
          locationHome={`/${this.state.chosenGym.cityUrl}/${this.state.chosenGym.urlName}`}
        />
      );
      header = <Title header="Health Statement" subHeader="" />;
    }

    if (this.state.loading) {
      return (
        <WhiteBackground>
          <Loading />
        </WhiteBackground>
      );
    } else if (this.state.error && !this.state.loading) {
      return (
        <WhiteBackground>
          <Notification
            message="Something went wrong. Please try again."
            status="warning"
            className="ss-top-notification"
          />
        </WhiteBackground>
      );
    } else {
      return (
        <WhiteBackground>
          <div className={classes.signupContainer}>
            {registerNotification}

            {header}

            {formWarning}

            {stepToDisplay}
          </div>
        </WhiteBackground>
      );
    }
  }
}

export default withRouter(connect(null, mapDispatchToProps)(Signup));
