import React from 'react';
import Table from 'grommet/components/Table';
import TableRow from 'grommet/components/TableRow';
import ProfilePlanStatus from '../../UI/ProfilePlanStatus/ProfilePlanStatus';

import './ClientProfilePlanStatus.scss';

import { returnFormattedDate } from '../../../shared/date-utility';

import { CLIENTS_TYPES } from '../../../constants';

const ClientProfilePlanStatus = (props) => {
  const getPlanName = () => {
    if (props.selectedClient.customerType === CLIENTS_TYPES.FULL_MEMBER) {
      return props.selectedClient.currentMembershipPlan
        ? props.selectedClient.currentMembershipPlan.ssPlanId.name
        : "This user hasn't signed up for any plan yet";
    }
  };

  return (
    <>
      {props.selectedClient.customerType === CLIENTS_TYPES.FULL_MEMBER && (
        <div className="profile-details__container" style={{ marginTop: '10px' }}>
          <h3 style={{ fontSize: '18px' }}>{`MEMBERSHIP PLAN DETAILS - ${getPlanName()}`}</h3>

          {props.selectedClient.customerType === CLIENTS_TYPES.FULL_MEMBER &&
            props.selectedClient.currentMembershipPlan && (
              <Table>
                <tbody>
                  <TableRow>
                    <td>Membership end date</td>
                    <td>
                      <ProfilePlanStatus currentMembershipPlan={props.selectedClient.currentMembershipPlan} />
                    </td>
                  </TableRow>
                  <TableRow>
                    <td>Contract Ends</td>
                    <td>{returnFormattedDate(props.selectedClient.currentMembershipPlan.contractEnd)}</td>
                  </TableRow>
                  <TableRow>
                    <td>Current Period End</td>
                    <td>{returnFormattedDate(props.selectedClient.currentMembershipPlan['current_period_end'])}</td>
                  </TableRow>
                  <TableRow>
                    <td>Minimum Contract Paid</td>
                    <td>{props.selectedClient.currentMembershipPlan.isMinContractEndPaid ? 'Yes' : 'No'}</td>
                  </TableRow>
                  {props.selectedClient.customerType === CLIENTS_TYPES.FULL_MEMBER && (
                    <TableRow>
                      <td>Joining Fee</td>
                      <td>{props.selectedClient.joiningFeePaid ? 'Paid' : 'Not Paid'}</td>
                    </TableRow>
                  )}
                </tbody>
              </Table>
            )}
        </div>
      )}
    </>
  );
};
export default ClientProfilePlanStatus;
