import { ALL_CURRENCIES } from '../../constants';
import { getCurrencyDetail } from '../../shared/utility';
import { returnDateAndTime } from '../../shared/date-utility';

const unEditableFields = ['currency', 'membershipClassPrice', 'active'];

export const returnFormFields = (classType, data = null, gym, isNew = true) => {
  let isPersonalTraining = classType === 'personalTraining' ? true : false;
  let defaultHours = isPersonalTraining ? 24 : '';
  let minimumCancellationHours = data
    ? data.defaultMinimalTimeCancellationInHours
      ? data.defaultMinimalTimeCancellationInHours
      : data.minimalTimeCancellationInHours
    : defaultHours;
  let result = {
    name: {
      label: 'Name',
      type: 'text',
      placeholder: 'Name',
      value: data ? (data.defaultName ? data.defaultName : data.name) : '',
      validation: {
        required: true
      },
      valid: !!data,
      touched: !!data,
      errorText: 'Name is required',
      dataCy: 'name'
    },
    description: {
      label: 'Description',
      type: 'text',
      placeholder: 'Description',
      value: data ? (data.defaultDescription ? data.defaultDescription : data.description) : '',
      validation: {
        required: true
      },
      valid: !!data,
      touched: !!data,
      errorText: 'Description is required',
      dataCy: 'description'
    },
    location: {
      label: 'Location',
      type: 'text',
      placeholder: 'Location',
      value: data && data.location ? data.location : '',
      validation: {
        required: true
      },
      valid: !!data,
      touched: !!data,
      errorText: 'Location is required',
      dataCy: 'location'
    },
    limit: {
      label: 'Limit',
      type: 'text',
      placeholder: 'Enter limit',
      value: data ? (data.defaultLimit ? data.defaultLimit : data.limit) : '',
      validation: {
        required: true,
        isNumeric: true
      },
      valid: !!data,
      touched: !!data,
      errorText: 'Limit is required',
      dataCy: 'limit'
    },
    duration: {
      label: 'Duration (in minutes)',
      type: 'text',
      placeholder: 'Enter duration',
      value: data ? (data.defaultDuration ? data.defaultDuration : data.duration) : '',
      validation: {
        required: true,
        isNumericDecimal: true
      },
      valid: !!data,
      touched: !!data,
      errorText: 'Duration is required',
      dataCy: 'duration'
    },
    currency: {
      label: 'Currency',
      type: 'select',
      options: ALL_CURRENCIES,
      placeholder: 'Select',
      value: getCurrencyDetail(data && data.currency),
      validation: {
        required: true
      },
      valid: true,
      touched: !!data,
      errorText: null,
      dataCy: 'currency'
    },
    cost: {
      label: 'Cost',
      type: 'text',
      placeholder: 'Enter cost',
      value: data ? (data.defaultCost !== undefined ? data.defaultCost : data.cost) : '',
      validation: {
        required: true,
        isNumericDecimal: true
      },
      valid: !!data,
      touched: !!data,
      errorText: 'Value is required',
      dataCy: 'cost'
    },
    membershipClassPrice: {
      label: 'Membership Class Price',
      type: 'text',
      placeholder: 'Enter Membership Class price',
      value: data && data.membershipClassPrice !== undefined ? data.membershipClassPrice : '',
      validation: {
        required: false,
        isNumericDecimal: true
      },
      valid: !!data,
      touched: !!data,
      errorText: 'Value is required',
      dataCy: 'membership_class_price'
    },
    notesForClient: {
      label: 'Notes for client',
      type: 'text',
      placeholder: 'Enter notes for client',
      value: data && data.notesForClient ? data.notesForClient : '',
      validation: {
        required: false
      },
      valid: true,
      touched: true,
      errorText: 'Notes are required',
      dataCy: 'notes_for_client'
    },
    notesForCompany: {
      label: 'Notes for you',
      type: 'text',
      placeholder: 'Enter notes for you',
      value: data && data.notesForCompany ? data.notesForCompany : '',
      validation: {
        required: false
      },
      valid: true,
      touched: true,
      errorText: 'Notes are required',
      dataCy: 'notes_for_company'
    },
    dateTime: {
      label: 'Date & time',
      type: 'datetime',
      placeholder: 'Enter date and time',
      value: data && data.classDate && data.classTime ? returnDateAndTime(data.classDate, data.classTime) : '',
      validation: {
        required: true,
        isDateTime: true
      },
      valid: data && data.classDate !== null && data.classTime !== null,
      touched: data && data.classDate !== null && data.classTime !== null,
      errorText: 'Select date and time',
      dataCy: 'date_and_time'
    },
    minimalTimeCancellationInHours: {
      label: 'Minimal cancellation time (in hours)',
      type: 'text',
      placeholder: 'Enter time',
      value: minimumCancellationHours,
      validation: {
        required: true,
        isNumericDecimal: true
      },
      valid: true,
      touched: true,
      errorText: 'Value is required',
      dataCy: 'minimal_cancellation_time'
    },
    private: {
      label: 'Private',
      type: 'checkbox',
      value: data && data.private !== null ? data.private : false,
      validation: {
        required: false
      },
      valid: true,
      touched: false,
      errorText: null,
      dataCy: 'private'
    },
    enabled: {
      label: 'Enabled',
      type: 'checkbox',
      value: data && data.enabled !== null ? data.enabled : true,
      validation: {
        required: false
      },
      valid: true,
      touched: false,
      errorText: null,
      dataCy: 'enabled'
    }
  };

  if (!gym.settings.membershipProducts) {
    delete result.membershipClassPrice;
  }

  const sameClassTypeLogic = ['gymClass', 'swimmingClass', 'tennisClass', 'induction', 'massageClass'];
  if (sameClassTypeLogic.includes(classType)) {
    delete result.name;
    delete result.description;
    delete result.location;
    delete result.notesForClient;
    delete result.notesForCompany;
  }

  if (classType === 'class') {
    delete result.notesForClient;
    delete result.notesForCompany;
  }

  if (classType === 'personalTraining') {
    delete result.name;
    delete result.description;
    delete result.location;
    delete result.limit;
    delete result.duration;
    delete result.cost;
    delete result.currency;
    delete result.membershipClassPrice;
  }

  if (data !== null && unEditableFields && !isNew) {
    unEditableFields.forEach((elem) => delete result[elem]);
  }

  return result;
};
