import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import Modal from 'react-modal';
import Loading from '../../../../components/Loading/Loading';
import classes from './CancelAttendanceOrClass.module.scss';
import Close from 'grommet/components/icons/base/Close';
import { returnCancelClassDetails } from '../../../../shared/classFunctions';
import withUser from '../../../../store/hoc/withUser';
import { useMutation } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import { delayFetchFunctions, cancelAttendance, cancelClass } from '../../../../apiFunctions/apiFunctions';
import CustomToast from '../../../../components/CustomToast/CustomToast';
import { returnErrorFromResponse } from '../../../../shared/utility';

const CancelAttendanceOrClass = ({ type, selectedClass, user, attendance, cancelType = 'upcoming' }) => {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [toast, setToast] = useState(null);
  const cancelClassDetails = returnCancelClassDetails(selectedClass, user.role);
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  });

  const mutateCancelAttendance = useMutation(
    ({ shouldAdminChargeClients }) => cancelAttendance(attendance._id, user._id, shouldAdminChargeClients),
    {
      onSuccess: () => {
        setLoading(false);
        setShowModal(false);
        setToast({
          status: 'ok',
          msg: 'Attendance cancelled',
          show: true
        });
        if (cancelType === 'singleClass') {
          delayFetchFunctions(['singleClass']);
        } else {
          delayFetchFunctions([
            ['upcomingClasses', selectedClass.__t],
            ['clientUpcomingClasses', selectedClass.__t],
            ['userData']
          ]);
        }
      },
      onError: (response) => {
        setLoading(false);
        setShowModal(false);
        setToast({
          status: 'critical',
          msg: response.data.message ? response.data.message : 'Something went wrong, try again',
          show: true
        });
      }
    }
  );

  const mutateCancelClass = useMutation(
    ({ shouldAdminChargeClients }) => cancelClass(selectedClass._id, shouldAdminChargeClients),
    {
      onSuccess: () => {
        setShowModal(false);
        setToast({
          status: 'ok',
          msg: 'Successfully cancelled',
          show: true
        });

        if (cancelType === 'singleClass') {
          delayFetchFunctions(['singleClass']);
        } else if (cancelType === 'upcomingClassesInAllGyms') {
          delayFetchFunctions(['fetchClassesInAllGyms', selectedClass.__t], 500);
        } else {
          delayFetchFunctions([['upcomingClasses', selectedClass.__t]]);
        }
      },
      onError: (err) => {
        setShowModal(false);
        setShowModal(false);
        setToast({
          status: 'critical',
          msg: returnErrorFromResponse(err),
          show: true
        });
      }
    }
  );

  if (!selectedClass.active) {
    return null;
  }

  const returnProperButton = (type) => {
    switch (type) {
      case 'cancelAttendanceButton':
        if (isMobile && window.location.pathname === '/gym') {
          return (
            <Close
              style={{ cursor: 'pointer', margin: '0px' }}
              box
              onClick={() => setShowModal(true)}
              data-cy="cancelBtn"
            />
          );
        }

        return (
          <CustomButton
            style={{
              width: '100%',
              height: '40px',
              backgroundColor: '#414142',
              fontWeight: '700',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            box
            label="Cancel"
            secondary
            onClick={() => setShowModal(true)}
            dataCy="cancelBtn"
          />
        );

      case 'cancelAttendanceXicon':
        return <Close style={{ cursor: 'pointer', margin: '0px' }} onClick={() => setShowModal(true)} />;

      case 'cancelClassXicon':
        return <Close style={{ cursor: 'pointer', margin: '0px' }} onClick={() => setShowModal(true)} />;

      case 'cancelClassButton':
        return <CustomButton label="Cancel" secondary onClick={() => setShowModal(true)} dataCy="cancelBtn" />;

      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <CustomToast toast={toast} />
      <Modal onRequestClose={() => setShowModal(false)} isOpen={showModal} className="ss-modal">
        <div>{cancelClassDetails.message}</div>
        <div>
          {loading ? (
            <Loading />
          ) : (
            <div className="ss-modal__buttons-container">
              <CustomButton
                box
                label="Yes"
                dataCy="yes_btn"
                secondary
                onClick={() =>
                  type === 'cancelAttendanceButton' || type === 'cancelAttendanceXicon'
                    ? mutateCancelAttendance.mutate({ shouldAdminChargeClients: true })
                    : mutateCancelClass.mutate({
                        shouldAdminChargeClients: true
                      })
                }
                style={cancelClassDetails.checkTiming ? { width: '100px' } : {}}
              />
              {cancelClassDetails.checkTiming && cancelClassDetails.areAnyAttendees && (
                <CustomButton
                  box
                  label="No"
                  dataCy="no_btn"
                  primary
                  onClick={() =>
                    type === 'cancelAttendanceButton' || type === 'cancelAttendanceXicon'
                      ? mutateCancelAttendance.mutate({ shouldAdminChargeClients: false })
                      : mutateCancelClass.mutate({ shouldAdminChargeClients: false })
                  }
                  style={cancelClassDetails.checkTiming ? { width: '100px' } : {}}
                />
              )}
              <CustomButton
                box
                label="Cancel"
                primary={!cancelClassDetails.checkTiming}
                onClick={() => setShowModal(false)}
                style={cancelClassDetails.checkTiming ? { width: '150px' } : {}}
              />
            </div>
          )}
        </div>
      </Modal>

      {returnProperButton(type)}
    </div>
  );
};

export default withRouter(withUser(CancelAttendanceOrClass));
