import React, { useState } from 'react';
import { Tabs, Tab } from 'grommet';
import withAdminDashboard from '../../store/hoc/withAdminDashboard';
import { returnAvailableClassTypesInGym } from '../../shared/gymFunctions';
import DateRangePicker from '../../components/CalendarComponents/DateRangePicker/DateRangePicker';
import SingleClassTypeHistoryComponent from './SingleClassTypeHistoryComponent';
import moment from 'moment';
import useTabIndex from '../../hooks/useTabIndex';

const startDate = moment().subtract(14, 'd').format('YYYY-MM-DD');
const endDate = moment().format('YYYY-MM-DD');

const HistoryComponent = ({ selectedGym }) => {
  const [dateRange, setDateRange] = useState({ dateFrom: startDate, dateTo: endDate });
  const tabs = returnAvailableClassTypesInGym(selectedGym.settings);
  const { activeTabIndex, onTabChange } = useTabIndex();

  return (
    <div data-cy="history">
      <DateRangePicker onChange={setDateRange} onlyPast startDate={startDate} endDate={endDate} />
      <Tabs
        responsive={false}
        style={{ borderBottom: 'none', paddingBottom: '20px', fontWeight: 'bold' }}
        justify="start"
        activeIndex={activeTabIndex}
        onActive={onTabChange}>
        {tabs.map((elem) => (
          <Tab key={elem.type} title={elem.title}>
            <SingleClassTypeHistoryComponent classesDetails={elem} dateRange={dateRange} selectedGym={selectedGym} />
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default withAdminDashboard(HistoryComponent);
