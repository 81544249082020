import React from 'react';
import moment from 'moment';
import SingleClass from '../SingleClass/SingleClass';
import classes from './SingleDay.module.scss';

export default function SingleDay({ day, classesDetails, classConfig }) {
  const renderClasses = () =>
    day.classes.map((elem, index) => (
      <SingleClass
        selectedClass={elem}
        day={day._id}
        key={index}
        classesDetails={classesDetails}
        classConfig={classConfig}
      />
    ));

  return (
    <div className={classes.singleDay}>
      <h4 className={['ss-list-header', classes.header].join(' ')}>
        {`${moment(day._id).format('dddd')}`} ({`${moment(day._id).format('DD/MM')}`})
      </h4>
      {renderClasses()}
    </div>
  );
}
