import React, { useState } from 'react';
import { moveClientsTableData, moveClientsTableHeaderLabels } from '../table/table';
import { useMutation } from 'react-query';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import classes from './StepTree_Summary.module.scss';
import { delayFetchFunctions, moveClientsBetweenClasses } from '../../../../apiFunctions/apiFunctions';
import { returnErrorFromResponse } from '../../../../shared/utility';
import CustomToast from '../../../../components/CustomToast/CustomToast';
import Notification from 'grommet/components/Notification';
import { withRouter } from 'react-router-dom';

const StepTree_Summary = ({ currentClass, selectedClient, selectedClass, onCloseModal, history }) => {
  const [toast, setToast] = useState(null);
  const [isClientSuccessfullyMoved, setIsClientSuccessfullyMoved] = useState(false);
  const clientAttendance = currentClass.attendanceList
    .filter((elem) => elem.user._id === selectedClient._id)
    .filter((elem) => elem.status !== 'cancel' || elem.status !== 'late-cancellation')[0]._id;

  const mutateMoveClient = useMutation(() => moveClientsBetweenClasses(clientAttendance, selectedClass._id), {
    onSuccess: () => setIsClientSuccessfullyMoved(true),
    onError: (err) => {
      setToast({
        status: 'critical',
        msg: returnErrorFromResponse(err),
        show: true
      });
    }
  });
  const renderClientDetails = (
    <div>
      <div className={classes.client}>Client:</div>
      {selectedClient.name} {selectedClient.last_name}
    </div>
  );
  const renderClassDetails = (
    <div>
      <div className={classes.label}>Current:</div>
      <div>{moveClientsTableHeaderLabels()}</div>
      <div>{moveClientsTableData(selectedClass, selectedClient, [currentClass], false)}</div>

      <div className={classes.label}>Selected:</div>
      <div>{moveClientsTableHeaderLabels()}</div>
      <div>{moveClientsTableData(selectedClass, selectedClient, [selectedClass], false)}</div>
    </div>
  );
  const renderMoveClientsBtn = (
    <div className={classes.btn}>
      <CustomButton
        fullWidth
        isLoading={mutateMoveClient.isLoading}
        label="Move client"
        secondary
        onClick={mutateMoveClient.mutate}
      />
    </div>
  );

  if (isClientSuccessfullyMoved) {
    const redirectToClass = () => {
      history.push(`/gym/${selectedClass.gymId[0]._id}/class/${selectedClass._id}`);
      onCloseModal();
    };
    const handleClose = () => {
      delayFetchFunctions(['singleClass'], 0);
      onCloseModal();
    };
    return (
      <div>
        <Notification message="Client has been successfully moved." status="ok" />
        <div className={classes.question}>Do you want to be redirected to that event?</div>
        <div className="ss-modal__buttons-container">
          <CustomButton fullWidth label="Yes" secondary onClick={redirectToClass} />
          <CustomButton fullWidth label="No" onClick={handleClose} />
        </div>
      </div>
    );
  }

  return (
    <div>
      <CustomToast toast={toast} />
      <div>{renderClientDetails}</div>
      <div>{renderClassDetails}</div>
      <div>{renderMoveClientsBtn}</div>
    </div>
  );
};

export default withRouter(StepTree_Summary);
