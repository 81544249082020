import React, { useState } from 'react';
import { ListItem, List, RadioButton } from 'grommet';
import RedirectToUserDetails from '../../../ActionButtons/RedirectToUserDetails/RedirectToUserDetails';
import classes from '../../ClassPage.module.scss';
import { useMutation } from 'react-query';
import { changeAttendanceStatus, delayFetchFunctions } from '../../../../apiFunctions/apiFunctions';
import { returnErrorFromResponse } from '../../../../shared/utility';
import CustomToast from '../../../../components/CustomToast/CustomToast';
import CancelAttendanceOrClass from '../../../ActionButtons/ClassActionButtons/CancelAttandanceOrClass/CancelAttendanceOrClass';
import SelectAndPayForProduct from '../../../ActionButtons/SelectAndPayForProduct/SelectAndPayForProduct';
import withUser from '../../../../store/hoc/withUser';

const defaultDescription = (type, attendanceList, singleClass) => {
  const active = {
    header: `ATTENDANCE LIST (${attendanceList.length}/${singleClass.limit})`,
    noUsers: `No attendees`
  };

  const waiting = {
    header: `WAITING LIST (${attendanceList.length})`,
    noUsers: `No waiting list`
  };

  const pending = {
    header: `AWAITING PAYMENT (${attendanceList.length})`,
    noUsers: `No payments pending`
  };

  const lateCancellation = {
    header: `LATE CANCELLATION (${attendanceList.length})`,
    noUsers: `No late cancellations`
  };

  const cancelled = {
    header: `CANCELLED (${attendanceList.length})`,
    noUsers: `No cancellations`
  };
  return type === 'active'
    ? active
    : type === 'pending'
    ? pending
    : type === 'late-cancellation'
    ? lateCancellation
    : type === 'cancelled'
    ? cancelled
    : waiting;
};

const RenderAttendees = ({ attendanceList, type, singleClass, isAdmin, isLimitReached }) => {
  const [toast, setToast] = useState(null);

  const description = defaultDescription(type, attendanceList, singleClass);

  const mutateUpdateAttendance = useMutation(
    ({ attendanceId, status }) => changeAttendanceStatus(attendanceId, status),
    {
      onSuccess: () => {
        setToast({
          msg: `Attendance successfully updated.`,
          status: 'ok',
          show: true
        });
        delayFetchFunctions(['singleClass'], 0);
      },
      onError: (err) => {
        setToast({
          msg: returnErrorFromResponse(err),
          status: 'critical',
          show: true
        });
      }
    }
  );

  const actionButtons = (attendance) => {
    let content = null;

    if (type === 'active') {
      content = (
        <>
          <RadioButton
            id={attendance._id + 'attended'}
            label="Present"
            value="attended"
            checked={attendance.status === 'attended'}
            onChange={() =>
              mutateUpdateAttendance.mutate({
                attendanceId: attendance._id,
                status: 'attended'
              })
            }
          />
          <RadioButton
            id={attendance._id + 'absent'}
            label="Absent"
            value="absent"
            checked={attendance.status === 'absent'}
            onChange={() =>
              mutateUpdateAttendance.mutate({
                attendanceId: attendance._id,
                status: 'absent'
              })
            }
          />
          <CancelAttendanceOrClass
            type={'cancelAttendanceXicon'}
            selectedClass={singleClass}
            user={attendance.user}
            attendance={attendance}
            cancelType="singleClass"
          />
        </>
      );
    }
    if (type === 'pending') {
      content = isAdmin && !isLimitReached && (
        <SelectAndPayForProduct
          selectedUser={attendance.user}
          singleClass={singleClass}
          attendanceId={attendance._id}
        />
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          marginLeft: 'auto'
        }}>
        {content}
      </div>
    );
  };

  const attendees =
    attendanceList.length === 0 ? (
      <div>{description.noUsers}</div>
    ) : (
      <List>
        {attendanceList.map((elem) => (
          <ListItem key={elem._id} direction="column" align="start" separator="horizontal">
            <div className={classes.singleAttendee} data-cy="singleAttendee">
              {elem.user && elem.user.name} {elem.user && elem.user.last_name}
              <RedirectToUserDetails gymId={singleClass.gymId._id} clientId={elem.user && elem.user._id} />
              {actionButtons(elem)}
            </div>
          </ListItem>
        ))}
      </List>
    );

  return (
    <div
      style={{
        marginTop: '30px'
      }}>
      <CustomToast toast={toast} />
      <h4 className="ss-list-header">{description.header}</h4>
      {attendees}
    </div>
  );
};

export default withUser(RenderAttendees);
