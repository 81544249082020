import React from 'react';
import AddAttendee from './AddAttendee';
import RenderAttendees from './RenderAttendees';

const ClassAttendees = ({ singleClass }) => {
  const activeAttendanceList = singleClass.attendanceList.filter((elem) =>
    ['active', 'absent', 'attended'].includes(elem.status)
  );
  const isLimitReached = singleClass.limit - activeAttendanceList.length === 0;
  const isClassActive = singleClass.active;
  return (
    <div>
      {isClassActive && (
        <AddAttendee
          singleClass={singleClass}
          isLimitReached={isLimitReached}
          activeAttendanceList={activeAttendanceList}
        />
      )}
      <RenderAttendees singleClass={singleClass} attendanceList={activeAttendanceList} type={'active'} />

      <RenderAttendees
        singleClass={singleClass}
        attendanceList={singleClass.waitList.filter((elem) => elem.status === 'active')}
        type={'waiting'}
      />

      <RenderAttendees
        isLimitReached={isLimitReached}
        singleClass={singleClass}
        attendanceList={singleClass.attendanceList.filter((elem) => elem.status === 'pending')}
        type={'pending'}
      />
      <RenderAttendees
        singleClass={singleClass}
        attendanceList={singleClass.attendanceList.filter((elem) => elem.status === 'late-cancellation')}
        type={'late-cancellation'}
      />
      <RenderAttendees
        singleClass={singleClass}
        attendanceList={singleClass.attendanceList.filter((elem) => elem.status === 'cancelled')}
        type={'cancelled'}
      />
    </div>
  );
};

export default ClassAttendees;
