import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from '../../../../../axios-global';
import classes from './AddEditAdmin.module.scss';
import Notification from 'grommet/components/Notification';
import Loading from '../../../../../components/Loading/Loading';
import FormCreator from '../../../../../components/UI/FormCreator/FormCreator';
import { checkValidity, returnErrorTextForField } from '../../../../../shared/utility';
import { isFullAccessAdminOrTrainer, returnGymsByAccess } from '../../../../../shared/userFunctions';
import CustomButton from '../../../../../components/CustomButton/CustomButton';
import withAdminDashboard from '../../../../../store/hoc/withAdminDashboard';
import GymAccessSelector from '../../GymAccessSelector/GymAccessSelector';

const cloneDeep = require('lodash.clonedeep');

class AddEditAdmin extends Component {
  state = {
    formIsValid: false,
    formValidated: false,
    loading: false,
    error: null,
    fullAccess: false,
    selectedGyms: null,
    userSelectedGyms: {},
    selectedGymsAreValid: null,
    adminForm: {
      title: {
        label: 'Title',
        type: 'select',
        options: ['Mr', 'Mrs', 'Miss', 'Ms', 'Dr'],
        placeholder: 'Select title',
        value: 'Mr',
        validation: {
          required: true
        },
        valid: true,
        touched: false,
        errorText: null
      },
      name: {
        label: 'First name',
        type: 'text',
        placeholder: 'Enter first name',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      last_name: {
        label: 'Last name',
        type: 'text',
        placeholder: 'Enter last name',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      email: {
        label: 'E-mail',
        type: 'text',
        placeholder: 'Enter e-mail',
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      phone_number: {
        label: 'Phone number',
        type: 'text',
        placeholder: 'Enter telephone number',
        value: '',
        validation: {
          required: true,
          isUkTelephone: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      password: {
        label: 'Password',
        type: 'password',
        placeholder: 'Enter password',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      confirmPassword: {
        label: 'Confirm password',
        type: 'password',
        placeholder: 'Confirm password',
        value: '',
        basePasswordValue: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      }
    }
  };

  componentDidMount() {
    let gymsList = {};
    this.props.locations.forEach((elem) => {
      gymsList[elem._id] = false;
    });

    this.setState({
      selectedGyms: gymsList
    });
    if (this.props.editAdmin) {
      const copiedForm = cloneDeep(this.state.adminForm);
      delete copiedForm.password;
      delete copiedForm.confirmPassword;

      const selectedGyms =
        this.props.editAdmin['gymsFullAccess'] === undefined || this.props.editAdmin.gymsFullAccess
          ? this.setFullAccess(gymsList)
          : this.setSelectedGyms(this.props.editAdmin.gymId, gymsList);

      const fullAccess =
        this.props.editAdmin['gymsFullAccess'] === undefined || this.props.editAdmin.gymsFullAccess
          ? this.setFullAccess(gymsList)
          : false;

      this.setState(
        {
          adminForm: copiedForm,
          selectedGyms,
          userSelectedGyms: gymsList,
          fullAccess
        },
        () => this.assignExisitingValues()
      );
    }
  }

  handleClose = () => {
    if (this.props.editAdmin) {
      this.props.closeModal();
    } else {
      this.props.history.push(`/gym/${this.props.selectedGym._id}/admins`);
    }
  };

  setSelectedGyms = (array, selectedGyms) => {
    array.forEach((elem) => {
      selectedGyms[elem] = true;
    });
    return selectedGyms;
  };

  assignExisitingValues = () => {
    const formNames = Object.keys(this.props.editAdmin);
    const previousValues = Object.values(this.props.editAdmin);
    const copiedForm = cloneDeep(this.state.adminForm);

    formNames.forEach((element, index) => {
      if (copiedForm.hasOwnProperty(element) && copiedForm[element] !== undefined) {
        copiedForm[element] = {
          ...copiedForm[element],
          value: previousValues[index],
          valid: true,
          touched: true
        };
      }
    });

    this.setState({
      adminForm: copiedForm
    });
  };

  valueChangedHandler = (newValue, identifier) => {
    const copiedForm = cloneDeep(this.state.adminForm);

    if (identifier !== 'confirmPassword') {
      copiedForm[identifier] = {
        ...copiedForm[identifier],
        value: newValue,
        valid: checkValidity(newValue, this.state.adminForm[identifier].validation),
        errorText: returnErrorTextForField(this.state.adminForm[identifier].validation),
        touched: true
      };
      return this.setState({
        adminForm: copiedForm
      });
    }

    // update for confirm password
    copiedForm[identifier] = {
      ...copiedForm[identifier],
      value: newValue,
      valid: checkValidity(newValue, this.state.adminForm[identifier].validation),
      errorText: returnErrorTextForField(this.state.adminForm[identifier].validation),
      touched: true,
      basePasswordValue: this.state.adminForm.password.value
    };

    return this.setState({
      adminForm: copiedForm
    });
  };

  checkFormValidity = () => {
    this.setState({ formValidated: true });
    const selectedGymsAreValid =
      Object.keys(this.state.selectedGyms).filter((k) => this.state.selectedGyms[k]).length > 0;

    let formIsValid;

    for (let input in this.state.adminForm) {
      if (!this.state.adminForm[input].valid) {
        const copiedForm = cloneDeep(this.state.adminForm);
        copiedForm[input] = {
          ...copiedForm[input],
          valid: checkValidity(copiedForm[input].value, copiedForm[input].validation),
          errorText: returnErrorTextForField(copiedForm[input].validation),
          touched: true
        };

        if (this.props.editAdmin) {
          document.getElementsByClassName('ss-modal')[0].scrollTop = 0;
        } else {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }
        formIsValid = false;

        return this.setState({
          formIsValid,
          trainerForm: copiedForm,
          selectedGymsAreValid
        });
      }
    }

    formIsValid = selectedGymsAreValid;
    this.setState({
      formIsValid,
      selectedGymsAreValid
    });

    if (selectedGymsAreValid && formIsValid) {
      if (this.props.editAdmin) {
        this.saveEditedAdmin();
      } else {
        this.adminsAddAdmin();
      }
    }
    return;
  };

  passErrorMessagesToForm = (error) => {
    if (error.response && error.response.data && error.response.data.errors) {
      const formNames = Object.keys(error.response.data.errors);
      const errorsMessages = Object.values(error.response.data.errors);
      const copiedForm = cloneDeep(this.state.adminForm);

      formNames.forEach((element, index) => {
        if (this.state.adminForm.hasOwnProperty(element) && this.state.adminForm[element] !== undefined) {
          copiedForm[element] = {
            ...copiedForm[element],
            valid: false,
            errorText: errorsMessages[index].message,
            touched: true
          };
        }
      });

      this.setState({
        adminForm: copiedForm
      });
    } else {
      // eslint-disable-next-line
      console.log(error.response);
    }
  };

  adminsAddAdmin = () => {
    this.setState({ loading: true });

    const adminFormData = {
      role: 'admin'
    };
    for (let input in this.state.adminForm) {
      adminFormData[input] = this.state.adminForm[input].value;

      if (typeof adminFormData[input] === 'string' && adminFormData[input].toLowerCase() === 'yes') {
        adminFormData[input] = true;
      }
      if (typeof adminFormData[input] === 'string' && adminFormData[input].toLowerCase() === 'no') {
        adminFormData[input] = false;
      }
    }

    adminFormData.gymsFullAccess = this.state.fullAccess;

    let selectedGyms = [];
    const gyms = this.state.selectedGyms;
    if (!this.state.fullAccess) {
      Object.keys(gyms).forEach(function (key) {
        if (gyms[key]) {
          selectedGyms.push(key);
        }
      });
    }

    adminFormData.gymId = adminFormData.gymsFullAccess ? [] : selectedGyms;

    axios
      .post(`/users/admin`, adminFormData)
      .then(() => {
        this.setState({ loading: false });
        this.props.history.push(`/gym/${this.props.selectedGym._id}/admins`);
      })
      .catch((error) => {
        this.passErrorMessagesToForm(error);

        this.setState({
          loading: false
        });
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      });
  };
  setFullAccess = (gyms) => {
    let selectedGyms = {};
    let gymsListNew = {};
    this.props.locations.forEach((elem) => {
      gymsListNew[elem._id] = true;
    });

    if (gyms) {
      Object.keys(gyms).forEach(function (key) {
        selectedGyms[key] = true;
      });
    }

    return gyms ? selectedGyms : gymsListNew;
  };

  saveEditedAdmin = () => {
    const adminFormData = {};
    for (let input in this.state.adminForm) {
      adminFormData[input] =
        typeof this.state.adminForm[input].value === 'string'
          ? this.state.adminForm[input].value.trim()
          : this.state.adminForm[input].value;

      if (typeof adminFormData[input] === 'string' && adminFormData[input].toLowerCase() === 'yes') {
        adminFormData[input] = true;
      }
      if (typeof adminFormData[input] === 'string' && adminFormData[input].toLowerCase() === 'no') {
        adminFormData[input] = false;
      }
    }
    adminFormData.gymsFullAccess = this.state.fullAccess;

    let selectedGyms = [];
    const gyms = this.state.selectedGyms;
    if (!this.state.fullAccess) {
      Object.keys(gyms).forEach(function (key) {
        if (gyms[key]) {
          selectedGyms.push(key);
        }
      });
    } else {
      adminFormData.gymsFullAccess = true;
    }

    adminFormData.gymId = adminFormData.gymsFullAccess ? [] : selectedGyms;
    axios
      .patch(`/users/admin/${this.props.editAdmin._id}`, adminFormData)
      .then(() => {
        this.props.closeModal();
        this.props.refreshData();
      })
      .catch((error) => {
        this.passErrorMessagesToForm(error);
      });
    this.setState({ loading: false });
  };
  handleGymAccessChange = (isFullAccess, selectedGyms) =>
    this.setState({
      fullAccess: isFullAccess,
      selectedGyms
    });

  render() {
    let formWarning = null;
    let gymOptionsForSelector = this.props.locations.map((elem) => elem.name);
    gymOptionsForSelector.unshift('FULL ACCESS');

    if (!this.state.formIsValid && this.state.formValidated) {
      formWarning = (
        <Notification message="Form is not filled in correctly" status="warning" className="ss-top-notification" />
      );
    }

    let error = null;
    if (this.state.error) {
      error = <Notification message={this.state.error} status="critical" className="ss-top-notification" />;
    }
    if (!this.state.selectedGyms) {
      return <Loading />;
    }

    return (
      <>
        {error}
        {formWarning}
        <div className={classes.container}>
          <FormCreator formData={this.state.adminForm} valueChanged={this.valueChangedHandler} />
          <GymAccessSelector
            isFullAccessAdminOrTrainer={isFullAccessAdminOrTrainer(this.props.userData)}
            gymsList={returnGymsByAccess(this.props.locations, this.props.userData)}
            defaultSelectedGyms={this.state.selectedGyms}
            defaultIsFullAccess={this.state.fullAccess}
            onChange={this.handleGymAccessChange}
          />
        </div>
        <div className={'ss-modal__buttons-container'}>
          <CustomButton
            fullWidth
            isLoading={this.state.loading}
            label={this.props.editAdmin ? 'Save' : 'Add'}
            secondary={true}
            onClick={this.checkFormValidity}
          />

          <CustomButton fullWidth label="Cancel" onClick={this.handleClose} />
        </div>
      </>
    );
  }
}

export default withRouter(withAdminDashboard(AddEditAdmin));
