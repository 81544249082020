import React, { useState, useEffect } from 'react';
import withAdminDashboard from '../../../store/hoc/withAdminDashboard';
import classes from './UpcomingClassesForAllGyms.module.scss';
import { ALL_CLASS_TYPES_WITH_DETAILS } from '../../../constants';
import { Tab, Tabs } from 'grommet';
import DefaultTimetable from '../DefaultTimetable/DefaultTimetable/DefaultTimetable';
import { useQuery } from 'react-query';
import { delayFetchFunctions, fetchUpcomingClassesInAllGyms } from '../../../apiFunctions/apiFunctions';
import { returnClassConfig } from '../../../shared/gymFunctions';
import AddEditSingleClass from '../../AddEditSingleClass/AddEditSingleClass';
import CustomButton from '../../../components/CustomButton/CustomButton';
import GymSelector from '../../../components/Selectors/GymSelector/GymSelector';
import UpcomingClassesForAllGymsFilters from './UpcomingClassesForAllGymsFilters';
import useTabIndex from '../../../hooks/useTabIndex';

const returnClassesList = (array = []) => {
  let result = [];
  array.forEach(({ classes }) => {
    result = result.concat(classes);
    return;
  });
  result = result.map((elem) => {
    return {
      limit: elem.capacity,
      _id: elem.classId,
      name: elem.className,
      cost: elem.cost,
      currency: elem.currency,
      classDate: elem.date,
      classTime: elem.time,
      gymId: [
        {
          _id: elem.locationId,
          name: elem.locationName
        }
      ],
      gymName: [elem.locationName],
      joinedUsers: elem.totalNumberOfAttendees,
      trainer: {
        name: elem.trainerName
      },
      active: true,
      duration: 60
    };
  });

  return result;
};

const UpcomingClassesForAllGyms = ({ userGyms, cancelType }) => {
  const classTypes = ALL_CLASS_TYPES_WITH_DETAILS();
  const [selectedClassType, setSelectedClassType] = useState(classTypes[0].type);
  const [showAddNew, setShowAddNew] = useState(false);
  const [gymSelector, setGymSelector] = useState(null);
  const [selectedClassConfig, setSelectedClassConfig] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const { activeTabIndex, onTabChange } = useTabIndex();

  const { data, error, isLoading } = useQuery(['fetchClassesInAllGyms', selectedClassType], () =>
    fetchUpcomingClassesInAllGyms(selectedClassType)
  );

  useEffect(() => {
    setSelectedClassType(classTypes[activeTabIndex].type);
  }, [activeTabIndex, classTypes]);

  useEffect(() => {
    setFilteredData(data !== undefined ? returnClassesList(data) : []);
  }, [data]);

  const renderTabs = (
    <Tabs responsive={false} justify="start" activeIndex={activeTabIndex} onActive={onTabChange}>
      {classTypes.map((elem) => (
        <Tab title={elem.title} key={elem.type}>
          <DefaultTimetable
            classesList={filteredData}
            classesListLoading={isLoading}
            classesListError={error}
            classesDetails={elem}
            classType={elem.type}
            showAllGymsData={true}
            classConfig={{}}
            cancelType={cancelType}
          />
        </Tab>
      ))}
    </Tabs>
  );

  if (showAddNew) {
    const onSelect = (gym) => {
      setGymSelector(gym);
      setSelectedClassConfig(returnClassConfig(gym.settings.classConfig, selectedClassType));
    };

    return (
      <>
        <GymSelector classType={selectedClassType} onSelect={onSelect} filterGymsWithClassType={true} />
        {gymSelector && (
          <AddEditSingleClass
            classType={selectedClassType}
            classConfig={selectedClassConfig}
            gymSelector={gymSelector}
            onClose={() => {
              setShowAddNew(false);
              delayFetchFunctions([['fetchUpcomingClassesInAllGyms', selectedClassType]], 0);
            }}
          />
        )}
      </>
    );
  }

  return (
    <div data-cy={'all_bookings'}>
      <div className={classes.addNewButton}>
        <CustomButton secondary label="Add New" onClick={() => setShowAddNew(true)} position="right" />
      </div>
      <UpcomingClassesForAllGymsFilters
        gyms={userGyms}
        onFilter={setFilteredData}
        data={returnClassesList(data)}
        activeIndex={activeTabIndex}
      />
      {renderTabs}
    </div>
  );
};

export default withAdminDashboard(UpcomingClassesForAllGyms);
