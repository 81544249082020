import React from 'react';
import { withRouter } from 'react-router-dom';
import classes from './SingleClient.module.scss';
import axios from '../../../../../axios-global';
import AddUserIcon from 'grommet/components/icons/base/UserAdd';
import DenyUserIcon from 'grommet/components/icons/base/Clear';
import InactiveUserIcon from 'grommet/components/icons/base/Close';
import RedirectToUserDetails from '../../../../ActionButtons/RedirectToUserDetails/RedirectToUserDetails';
import Modal from 'react-modal';
import CustomButton from '../../../../../components/CustomButton/CustomButton';
import CustomToast from '../../../../../components/CustomToast/CustomToast';

class SingleClient extends React.Component {
  state = {
    showError: false,
    showConfirm: false,
    showModal: false
  };

  changeClientStatus = (status) => {
    axios
      .patch(`/users/${this.props.client._id}`, { status: status })
      .then(() => {
        this.setState({
          showError: false
        });
        this.props.fetchClients(this.props.gymId);
      })
      .catch(() => this.setState({ showError: true }));
  };

  closeToast = () => {
    this.setState({
      showConfirm: false
    });
  };

  addUser = () => (
    <AddUserIcon
      size={'large'}
      type={'status'}
      className={classes.icon}
      onClick={() => this.changeClientStatus('active')}
    />
  );

  deniedUser = () => (
    <DenyUserIcon
      size={'large'}
      type={'status'}
      className={classes.icon}
      onClick={() => this.changeClientStatus('permissionDenied')}
    />
  );

  inactiveUser = () => (
    <InactiveUserIcon
      size={'large'}
      type={'status'}
      className={classes.icon}
      onClick={() => this.setState({ showModal: true })}
    />
  );

  render() {
    return (
      <div key={this.props.client.id} className={classes.root}>
        <CustomToast
          toast={{
            show: this.props.updateUserDetailsError,
            status: 'warning',
            msg: 'Something went wrong. Try again.'
          }}
        />

        <Modal
          onRequestClose={() => this.setState({ showModal: false })}
          isOpen={this.state.showModal}
          className={'ss-modal'}>
          <div className="buttonContainer">
            <div>
              Do you want to deactivate {this.props.client.name} {this.props.client.last_name}?
            </div>
            <div className="ss-modal__buttons-container">
              <CustomButton primary label={'Yes'} onClick={() => this.changeClientStatus('inactive')} />
              <CustomButton secondary label={'Cancel'} onClick={() => this.setState({ showModal: false })} />
            </div>
          </div>
        </Modal>

        <div className={classes.name}>
          <RedirectToUserDetails gymId={this.props.gymId} clientId={this.props.client._id} />
          <div
            className={classes.singleClientName}
            onClick={() => this.props.history.push(`${this.props.match.url}/${this.props.client._id}`)}>
            {this.props.client.name} {this.props.client.last_name}
          </div>
        </div>

        <div>
          {this.props.isAdmin && (
            <div className={classes.actionButtons}>
              {this.props.client.status === 'waitingForApproval' && (
                <>
                  <span>Activate new client?</span>
                  {this.addUser()}
                  {this.deniedUser()}
                </>
              )}
              {this.props.client.status === 'active' && <> {this.inactiveUser()}</>}
              {this.props.client.status === 'inactive' && (
                <>
                  <span>Inactive client</span>
                  {this.addUser()}
                </>
              )}
              {this.props.client.status === 'permissionDenied' && (
                <>
                  <span>Permission denied</span>
                  {this.addUser()}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(SingleClient);
