import React from 'react';
import TilesBoard from '../../../components/UI/TilesBoard/TilesBoard';
import CalendarIcon from '../../../brandConfig/assets/svg/calendar.svg';
import StarIcon from '../../../brandConfig/assets/svg/star.svg';

export default (props) => {
  const tiles = [
    {
      title: 'Pay As You Go',
      icon: <CalendarIcon />,
      actionPayload: 'class-pass-member',
      dataCy: 'classPassesTile'
    },
    {
      title: 'Membership',
      icon: <StarIcon />,
      actionPayload: 'full-member',
      dataCy: 'membershipTile'
    }
  ];

  const accountTypeSelected = (type) => {
    props.typeSelected(type);
  };

  return <TilesBoard tiles={tiles} action={accountTypeSelected} />;
};
