import React from 'react';

import './SignUpSummary.scss';
import { getCurrencySymbol } from '../../../../shared/utility';

export default function SignUpSummary(props) {
  if (!props.plan || !props.paidPeriodInMonths) {
    return 'Please select a plan and the number of months';
  }

  return (
    <>
      <div className="sign-up-summary__container">
        <div>
          <span className="sign-up-summary__label">Minimum contract length:</span> {props.plan.minContractLength} month
          {props.plan.minContractLength > 1 ? 's' : null}
        </div>

        <div>
          <span className="sign-up-summary__label">Classes included:</span>{' '}
          {props.plan.unlimited ? 'Unlimited' : props.plan.classesIncluded}
        </div>

        {!props.joiningFeePaid && props.plan.joiningFee ? (
          <div>
            <span className="sign-up-summary__label">Joining fee: {getCurrencySymbol(props.plan.currency)} </span>
            {props.plan.joiningFee}
          </div>
        ) : null}

        <div>
          <span className="sign-up-summary__label">Membership price for number of months:</span>{' '}
          {getCurrencySymbol(props.plan.currency)}&nbsp;
          {props.plan.price * props.paidPeriodInMonths}
        </div>
      </div>
      <div className="total-amount-chargeable__container">
        <span className="total-amount-chargeable__label">
          Total price: {getCurrencySymbol(props.plan.currency)}
          {!props.joiningFeePaid && props.plan.joiningFee
            ? props.plan.price * props.paidPeriodInMonths + props.plan.joiningFee
            : props.plan.price * props.paidPeriodInMonths}
        </span>
      </div>
    </>
  );
}
