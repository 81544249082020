import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { fetchCoupons } from '../../apiFunctions/apiFunctions';
import { Notification } from 'grommet';
import classes from './ManageCoupons.module.scss';
import CustomButton from '../../components/CustomButton/CustomButton';
import Loading from '../../components/Loading/Loading';
import AddEditCoupon from './AddEditCoupon/AddEditCoupon';
import ShowCoupon from './ShowCoupons/ShowCoupons';

const ManageCoupons = ({ selectedGym, membershipPlans }) => {
  const [showAddEditCoupon, setShowAddEditCoupon] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  const { data, isLoading, error } = useQuery(['coupons'], () => fetchCoupons(selectedGym._id));

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <Notification message={error} status="critical" data-cy={'coupons'} />;
  }

  const addNewCouponButton = (
    <CustomButton
      position="right"
      label={'Add'}
      secondary={true}
      onClick={() => {
        setSelectedCoupon(null);
        setShowAddEditCoupon(true);
      }}
    />
  );

  const updateCoupon = (coupon) => {
    setSelectedCoupon(coupon);
    setShowAddEditCoupon(true);
  };

  if (showAddEditCoupon) {
    return (
      <AddEditCoupon
        selectedCoupon={selectedCoupon}
        selectedGym={selectedGym}
        onClose={() => setShowAddEditCoupon(false)}
        membershipPlans={membershipPlans}
      />
    );
  }

  const returnCoupons =
    data && data.length > 0 ? <ShowCoupon data={data} onUpdateCoupon={updateCoupon} /> : <h3>No coupons</h3>;

  return (
    <div data-cy={'coupons'}>
      <div className={classes.buttonContainer}>{addNewCouponButton}</div>
      {returnCoupons}
    </div>
  );
};

export default ManageCoupons;
