import React, { useState, useEffect } from 'react';
import classes from './DayView.module.scss';
import LinkNextIcon from 'grommet/components/icons/base/LinkNext';
import LinkPreviousIcon from 'grommet/components/icons/base/LinkPrevious';
import UserIcon from 'grommet/components/icons/base/User';
import moment from 'moment';
import { returnProductName } from '../../../../shared/classFunctions';
import useWindowSize from '../../../../hooks/useWindowSize';
import { slotsData, maxComponentWidth } from './constants';

const returnCurrentVisibleSlotsArray = (firstIndex = 0, slotsNumber = 1) => {
  const arr = [...slotsData];
  const index = firstIndex === 0 ? 0 : firstIndex - 1;
  return arr.splice(index, slotsNumber);
};

const DayView = ({ timetable, showClassDetail }) => {
  const [numberOfVisibleSlots, setNumberOfVisibleSlots] = useState(1);
  const [currentVisibleSlotsArray, setCurrentVisibleSlotsArray] = useState(['12:00 PM']);
  const [firstVisibleSlotIndex, setFirstVisibleSlotIndex] = useState(0);
  const [windowWidth] = useWindowSize();

  useEffect(
    () => {
      const timeStarts =
        moment().startOf('hour') === moment('12:00 AM', 'h:mm A')
          ? '12:00 AM'
          : moment('7:00 PM', 'h:mm A').startOf('hour').format('h:mm A').toString();
      const firstSlotTimeIndex = slotsData.indexOf(timeStarts);
      const numberOfVisibleSlots = calculateNumberOfVisibleSlots();
      const currentSlotsArr = returnCurrentVisibleSlotsArray(firstSlotTimeIndex, numberOfVisibleSlots);
      setCurrentVisibleSlotsArray(currentSlotsArr);
      setNumberOfVisibleSlots(numberOfVisibleSlots);
      setFirstVisibleSlotIndex(firstSlotTimeIndex);
    }, // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      const numberOfVisibleSlots = calculateNumberOfVisibleSlots();
      const currentSlotsArr = returnCurrentVisibleSlotsArray(firstVisibleSlotIndex, numberOfVisibleSlots);
      setCurrentVisibleSlotsArray(currentSlotsArr);
      setNumberOfVisibleSlots(numberOfVisibleSlots);
    }, // eslint-disable-next-line
    [windowWidth, firstVisibleSlotIndex]
  );

  const calculateNumberOfVisibleSlots = () => {
    const widthToCalculate = maxComponentWidth > windowWidth ? windowWidth : maxComponentWidth;
    const number = Math.floor((widthToCalculate - 230 - 50) / 120);
    return number > 0 ? number : 1;
  };

  const paginate = (type) => setFirstVisibleSlotIndex((state) => (type === 'previous' ? state - 1 : state + 1));

  const renderEventCell = (rowIndex, cellIndex, time, events) => {
    let currentEvent = events.filter((evt) => {
      return moment(evt.time, 'h:mm A').isBetween(
        moment(time, 'h:mm A'),
        moment(time, 'h:mm A').add(1, 'hours'),
        null,
        '[)'
      );
    });

    let startedEvent = [];
    if (currentEvent.length === 0 && currentVisibleSlotsArray.indexOf(time) === 0) {
      startedEvent = events.filter((evt) => {
        return moment(time, 'h:mm A').isBetween(
          moment(evt.time, 'h:mm A'),
          moment(evt.time, 'h:mm A').add(evt.duration, 'minutes'),
          null,
          '()'
        );
      });
    }

    const eventContainer = () => {
      const calendarEvent = startedEvent.length > 0 ? startedEvent[0] : currentEvent[0];
      calendarEvent.timeTo = moment(calendarEvent.time, 'h:mm A')
        .add(calendarEvent.duration, 'minutes')
        .format('h:mm A');
      const cellTime = moment(time, 'h:mm A');
      const eventStartFrom = moment(calendarEvent.time, 'h:mm A');
      const eventStartTo = moment(calendarEvent.timeTo, 'h:mm A');
      let minutes = eventStartTo.diff(eventStartFrom, 'minutes');
      let marginTime = eventStartFrom.diff(cellTime, 'minute');
      const visibleEndTime =
        firstVisibleSlotIndex + numberOfVisibleSlots > 24
          ? moment(slotsData[23], 'h:mm A')
          : moment(slotsData[firstVisibleSlotIndex + numberOfVisibleSlots - 1], 'h:mm A');
      const isEventStartTimeAfterSlotStartTime = eventStartFrom.isAfter(
        moment(slotsData[firstVisibleSlotIndex - 1], 'h:mm A')
      );
      const isEventEndTimeAfterSlotEndTime = eventStartTo.isAfter(
        moment(slotsData[firstVisibleSlotIndex + numberOfVisibleSlots - 1], 'h:mm A')
      );
      const durationInHours = Math.floor(calendarEvent.duration / 60);
      let addPx = startedEvent.length > 0 ? -2 : durationInHours > 1 ? durationInHours * 9 : 1;

      if (isEventStartTimeAfterSlotStartTime && isEventEndTimeAfterSlotEndTime) {
        minutes = visibleEndTime.diff(eventStartFrom, 'minutes');
        addPx = 6 * durationInHours;
      }

      if (!isEventStartTimeAfterSlotStartTime && isEventEndTimeAfterSlotEndTime) {
        minutes = 60;
        addPx = 8 * durationInHours;
        marginTime = -3;
      }

      if (!isEventStartTimeAfterSlotStartTime && !isEventEndTimeAfterSlotEndTime) {
        let ww = numberOfVisibleSlots + firstVisibleSlotIndex > 24 ? 0 : numberOfVisibleSlots;
        minutes = ww * 60 - visibleEndTime.diff(eventStartTo, 'minutes');
        marginTime = -3;
        addPx = 5 * durationInHours;
      }

      let activeAttendanceListTotal = 0;
      let totalUpdatedAttendee = 0;

      if (calendarEvent.numberAttendancesByStatus) {
        activeAttendanceListTotal =
          (calendarEvent.numberAttendancesByStatus.active ? calendarEvent.numberAttendancesByStatus.active : 0) +
          (calendarEvent.numberAttendancesByStatus.absent ? calendarEvent.numberAttendancesByStatus.absent : 0) +
          (calendarEvent.numberAttendancesByStatus.attended ? calendarEvent.numberAttendancesByStatus.attended : 0);

        totalUpdatedAttendee =
          (calendarEvent.numberAttendancesByStatus.absent ? calendarEvent.numberAttendancesByStatus.absent : 0) +
          (calendarEvent.numberAttendancesByStatus.attended ? calendarEvent.numberAttendancesByStatus.attended : 0);
      }

      const divClasses = [classes.event, classes[calendarEvent.__t]];

      if (activeAttendanceListTotal !== 0 && activeAttendanceListTotal === totalUpdatedAttendee) {
        divClasses.push(classes.allAttendeesUpdated);
      }

      return (
        <div
          style={{
            width: `calc(${minutes * 1.666}% +  ${addPx}px)`,
            position: 'relative',
            padding: '3.5px',
            marginLeft: `calc(1.6% * ${marginTime})`,
            zIndex: '2'
          }}
          onClick={() => showClassDetail(calendarEvent)}>
          <div className={divClasses.join(' ')}>
            <p>{returnProductName(calendarEvent.__t, 'title')}</p>
          </div>
        </div>
      );
    };

    return (
      <div className={classes.eventCell} key={`${rowIndex}_${cellIndex}`}>
        {(currentEvent.length > 0 || startedEvent.length > 0) && eventContainer()}
      </div>
    );
  };

  const renderArrowLeft = firstVisibleSlotIndex > 1 && <LinkPreviousIcon onClick={() => paginate('previous')} />;
  const renderArrowRight = firstVisibleSlotIndex + numberOfVisibleSlots <= 24 && (
    <div>
      <LinkNextIcon onClick={() => paginate('next')} />
    </div>
  );
  // eslint-disable-next-line
  const firstColumnSlotStyles = windowWidth < 450 ? 'minmax(11' + '0px, 190px)' : '190px';
  const slotsStyles = {
    display: 'grid',
    gridTemplateColumns: ` ${firstColumnSlotStyles} repeat(${numberOfVisibleSlots}, 120px) 40px`,
    gridGap: '0px'
  };
  const header = (
    <div className={classes.header} style={slotsStyles}>
      <div className={[classes.headerItem, classes.headerName].join(' ')}>
        <div>Names</div>
        {renderArrowLeft}
      </div>

      {currentVisibleSlotsArray.map((time) => (
        <div key={time} className={classes.headerItem}>
          {time}
        </div>
      ))}
      <div className={classes.headerItem}>{renderArrowRight}</div>
    </div>
  );

  const renderRows = (rowIndex, userEvents) => (
    <div key={rowIndex} style={slotsStyles}>
      <div className={`${classes.item} ${classes.trainerName} `}>
        <div className={classes.xsOnly}>
          {userEvents.leader.name.charAt(0)} {userEvents.leader.last_name}
        </div>
        <div className={classes.xsHidden}>
          <UserIcon /> {userEvents.leader.name} {userEvents.leader.last_name}
        </div>
      </div>
      {currentVisibleSlotsArray.map((time, index) => (
        <div className={classes.item} key={index}>
          {renderEventCell(rowIndex, index, time, userEvents.classes)}
        </div>
      ))}
      <div className={classes.item}></div>
    </div>
  );
  return (
    <div className={classes.root}>
      {header}
      {timetable.map((userEvents, index) => renderRows(index, userEvents))}
    </div>
  );
};
export default DayView;
