import React, { useState } from 'react';
import Tab from 'grommet/components/Tab';
import Tabs from 'grommet/components/Tabs';
import ProfileTextWithLabel from './../../../components/UI/ProfileTextWithLabel/ProfileTextWithLabel';
import classes from './ClientCreditDetails.module.scss';
import { returnArrayOfClassesDetailInSelectedGym } from '../../../shared/classFunctions';
import { isActiveMembershipUser } from '../../../shared/userFunctions';
import { returnClassConfigFieldValue } from '../../../shared/gymFunctions';
import CustomButton from '../../../components/CustomButton/CustomButton';

const ClientCreditDetails = ({ gymSettings, selectedClient, history }) => {
  const [selectedTab, setSelectedTab] = useState(0);
  let tabs = returnArrayOfClassesDetailInSelectedGym(gymSettings);
  tabs = tabs.filter((elem) => elem.name !== 'induction');

  if (selectedClient.customerType === 'full-member' && !isActiveMembershipUser(selectedClient)) {
    tabs = tabs.filter((elem) => elem.name !== 'class');
  }

  tabs = tabs.filter((elem) => returnClassConfigFieldValue(gymSettings.classConfig, elem.type, 'allowedToBuyPasses'));

  const onActiveTab = (tabIndex) => setSelectedTab(tabIndex);

  const renderBuyPassBtn = (type, passesUrl) =>
    !(selectedClient.customerType === 'full-member' && type === 'class') && (
      <CustomButton
        className={classes.redirectButton}
        label={'Buy Pass'}
        primary={true}
        onClick={() => history.push(`/gym/${passesUrl}`)}
      />
    );

  const renderRemainingClasses = (type, usersClassesLeft) => {
    let text = selectedClient[usersClassesLeft];
    if (selectedClient.customerType === 'full-member' && type === 'class') {
      return (
        <>
          <ProfileTextWithLabel label="Remaining this month:" text={selectedClient.classesLeft} />
          <ProfileTextWithLabel label="Remaining next month:" text={selectedClient.classesLeftNextMonth} />
        </>
      );
    }

    if (type === 'personalTraining') {
      text =
        selectedClient[usersClassesLeft] === 'error'
          ? 'error'
          : selectedClient[usersClassesLeft].length > 0
          ? selectedClient[usersClassesLeft].map((elem, i) => (
              <span key={i}>
                {elem.classesLeft} x {elem.duration} mins {elem.places > 1 && `(${elem.places} places)`}
              </span>
            ))
          : 0;
    }

    return <ProfileTextWithLabel label="Remaining:" text={text} />;
  };

  return (
    <div className={classes.remainingCredits} data-cy="remainingCredits">
      <h3>Remaining Credits</h3>
      <Tabs responsive={false} justify="start" activeIndex={selectedTab} onActive={onActiveTab}>
        {tabs.map(({ title, type, passesUrl, usersClassesLeft }) => (
          <Tab title={title} key={title}>
            {renderRemainingClasses(type, usersClassesLeft)}
            {renderBuyPassBtn(type, passesUrl)}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
};
export default ClientCreditDetails;
