import React, { useState } from 'react';
import classes from './SinglePassesView.module.scss';
import RenderPassActionButtons from '../../RenderPassActionButtons/RenderPassActionButtons';
import { withRouter } from 'react-router-dom';
import { getCurrencySymbol } from '../../../../shared/utility';
import InfoIcon from 'grommet/components/icons/base/Info';
import Modal from 'react-modal';
import moment from 'moment';
import CustomButton from '../../../../components/CustomButton/CustomButton';

const SinglePassesView = ({ data = [], location, history, classType }) => {
  const [infoModal, setInfoModal] = useState();
  const goBackToPreviousPage = () =>
    location && location.state && location.state.goBackToPreviousPage ? history.goBack() : null;

  const open = (id) => setInfoModal(id);
  const close = () => setInfoModal(null);

  const singlePass = (elem) => {
    const renderLabel = (label, value) =>
      value && (
        <div className={classes.row}>
          <div className={classes.label}>{label}:</div>
          <div className={classes.data}> {value}</div>
        </div>
      );

    return (
      <div key={elem._id} className={classes.singlePass} data-cy="singleClassPass">
        <Modal
          key={`modal_${elem._id}`}
          appElement={document.getElementById('root')}
          isOpen={infoModal === elem._id}
          onRequestClose={close}
          className={'ss-modal'}>
          <div className={classes.explainer}>
            <InfoIcon size="large" />
            {elem.explainer}
            <div className={classes.explainerAction}>
              <CustomButton label="Done" onClick={close} />
            </div>
          </div>
        </Modal>
        <div className={classes.singlePassHeading}>
          <h3>{elem.name}</h3>
          {elem && !!elem.explainer && (
            <div className={classes.infoIcon}>
              <InfoIcon onClick={() => open(elem._id)} />
            </div>
          )}
        </div>
        <div className={classes.description}>{elem.description} </div>
        <div className={classes.details}>
          {renderLabel('Places', elem.places)}
          {renderLabel('Included', elem.classCount)}
          {renderLabel('Duration', elem.duration)}
          {renderLabel('Price', `${getCurrencySymbol(elem.currency)} ${elem.cost}`)}
          {renderLabel('Expiry date', elem.expiryDate ? moment(elem.expiryDate).format('DD/MM/YYYY') : 'None')}
        </div>

        <div className={classes.actionBtn}>
          <RenderPassActionButtons
            classPass={elem}
            classType={classType}
            editPass={() => {}}
            goBackToPreviousPage={goBackToPreviousPage}
          />
        </div>
      </div>
    );
  };

  return <div className={classes.root}>{data.map((elem) => singlePass(elem))}</div>;
};

export default withRouter(SinglePassesView);
