import React from 'react';
import CustomButton from '../../../components/CustomButton/CustomButton';
import './ClientProfileOperationalButtons.scss';

export default function ClientProfileOperationalButtons(props) {
  return (
    <div className="client-profile__buttons-container">
      {props.showQRButton && (
        <CustomButton
          className="client-profile__qr-button"
          label="Add extra QR code"
          primary
          onClick={props.handleQrModalOpen}
        />
      )}

      <div className="ss-modal__buttons-container">
        <CustomButton label="Edit client" secondary={true} onClick={props.handleEditModalOpen} />

        <CustomButton
          className="client-profile__delete-button"
          label="Deactivate client"
          onClick={props.handleDeleteModalOpen}
        />
      </div>
    </div>
  );
}
