import React from 'react';

import classes from './HeaderWithBorder.module.scss';

const HeaderWithBoarder = ({ header, forId }) => (
  <div className={classes.header}>
    <label htmlFor={forId}>{header}</label>
  </div>
);
export default HeaderWithBoarder;
