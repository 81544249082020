import React from 'react';
import withUser from '../../../store/hoc/withUser';
import PayButton from './PayButton/PayButton';
import SignUpAndPayButton from './SignUpAndPayButton/SignUpAndPayButton';
import CancelAttendanceOrClass from './CancelAttandanceOrClass/CancelAttendanceOrClass';
import { hasClassStarted } from '../../../shared/classFunctions';
import Status from 'grommet/components/icons/Status';
import WaitListModal from '../../WaitList/WaitListModal';
import RedirectToClassPage from '../RedirectToClassPage/RedirectToClassPage';
import classes from './ClassActionButtons.module.scss';
import GoogleApplePayModal from '../GoogleApplePayButton/GoogleApplePayModal';
import { returnUserClassesLeftAmount } from '../../../shared/userFunctions';

const ClassActionButtons = ({
  userData,
  selectedClass,
  userAttendance = null,
  sourcesList,
  isHistoryComponent = false,
  type = null,
  cancelType
}) => {
  const userEventAttendance =
    userAttendance !== null
      ? userAttendance
      : {
          _id: selectedClass.userAttendanceId,
          status: selectedClass.userAttendanceStatus,
          stripeOrderId: selectedClass.userAttendanceStripeOrderId
        };
  const isUser = userData.role === 'user';
  const isAdmin = userData.role === 'admin';
  const isTrainer = userData.role === 'trainer';
  const hasClassAlreadyStarted = hasClassStarted(selectedClass, 15);
  const userHasClassesLeft = returnUserClassesLeftAmount(selectedClass.__t, userData, selectedClass) > 0;
  const needsAddPaymentMethod =
    sourcesList && sourcesList.length === 0 && selectedClass.cost > 0 && !userHasClassesLeft;
  const isClassFull = selectedClass.limit - selectedClass.joinedUsers === 0;
  const isClassCancelled = !selectedClass.active;

  const isClassCancelledContent = <p className={classes.warningClassInfo}>Cancelled</p>;

  const hasClassAlreadyStartedContent = (
    <div>
      <Status value="disabled" style={{ width: '14px', marginRight: '5px' }} />
      Already taken place.
    </div>
  );

  const cancelButton = (
    <CancelAttendanceOrClass
      type={isUser ? (type !== null ? type : 'cancelAttendanceButton') : 'cancelClassXicon'}
      selectedClass={selectedClass}
      user={userData}
      attendance={userEventAttendance}
    />
  );

  const redirectToClassPage = (
    <RedirectToClassPage
      gymId={isUser ? selectedClass.locationId : selectedClass.gymId[0]._id}
      classId={selectedClass._id}
    />
  );

  if (isUser) {
    if (isClassCancelled) {
      return isClassCancelledContent;
    }

    if (hasClassAlreadyStarted) {
      return hasClassAlreadyStartedContent;
    }

    if (userEventAttendance && userEventAttendance.status === 'active') {
      return <div>{cancelButton}</div>;
    }

    if (userEventAttendance && (userEventAttendance.status === 'attended' || userEventAttendance.status === 'absent')) {
      return null;
    }

    if (isClassFull) {
      if (selectedClass.waitListConfig && selectedClass.waitListConfig.active) {
        return <WaitListModal selectedClass={selectedClass} />;
      }
      return <p className={classes.warningClassInfo}>Full</p>;
    }

    if (needsAddPaymentMethod) {
      return <GoogleApplePayModal selectedClass={selectedClass} productType={'class'} sourcesList={sourcesList} />;
    }

    if (userEventAttendance && userEventAttendance.status === 'pending') {
      return (
        <div>
          {userEventAttendance?.stripeOrderId && !userEventAttendance?.paid ? (
            <PayButton attendanceId={userEventAttendance._id} stripeOrderId={userEventAttendance.stripeOrderId} />
          ) : (
            'Payment pending'
          )}
        </div>
      );
    }

    return <SignUpAndPayButton selectedClass={selectedClass} />;
  }

  if (isHistoryComponent) {
    return <div className={classes.adminActionButtonContainer}>{redirectToClassPage}</div>;
  }

  return (
    <div className={classes.adminActionButtonContainer}>
      {isClassCancelled && isClassCancelledContent}
      {!isClassCancelled && hasClassAlreadyStarted && hasClassAlreadyStartedContent}
      <WaitListModal selectedClass={selectedClass} />
      {redirectToClassPage}
      {(isAdmin || isTrainer) && (
        <CancelAttendanceOrClass
          cancelType={cancelType}
          type="cancelClassXicon"
          selectedClass={selectedClass}
          user={userData}
          attendance={selectedClass.attendanceList}
        />
      )}
    </div>
  );
};

export default withUser(ClassActionButtons);
