export const typeSelectOptions = (allClassTypesInGym) => {
  let result = allClassTypesInGym.map((elem) => {
    return { value: elem.type, label: elem.title };
  });

  result = [...result, { value: 'schedule', label: 'Schedule' }];
  return result;
};

export const returnLabelsForDataType = (type) => {
  const defaultData = [
    {
      name: '_id',
      label: 'All',
      selected: false
    },
    {
      name: 'name',
      label: 'Name',
      selected: false
    },
    {
      name: 'description',
      label: 'Description',
      selected: false
    },
    {
      name: 'location',
      label: 'Location',
      selected: false
    },
    {
      name: 'trainer',
      label: 'Trainer',
      selected: false
    },
    {
      name: 'limit',
      label: 'Limit',
      selected: false
    },
    {
      name: 'classDate',
      label: 'Date',
      selected: false
    },
    {
      name: 'classTime',
      label: 'Time',
      selected: false
    },
    {
      name: 'duration',
      label: 'Duration',
      selected: false
    },

    {
      name: 'minimalTimeCancellationInHours',
      label: 'Minimal Cancellation',
      selected: false
    },
    {
      name: 'private',
      label: 'Private',
      selected: false
    },
    /*   {
          name: 'enabled',
          label: 'Enabled',
          selected: false
        },*/
    /*   {
          name: 'active',
          label: 'Active',
          selected: false
        },*/
    {
      name: 'waitListConfig',
      label: 'Show Wait List',
      selected: false
    },
    {
      name: 'cost',
      label: 'Cost',
      selected: false
    }
  ];

  switch (type) {
    case 'class': {
      return defaultData;
    }
    case 'personalTraining': {
      const fieldsToRemove = ['duration', 'location', 'limit', 'waitListConfig'];
      return defaultData.filter((elem) => !fieldsToRemove.includes(elem.name));
    }
    case 'gymClass':
    case 'swimmingClass':
    case 'tennisClass':
    case 'massageClass': {
      const fieldsToRemove = ['name', 'description', 'location', 'private'];
      return defaultData.filter((elem) => !fieldsToRemove.includes(elem.name));
    }

    default:
      return defaultData;
  }
};
