import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import classes from './ManageAdmins.module.scss';
import List from 'grommet/components/List';
import Notification from 'grommet/components/Notification';
import SingleAdmin from './SingleTrainer/SingleAdmin';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import Filter from '../../../../components/Filter';
import withAdminDashboard from '../../../../store/hoc/withAdminDashboard';
import Loading from '../../../../components/Loading/Loading';
import { useQuery } from 'react-query';
import { delayFetchFunctions, fetchAdmins } from '../../../../apiFunctions/apiFunctions';

const ManageAdmins = ({ history, selectedGym }) => {
  const [filteredAdmins, setFilteredAdmins] = useState([]);
  const {
    data: adminsList,
    isLoading: isAdminsListLoading,
    error: isAdminsListError
  } = useQuery(['adminsList', selectedGym._id], () => fetchAdmins(selectedGym._id));

  useEffect(() => {
    if (adminsList) {
      setFilteredAdmins(adminsList);
    }
  }, [adminsList]);

  const renderAdminList = () => {
    if (isAdminsListError && !isAdminsListLoading) {
      return <Notification message={'Something went wrong'} status="critical" />;
    }

    if (filteredAdmins.length > 0) {
      return filteredAdmins.map((admin) => (
        <SingleAdmin
          singleAdmin={admin}
          key={admin._id}
          refreshData={() => delayFetchFunctions([['adminsList', selectedGym._id]], 0)}
        />
      ));
    }

    if (filteredAdmins.length === 0) {
      return <h3>No admins</h3>;
    }
    return <Loading />;
  };

  const onSearchChanged = (value) => {
    const filteredArray = adminsList.filter((elem) => {
      return elem.name.toLowerCase().includes(value) || elem.last_name.toLowerCase().includes(value);
    });
    setFilteredAdmins(filteredArray);
  };

  return (
    <div data-cy={'admins'}>
      <div className={classes.header}>
        <Filter title={'Filter by name'} onFilter={onSearchChanged} placeHolder={'Admin name'} />
        <CustomButton
          label="Add new"
          secondary={true}
          onClick={() => history.push(`/gym/${selectedGym._id}/admins/add`)}
        />
      </div>
      <List>{renderAdminList()}</List>
    </div>
  );
};

export default withRouter(withAdminDashboard(ManageAdmins));
