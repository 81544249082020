export const createAction = (type, payload) => ({ type, payload });

//manage admins
export const GET_ADMINS_START = 'GET_ADMINS_START';
export const GET_ADMINS_SUCCESS = 'GET_ADMINS_SUCCESS';
export const GET_ADMINS_ERROR = 'GET_ADMINS_ERROR';

export const CREATE_ADMIN_START = 'CREATE_ADMIN_START';
export const CREATE_ADMIN_SUCCESS = 'CREATE_ADMIN_SUCCESS';
export const CREATE_ADMIN_ERROR = 'CREATE_ADMIN_ERROR';

export const UPDATE_ADMIN_START = 'UPDATE_ADMIN_START';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_ERROR = 'UPDATE_ADMIN_ERROR';

export const DELETE_ADMIN_START = 'DELETE_ADMIN_START';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_ERROR = 'DELETE_ADMIN_ERROR';

// table
export const SET_WINDOW_WIDTH = 'SET_WINDOW_WIDTH';
export const SET_GLOBAL_ERROR_500 = 'SET_GLOBAL_ERROR_500';
export const SET_GLOBAL_NETWORK_ERROR = 'SET_GLOBAL_NETWORK_ERROR';
export const SET_UNAUTHORIZED_ERROR = 'SET_UNAUTHORIZED_ERROR';

// gyms locations
export const SET_LOCATIONS_START = 'SET_LOCATIONS_START';
export const SET_LOCATIONS_SUCCESS = 'SET_LOCATIONS_SUCCESS';
export const SET_LOCATIONS_FAILED = 'SET_LOCATIONS_FAILED';

// auth operations
export const AUTH_START = 'AUTH_START';
export const AUTH_RESET = 'AUTH_RESET';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SET_USER_DATA = 'AUTH_SET_USER_DATA';
export const AUTH_SET_USER_DATA_START = 'AUTH_SET_USER_DATA_START';

// admin - gym data
export const ADMIN_SET_GYM_DATA_START = 'ADMIN_SET_GYM_DATA_START';
export const ADMIN_SET_GYM_DATA_SUCCESS = 'ADMIN_SET_GYM_DATA_SUCCESS';
export const ADMIN_SET_GYM_DATA_FAILED = 'ADMIN_SET_GYM_DATA_FAILED';

// admin - membership plans
export const ADMIN_FETCH_PLANS_START = 'ADMIN_FETCH_PLANS_START';
export const ADMIN_FETCH_PLANS_SUCCESS = 'ADMIN_FETCH_PLANS_SUCCESS';
export const ADMIN_FETCH_PLANS_FAILED = 'ADMIN_FETCH_PLANS_FAILED';

// admin - clients in selected gym
export const ADMIN_FETCH_GYM_USERS_START = 'ADMIN_FETCH_GYM_USERS_START';
export const ADMIN_FETCH_GYM_USERS_SUCCESS = 'ADMIN_FETCH_GYM_USERS_SUCCESS';
export const ADMIN_FETCH_GYM_USERS_FAILED = 'ADMIN_FETCH_GYM_USERS_FAILED';

// admin - trainers list
export const ADMIN_SET_TRAINERS_START = 'ADMIN_SET_TRAINERS_START';
export const ADMIN_SET_TRAINERS_SUCCESS = 'ADMIN_SET_TRAINERS_SUCCESS';
export const ADMIN_SET_TRAINERS_FROM_ALL_GYMS_SUCCESS = 'ADMIN_SET_TRAINERS_FROM_ALL_GYMS_SUCCESS';
export const ADMIN_SET_TRAINERS_FAILED = 'ADMIN_SET_TRAINERS_FAILED';

// admin - fetch single client
export const ADMIN_FETCH_SINGLE_CLIENT_START = 'ADMIN_FETCH_SINGLE_CLIENT_START';
export const ADMIN_FETCH_SINGLE_CLIENT_SUCCESS = 'ADMIN_FETCH_SINGLE_CLIENT_SUCCESS';
export const ADMIN_FETCH_SINGLE_CLIENT_FAILED = 'ADMIN_FETCH_SINGLE_CLIENT_FAILED';
export const ADMIN_FETCH_SINGLE_CLIENT_RESET = 'ADMIN_FETCH_SINGLE_CLIENT_RESET';

// admin - manage global QR reader
export const ADMIN_CHANGE_GLOBAL_QR_READER = 'ADMIN_CHANGE_GLOBAL_QR_READER';

// admin - classes templates
export const ADMIN_FETCH_CLASSES_TEMPLATES_START = 'ADMIN_FETCH_CLASSES_TEMPLATES_START';
export const ADMIN_FETCH_CLASSES_TEMPLATES_SUCCESS = 'ADMIN_FETCH_CLASSES_TEMPLATES_SUCCESS';
export const ADMIN_FETCH_CLASSES_TEMPLATES_FAILED = 'ADMIN_FETCH_CLASSES_TEMPLATES_FAILED';

// admin - get upcoming PT Schedule
export const ADMIN_FETCH_UPCOMING_PTS_START = 'ADMIN_FETCH_UPCOMING_PTS_START';
export const ADMIN_FETCH_UPCOMING_PTS_SUCCESS = 'ADMIN_FETCH_UPCOMING_PTS_SUCCESS';
export const ADMIN_FETCH_UPCOMING_PTS_FAILED = 'ADMIN_FETCH_UPCOMING_PTS_FAILED';

// admin - cancel inductions
export const ADMIN_CANCEL_CLASS_START = 'ADMIN_CANCEL_CLASS_START';
export const ADMIN_CANCEL_CLASS_SUCCESS = 'ADMIN_CANCEL_CLASS_SUCCESS';
export const ADMIN_CANCEL_CLASS_FAILED = 'ADMIN_CANCEL_CLASS_FAILED';

// client - update details
export const CLIENT_UPDATE_DETAILS_START = 'CLIENT_UPDATE_DETAILS_START';
export const CLIENT_UPDATE_DETAILS_SUCCESS = 'CLIENT_UPDATE_DETAILS_SUCCESS';
export const CLIENT_UPDATE_DETAILS_FAILED = 'CLIENT_UPDATE_DETAILS_FAILED';

// client - current plan status
export const CLIENT_SUBSCRIPTION_STATUS_START = 'CLIENT_SUBSCRIPTION_STATUS_START';
export const CLIENT_SUBSCRIPTION_STATUS_SUCCESS = 'CLIENT_SUBSCRIPTION_STATUS_SUCCESS';
export const CLIENT_SUBSCRIPTION_STATUS_FAILED = 'CLIENT_SUBSCRIPTION_STATUS_FAILED';

// client - fetch available plans
export const CLIENT_FETCH_PLANS_START = 'CLIENT_FETCH_PLANS_START';
export const CLIENT_FETCH_PLANS_SUCCESS = 'CLIENT_FETCH_PLANS_SUCCESS';
export const CLIENT_FETCH_PLANS_FAILED = 'CLIENT_FETCH_PLANS_FAILED';

// client - manage payment methods (stripe sources)
export const CLIENT_FETCH_SOURCES_START = 'CLIENT_FETCH_SOURCES_START';
export const CLIENT_FETCH_SOURCES_SUCCESS = 'CLIENT_FETCH_SOURCES_SUCCESS';
export const CLIENT_FETCH_SOURCES_FAILED = 'CLIENT_FETCH_SOURCES_FAILED';

// client - get his / her upcoming classes
export const CLIENT_FETCH_UPCOMING_CLASSES_START = 'CLIENT_FETCH_UPCOMING_CLASSES_START';
export const CLIENT_FETCH_UPCOMING_CLASSES_SUCCESS = 'CLIENT_FETCH_UPCOMING_CLASSES_SUCCESS';
export const CLIENT_FETCH_UPCOMING_CLASSES_FAILED = 'CLIENT_FETCH_UPCOMING_CLASSES_FAILED';

// client - get his / her upcoming PTs
export const CLIENT_FETCH_UPCOMING_PTS_START = 'CLIENT_FETCH_UPCOMING_PTS_START';
export const CLIENT_FETCH_UPCOMING_PTS_SUCCESS = 'CLIENT_FETCH_UPCOMING_PTS_SUCCESS';
export const CLIENT_FETCH_UPCOMING_PTS_FAILED = 'CLIENT_FETCH_UPCOMING_PTS_FAILED';

// client - change class passes limit
export const CLIENT_CHANGE_CLASS_PASSES_LIMIT = 'CLIENT_CHANGE_CLASS_PASSES_LIMIT';

// client - change PTs limit
export const CLIENT_CHANGE_PTS_LIMIT = 'CLIENT_CHANGE_PTS_LIMIT';

// client Wait List
export const CLIENT_FETCH_WAIT_LIST_START = 'CLIENT_FETCH_WAIT_LIST_START';
export const CLIENT_FETCH_WAIT_LIST_SUCCESS = 'CLIENT_FETCH_WAIT_LIST_SUCCESS';
export const CLIENT_FETCH_WAIT_LIST_FAILED = 'CLIENT_FETCH_WAIT_LIST_FAILED';

//location - get names of cities
export const FETCH_CITY_NAMES_START = 'FETCH_CITY_NAMES_START';
export const FETCH_CITY_NAMES_SUCCESS = 'FETCH_CITY_NAMES_SUCCESS';
export const FETCH_CITY_NAMES_FAILED = 'FETCH_CITY_NAMES_FAILED';

//admin fetch all users data from selected gym
export const FETCH_GYM_USERS_FULL_DATA_START = 'FETCH_GYM_USERS_FULL_DATA_START';
export const FETCH_GYM_USERS_FULL_DATA_SUCCESS = 'FETCH_GYM_USERS_FULL_DATA_SUCCESS';
export const FETCH_GYM_USERS_FULL_DATA_FAILED = 'FETCH_GYM_USERS_FULL_DATA_FAILED';

export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_VIDEO_PRODUCTS = 'UPDATE_VIDEO_PRODUCTS';
