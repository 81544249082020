import React from 'react';
import Spinning from 'grommet/components/icons/Spinning';

//xsmall|small|medium|large|xlarge|huge

const Loading = ({ size = 'small', topOffset = 0, bottomOffset = 0, centered = false }) => {
  const loadingStyles = { margin: `${topOffset} auto ${bottomOffset} auto`, display: 'block' };
  if (centered) {
    loadingStyles.textAlign = 'center';
  }
  return (
    <div style={loadingStyles}>
      <Spinning size={size} />
    </div>
  );
};

export default Loading;
