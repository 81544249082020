import axios from '../../../../axios-global';
import React, { useState, useEffect } from 'react';
import classes from './AttendancesReports.module.scss';
import moment from 'moment';
import ExportToCSV from '../../ExportToCSV/ExportToCSV';
import withAdminDashboard from '../../../../store/hoc/withAdminDashboard';
import GymSelector from '../../../../components/Selectors/GymSelector/GymSelector';
import Filter from '../../../../components/Filter';
import Loading from '../../../../components/Loading/Loading';
import RedirectToUserDetails from '../../../ActionButtons/RedirectToUserDetails/RedirectToUserDetails';
import Notification from 'grommet/components/Notification';

export const AttendancesReports = ({ selectedGym }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filterClientName, setFilterClientName] = useState('');
  const [gymSelection, setGymSelection] = useState(selectedGym);
  const [fetchedData, setFetchedData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(
    () => {
      const getReport = () => {
        setIsLoading(true);
        let url = `/gym-attendances/all?gymId=${gymSelection._id}&startDate=${startDate}&endDate=${endDate}`;

        axios
          .get(url)
          .then((response) => {
            setIsLoading(false);
            setFetchedData(response.data);
            if (filterClientName?.length) {
              onClientNameFilter(filterClientName);
            } else {
              setFilteredData(response.data);
            }
          })
          .catch((err) => {
            setIsError(err.response.data.errors[0].msg);
            setIsLoading(false);
          });
      };
      if (startDate !== null && endDate !== null) {
        getReport();
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [startDate, endDate, gymSelection]
  );

  const onClientNameFilter = (name) => {
    const filteredArray = fetchedData.filter((result) => {
      return result.userId.name.toLowerCase().includes(name) || result.userId.last_name.toLowerCase().includes(name);
    });
    setFilterClientName(name);
    setFilteredData(filteredArray);
  };

  const filters = () => (
    <div className={classes.filters}>
      <Filter type={'date'} title={'Start date'} onFilter={setStartDate} />
      <Filter type={'date'} title={'End date'} onFilter={setEndDate} />
      <GymSelector onSelect={setGymSelection} defaultChecked={selectedGym} />
      <Filter title="Filter by name" placeHolder={'Client name'} onFilter={onClientNameFilter} />
    </div>
  );

  const returnData = () => {
    const item = ({ userId: { name, last_name, _id }, createdDate, gymId }) => (
      <div className={classes.item}>
        <div>
          {name} {last_name}
        </div>
        <div>{moment(createdDate).format('DD/MM/YYYY hh:mm a')}</div>
        <RedirectToUserDetails gymId={gymId} clientId={_id} />
      </div>
    );

    if (isLoading) {
      return <Loading />;
    }

    if (isError !== null) {
      return <Notification message={isError} status="critical" />;
    }

    if (filteredData.length === 0) {
      return <h3>No clients found</h3>;
    }

    return <div>{filteredData.map((elm) => item(elm))}</div>;
  };

  return (
    <div>
      <ExportToCSV
        data={filteredData}
        type={'attendanceReport'}
        gymName={gymSelection.name}
        disabled={filteredData.length === 0}
      />
      {filters()}
      {returnData()}
    </div>
  );
};

export default withAdminDashboard(AttendancesReports);
