import React, { Component } from 'react';
import axios from '../../../../../axios-global';
import Notification from 'grommet/components/Notification';
import classes from './AddEditTrainer.module.scss';
import FormCreator from '../../../../../components/UI/FormCreator/FormCreator';
import { checkValidity, returnErrorTextForField } from '../../../../../shared/utility';
import { isFullAccessAdminOrTrainer, returnGymsByAccess } from '../../../../../shared/userFunctions';
import ManageWorkingDays from '../ManageWorkingDays/ManageWorkingDays';
import Loading from '../../../../../components/Loading/Loading';
import CustomButton from '../../../../../components/CustomButton/CustomButton';
import GymAccessSelector from '../../GymAccessSelector/GymAccessSelector';
import { withRouter } from 'react-router-dom';
import withAdminDashboard from '../../../../../store/hoc/withAdminDashboard';

const cloneDeep = require('lodash.clonedeep');

class TrainersAddEditTrainer extends Component {
  state = {
    formIsValid: false,
    formValidated: false,
    loading: false,
    error: null,
    fullAccess: false,
    selectedGyms: null,
    selectedGymsAreValid: null,
    userSelectedGyms: {},
    shifts: [],
    shiftsAreValid: true,
    trainerForm: {
      title: {
        label: 'Title',
        type: 'select',
        options: ['Mr', 'Mrs', 'Miss', 'Ms', 'Dr'],
        placeholder: 'Select title',
        value: 'Mr',
        validation: {
          required: true
        },
        valid: true,
        touched: false,
        errorText: null
      },
      name: {
        label: 'First name',
        type: 'text',
        placeholder: 'Enter first name',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      last_name: {
        label: 'Last name',
        type: 'text',
        placeholder: 'Enter last name',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      email: {
        label: 'E-mail',
        type: 'text',
        placeholder: 'Enter e-mail',
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      phone_number: {
        label: 'Phone number',
        type: 'text',
        placeholder: 'Enter telephone number',
        value: '',
        validation: {
          required: true,
          isUkTelephone: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      password: {
        label: 'Password',
        type: 'password',
        placeholder: 'Enter password',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      confirmPassword: {
        label: 'Confirm password',
        type: 'password',
        placeholder: 'Confirm password',
        value: '',
        basePasswordValue: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      }
    }
  };

  componentDidMount() {
    let gymsList = {};
    this.props.locations.forEach((elem) => {
      gymsList[elem._id] = false;
    });

    this.setState({
      selectedGyms: gymsList
    });
    if (this.props.editTrainer) {
      const copiedForm = cloneDeep(this.state.trainerForm);
      delete copiedForm.password;
      delete copiedForm.confirmPassword;

      const selectedGyms =
        this.props.editTrainer['gymsFullAccess'] === undefined || this.props.editTrainer.gymsFullAccess
          ? this.setFullAccess(gymsList)
          : this.setSelectedGyms(this.props.editTrainer.gymId, gymsList);

      const fullAccess =
        this.props.editTrainer['gymsFullAccess'] === undefined || this.props.editTrainer.gymsFullAccess;
      this.setState(
        {
          trainerForm: copiedForm,
          selectedGyms,
          userSelectedGyms: gymsList,
          fullAccess,
          shifts: this.props.editTrainer.shifts
        },

        () => this.assignExisitingValues()
      );
    }
  }

  setSelectedGyms = (array, selectedGyms) => {
    array.forEach((elem) => {
      selectedGyms[elem] = true;
    });
    return selectedGyms;
  };

  assignExisitingValues = () => {
    const formNames = Object.keys(this.props.editTrainer);
    const previousValues = Object.values(this.props.editTrainer);
    const copiedForm = cloneDeep(this.state.trainerForm);

    formNames.forEach((element, index) => {
      if (copiedForm.hasOwnProperty(element) && copiedForm[element] !== undefined) {
        copiedForm[element] = {
          ...copiedForm[element],
          value: previousValues[index],
          valid: true,
          touched: true
        };
      }
    });

    this.setState({
      trainerForm: copiedForm
    });
  };

  valueChangedHandler = (newValue, identifier) => {
    const copiedForm = cloneDeep(this.state.trainerForm);

    if (identifier !== 'confirmPassword') {
      copiedForm[identifier] = {
        ...copiedForm[identifier],
        value: newValue,
        valid: checkValidity(newValue, this.state.trainerForm[identifier].validation),
        errorText: returnErrorTextForField(this.state.trainerForm[identifier].validation),
        touched: true
      };
      return this.setState({
        trainerForm: copiedForm
      });
    }

    // update for confirm password
    copiedForm[identifier] = {
      ...copiedForm[identifier],
      value: newValue,
      valid: checkValidity(newValue, this.state.trainerForm[identifier].validation),
      errorText: returnErrorTextForField(this.state.trainerForm[identifier].validation),
      touched: true,
      basePasswordValue: this.state.trainerForm.password.value
    };

    return this.setState({
      trainerForm: copiedForm
    });
  };

  checkFormValidity = () => {
    this.setState({ formValidated: true });
    const selectedGymsAreValid =
      Object.keys(this.state.selectedGyms).filter((k) => this.state.selectedGyms[k]).length > 0;

    let formIsValid;

    for (let input in this.state.trainerForm) {
      if (!this.state.trainerForm[input].valid) {
        const copiedForm = cloneDeep(this.state.trainerForm);
        copiedForm[input] = {
          ...copiedForm[input],
          valid: checkValidity(copiedForm[input].value, copiedForm[input].validation),
          errorText: returnErrorTextForField(copiedForm[input].validation),
          touched: true
        };

        if (this.props.editTrainer) {
          document.getElementsByClassName('ss-modal')[0].scrollTop = 0;
        } else {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        }
        formIsValid = false;

        return this.setState({
          formIsValid,
          trainerForm: copiedForm,
          selectedGymsAreValid
        });
      }
    }
    formIsValid = selectedGymsAreValid;
    this.setState({
      formIsValid,
      selectedGymsAreValid
    });

    if (this.state.shifts.length === 1 && !this.props.editTrainer) {
      const invalidFields = this.state.shifts.filter((field) => field.startingFrom === null || field.endsAt === null);

      if (invalidFields !== null && invalidFields.length !== 0) {
        formIsValid = false;
        this.setState({
          shiftsAreValid: false
        });
      }
    }

    if (!this.state.shiftsAreValid) {
      formIsValid = false;
    }

    if (selectedGymsAreValid && formIsValid) {
      if (this.props.editTrainer) {
        this.saveEditedTrainer();
      } else {
        this.trainersAddTrainer();
      }
    }
    return;
  };

  passErrorMessagesToForm = (error) => {
    if (error.response && error.response.data && error.response.data.errors) {
      const formNames = Object.keys(error.response.data.errors);
      const errorsMessages = Object.values(error.response.data.errors);
      const copiedForm = cloneDeep(this.state.trainerForm);

      formNames.forEach((element, index) => {
        if (this.state.trainerForm.hasOwnProperty(element) && this.state.trainerForm[element] !== undefined) {
          copiedForm[element] = {
            ...copiedForm[element],
            valid: false,
            errorText: errorsMessages[index].message,
            touched: true
          };
        }
      });

      this.setState({
        trainerForm: copiedForm
      });
    } else {
      // eslint-disable-next-line
      console.log(error.response);
    }
  };

  trainersAddTrainer = () => {
    this.setState({ loading: true });

    const trainerFormData = {
      role: 'trainer'
    };
    for (let input in this.state.trainerForm) {
      trainerFormData[input] = this.state.trainerForm[input].value;

      if (typeof trainerFormData[input] === 'string' && trainerFormData[input].toLowerCase() === 'yes') {
        trainerFormData[input] = true;
      }
      if (typeof trainerFormData[input] === 'string' && trainerFormData[input].toLowerCase() === 'no') {
        trainerFormData[input] = false;
      }
    }

    trainerFormData.gymsFullAccess = this.state.fullAccess;

    let selectedGyms = [];
    const gyms = this.state.selectedGyms;
    if (!this.state.fullAccess) {
      Object.keys(gyms).forEach(function (key) {
        if (gyms[key]) {
          selectedGyms.push(key);
        }
      });
    }

    trainerFormData.gymId = trainerFormData.gymsFullAccess ? [] : selectedGyms;
    trainerFormData.shifts = this.state.shifts;

    axios
      .post(`/users/gym/${this.props.selectedGym._id}`, trainerFormData)
      .then(() => {
        this.setState({ loading: false });
        this.props.history.push(`/gym/${this.props.selectedGym._id}/trainers`);
      })
      .catch((error) => {
        this.passErrorMessagesToForm(error);

        this.setState({
          loading: false
        });
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      });
  };

  setFullAccess = (gyms) => {
    let selectedGyms = {};
    let gymsListNew = {};
    this.props.locations.forEach((elem) => {
      gymsListNew[elem._id] = true;
    });

    if (gyms) {
      Object.keys(gyms).forEach(function (key) {
        selectedGyms[key] = true;
      });
    }

    return gyms ? selectedGyms : gymsListNew;
  };

  saveEditedTrainer = () => {
    const trainerFormData = {};
    for (let input in this.state.trainerForm) {
      trainerFormData[input] =
        typeof this.state.trainerForm[input].value === 'string'
          ? this.state.trainerForm[input].value.trim()
          : this.state.trainerForm[input].value;

      if (typeof trainerFormData[input] === 'string' && trainerFormData[input].toLowerCase() === 'yes') {
        trainerFormData[input] = true;
      }
      if (typeof trainerFormData[input] === 'string' && trainerFormData[input].toLowerCase() === 'no') {
        trainerFormData[input] = false;
      }
    }

    trainerFormData.gymsFullAccess = this.state.fullAccess;
    trainerFormData.shifts = this.state.shifts;

    let selectedGyms = [];
    const gyms = this.state.selectedGyms;
    if (!this.state.fullAccess) {
      Object.keys(gyms).forEach(function (key) {
        if (gyms[key]) {
          selectedGyms.push(key);
        }
      });
    }

    trainerFormData.gymId = trainerFormData.gymsFullAccess ? [] : selectedGyms;

    axios
      .patch(`/users/${this.props.editTrainer._id}`, trainerFormData)
      .then(() => {
        this.props.closeModal(true);
        this.props.refreshData();
      })
      .catch((error) => this.passErrorMessagesToForm(error));

    this.setState({
      loading: false
    });
  };

  handleShift = (shiftArray) => {
    this.setState({
      shifts: shiftArray
    });
  };

  hadleShiftError = (isShiftsValid) => {
    this.setState({
      shiftsAreValid: isShiftsValid
    });
  };

  handleGymAccessChange = (isFullAccess, selectedGyms) =>
    this.setState({
      fullAccess: isFullAccess,
      selectedGyms
    });

  handleClose = () => {
    if (this.props.editTrainer) {
      this.props.closeModal(true);
    } else {
      this.props.history.push(`/gym/${this.props.selectedGym._id}/trainers`);
    }
  };

  render() {
    let formWarning = null;
    if (!this.state.formIsValid && this.state.formValidated) {
      formWarning = (
        <Notification message="Form is not filled in correctly" status="warning" className="ss-top-notification" />
      );
    }

    let error = null;
    if (this.state.error) {
      error = <Notification message={this.state.error} status="critical" className="ss-top-notification" />;
    }
    if (!this.state.selectedGyms) {
      return <Loading />;
    }

    return (
      <>
        {error}
        {formWarning}
        <div className={classes.container}>
          <FormCreator formData={this.state.trainerForm} valueChanged={this.valueChangedHandler} />
          <GymAccessSelector
            isFullAccessAdminOrTrainer={isFullAccessAdminOrTrainer(this.props.userData)}
            gymsList={returnGymsByAccess(this.props.locations, this.props.userData)}
            defaultSelectedGyms={this.state.selectedGyms}
            defaultIsFullAccess={this.state.fullAccess}
            onChange={this.handleGymAccessChange}
          />
        </div>

        <ManageWorkingDays
          shifts={this.state.shifts}
          shiftGenerated={this.handleShift}
          isShiftAreValid={this.hadleShiftError}
          isEditTrainer={this.props.editTrainer}
        />

        {!this.state.shiftsAreValid && (
          <div className={classes.endTimeError}>
            Please select appropriate start time & end time, and end time should be greater than start time
          </div>
        )}

        <div className={'ss-modal__buttons-container'} style={{ marginTop: '30px' }}>
          <CustomButton
            fullWidth
            isLoading={this.state.loading}
            label={this.props.editTrainer ? 'Save' : 'Add'}
            secondary={true}
            onClick={this.checkFormValidity}
          />
          <CustomButton fullWidth label="Cancel" onClick={this.handleClose} />
        </div>
      </>
    );
  }
}

export default withRouter(withAdminDashboard(TrainersAddEditTrainer));
