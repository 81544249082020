import React from 'react';

import Modal from 'react-modal';
import classes from './CalendarModal.module.scss';
import CustomButton from '../../../../components/CustomButton/CustomButton';

const CalendarModal = ({ children, isOpen, onRequestClose }) => (
  <Modal className="ss-modal" isOpen={isOpen} onRequestClose={onRequestClose}>
    <h2 className={classes.header}>Add to Calendar</h2>
    <div className={classes.content}>{children}</div>
    <CustomButton onClick={onRequestClose} secondary className={classes.btn} label={'Close'} />
  </Modal>
);
export default CalendarModal;
