import React, { useState } from 'react';
import Modal from 'react-modal';
import ListItem from 'grommet/components/ListItem';
import Icons from '../../../../../components/UI/Icons/Icons';
import AddEditAdmin from '../AddEditAdmin/AddEditAdmin';
import classes from './SingleAdmin.module.scss';
import withAdminDashboard from '../../../../../store/hoc/withAdminDashboard';
import { isFullAccessAdminOrTrainer } from '../../../../../shared/userFunctions';
import { useMutation } from 'react-query';
import { deleteAdmin } from '../../../../../apiFunctions/apiFunctions';
import CustomToast from '../../../../../components/CustomToast/CustomToast';

const SingleAdmin = ({ isFullAccessUserData, userData, singleAdmin, refreshData }) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [toast, setToast] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const mutateDeleteAdmin = useMutation(() => deleteAdmin(singleAdmin._id), {
    onSuccess: () => {
      setToast({
        status: 'ok',
        msg: 'Successfully deleted',
        show: true
      });
      refreshData();
    },
    onError: (response) => {
      setShowModal(false);
      setToast({
        status: 'critical',
        msg: response.data.message ? response.data.message : 'Something went wrong, try again',
        show: true
      });
    }
  });

  const handleAdminDelete = () => {
    if (!showConfirm) {
      setToast({
        status: 'warning',
        msg: 'Click once again to confirm the deletion of the admin in all gyms',
        show: true
      });
      return setShowConfirm(true);
    }
    mutateDeleteAdmin.mutate();
  };

  const handleOpenModal = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  return (
    <>
      <CustomToast toast={toast} />
      <Modal
        onRequestClose={handleModalClose}
        isOpen={showModal}
        className="ss-modal ss-modal--auto"
        ariaHideApp={false}>
        <div className="grommet" style={{ padding: '0 10px' }}>
          <AddEditAdmin editAdmin={singleAdmin} closeModal={handleModalClose} refreshData={refreshData} />
        </div>
      </Modal>

      <ListItem direction="column" align="start" separator="horizontal">
        <div className={classes.singleAdmin}>
          <div className={classes.adminName}>
            <div>
              {singleAdmin.name} {singleAdmin.last_name}
            </div>
            {isFullAccessAdminOrTrainer(singleAdmin) && <div>Full access</div>}
          </div>

          {userData._id !== singleAdmin._id && (
            <Icons edit delete={isFullAccessUserData} editClick={handleOpenModal} deleteClick={handleAdminDelete} />
          )}
        </div>
      </ListItem>
    </>
  );
};

export default withAdminDashboard(SingleAdmin);
