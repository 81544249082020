import React from 'react';
import classes from '../../components/Contact/Contact.module.scss';
import Facebook from 'grommet/components/icons/base/SocialFacebook';
import Instagram from 'grommet/components/icons/base/SocialInstagram';
import Twitter from 'grommet/components/icons/base/SocialTwitter';

export default () => (
  <div className={'container'}>
    <div className={classes.detailsContainer}>
      <div className={classes.contactDetails}>
        <div className={classes.singleDetail}>
          <span className={classes.label}>Mail:</span>
          <a href="info@m8group.co.uk">info@m8group.co.uk</a>
        </div>

        <div className={classes.singleDetail}>
          <span className={classes.label}>Address:</span>
          <div>
            motive8 Group Ltd
            <br />
            Gibraltar House, Rodd Estate <br />
            Govett Avenue, Shepperton <br />
            TW17 8AB <br />
          </div>
        </div>
        <div className={classes.singleDetail}>
          <span className={classes.label}>Telephone number:</span>
          Freephone 0800 028 0198 <br />
          Telephone 020 8481 9700
        </div>
      </div>

      <div className={classes.location}>
        <div className={classes.locationName}>London</div>
        <div>
          65 High Street <br />
          Teddington
          <br />
          Middlesex
          <br />
          TW11 8HA
          <br /> <br />
          Telephone: 0800 028 0198
        </div>
      </div>

      <div className={classes.location}>
        <div className={classes.locationName}>Leeds</div>
        <div>
          7 Marshall Court
          <br />
          Marshall Street
          <br />
          Leeds
          <br />
          LS11 9YJ
          <br /> <br />
          Telephone: 0113 243 0161
        </div>
      </div>

      <div className={classes.iconsContainer}>
        <a
          href="https://www.facebook.com/m8group/"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.contactIcon}>
          <Facebook size="large" className={classes.icons} />
        </a>

        <a
          href="https://www.instagram.com/motive8group/"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.contactIcon}>
          <Instagram size="large" className={classes.icons} />
        </a>

        <a href="https://twitter.com/m8group" target="_blank" rel="noopener noreferrer" className={classes.contactIcon}>
          <Twitter size="large" className={classes.icons} />
        </a>
      </div>
    </div>
  </div>
);
