import React, { useEffect, useState } from 'react';
import { CheckBox } from 'grommet';
import { returnArrayOfClassesDetailInSelectedGym } from '../../../shared/classFunctions';
import classes from './ClassTypeCheckboxes.module.scss';
import { CLASS_TYPES } from '../../../constants';

export const returnInitialStateForSelectedClassType = (classTypes) => {
  let result = {};
  classTypes.forEach((classType) => (result[classType.type] = true));
  return result;
};
export const returnSelectedClassTypesAsArray = (classTypes) => {
  const keys = Object.keys(classTypes);
  return keys.filter((key) => !!classTypes[key]);
};

const ClassTypesCheckboxes = ({ gym, allClassTypes = false, onChange = () => {} }) => {
  const [selectedClassType, setSelectedClassType] = useState([]);
  const [classTypes, setClassTypes] = useState([]);

  useEffect(
    () => {
      if (allClassTypes) {
        const selected = returnInitialStateForSelectedClassType(CLASS_TYPES);
        setSelectedClassType(selected);
        setClassTypes(CLASS_TYPES);
        onChange(selected);
      } else {
        const classTypesInGym = returnArrayOfClassesDetailInSelectedGym(gym.settings);
        const selected = returnInitialStateForSelectedClassType(classTypesInGym);
        setSelectedClassType(selected);
        setClassTypes(classTypesInGym);
        onChange(selected);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [gym, allClassTypes]
  );

  const handleClassTypeChange = (type) => {
    const selected = {
      ...selectedClassType,
      [type]: !selectedClassType[type]
    };
    setSelectedClassType(selected);
    onChange(selected);
  };

  return (
    <div className={classes.root}>
      {classTypes.map(({ title, type }) => (
        <CheckBox
          key={type}
          checked={selectedClassType[type]}
          onChange={() => handleClassTypeChange(type)}
          label={title}
        />
      ))}
    </div>
  );
};

export default ClassTypesCheckboxes;
