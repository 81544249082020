import React from 'react';
import moment from 'moment';
import classes from './ShowData.module.scss';

const DebtorsReportsPayg = ({ data }) => {
  const header = ['Name', 'Type', 'Created', 'Class date', 'Amount'];
  const item = ({ name, last_name, userType, createdDate, classDate, cost, userId }) => (
    <div key={userId} className={[classes.paygGrid, classes.item].join(' ')}>
      <div className={classes.name}>
        {name} {last_name}
      </div>
      <div>
        <span className={classes.label}>Type:</span>
        {userType}
      </div>
      <div>
        <span className={classes.label}>Created:</span>
        {moment(createdDate).format('DD/MM/YYYY')}
      </div>
      <div>
        <span className={classes.label}>Class date:</span>
        {moment(classDate).format('DD/MM/YYYY')}
      </div>
      <div>
        <span className={classes.label}>Amount:</span>
        {cost}
      </div>
    </div>
  );

  if (data.length === 0) {
    return <h3>No history</h3>;
  }

  return (
    <div>
      <div className={classes.header}>
        <div className={['table-header', classes.paygGrid].join(' ')}>
          {header.map((elem) => (
            <div key={elem}>{elem}</div>
          ))}
        </div>
      </div>
      <div className={classes.dataContainer}>{data.map((elm) => item(elm))}</div>
    </div>
  );
};

export default DebtorsReportsPayg;
