import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import AddEditCategory from '../AddEditCategory/AddEditCategory';

function AddCategory({ open, onClose }) {
  return (
    <Modal appElement={document.getElementById('root')} onRequestClose={onClose} isOpen={open} className={'ss-modal'}>
      <AddEditCategory onClose={onClose} />
    </Modal>
  );
}

AddCategory.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default AddCategory;
