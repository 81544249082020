import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { checkIfTrainerIsAvailableAtProvidedSchedule } from '../../../../../../shared/classFunctions';
import CustomButton from '../../../../../CustomButton/CustomButton';
import classes from './ScheduleValidator.module.scss';

const ScheduleValidator = ({ schedule, selectedTrainer, setStaffShiftsSolution }) => {
  const [showModal, setShowModal] = useState(false);
  const [incorrectClasses, setIncorrectClasses] = useState([]);

  useEffect(
    () => {
      if (schedule !== null) {
        if (selectedTrainer && selectedTrainer.shifts && selectedTrainer.shifts.length === 0) {
          setShowModal(true);
        } else {
          const countWrongClasses = checkIfTrainerIsAvailableAtProvidedSchedule(selectedTrainer, schedule);
          setIncorrectClasses(countWrongClasses);
          setShowModal(countWrongClasses.length > 0);
          if (countWrongClasses.length === 0) {
            saveFunction();
          }
        }
      }
    }, // eslint-disable-next-line
    [schedule]
  );

  const saveFunction = () => {
    setStaffShiftsSolution('save');
    setShowModal(false);
  };
  const cancelFunction = () => {
    setStaffShiftsSolution('cancel');
    setShowModal(false);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const renderInfo = () =>
    selectedTrainer && selectedTrainer.shifts && selectedTrainer.shifts.length === 0 ? (
      <div className={classes.text}>No shifts are set for this trainer.</div>
    ) : (
      <div>
        <div className={classes.text}>Trainer is not available at below time:</div>
        <div className={classes.classesList}>
          {incorrectClasses.map((elem, i) => (
            <div key={i}>{elem}</div>
          ))}
        </div>
      </div>
    );
  return (
    <Modal isOpen={showModal} className="ss-modal" onClose={handleCloseModal}>
      {renderInfo()}
      <div className={classes.text}>Do you still want to save schedule?</div>
      <div className="ss-modal__buttons-container">
        <CustomButton secondary label={'Confirm'} onClick={saveFunction} />
        <CustomButton label={'Cancel'} onClick={cancelFunction} />
      </div>
    </Modal>
  );
};

export default ScheduleValidator;
