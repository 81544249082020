import React, { useState } from 'react';
import classes from './CalendarHeader.module.scss';
import ArrowLeft from '../../brandConfig/assets/svg/arrow-left.svg';
import ArrowRight from '../../brandConfig/assets/svg/arrow-right.svg';
import Heading from 'grommet/components/Heading';
import moment from 'moment';
import { returnDate } from '../../shared/date-utility';

const CalendarHeader = ({
  startDate = moment(),
  daysJumpValue = 7,
  hasNext = true,
  hasPrevious = true,
  onLeftArrow = () => {},
  onRightArrow = () => {}
}) => {
  const [currentStartDate, setCurrentDate] = useState(startDate);

  const countEndDate = (date) => moment(date, 'YYYY-MM-DD').add(daysJumpValue, 'd').format('DD/MM');
  const onLeftArrowClick = () => {
    onLeftArrow();
    setCurrentDate((state) => moment(state, 'YYYY-MM-DD').subtract(daysJumpValue, 'd'));
  };
  const onRightArrowClick = () => {
    onRightArrow();
    setCurrentDate((state) => moment(state, 'YYYY-MM-DD').add(daysJumpValue, 'd'));
  };

  const leftArrow = hasPrevious ? <img src={ArrowLeft} alt="leftArrow" onClick={onLeftArrowClick} /> : null;
  const rightArrow = hasNext ? <img src={ArrowRight} alt="rightArrow" onClick={onRightArrowClick} /> : null;
  const date =
    daysJumpValue === 1 ? (
      <Heading tag="h3">
        {moment(currentStartDate, 'YYYY-MM-DD').format('dddd').toUpperCase()} - {returnDate(currentStartDate)}
      </Heading>
    ) : (
      <Heading tag="h3">
        {returnDate(currentStartDate)} - {countEndDate(currentStartDate)}
      </Heading>
    );

  return (
    <div className={classes.root}>
      <div>{leftArrow}</div>
      <div>{date}</div>
      <div>{rightArrow}</div>
    </div>
  );
};

export default CalendarHeader;
