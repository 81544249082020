import React from 'react';

import moment from 'moment';

import classes from './ClientUpcomingClasses.module.scss';

const ClientUpcomingClasses = (props) => {
  const divClasses = [classes.upcomingClassesBox];
  if (props.type === 'classes') {
    divClasses.push(classes.upcomingClassesBoxNoMargin);
  }

  if (props.upcomingClassesLoading) {
    return 'Loading...';
  }

  if (props.upcomingClassesError) {
    return (
      <div className={divClasses.join(' ')}>
        Error when fetching upcoming {props.type === 'PT' ? 'PT sessions' : props.type}
      </div>
    );
  }

  if (props.upcomingClasses.length === 0) {
    return (
      <div className={divClasses.join(' ')}>
        You have no upcoming {props.type === 'PT' ? 'PT sessions' : props.type}
      </div>
    );
  }

  if (props.upcomingClasses.length === 1) {
    return (
      <div className={divClasses.join(' ')}>
        <div className={classes.info__text}>Upcoming</div>

        <div>
          {moment(props.upcomingClasses[0].class.classDate).format('DD/MM')},{' '}
          {props.upcomingClasses[0].class.classTime.toUpperCase()},{' '}
          {props.type === 'PTs' ? props.upcomingClasses[0].class.notesForClient : props.upcomingClasses[0].class.name}
        </div>
      </div>
    );
  }

  if (props.upcomingClasses.length > 1) {
    return (
      <div className={divClasses.join(' ')}>
        <div className={classes.info__text}>Upcoming</div>

        <div className={classes.singleClass}>
          {moment(props.upcomingClasses[0].class.classDate).format('DD/MM')},{' '}
          {props.upcomingClasses[0].class.classTime.toUpperCase()},{' '}
          {props.type === 'PTs' ? props.upcomingClasses[0].class.notesForClient : props.upcomingClasses[0].class.name}
        </div>

        <div className={classes.singleClass}>
          {moment(props.upcomingClasses[1].class.classDate).format('DD/MM')},{' '}
          {props.upcomingClasses[1].class.classTime.toUpperCase()},{' '}
          {props.type === 'PTs' ? props.upcomingClasses[1].class.notesForClient : props.upcomingClasses[1].class.name}
        </div>
      </div>
    );
  }

  return null;
};

export default ClientUpcomingClasses;
