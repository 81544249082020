import React from 'react';
import CheckBox from 'grommet/components/CheckBox';
import classes from './CustomCheckboxes.module.scss';
import useCustomCheckboxes from './useCustomCheckboxes';

const CustomCheckboxes = ({
  checkboxes = [],
  defaultChecked = [],
  defaultCheckedAll = false,
  onChange = () => {},
  className = null
}) => {
  const initialState = defaultCheckedAll ? checkboxes.map((elm) => elm.value) : defaultChecked;
  const { onCheckboxCheck, selected } = useCustomCheckboxes(initialState, onChange);

  return (
    <div className={className}>
      {checkboxes.map(({ label, value }) => (
        <CheckBox
          label={label}
          checked={selected[value]}
          onChange={() => onCheckboxCheck(value)}
          key={value}
          className={classes.singleCheckbox}
        />
      ))}
    </div>
  );
};

export default CustomCheckboxes;
