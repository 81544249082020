import React from 'react';
import FormField from 'grommet/components/FormField';
import TextInput from 'grommet/components/TextInput';
import PasswordInput from 'grommet/components/PasswordInput';
import NumberInput from 'grommet/components/NumberInput';
import DateTime from 'grommet/components/DateTime';
import Label from 'grommet/components/Label';
import Select from 'grommet/components/Select';
import Edit from 'grommet/components/icons/base/Edit';

import classes from './FormCreatorSmall.module.scss';

const FormCreatorSmall = (props) => {
  let inputs = [];
  for (let form in props.formData) {
    props.formData[form].identifier = form;
    inputs.push(props.formData[form]);
  }

  return (
    <form>
      {inputs.map((element) => {
        let warning = null;
        if (element.touched && !element.valid && element.errorText) {
          warning = <div className={classes.warning}>{element.errorText}</div>;
        }
        const selectClassList = [classes.select];
        if (element.identifier !== 'title' && element.type) {
          selectClassList.push(classes.selectLong);
        }

        switch (element.type) {
          case 'select':
            return (
              <div key={element.identifier} className={classes.personalDetailsInput}>
                <Label className={classes.label}>{element.label}</Label>
                <Select
                  className={selectClassList.join(' ')}
                  options={element.options}
                  value={element.value}
                  onChange={(event) => {
                    props.valueChanged(event.value ? event.value : event.target.value, element.identifier);
                  }}
                />
              </div>
            );
          case 'password':
            if (
              element.identifier === 'confirmPassword' &&
              element.touched &&
              element.value !== element.basePasswordValue
            ) {
              warning = <div className={classes.warning}>Passwords are not equal!</div>;
            }
            return (
              <div key={element.identifier} className={classes.personalDetailsInput}>
                <Label className={classes.label}>{element.label}</Label>
                <div>
                  <FormField className={classes.input}>
                    <PasswordInput
                      value={element.value}
                      placeholder={element.placeholder}
                      onChange={(event) =>
                        props.valueChanged(event.value ? event.value : event.target.value, element.identifier)
                      }
                      onKeyPress={element.keyPress ? (event) => props.keyPress(event, element.identifier) : null}
                    />
                    {warning}

                    <Edit className={classes.editIcon} />
                  </FormField>
                </div>
              </div>
            );
          case 'number':
            return (
              <div key={element.identifier} className={classes.personalDetailsInput}>
                <Label className={classes.label}>{element.label}</Label>
                <FormField className={classes.input}>
                  <NumberInput
                    value={element.value}
                    placeholder={element.placeholder}
                    onChange={(event) =>
                      props.valueChanged(event.value ? event.value : event.target.value, element.identifier)
                    }
                  />
                  {warning}

                  <Edit className={classes.editIcon} />
                </FormField>
              </div>
            );
          case 'datetime':
            return (
              <div key={element.identifier} className={classes.personalDetailsInput}>
                <Label className={classes.label}>{element.label}</Label>
                <div>
                  <FormField className={classes.input}>
                    <DateTime
                      placeholder={element.placeholder}
                      format="DD/MM/YYYY h:mm a"
                      step={5}
                      value={element.value}
                      onChange={(event) => props.valueChanged(event ? event : null, element.identifier)}
                    />
                    {warning}

                    <Edit className={classes.editIcon} />
                  </FormField>
                </div>
              </div>
            );
          case 'date':
            return (
              <div key={element.identifier} className={classes.personalDetailsInput}>
                <Label className={classes.label}>{element.label}</Label>
                <div>
                  <FormField className={classes.input}>
                    <DateTime
                      placeholder={element.placeholder}
                      format="DD/MM/YYYY"
                      value={element.value}
                      onChange={(event) => props.valueChanged(event ? event : null, element.identifier)}
                    />
                    {warning}

                    <Edit className={classes.editIcon} />
                  </FormField>
                </div>
              </div>
            );
          default:
            return (
              <div key={element.identifier} className={classes.personalDetailsInput}>
                <Label className={classes.label}>{element.label}</Label>
                <div className={classes.inputContainer}>
                  <FormField className={classes.input}>
                    <TextInput
                      value={element.value.toString()}
                      placeHolder={element.placeholder}
                      onDOMChange={(event) =>
                        props.valueChanged(event.value ? event.value : event.target.value, element.identifier)
                      }
                    />
                    {warning}

                    <Edit className={classes.editIcon} />
                  </FormField>
                </div>
              </div>
            );
        }
      })}
    </form>
  );
};
export default FormCreatorSmall;
