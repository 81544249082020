import React, { useState } from 'react';
import classes from './SelectCategory.module.scss';
import Select from 'grommet/components/Select';
import AddEditCategory from '../AddEditCategory/AddEditCategory';
import Modal from 'react-modal';
import { useMutation, useQueryClient } from 'react-query';
import Heading from 'grommet/components/Heading';
import { editVideoCategory } from '../../../apiFunctions/apiFunctions';
import { returnErrorFromResponse } from '../../../shared/utility';
import EditIcon from 'grommet/components/icons/base/Edit';
import AddIcon from 'grommet/components/icons/base/Add';
import SubtractIcon from 'grommet/components/icons/base/Subtract';

const SelectCategory = ({
  category,
  onChange = () => {},
  setToast = () => {},
  isEditMode = false,
  isActive = false
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);

  const queryClient = useQueryClient();
  const { mutate } = useMutation((active) => editVideoCategory(category.id, { active }), {
    onSuccess: () => {
      setToast({
        status: 'ok',
        msg: 'Categories Updated',
        show: true
      });
    },
    onError: (err) => {
      setToast({
        status: 'critical',
        msg: returnErrorFromResponse(err),
        show: true
      });
    },
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries('fetchVideoCategories');
      }, 1000);
    }
  });

  const handleChange = (event) => {
    setSelectedOption(event.option);
  };

  const onChangeActive = (active) => {
    onChange(category.id, active);
    mutate(active);
  };

  return (
    <div>
      <Modal
        appElement={document.getElementById('root')}
        onRequestClose={() => setShowEditModal(false)}
        isOpen={showEditModal}
        className={'ss-modal'}>
        <AddEditCategory onClose={() => setShowEditModal(false)} category={category} />
      </Modal>
      <div className={classes.heading}>
        <Heading tag="h4">{category.name}</Heading>
        {isEditMode && (
          <div className={classes.editIcons}>
            <EditIcon onClick={() => setShowEditModal(true)} />
            {isActive ? (
              <SubtractIcon onClick={() => onChangeActive(false)} />
            ) : (
              <AddIcon onClick={() => onChangeActive(true)} />
            )}
          </div>
        )}
      </div>
      <Select
        className={classes.input}
        placeHolder={'Select ' + category.name}
        options={category.options}
        value={selectedOption}
        onChange={handleChange}
      />
    </div>
  );
};

export default SelectCategory;
