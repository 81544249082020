import axios from '../../../../axios-global';
import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import classes from './ClassAttendancesReports.module.scss';
import { RadioButton, CheckBox } from 'grommet';
import moment from 'moment';
import ExportToCSV from '../../ExportToCSV/ExportToCSV';
import withAdminDashboard from '../../../../store/hoc/withAdminDashboard';
import Loading from '../../../../components/Loading/Loading';
import Filter from '../../../../components/Filter';
import GymSelector from '../../../../components/Selectors/GymSelector/GymSelector';
import { returnClassesDetails } from '../../../../shared/classFunctions';
import ClassTypesCheckboxes, {
  returnSelectedClassTypesAsArray
} from '../../../../components/Selectors/ClassTypesCheckboxes/ClassTypesCheckboxes';
import Notification from 'grommet/components/Notification';

const gridTemplateForNameDetails = { gridTemplateColumns: '1fr 100px 1fr 50px 50px 100px 1fr' };

export const ClassAttendanceReports = ({ selectedGym, history }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [notUpdated, setNotUpdated] = useState(false);
  const [classAttendanceReport, setClassAttendanceReport] = useState([]);
  const [filteredReport, setFilteredReport] = useState([]);
  const [filterTrainer, setFilterTrainer] = useState('');
  const [filterClass, setFilterClass] = useState('');
  const [reportType, setReportType] = useState('totals');
  const [selectedClassType, setSelectedClassType] = useState([]);
  const [gymSelection, setGymSelection] = useState();

  useEffect(
    () => {
      const getReport = () => {
        setIsLoading(true);
        let gymId = gymSelection ? gymSelection._id : 'all';
        let url = `/reports/class-attendance/${gymId}?fromDate=${startDate}&toDate=${endDate}${
          reportType === 'name-details' ? '&includeAttendeesDetails=true' : ''
        }`;
        axios
          .get(url)
          .then((response) => {
            setIsLoading(false);
            setClassAttendanceReport(response.data);
            onSearchUpdate(response.data);
          })
          .catch((err) => {
            setIsError(err.response.data.errors[0].msg);
            setIsLoading(false);
          });
      };
      if (startDate !== '' && endDate !== '') {
        getReport();
      }
    }, //eslint-disable-next-line react-hooks/exhaustive-deps
    [startDate, endDate, gymSelection, reportType]
  );

  useEffect(
    () => {
      onSearchUpdate(classAttendanceReport);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [notUpdated, selectedClassType, filterTrainer, filterClass]
  );

  const onSearchUpdate = (unFilteredArray) => {
    let filteredArray = unFilteredArray.filter((classObj) => classObj.eventName.toLowerCase().includes(filterClass));

    if (filterTrainer !== '') {
      filteredArray = filteredArray.filter(
        (classObj) => classObj.hostName !== undefined && classObj.hostName.toLowerCase().includes(filterTrainer)
      );
    }

    if (filteredArray.length > 0 && notUpdated) {
      filteredArray = filteredArray.filter((classObj) => {
        return classObj.attendances && classObj.attendances.pending;
      });
    }

    const selectedClassTypesAsArray = returnSelectedClassTypesAsArray(selectedClassType);
    filteredArray = filteredArray.filter((elem) => selectedClassTypesAsArray.includes(elem.eventType));
    setFilteredReport(filteredArray);
  };

  const countAttendees = (attendance) => {
    const activeCount = attendance && attendance.active && attendance.active.count ? attendance.active.count : 0;
    const attendedCount =
      attendance && attendance.attended && attendance.attended.count ? attendance.attended.count : 0;
    return activeCount + attendedCount;
  };

  const returnData = () => {
    const renderGridStyle = reportType === 'name-details' ? gridTemplateForNameDetails : {};

    const headers = ['Name', 'Gym', 'Type', 'Trainer', 'Date', 'Time', 'Attendance'];
    if (reportType === 'name-details') {
      headers.push('First Attendee');
    }

    const item = ({ eventName, gymName, eventType, hostName, attendances, date, time, capacity, id, gymId }) => (
      <div className={classes.singleClass} onClick={() => history.push(`/gym/${gymId}/class/${id}`)}>
        <div className={classes.grid} style={renderGridStyle}>
          <div className={classes.name}>{eventName}</div>
          <div>
            <span>Gym: </span>
            {gymName}
          </div>
          <div>
            <span>Type: </span>
            {returnClassesDetails(eventType).title}
          </div>
          <div>
            <span>Trainer: </span>
            {hostName}
          </div>
          <div>
            <span>Date: </span>
            {moment(date).format('DD/MM/YYYY')}
          </div>
          <div>
            <span>Time: </span>
            {time}
          </div>
          <div className={classes.attendance}>
            <span>Attendance: </span>
            {countAttendees(attendances)}/{capacity}
          </div>
          {reportType === 'name-details' && attendances && attendances.active && attendances.active.attendeesDetail && (
            <div>
              {attendances.active.attendeesDetail[0].name} {attendances.active.attendeesDetail[0].lastName}
            </div>
          )}
        </div>
      </div>
    );

    if (isLoading) {
      return <Loading />;
    }
    if (isError !== null) {
      return <Notification message={isError} status="critical" />;
    }
    if (filteredReport.length === 0) {
      return <h3>No any class found</h3>;
    }

    return (
      <div>
        <div className={[classes.grid, classes.header, 'table-header'].join(' ')} style={renderGridStyle}>
          {headers.map((title) => (
            <div key={title}>{title}</div>
          ))}
        </div>
        {filteredReport.map((classReport) => item(classReport))}
      </div>
    );
  };

  const filters = () => (
    <div className={classes.filters}>
      <div>
        <GymSelector onSelect={setGymSelection} defaultChecked={null} placeHolder={'All Gyms'} refresh />
        <div className={classes.container}>
          <Filter type={'date'} title={'From date'} onFilter={setStartDate} />
          <Filter type={'date'} title={'To date'} onFilter={setEndDate} />
          <Filter title="Filter by name" placeHolder={'Class name'} onFilter={setFilterClass} />
          <Filter title="Filter by trainer" placeHolder={'Trainer'} onFilter={setFilterTrainer} />
        </div>
      </div>

      <div className={classes.rightColumn}>
        <CheckBox checked={notUpdated} onChange={() => setNotUpdated(!notUpdated)} label="Not updated attendance" />
        <ClassTypesCheckboxes gym={gymSelection ? gymSelection : selectedGym} onChange={setSelectedClassType} />
        <div className={classes.radioButtonContainer}>
          <RadioButton
            name="totals"
            label="Totals"
            checked={reportType === 'totals'}
            onChange={() => setReportType('totals')}
          />
          <RadioButton
            name="name-details"
            label="Name details"
            checked={reportType === 'name-details'}
            onChange={() => setReportType('name-details')}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <ExportToCSV
        data={filteredReport}
        type={reportType === 'totals' ? 'classAttendanceReport' : 'classAttendanceNameReport'}
        gymName={gymSelection ? gymSelection.name : 'All gyms'}
        disabled={filteredReport.length === 0}
      />
      {filters()}
      {returnData()}
    </div>
  );
};

export default withRouter(withAdminDashboard(ClassAttendanceReports));
