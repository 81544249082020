import React, { useState, useEffect } from 'react';
import classes from './UpcomingClassesForAllGyms.module.scss';
import withAdminDashboard from '../../../store/hoc/withAdminDashboard';
import { TextInput, Select } from 'grommet';
import CustomButton from '../../../components/CustomButton/CustomButton';
import RefreshIcon from 'grommet/components/icons/base/Refresh';

const UpcomingClassesForAllGymsFilters = ({ onFilter, data, gyms, activeIndex }) => {
  const [filteredData, setFilteredData] = useState(data);
  const [filteredName, setFilteredName] = useState('');
  const [selectedGyms, setSelectedGyms] = useState([]);
  const [filteredGym, setFilteredGym] = useState(null);
  const [filteredDataFromGym, setFilteredDataFromGym] = useState(null);
  let options = gyms.map((elem) => {
    return {
      label: elem.name,
      value: elem._id
    };
  });
  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  useEffect(() => {
    setFilteredName('');
    setSelectedGyms([]);
    setFilteredDataFromGym(null);
  }, [activeIndex]);

  useEffect(() => {
    handleNameFilter(filteredName);
    // eslint-disable-next-line
  }, [filteredDataFromGym]);

  const gymSelected = (event) => {
    setSelectedGyms(event.value);
    handleGymFilter(event.value);
  };

  const onFilterGymList = (e) => {
    const filter = e.target.value.toLowerCase();
    const filteredGymList = gyms.filter((gym) => gym.name.toLowerCase().includes(filter));

    setFilteredGym(
      filteredGymList.map((elem) => {
        return {
          label: elem.name,
          value: elem._id
        };
      })
    );
  };

  const mapLocations = gyms.length > 0 && (
    <div className={classes.gymSelector}>
      <h4>Gym name</h4>
      <Select
        placeHolder="Gym: all"
        multiple={true}
        options={filteredGym || options}
        value={selectedGyms}
        onChange={gymSelected}
        onSearch={onFilterGymList}
      />
    </div>
  );

  const filterByName = (
    <div>
      <h4>Class name</h4>
      <TextInput
        className={classes.classNameFilter}
        name="classFilter"
        placeHolder="Class name"
        onDOMChange={(e) => handleNameFilter(e.target.value)}
        value={filteredName}
      />
    </div>
  );

  const filterByClassName = (array, name) =>
    name !== null ? array.filter((elem) => elem.name.toLowerCase().includes(name.toLowerCase())) : array;
  const filterByGymName = (array, selectedGyms) => array.filter((elem) => selectedGyms.includes(elem.gymName[0]));

  const handleNameFilter = (value) => {
    const arrayToPassForFilter = filteredDataFromGym ? filteredDataFromGym : filteredData;
    const result = value !== null ? filterByClassName(arrayToPassForFilter, value) : data;
    setFilteredData(result);
    setFilteredName(value);
    onFilter(result);
  };

  const handleGymFilter = (selectionForGym) => {
    setFilteredDataFromGym(null);
    let newSelection = [];
    selectionForGym.forEach((entry) => {
      newSelection.push(entry.label);
    });

    const result = newSelection.length > 0 ? filterByGymName(filteredData, newSelection) : data;
    setFilteredData(result);
    setFilteredDataFromGym(result);
    onFilter(result);
  };

  const clearFilters = () => {
    const handleClearFilters = () => {
      setFilteredName('');
      setSelectedGyms([]);
      setFilteredDataFromGym(null);
      onFilter(data);
    };
    return (
      <CustomButton
        className={classes.clearFilters}
        icon={<RefreshIcon size={'medium'} />}
        primary
        onClick={handleClearFilters}
      />
    );
  };

  return (
    <div className={classes.filtersContainer}>
      {mapLocations}
      {filterByName}
      {clearFilters()}
    </div>
  );
};

export default withAdminDashboard(UpcomingClassesForAllGymsFilters);
