import React, { useState } from 'react';
import classes from './UploadFAQ.module.scss';
import { useMutation } from 'react-query';
import { delayFetchFunctions, uploadFAQJsonFile } from '../../../apiFunctions/apiFunctions';
import { returnErrorFromResponse } from '../../../shared/utility';
import CustomToast from '../../../components/CustomToast/CustomToast';
import withAdminDashboard from '../../../store/hoc/withAdminDashboard';
import CustomButton from '../../../components/CustomButton/CustomButton';

const UploadFaq = ({ selectedGym, setShowAddEditFAQ, setSelectedFAQ, isAdmin }) => {
  const [toast, setToast] = useState(null);

  const mutateSaveJSONFAQ = useMutation((data) => uploadFAQJsonFile(selectedGym._id, data), {
    onSuccess: () => {
      setToast({
        status: 'ok',
        msg: 'JSON file successfully uploaded.',
        show: true
      });
      delayFetchFunctions([['faq']], 0);
    },
    onError: (err) => {
      setToast({
        status: 'critical',
        msg: returnErrorFromResponse(err),
        show: true
      });
    }
  });

  const uploadFromJSON = () => {
    const uploadFileFunction = (event) => {
      let file = event.target.files[0];
      let reader = new FileReader();

      reader.onload = () => {
        let content = reader.result;
        mutateSaveJSONFAQ.mutate(JSON.parse(content));
      };
      reader.readAsText(file);
    };
    return (
      <div className={classes.uploadFAQ}>
        <a href={`${process.env.PUBLIC_URL}/assets/faqExample.json`} download="faqExample">
          Get JSON template
        </a>
        <label htmlFor="file">Upload JSON</label>
        <input type="file" name="file" id="file" accept=".json" onChange={uploadFileFunction} />
      </div>
    );
  };

  if (isAdmin) {
    return (
      <div>
        <CustomToast toast={toast} />
        <div className={classes.container}>
          {uploadFromJSON()}
          <CustomButton
            label="Add new"
            secondary={true}
            onClick={() => {
              setSelectedFAQ(null);
              setShowAddEditFAQ(true);
            }}
          />
        </div>
      </div>
    );
  }
  return null;
};

export default withAdminDashboard(UploadFaq);
