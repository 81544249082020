import React, { useState } from 'react';
import classes from './CouponField.module.scss';
import CircleInformation from 'grommet/components/icons/base/CircleInformation';
import TextInput from 'grommet/components/TextInput';
import { getDiscountedPriceForCoupon } from '../../../apiFunctions/apiFunctions';
import { getCurrencySymbol } from '../../../shared/utility';
import withUser from '../../../store/hoc/withUser';
import { useMutation } from 'react-query';
import CustomButton from '../../../components/CustomButton/CustomButton';

const returnSuccessMsg = (response, type = 'pass') => {
  if (type === 'pass') {
    let message = response.message;
    let lastIndex = message.lastIndexOf(' ');
    let priceWithCurrency = getCurrencySymbol(response.currency) + message.substring(lastIndex, message.length);
    message = message.substring(0, lastIndex);

    if (response.discountedCost !== 0) {
      return `${message} ${priceWithCurrency}, you have to pay only ${getCurrencySymbol(response.currency)}${
        response.discountedCost
      } for the pass of worth ${getCurrencySymbol(response.currency)} ${response.actualCost}`;
    } else if (response.discountedCost <= 0) {
      return `${message} ${priceWithCurrency},  New cost is ${getCurrencySymbol(response.currency)} 0`;
    }
    return null;
  }
};

const CouponField = ({ productId, setCoupon, gymSettings, type = 'pass' }) => {
  const [msg, setMsg] = useState(null);
  const [promoCode, setPromoCode] = useState('');

  const mutateApplyPromoCode = useMutation(() => getDiscountedPriceForCoupon(productId, promoCode), {
    onSuccess: (response) => {
      setMsg(returnSuccessMsg(response, type));
      setCoupon(promoCode);
    },
    onError: (error) => {
      setMsg(error.response.data.errors[0].msg);
    }
  });

  const handleApplyBtn = () => {
    if (productId !== undefined && productId !== null && promoCode.length > 0) {
      setMsg(null);
      mutateApplyPromoCode.mutate();
    }
  };

  const handlePromoCodeChange = (e) => {
    if (e.target.value !== promoCode) {
      setMsg('Please apply coupon by clicking on the button');
    }
    setPromoCode(e.target.value.trim());
    setCoupon(e.target.value.trim());
  };
  const renderMsg = () =>
    msg && (
      <div className={classes.info}>
        <CircleInformation />
        {msg}
      </div>
    );

  if (gymSettings.coupon) {
    return (
      <div>
        {renderMsg()}
        <div className={classes.promoCodeDiv}>
          Please enter a coupon code if you have any
          <TextInput
            placeHolder="Enter coupon code"
            value={promoCode}
            className={classes.promoCode}
            onDOMChange={handlePromoCodeChange}
            data-cy="coupon_code"
          />
          <CustomButton
            isLoading={mutateApplyPromoCode.isLoading}
            label="Apply"
            secondary={true}
            onClick={handleApplyBtn}
            dataCy="coupon_apply_btn"
          />
        </div>
      </div>
    );
  }
  return null;
};

export default withUser(CouponField);
