import React from 'react';
import { Tab, Tabs } from 'grommet';
import SingleClassTypeHistory from '../../../HistoryComponent/SingleClassTypeHistoryComponent';
import DefaultTimetable from '../../DefaultTimetable/DefaultTimetable/DefaultTimetable';
import { returnAllUpcomingClasses } from '../../../../shared/classFunctions';
import withUser from '../../../../store/hoc/withUser';
import AddEditSingleClass from '../../../AddEditSingleClass/AddEditSingleClass';
import ManageVideos from '../../../ManageVideos/ManageVideos';
import { CLASS } from '../../../../constants';
import useTabIndex from '../../../../hooks/useTabIndex';
const TrainerView = ({
  classesList,
  classesListLoading,
  classesListError,
  classType,
  classesDetails,
  classConfig,
  showAllGymsData
}) => {
  const { activeTabIndex, onTabChange } = useTabIndex();

  const upcomingClassesComponent = classConfig.showUpcoming && (
    <Tab key="Upcoming" title="Upcoming">
      <DefaultTimetable
        classesList={returnAllUpcomingClasses(classesList)}
        classesListLoading={classesListLoading}
        classesListError={classesListError}
        classesDetails={classesDetails}
        classType={classesDetails.type}
        classConfig={classConfig}
        showAllGymsData={showAllGymsData}
      />
    </Tab>
  );

  const historyComponent = classConfig.showHistory && (
    <Tab key="History" title="History">
      <SingleClassTypeHistory classType={classType} classConfig={classConfig} />
    </Tab>
  );

  const addNewComponent = classConfig.showAddNew && (
    <Tab key="Add New" title="Add New">
      <AddEditSingleClass classType={classType} classConfig={classConfig} />
    </Tab>
  );

  const videosComponent = classType === CLASS.VIDEO && (
    <Tab key="Videos" title="Videos">
      <ManageVideos />
    </Tab>
  );

  return (
    <div data-cy={classType + '_bookings'}>
      <Tabs
        responsive={false}
        style={tabStyles}
        justify="start"
        data-cy="tabList"
        activeIndex={activeTabIndex}
        onActive={onTabChange}>
        {[upcomingClassesComponent, historyComponent, addNewComponent, videosComponent].filter(
          (component) => !!component
        )}
      </Tabs>
    </div>
  );
};

export default withUser(TrainerView);

const tabStyles = {
  borderBottom: 'none',
  paddingBottom: '20px',
  fontWeight: 'bold'
};
