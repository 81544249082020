import React, { useState, useEffect } from 'react';

import {
  CLIENTS_FILTERS,
  CLIENTS_ACTIVE_FILTERS,
  CLIENTS_STATUS_FILTERS,
  MEMBERSHIP_STATUS_FILTERS
} from '../../../../constants';

import Loading from '../../../../components/Loading/Loading';
import Notification from 'grommet/components/Notification';
import ExportToCSV from '../../ExportToCSV/ExportToCSV';

import SingleClient from './SingleClient/SingleClient';
import Filters from '../../../../components/ClientFilters';
import classes from './ManageClients.module.scss';
import withAdminDashboard from '../../../../store/hoc/withAdminDashboard';
import withGymsUsersFullData from '../../../../store/hoc/withGymsUsersFullData';
import ClientListWithErrorData from './ClientListWithErrorData/ClientListWithErrorData';
import moment from 'moment';

const ManageClient = ({
  classPasses,
  personalTrainings,
  gymMemberships,
  allGymsClients,
  gymsUsersFullData,
  gymsUsersFullDataLoading,
  history,
  onFetchGymUsersFullData,
  gymsUsersFullDataError,
  isAdmin,
  match,
  gymSettings,
  selectedGym
}) => {
  const [clientsList, setClientsList] = useState(gymsUsersFullData);

  useEffect(
    () => {
      if (gymsUsersFullData.length > 0) {
        handleFilterClientTypes({ statusFilter: 'active' });
      } else {
        setClientsList(gymsUsersFullData);
      }
    }, // eslint-disable-next-line
    [gymsUsersFullData]
  );

  let clients = gymsUsersFullDataLoading ? <Loading /> : <p>Please select gym</p>;

  if (!gymsUsersFullDataLoading && gymsUsersFullDataError) {
    clients = <Notification message={gymsUsersFullDataError} status="critical" />;
  }

  if (!gymsUsersFullDataLoading && !gymsUsersFullDataError && clientsList) {
    clients = clientsList.map((client) => (
      <SingleClient
        isAdmin={isAdmin}
        key={client._id}
        client={client}
        history={history}
        match={match}
        fetchClients={onFetchGymUsersFullData}
        gymId={selectedGym._id}
      />
    ));
  }
  if (clientsList && clientsList.length === 0) {
    clients = 'No clients';
  }

  const handleFilterClientTypes = (filter) => {
    if (gymsUsersFullData) {
      let filteredClients = gymsUsersFullData;

      if (filter.statusFilter && filter.statusFilter !== CLIENTS_STATUS_FILTERS.ALL) {
        filteredClients = filteredClients.filter((x) => x.status === filter.statusFilter);
      }

      if (filter.gymMembershipsStatusFilter && filter.gymMembershipsStatusFilter !== MEMBERSHIP_STATUS_FILTERS.ALL) {
        filteredClients = filteredClients.filter((x) => {
          if (filter.gymMembershipsStatusFilter === MEMBERSHIP_STATUS_FILTERS.ACTIVE && x.currentMembershipPlan) {
            return moment().isBefore(x.currentMembershipPlan.current_period_end);
          }
          if (filter.gymMembershipsStatusFilter === MEMBERSHIP_STATUS_FILTERS.EXPIRED && x.currentMembershipPlan) {
            return moment().isAfter(x.currentMembershipPlan.current_period_end);
          }
          return null;
        });
      }

      if (filter.productFilter && filter.productFilter !== CLIENTS_FILTERS.ALL_MEMBERS) {
        filteredClients = filteredClients.filter((x) => x.customerType === filter.productFilter);
      }

      if (filter.activeFilter && filter.activeFilter !== CLIENTS_ACTIVE_FILTERS.ALL) {
        filteredClients = filteredClients.filter((x) => x.status === filter.activeFilter);
      }

      if (filter.classPassFilter && filter.classPassFilter !== 'all') {
        filteredClients = filteredClients.filter((x) =>
          x.products.some((p) => {
            return p.productType === 'classPass' && p.productId._id === filter.classPassFilter;
          })
        );
      }

      if (filter.gymMembershipFilter && filter.gymMembershipFilter !== 'all') {
        filteredClients = filteredClients.filter(
          (x) =>
            x.currentMembershipPlan &&
            x.currentMembershipPlan.ssPlanId &&
            x.currentMembershipPlan.ssPlanId._id === filter.gymMembershipFilter
        );
      }

      if (filter.personalTrainingsFilter && filter.personalTrainingsFilter !== 'all') {
        filteredClients = filteredClients.filter((x) =>
          x.products.some((p) => {
            return p.productType === 'personalTrainingPass' && p.productId._id === filter.personalTrainingsFilter;
          })
        );
      }

      if (filter.nameFilter && filter.nameFilter !== '') {
        filteredClients = filteredClients.filter(
          (x) =>
            x.name.toLowerCase().startsWith(filter.nameFilter.toLowerCase()) ||
            x.last_name.toLowerCase().startsWith(filter.nameFilter.toLowerCase())
        );
      }

      setClientsList(filteredClients);
    }
  };

  return (
    <div data-cy={'clients'}>
      <ClientListWithErrorData />
      {gymsUsersFullData !== null && (
        <Filters
          onFilter={handleFilterClientTypes}
          membershipProducts={allGymsClients ? null : gymSettings.membershipProducts}
          classPasses={allGymsClients ? null : gymSettings.class ? classPasses : null}
          personalTrainings={allGymsClients ? null : gymSettings.personalTraining ? personalTrainings : null}
          gymMemberships={allGymsClients ? null : gymSettings.membershipProducts ? gymMemberships : null}
          allClientStatuses={allGymsClients ? true : gymSettings.newClientsHaveToBeApprovedByAdmin}
          membershipType={gymSettings.membershipProducts}
        />
      )}
      <div className={classes.header}>
        <h4 className={classes.listHeader}>Clients list</h4>
        {clientsList && clientsList.length > 0 && (
          <ExportToCSV data={clients} type={'clientsDetailReport'} gymName={selectedGym.name} />
        )}
      </div>
      {clientsList && clientsList.length > 0 ? clients : <h3>No clients</h3>}
    </div>
  );
};

export default withAdminDashboard(withGymsUsersFullData(ManageClient));
