import React from 'react';
import List from 'grommet/components/List';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import SingleName from './SingleName';
import { Box, Form, FormField, Label, Notification, TextInput, Layer } from 'grommet';
import axios from '../../../../axios-global';
import * as actions from '../../../../store/actions/index';
import { connect } from 'react-redux';

const cloneDeep = require('lodash.clonedeep');

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchGymDetails: (gymId) => dispatch(actions.fetchGymDetails(gymId))
  };
};

class EddiAssociatedNames extends React.Component {
  state = {
    showLayer: false,
    value: null,
    loading: false,
    savingFailed: false,
    isValid: true
  };

  saveName = () => {
    let copiedNames = cloneDeep(this.props.selectedGym.associatedNames);
    copiedNames.push(this.state.value);

    axios
      .patch(`/gym/${this.props.selectedGym._id}`, {
        associatedNames: copiedNames
      })
      .then(() => {
        this.setState({ loading: false, showLayer: false });
        this.props.onFetchGymDetails(this.props.selectedGym._id);
      })
      .catch(() => {
        this.setState({
          loading: false,
          savingFailed: true
        });
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      });
  };

  showAddLayer() {
    return (
      <>
        <Layer
          align={'center'}
          closer={true}
          hidden={false}
          overlayClose={true}
          onClose={() => this.setState({ showLayer: false })}>
          <Box pad={'large'}>
            <Form>
              <Label>Enter associated name</Label>

              {this.state.savingFailed ? (
                <Notification
                  message="Something went wrong. Try again"
                  status="waring"
                  className="ss-top-notification"
                />
              ) : null}

              <FormField>
                <TextInput
                  defaultValue={this.state.value}
                  placeHolder={'Enter associated name'}
                  onDOMChange={(event) => {
                    const newValue = event.value ? event.value : event.target.value;
                    var newStr = newValue.replace(/[a-zA-Z0-9-_]/g, '');
                    return this.setState({
                      isValid: newValue.length > 0 && newStr.length === 0,
                      value: newValue
                    });
                  }}
                />
              </FormField>

              {this.state.isValid ? (
                <div className="container--center">
                  <CustomButton label="Save" secondary onClick={this.saveName} />{' '}
                </div>
              ) : (
                <p style={{ color: 'red' }}> Only a-z, 0-9 and - are allowed </p>
              )}
            </Form>
          </Box>
        </Layer>
      </>
    );
  }

  render() {
    const renderList = () => {
      if (this.props.names.length > 0) {
        return this.props.names.map((element) => (
          <SingleName key={element} element={element} history={this.props.history} />
        ));
      }
      return 'No associated names';
    };

    return (
      <>
        <div style={{ paddingBottom: '40px' }}>
          <h3 style={{ color: '#80B91E' }}>Gym URL name: {this.props.selectedGym.urlName}</h3>
          <CustomButton
            style={{ position: 'absolute', right: 0 }}
            label="Add new"
            secondary={true}
            onClick={() => this.setState({ showLayer: true })}
          />
        </div>
        {this.state.showLayer ? this.showAddLayer() : null}
        <List>{renderList()}</List>
      </>
    );
  }
}

export default connect(null, mapDispatchToProps)(EddiAssociatedNames);
