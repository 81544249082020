import React, { Component } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { connect } from 'react-redux';
import axios from '../../../axios-global';

import CustomButton from '../../../components/CustomButton/CustomButton';
import Notification from 'grommet/components/Notification';
import Toast from 'grommet/components/Toast';
import PaymentMethods from './PaymentMethods/PaymentMethods';
import FormCreatorSmall from '../../../components/UI/FormCreatorSmall/FormCreatorSmall';
import HeaderWithBorder from '../../../components/UI/HeaderWithBorder/HeaderWithBorder';
import ProfilePicture from '../../../components/UI/ProfilePicture/ProfilePicture';

import { updateObject, checkValidity, returnErrorTextForField } from '../../../shared/utility';

import * as actions from '../../../store/actions';

import classes from './UserSettings.module.scss';
import Title from '../../../components/UI/Title/Title';
import Loading from '../../../components/Loading/Loading';

const cloneDeep = require('lodash.clonedeep');

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchUserDetails: () => dispatch(actions.fetchUserDetails())
  };
};

class UserSettings extends Component {
  state = {
    personalDetails: {
      email: {
        label: 'E-mail',
        type: 'text',
        placeholder: 'Enter e-mail',
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      phone_number: {
        label: 'Phone number',
        type: 'text',
        placeholder: 'Enter telephone number',
        value: '',
        validation: {
          required: true,
          isUkTelephone: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      address_line1: {
        label: 'Address',
        type: 'text',
        placeholder: 'Enter address',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      city: {
        label: 'City',
        type: 'text',
        placeholder: 'Enter city',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      postcode: {
        label: 'Postcode',
        type: 'text',
        placeholder: 'Enter postcode',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      }
    },

    emergencyContactForm: {
      emergency_contact_name: {
        label: 'Name',
        type: 'text',
        placeholder: 'Enter emergency contact name',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      emergency_contact_number: {
        label: 'Phone',
        type: 'text',
        placeholder: 'Enter emergency contact phone',
        value: '',
        validation: {
          required: true,
          isNumeric: true
        },
        valid: false,
        touched: false,
        errorText: null
      }
    },

    formIsValid: true,
    notificationText: null,

    newDataSaving: false,

    profilePictureLoading: false,

    toast: null,
    toastText: null
  };

  componentDidMount() {
    this.assignExistingValues();
  }

  assignExistingValues = () => {
    const formNames = Object.keys(this.props.userData);
    const previousValues = Object.values(this.props.userData);
    const copiedPersonalDetailsForm = cloneDeep(this.state.personalDetails);
    const copiedEmergencyContactFormForm = cloneDeep(this.state.emergencyContactForm);

    formNames.forEach((element, index) => {
      if (copiedPersonalDetailsForm.hasOwnProperty(element) && copiedPersonalDetailsForm[element] !== undefined) {
        copiedPersonalDetailsForm[element] = {
          ...copiedPersonalDetailsForm[element],
          value: previousValues[index],
          valid: true,
          touched: true
        };
      }

      if (
        copiedEmergencyContactFormForm.hasOwnProperty(element) &&
        copiedEmergencyContactFormForm[element] !== undefined
      ) {
        copiedEmergencyContactFormForm[element] = {
          ...copiedEmergencyContactFormForm[element],
          value: previousValues[index],
          valid: true,
          touched: true
        };
      }
    });

    this.setState({
      personalDetails: copiedPersonalDetailsForm,
      emergencyContactForm: copiedEmergencyContactFormForm
    });
  };

  personalDetailsValueChangedHandler = (newValue, identifier) => {
    const updatedPersonalDetails = updateObject(this.state.personalDetails, {
      [identifier]: updateObject(this.state.personalDetails[identifier], {
        value: newValue,
        valid: checkValidity(newValue, this.state.personalDetails[identifier].validation),
        errorText: returnErrorTextForField(this.state.personalDetails[identifier].validation),
        touched: true
      })
    });

    return this.setState({
      personalDetails: updatedPersonalDetails
    });
  };

  emergencyContactValueChangedHandler = (newValue, identifier) => {
    const updatedEmergencyContactForm = updateObject(this.state.emergencyContactForm, {
      [identifier]: updateObject(this.state.emergencyContactForm[identifier], {
        value: newValue,
        valid: checkValidity(newValue, this.state.emergencyContactForm[identifier].validation),
        errorText: returnErrorTextForField(this.state.emergencyContactForm[identifier].validation),
        touched: true
      })
    });

    return this.setState({
      emergencyContactForm: updatedEmergencyContactForm
    });
  };

  checkFormValidity = () => {
    this.setState({
      formIsValid: true,
      notificationText: null,
      newDataSaving: true
    });

    for (let input in this.state.personalDetails) {
      if (!this.state.personalDetails[input].valid) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        const updatedPersonalDetails = updateObject(this.state.personalDetails, {
          [input]: updateObject(this.state.personalDetails[input], {
            valid: checkValidity(this.state.personalDetails[input].value, this.state.personalDetails[input].validation),
            errorText: returnErrorTextForField(this.state.personalDetails[input].validation),
            touched: true
          })
        });
        return this.setState({
          personalDetails: updatedPersonalDetails,
          formIsValid: false,
          notificationText: 'Form is not filled in correctly.',
          newDataSaving: false
        });
      }
    }

    for (let input in this.state.emergencyContactForm) {
      if (!this.state.emergencyContactForm[input].valid) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        const updatedEmergencyContactForm = updateObject(this.state.emergencyContactForm, {
          [input]: updateObject(this.state.emergencyContactForm[input], {
            valid: checkValidity(
              this.state.emergencyContactForm[input].value,
              this.state.emergencyContactForm[input].validation
            ),
            errorText: returnErrorTextForField(this.state.emergencyContactForm[input].validation),
            touched: true
          })
        });
        return this.setState({
          emergencyContactForm: updatedEmergencyContactForm,
          formIsValid: false,
          notificationText: 'Form is not filled in correctly.',
          newDataSaving: false
        });
      }
    }

    return this.setState(
      {
        formIsValid: true,
        notificationText: null
      },
      () => {
        if (this.state.formIsValid) {
          this.saveAccountDetails();
        }
      }
    );
  };

  saveAccountDetails = () => {
    const newData = {};
    for (let input in this.state.personalDetails) {
      newData[input] = this.state.personalDetails[input].value;
    }
    for (let input in this.state.emergencyContactForm) {
      newData[input] = this.state.emergencyContactForm[input].value;
    }
    newData.gymId = this.state.chosenGymId;
    newData.marketing = this.state.marketing;

    axios
      .patch(`/users/${this.props.userData._id}`, newData)
      .then(() => {
        this.setState({
          newDataSaving: false,
          toast: true,
          toastText: 'Profile updated',
          toastStatus: 'ok'
        });
        this.props.onFetchUserDetails();
      })
      .catch((error) => this.passErrorMessagesToForm(error));
  };

  passErrorMessagesToForm = (error) => {
    if (error.response && error.response.status === 500) {
      return this.setState({
        formIsValid: false,
        newDataSaving: false,
        notificationText: 'Server error.'
      });
    }

    const copiedPersonalDetailsForm = cloneDeep(this.state.personalDetails);
    const copiedEmergencyContactFormForm = cloneDeep(this.state.emergencyContactForm);

    error.response.data.errors.forEach((element) => {
      if (
        this.state.personalDetails.hasOwnProperty(element.field) &&
        this.state.personalDetails[element.field] !== undefined
      ) {
        copiedPersonalDetailsForm[element.field] = {
          ...copiedPersonalDetailsForm[element.field],
          valid: false,
          errorText: element.message,
          touched: true
        };
      }

      if (
        this.state.emergencyContactForm.hasOwnProperty(element.field) &&
        this.state.emergencyContactForm[element.field] !== undefined
      ) {
        copiedEmergencyContactFormForm[element.field] = {
          ...copiedEmergencyContactFormForm[element.field],
          valid: false,
          errorText: element.message,
          touched: true
        };
      }
    });

    this.setState({
      personalDetails: copiedPersonalDetailsForm,
      emergencyContactForm: copiedEmergencyContactFormForm
    });
  };

  onNewPhotoUpload = (formData) => {
    this.setState({
      profilePictureLoading: true
    });

    axios
      .put(`/users/${this.props.userData._id}/image`, formData)
      .then((resp) => {
        axios
          .patch(`/users/${this.props.userData._id}/image`, {
            imageUrl: resp.data.imageUrl
          })
          .then(() => {
            this.setState({
              toast: true,
              toastText: 'Picture changed',
              toastStatus: 'ok',
              profilePictureLoading: false
            });
            this.props.onFetchUserDetails();
          });
      })
      .catch(() => {
        this.setState({
          profilePictureLoading: false
        });
      });
  };

  returnNotification = () => {
    if (!this.state.formIsValid) {
      return <Notification message={this.state.notificationText} status="warning" className="ss-top-notification" />;
    }
  };

  closeToast = () => {
    this.setState({
      toast: null,
      toastText: null,
      toastStatus: 'ok'
    });
  };

  returnToast = () => {
    if (this.state.toast) {
      return (
        <Toast status={this.state.toastStatus} onClose={this.closeToast}>
          {this.state.toastText}
        </Toast>
      );
    }

    return null;
  };

  render() {
    return (
      <StripeProvider
        apiKey={
          process.env.NODE_ENV === 'development'
            ? process.env.REACT_APP_STRIPE_KEY_DEV
            : process.env.REACT_APP_STRIPE_KEY_PROD
        }>
        <div className="container" style={{ marginTop: '50px', marginBottom: '30px', maxWidth: '800px' }}>
          <Title header="Account settings" subHeader="Your account settings" />
          {this.returnNotification()}
          {this.returnToast()}

          <div className={classes.formPhotoContainer}>
            <div className={classes.profilePictureInfo}>
              <ProfilePicture
                editable={this.props.userData.image ? false : true}
                uploadNewPhoto={this.onNewPhotoUpload}
                src={this.props.userData.image ? this.props.userData.image : null}
                profilePictureLoading={this.state.profilePictureLoading}
              />
              {!this.props.userData.image && (
                <span>
                  Upload a photo or take a quick snap. Please make sure we can see your whole face. <br />
                  You can only upload your photo once.
                </span>
              )}
            </div>

            <FormCreatorSmall
              formData={this.state.personalDetails}
              valueChanged={this.personalDetailsValueChangedHandler}
            />
          </div>

          <HeaderWithBorder header={'Emergency contact'} />
          <FormCreatorSmall
            formData={this.state.emergencyContactForm}
            valueChanged={this.emergencyContactValueChangedHandler}
          />
          {this.props.userData.role === 'user' && (
            <>
              {this.state.newDataSaving ? (
                <Loading />
              ) : (
                <CustomButton
                  style={{
                    margin: '20px 0',
                    display: 'block',
                    width: '100%',
                    maxWidth: '100%'
                  }}
                  label="Save settings"
                  secondary={true}
                  onClick={this.checkFormValidity}
                />
              )}
              <HeaderWithBorder header={'Payment methods'} />
              <Elements>
                <PaymentMethods />
              </Elements>
            </>
          )}
        </div>
      </StripeProvider>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
