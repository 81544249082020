import React from 'react';
import { Tab, Tabs } from 'grommet';
import SingleClassTypeHistoryComponent from '../../../HistoryComponent/SingleClassTypeHistoryComponent';
import DefaultSingleDayTimetable from '../../DefaultTimetable/DefaultTimetable/DefaultSingleDayTimetable';
import DefaultTimetable from '../../DefaultTimetable/DefaultTimetable/DefaultTimetable';
import { returnAllUpcomingClasses, returnClassesDetails } from '../../../../shared/classFunctions';
import ManagePasses from '../../../ManagePasses/ManagePasses';
import AddEditSingleClass from '../../../AddEditSingleClass/AddEditSingleClass';
import ManageSchedules from '../../../ManageSchedules/ManageSchedules';
import ManageTemplates from '../../../ManageTemplates/ManageTemplates';
import ManageVideos from '../../../ManageVideos/ManageVideos';
import { CLASS } from '../../../../constants';
import withAdminDashboard from '../../../../store/hoc/withAdminDashboard';
import useTabIndex from '../../../../hooks/useTabIndex';
const AdminView = ({
  classesList,
  classesListLoading,
  classesListError,
  classType,
  classesDetails,
  classConfig,
  onFetchClasses,
  showAllGymsData,
  selectedGym
}) => {
  const { activeTabIndex, onTabChange } = useTabIndex();
  const upcomingClassesComponent = classConfig.showUpcoming && (
    <Tab key="Upcoming" title="Upcoming" data-cy="upcoming_tab">
      {classConfig.singleDayTimetable ? (
        <DefaultSingleDayTimetable
          classesList={classesList}
          classesListLoading={classesListLoading}
          classesListError={classesListError}
          onFetchClasses={onFetchClasses}
          classesDetails={classesDetails}
          classConfig={classConfig}
          showAllGymsData={showAllGymsData}
        />
      ) : (
        <DefaultTimetable
          classesList={returnAllUpcomingClasses(classesList)}
          classesListLoading={classesListLoading}
          classesListError={classesListError}
          classesDetails={classesDetails}
          classType={classesDetails.type}
          classConfig={classConfig}
          showAllGymsData={showAllGymsData}
        />
      )}
    </Tab>
  );

  const historyComponent = classConfig.showHistory && (
    <Tab key="History" title="History" data-cy="history_tab">
      <SingleClassTypeHistoryComponent classesDetails={returnClassesDetails(classType)} selectedGym={selectedGym} />
    </Tab>
  );

  const addNewComponent = classConfig.showAddNew && (
    <Tab key="Add New" title="Add New" data-cy="add_new_tab">
      <AddEditSingleClass classType={classType} classConfig={classConfig} />
    </Tab>
  );

  const videosComponent = classType === CLASS.VIDEO && (
    <Tab key="Videos" title="Videos" data-cy="video_tab">
      <ManageVideos />
    </Tab>
  );

  const passesComponent = classConfig.allowedToBuyPasses && (
    <Tab key="Passes" title="Passes" data-cy="passes_tab">
      <ManagePasses classType={classType} />
    </Tab>
  );

  const scheduleComponent = classConfig.schedule && (
    <Tab key="Schedule" title="Schedule" data-cy="schedule_tab">
      <ManageSchedules classType={classType} />
    </Tab>
  );

  const templateComponent = classConfig.schedule && (
    <Tab key="Templates" title="Templates" data-cy="templates_tab">
      <ManageTemplates classType={classType} />
    </Tab>
  );

  return (
    <div data-cy={classType + '_bookings'}>
      <Tabs
        responsive={false}
        style={tabStyles}
        justify="start"
        data-cy="tabList"
        activeIndex={activeTabIndex}
        onActive={onTabChange}>
        {[
          upcomingClassesComponent,
          historyComponent,
          addNewComponent,
          videosComponent,
          passesComponent,
          scheduleComponent,
          templateComponent
        ].filter((component) => !!component)}
      </Tabs>
    </div>
  );
};

export default withAdminDashboard(AdminView);

const tabStyles = {
  borderBottom: 'none',
  paddingBottom: '20px',
  fontWeight: 'bold'
};
