import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import CustomButton from '../../../../components/CustomButton/CustomButton';
import CheckBox from 'grommet/components/CheckBox';

import SingleDay from './SingleDay/SingleDay';
import ArrowRight from '../../../../brandConfig/assets/svg/arrow-right.svg';
import ArrowLeft from '../../../../brandConfig/assets/svg/arrow-left.svg';

import classes from './FullDataTimetable.module.scss';
import withUser from '../../../../store/hoc/withUser';

const cloneDeep = require('lodash.clonedeep');

const FullDataTimetable = ({
  userData,
  isAdmin,
  match,
  width,
  onWindowsResize,
  classesList,
  classesDetails,
  classConfig,
  history
}) => {
  const [firstClassIndex, setFirstClassIndex] = useState(0);
  const [lastClassIndex, setLastClassIndex] = useState(0);
  const [mobileOffset, setMobileOffset] = useState(0);
  const [tabletOffset, setTabletOffset] = useState(0);
  const [activeOnly, setActiveOnly] = useState(false);

  window.addEventListener('resize', onWindowsResize);
  useEffect(() => {
    let windowLastClassIndex = classesList ? (classesList.length === 2 ? 1 : classesList.length === 1 ? 0 : 2) : [];

    if (width < 768 || width < 1280) {
      windowLastClassIndex = lastClassIndex > 0 ? lastClassIndex : 0;
    }

    const windowMobileOffset = width > 768 && mobileOffset > 0 ? 0 : mobileOffset;
    const windowTabletOffset = width > 1280 && tabletOffset > 0 ? 0 : tabletOffset;
    setLastClassIndex(windowLastClassIndex);
    setMobileOffset(windowMobileOffset);
    setTabletOffset(windowTabletOffset);
    // eslint-disable-next-line
  }, []);

  const renderDays = () => {
    if (!classesList || classesList.length === 0) {
      return 'No classes available at the moment, please check here again soon.';
    }

    let copiedTimetable = cloneDeep(classesList);

    if (activeOnly) {
      let filteredClasses = copiedTimetable
        .filter((element) => element.classes.some((classes) => classes.active))
        .map((element) => {
          let newTimetable = Object.assign({}, element);
          newTimetable.classes = newTimetable.classes.filter((classes) => classes.active);
          return newTimetable;
        });
      copiedTimetable = filteredClasses;
    }
    if (width < 768) {
      return copiedTimetable
        .splice(firstClassIndex + mobileOffset, 1)
        .map((elem) => (
          <SingleDay day={elem} key={elem._id} classesDetails={classesDetails} classConfig={classConfig} />
        ));
    }

    if (width < 1280) {
      return copiedTimetable
        .splice(firstClassIndex + tabletOffset, 2)
        .map((elem) => (
          <SingleDay day={elem} key={elem._id} classesDetails={classesDetails} classConfig={classConfig} />
        ));
    }

    return copiedTimetable
      .splice(firstClassIndex, 3)
      .map((elem) => (
        <SingleDay
          day={elem}
          key={elem._id}
          isClient={userData.role === 'user'}
          classesDetails={classesDetails}
          classConfig={classConfig}
        />
      ));
  };

  const renderActiveFilter = () =>
    isAdmin && <CheckBox checked={activeOnly} onChange={() => setActiveOnly(!activeOnly)} label="Active only" />;

  const renderArrowLeft = () => {
    if (!classesList) {
      return null;
    }

    if (firstClassIndex > 0) {
      return <img className={classes.arrowLeft} src={ArrowLeft} alt="prev icon" onClick={onArrowLeftClick} />;
    }

    return null;
  };

  const renderArrowRight = () => {
    if (!classesList) {
      return null;
    }

    if (width < 768) {
      if (lastClassIndex < classesList.length - 1) {
        return <img className={classes.arrowRight} src={ArrowRight} alt="next icon" onClick={onArrowRightClick} />;
      } else {
        return null;
      }
    }

    if (width < 1280) {
      if (lastClassIndex < classesList.length - 1) {
        return <img className={classes.arrowRight} src={ArrowRight} alt="next icon" onClick={onArrowRightClick} />;
      } else {
        return null;
      }
    }

    if (lastClassIndex < classesList.length - 1) {
      return <img className={classes.arrowRight} src={ArrowRight} alt="next icon" onClick={onArrowRightClick} />;
    } else {
      return null;
    }
  };

  const onArrowLeftClick = () => {
    if (width < 768 && lastClassIndex === classesList.length - 1 && mobileOffset > 0) {
      setMobileOffset(mobileOffset - 1);
    }

    if (width < 1280 && lastClassIndex === classesList.length - 1 && tabletOffset > 0) {
      setTabletOffset(tabletOffset - 1);
    }
    setFirstClassIndex(firstClassIndex - 1);
    setLastClassIndex(lastClassIndex - 1);
  };

  const onArrowRightClick = () => {
    if (width < 768 && lastClassIndex === classesList.length - 1) {
      setMobileOffset(mobileOffset + 1);
    }

    if (width < 1280 && lastClassIndex === classesList.length - 1) {
      setTabletOffset(tabletOffset + 1);
    }
    setFirstClassIndex(firstClassIndex + 1);
    setLastClassIndex(lastClassIndex + 1);
  };

  const addOneOffClassButton = () =>
    isAdmin ? (
      <CustomButton
        style={{ marginTop: '50px', display: 'inline-block' }}
        label="Add a one-off class"
        secondary={true}
        onClick={() => history.push(`${match.url}/add-one-off`)}
      />
    ) : null;

  return (
    <>
      <div className={classes.actionContainer} data-cy={classesDetails.type + '_bookings'}>
        {renderActiveFilter()}
        {addOneOffClassButton()}
      </div>
      <div className={classes.header}>
        {renderArrowLeft()}
        {renderArrowRight()}
      </div>
      <div data-testid="full-data-timetable" className={classes.classesContainer}>
        {renderDays()}
      </div>
    </>
  );
};

export default withRouter(withUser(FullDataTimetable));
