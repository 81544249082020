import React from 'react';
import { TextInput } from 'grommet';
import useSelectFilter from '../ClientFilters/hooks/useSelectFilter';
import './Filter.scss';
import FormField from 'grommet/components/FormField';
import DateTime from 'grommet/components/DateTime';
import useSelectDateFilter from '../ClientFilters/hooks/useSelectDateFilter';

const Filter = ({ onFilter = () => {}, placeHolder = '', title = '', type = 'text' }) => {
  const { onSelectedFilterChanged } = useSelectFilter('', onFilter);
  const { onSelectedDateFilterChanged, selectedFilter } = useSelectDateFilter('', onFilter);

  if (type === 'date') {
    return (
      <div className="filter">
        <h4>{title}</h4>
        <FormField>
          <DateTime
            name="filterDate"
            format="DD/MM/YYYY"
            onChange={onSelectedDateFilterChanged}
            value={selectedFilter}
          />
        </FormField>
      </div>
    );
  }

  return (
    <div className="filter">
      <h4>{title}</h4>
      <TextInput name="filter" placeHolder={placeHolder} onDOMChange={onSelectedFilterChanged} />
    </div>
  );
};

export default Filter;
