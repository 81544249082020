import React from 'react';
import { useQuery } from 'react-query';
import { fetchVideoById } from '../../../../apiFunctions/apiFunctions';
import Heading from 'grommet/components/Heading';
import Loading from '../../../../components/Loading/Loading';
import Notification from 'grommet/components/Notification';
import InfoIcon from 'grommet/components/icons/base/Info';
import ShowVideos from '../../../ManageVideos/ShowVideos/Menu';
import classes from './VideoPass.module.scss';

const VideoPass = ({ product }) => {
  const videosIds = product.videos || [];
  const {
    data: videos,
    isLoading: videosAreLoading,
    error: videoError
  } = useQuery([videosIds], () => Promise.all(videosIds.map((id) => fetchVideoById(id))), {
    enabled: videosIds && !!videosIds.length
  });

  if (videoError || !product) {
    return <Notification message="Something went wrong. Please try again" status="critical" />;
  }
  if (videosAreLoading) {
    return <Loading />;
  }

  return (
    <div className={classes.container}>
      <Heading>{product.name}</Heading>
      <Heading tag="h4">{product.description}</Heading>
      {product && !!product.explainer && (
        <div className={classes.explainer}>
          <InfoIcon />
          <div>{product.explainer}</div>
        </div>
      )}
      <ShowVideos
        defaultList
        videos={videos}
        selectedProduct={product}
        enforceVideoOrder={product?.enforceVideoOrder}
      />
    </div>
  );
};

VideoPass.propTypes = {};

export default VideoPass;
