import { useState } from 'react';

const useSelectFilter = (initialState, onFilter, isFromClientStatus = false) => {
  const [selectedFilter, setSelectedFilter] = useState(initialState);

  const onSelectedFilterChanged = (e) => {
    const filterText = e.target.value;
    const isCamelCase = /^([a-z]+)(([A-Z]([a-z]+))+)$/.test(filterText);
    const filter = !isFromClientStatus || !isCamelCase ? filterText.toLowerCase() : filterText;
    onFilter(filter);
    setSelectedFilter(filter);
  };

  const isSelectedFilter = (filter) => {
    return filter === selectedFilter;
  };

  return {
    onSelectedFilterChanged,
    isSelectedFilter,
    selectedFilter
  };
};

export default useSelectFilter;
