import React from 'react';
import { Tabs, Tab } from 'grommet';

import EditGym from './EditGymSettings/EditGym';
import EditGymSettings from './EditGymSettings/EditGymSettings';
import EditAssociatedNames from './AddEditAssociatedNames/EditAssociatedNames';
import useTabIndex from '../../../hooks/useTabIndex';
import withAdminDashboard from '../../../store/hoc/withAdminDashboard';

const ManageGym = ({ selectedGym }) => {
  const { activeTabIndex, onTabChange } = useTabIndex();

  return (
    <div data-cy={'gym_settings'}>
      <Tabs
        style={{
          borderBottom: 'none',
          paddingBottom: '20px',
          fontWeight: 'bold'
        }}
        responsive={false}
        justify="start"
        activeIndex={activeTabIndex}
        onActive={onTabChange}>
        <Tab title="Basic information">
          <EditGym data={selectedGym} />
        </Tab>

        <Tab title="Associated names">
          <EditAssociatedNames names={selectedGym.associatedNames} selectedGym={selectedGym} />
        </Tab>

        <Tab title="Gym properties">
          <EditGymSettings gym={selectedGym} />
        </Tab>
      </Tabs>
    </div>
  );
};

export default withAdminDashboard(ManageGym);
