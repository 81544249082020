import React, { Component } from 'react';
import Modal from 'react-modal';
import List from 'grommet/components/List';
import ListItem from 'grommet/components/ListItem';
import CustomButton from '../../../components/CustomButton/CustomButton';

export default class ClientShowPTPackages extends Component {
  state = {
    showConfirmationModal: false
  };

  handleModalOpen = () => {
    this.setState({
      showConfirmationModal: true
    });
  };

  handleModalClose = () => {
    this.setState({
      showConfirmationModal: false
    });
  };

  render() {
    let PtsList =
      this.props.PtsList === 'error'
        ? 'error'
        : this.props.PtsList.map((PtBlock) => (
            <ListItem key={PtBlock.productPassPurchaseId}>
              {PtBlock.classesLeft} sessions of {PtBlock.duration} minutes
            </ListItem>
          ));

    let modal = null;
    if (this.state.showConfirmationModal) {
      modal = (
        <Modal isOpen={this.state.showConfirmationModal} className="ss-modal" onRequestClose={this.handleModalClose}>
          <div className="grommet">
            <h3 style={{ marginBottom: '15px' }}> My PT packages</h3>
            <List>{PtsList}</List>
          </div>
        </Modal>
      );
    }

    return (
      <>
        {modal}
        <CustomButton
          style={{ maxWidth: '100%', minWidth: '100%' }}
          label="Show my PT packages"
          secondary={true}
          onClick={this.handleModalOpen}
        />
      </>
    );
  }
}
