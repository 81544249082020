import React from 'react';
import moment from 'moment';
import classes from './PayrollReports.module.scss';

const PayrollReportData = ({ data = [], trainer = '' }) => {
  const header = ['Name', 'Type', 'Gym', 'Trainer', 'Date', 'Time'];
  const item = ({ name, classType, gymName, classDate, classTime, id }) => (
    <div key={id} className={[classes.grid, classes.item].join(' ')}>
      <div className={classes.name}>{name}</div>
      <div>
        <span className={classes.label}>Type:</span>
        {classType}
      </div>
      <div>
        <span className={classes.label}>Gym:</span>
        {gymName}
      </div>
      <div>
        <span className={classes.label}>Trainer:</span>
        {trainer}
      </div>
      <div>
        <span className={classes.label}>Date:</span>
        {moment(classDate).format('DD/MM/YYYY')}
      </div>
      <div>
        <span className={classes.label}>Time:</span>
        {classTime}
      </div>
    </div>
  );

  return (
    <div>
      <div className={classes.header}>
        <div className={['table-header', classes.grid].join(' ')}>
          {header.map((elem) => (
            <div key={elem}>{elem}</div>
          ))}
        </div>
      </div>
      <div className={classes.dataContainer}>{data.map((elm) => item(elm))}</div>
    </div>
  );
};

export default PayrollReportData;
