import React from 'react';
import CheckClassDateAndTrainersShifts from './CheckClassDateAndTrainersShifts/CheckClassDateAndTrainersShifts';
import withAdminDashboard from '../../../../store/hoc/withAdminDashboard';
import ShowTrainersShifts from './ShowTrainersShifts/ShowTrainersShifts';

const TrainersShifts = ({
  selectedTrainer,
  show,
  classDateAndTime,
  gymId,
  setIsStaffShiftsValid,
  classDuration,
  setStaffShiftsSolution,
  isSchedule,
  schedule,
  isEditMode
}) => {
  if (show && selectedTrainer !== null) {
    return (
      <div>
        <CheckClassDateAndTrainersShifts
          gymId={gymId}
          classDateAndTime={classDateAndTime}
          selectedTrainer={selectedTrainer}
          setIsStaffShiftsValid={setIsStaffShiftsValid}
          classDuration={classDuration}
          setStaffShiftsSolution={setStaffShiftsSolution}
          isSchedule={isSchedule}
          schedule={schedule}
          isEditMode={isEditMode}
        />
        <ShowTrainersShifts shifts={selectedTrainer.shifts} />
      </div>
    );
  }
  return null;
};

export default withAdminDashboard(TrainersShifts);
