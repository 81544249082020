import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import CustomButton from '../../../../../CustomButton/CustomButton';
import classes from './SingleClassValidator.module.scss';
import { checkIfTrainerIsAvailableAtProvidedDateAndTime } from '../../../../../../shared/classFunctions';
import { withRouter } from 'react-router-dom';

const SingleClassValidator = ({
  selectedTrainer = null,
  classDateAndTime = null,
  classDuration = 60,
  setStaffShiftsSolution = null,
  isEditMode = false,
  location
}) => {
  const [show, setShow] = useState(false);
  const [previousTrainerId, setPreviousTrainerId] = useState(null);
  const [previousClassDateAndTime, setPreviousClassDateAndTime] = useState(classDateAndTime);
  const [previousDuration, setPreviousDuration] = useState(classDuration);
  const isPT = location.pathname.includes('personal-training-timetable');

  useEffect(
    () => {
      const trainerChanged = previousTrainerId !== selectedTrainer._id;
      const classDateAndTimeChanged = previousClassDateAndTime !== classDateAndTime;
      const classDurationChanged = previousDuration !== classDuration;

      const skipValidation = trainerChanged ? false : classDateAndTimeChanged ? false : !classDurationChanged;

      const check = (dateAndTime, duration) => {
        setStaffShiftsSolution(null);
        if (trainerChanged) {
          setPreviousTrainerId(selectedTrainer._id);
        }
        if (classDateAndTimeChanged) {
          setPreviousClassDateAndTime(classDateAndTime);
        }

        if (classDurationChanged) {
          setPreviousDuration(classDuration);
        }

        if (
          !skipValidation &&
          classDateAndTime !== null &&
          classDateAndTime !== '' &&
          !checkIfTrainerIsAvailableAtProvidedDateAndTime(selectedTrainer, dateAndTime, duration)
        ) {
          setShow(true);
        } else {
          setShow(false);
        }
      };

      check(classDateAndTime, classDuration);
    }, // eslint-disable-next-line
    [selectedTrainer, classDateAndTime, classDuration]
  );

  const msg =
    selectedTrainer && selectedTrainer.shifts.length > 0
      ? 'Trainer is not available at that time.'
      : 'No shifts are set for this trainer.';
  const question = isPT || isEditMode ? 'Do you still want to continue?' : 'Do you still want to save?';

  const onOkBtn = () => {
    setStaffShiftsSolution(isPT || isEditMode ? 'correct' : 'save');
    setShow(false);
    setPreviousTrainerId(selectedTrainer._id);
  };
  const onCancelBtn = () => {
    setStaffShiftsSolution('cancel');
    setShow(false);
  };

  return (
    <Modal isOpen={show} className="ss-modal" ariaHideApp={false}>
      <div className={classes.msg}>{msg}</div>
      <div className={classes.msg}>{question} </div>
      <div className="ss-modal__buttons-container">
        <CustomButton secondary label={'Confirm'} onClick={onOkBtn} />
        <CustomButton label={'Cancel'} onClick={onCancelBtn} />
      </div>
    </Modal>
  );
};

export default withRouter(SingleClassValidator);
