import React from 'react';
import { withRouter } from 'react-router-dom';
import Loading from '../../../../components/Loading/Loading';
import withUser from '../../../../store/hoc/withUser';
import { Table, TableHeader, TableRow } from 'grommet';
import moment from 'moment';
import Button from 'grommet/components/Button';
import classes from './DefaultClientUpcomingClasses.module.scss';
import ClientUpcomingClasses from '../../../ClientDashboard/ClientUpcomingClasses/ClientUpcomingClasses';
import ClientShowPTPackages from '../../../ClientDashboard/ClientPtSessions/ClientShowPTPackages';
import { useQuery } from 'react-query';
import { useMediaQuery } from 'react-responsive';
import { fetchClientUpcomingClasses } from '../../../../apiFunctions/apiFunctions';
import ClassActionButtons from '../../../ActionButtons/ClassActionButtons/ClassActionButtons';
import { returnClassesDetails } from '../../../../shared/classFunctions';
import AddToCalendarHOC from 'react-add-to-calendar-hoc';
import CalendarModal from '../CalendarModal/CalendarModal';
import Calendar from 'grommet/components/icons/base/Calendar';
import { firstLetterToUpperCase } from '../../../../shared/utility';
import CustomButton from '../../../../components/CustomButton/CustomButton';

const createEventForCalender = (event) => {
  let startDate = moment(event.class.classDate).format('YYYY/MM/DD');
  let dateTimeStart = moment(startDate + ' ' + event.class.classTime).format('YYYYMMDDTHHmmss');
  let dateTimeEnd = moment(dateTimeStart).add(event.class.duration, 'm').format('YYYYMMDDTHHmmss');
  let gymAddress = event?.class?.locationName
    ? `${event.class?.locationName}, ${event.class?.gymAddress}, ${event.class?.gymCity}, ${event.class?.gymPostcode}`
    : '';

  let eventObj = {
    title: event.class.name,
    description: event.class.description || '',
    location: gymAddress,
    startDatetime: dateTimeStart,
    endDatetime: dateTimeEnd
  };

  return eventObj;
};

const DefaultClientUpcomingClasses = ({ classType, userData, match, history }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 768px)'
  });
  const AddToCalendarModal = isMobile
    ? AddToCalendarHOC(Calendar, CalendarModal)
    : AddToCalendarHOC(Button, CalendarModal);

  const classesDetails = returnClassesDetails(classType);
  const {
    data: upcomingClasses,
    isLoading: upcomingClassesLoading,
    error: upcomingClassesError
  } = useQuery(['clientUpcomingClasses', classType], () => fetchClientUpcomingClasses(classType));

  const renderHeader = <h3>{firstLetterToUpperCase(classesDetails.name)} bookings</h3>;

  const clientUpcomingClasses = () => {
    const activeUpcomingClasses = upcomingClasses.filter(
      (classes) => classes.status === 'active' || classes.status === 'pending'
    );
    if (activeUpcomingClasses.length === 0) {
      return <div>You have no upcoming bookings</div>;
    }
    let tableHeader = isMobile ? ['Date', 'Time', '', ''] : ['Date', 'Time', 'Duration', '', ''];

    if (classType === 'class') {
      tableHeader.unshift('Name');
    }
    const rows = activeUpcomingClasses.map((elem) => (
      <TableRow key={elem._id} data-cy="tableRow">
        {classType === 'class' && <td>{elem.class.name}</td>}
        <td>{moment(elem.class.classDate).format('DD/MM')}</td>
        <td>{elem.class.classTime}</td>
        {!isMobile && <td>{elem.class.duration}</td>}
        <td>
          <ClassActionButtons
            selectedClass={elem.class}
            classesDetails={classesDetails}
            userAttendance={{ stripeOrderId: elem.stripeOrderId, status: elem.status, _id: elem._id }}
          />
        </td>
        <td>
          <AddToCalendarModal
            className={`${!isMobile ? classes.addToCalender : ''}`}
            event={createEventForCalender(elem)}
          />
        </td>
      </TableRow>
    ));

    return (
      <Table responsive={false} className={classes.bookingsTable}>
        <TableHeader labels={tableHeader} />
        <tbody>{rows}</tbody>
      </Table>
    );
  };

  const renderRedirectButton = ({ name }) => {
    let labelText = name === 'induction' ? 'options' : 'timetable';
    return (
      <div>
        <CustomButton
          className={classes.redirectButton}
          label={`Show ${name} ${labelText}`}
          secondary={true}
          onClick={() => history.push(`${match.url}/${name}-timetable`)}
        />
      </div>
    );
  };

  if (upcomingClassesLoading) {
    return <Loading />;
  }
  if (upcomingClassesError) {
    return <div>ERROR</div>;
  }
  if (classType === 'personalTraining') {
    return (
      <div className={classes.root}>
        <h3> Personal Training Sessions</h3>
        <ClientUpcomingClasses
          type={'PTs'}
          upcomingClasses={upcomingClasses.filter((elem) => elem.status === 'active')}
          upcomingClassesLoading={upcomingClassesLoading}
          upcomingClassesError={upcomingClassesError}
        />

        {userData.PTclassesLeft.length > 0 ? (
          <ClientShowPTPackages PtsList={userData.PTclassesLeft} />
        ) : (
          <CustomButton
            className={classes.redirectButton}
            label="Buy a PT package"
            secondary={true}
            onClick={() => history.push(`${match.url}/personal-training-passes`)}
          />
        )}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {renderHeader}
      {clientUpcomingClasses()}
      {renderRedirectButton(classesDetails)}
    </div>
  );
};

export default withRouter(withUser(DefaultClientUpcomingClasses));
