import React from 'react';
import { RadioButton } from 'grommet';
import useSelectFilter from '../hooks/useSelectFilter';
import { CLIENTS_STATUS_FILTERS } from '../../../constants';

const ClientStatusesFilter = ({ onFilter, allClientStatuses }) => {
  const { onSelectedFilterChanged, isSelectedFilter } = useSelectFilter(CLIENTS_STATUS_FILTERS.ACTIVE, onFilter, true);

  return (
    <div className="client__type-filter">
      <h4>Client Status</h4>

      {allClientStatuses && (
        <>
          <RadioButton
            id={CLIENTS_STATUS_FILTERS.WAITING_FOR_APPROVAL}
            name="client_status"
            type="radio"
            label="Waiting for approval"
            value={CLIENTS_STATUS_FILTERS.WAITING_FOR_APPROVAL}
            onChange={onSelectedFilterChanged}
            checked={isSelectedFilter(CLIENTS_STATUS_FILTERS.WAITING_FOR_APPROVAL)}
          />

          <RadioButton
            id={CLIENTS_STATUS_FILTERS.PERMISSION_DENIED}
            name="client_status"
            type="radio"
            label="Permission denied"
            value={CLIENTS_STATUS_FILTERS.PERMISSION_DENIED}
            onChange={onSelectedFilterChanged}
            checked={isSelectedFilter(CLIENTS_STATUS_FILTERS.PERMISSION_DENIED)}
          />
        </>
      )}

      <RadioButton
        id={CLIENTS_STATUS_FILTERS.INACTIVE}
        name="client_status"
        type="radio"
        label="Inactive"
        value={CLIENTS_STATUS_FILTERS.INACTIVE}
        onChange={onSelectedFilterChanged}
        checked={isSelectedFilter(CLIENTS_STATUS_FILTERS.INACTIVE)}
      />

      <RadioButton
        id={CLIENTS_STATUS_FILTERS.ACTIVE}
        name="client_status"
        type="radio"
        label="Active"
        value={CLIENTS_STATUS_FILTERS.ACTIVE}
        onChange={onSelectedFilterChanged}
        checked={isSelectedFilter(CLIENTS_STATUS_FILTERS.ACTIVE)}
      />

      <RadioButton
        id={CLIENTS_STATUS_FILTERS.ALL}
        name="client_status"
        type="radio"
        label="All clients"
        value={CLIENTS_STATUS_FILTERS.ALL}
        onChange={onSelectedFilterChanged}
        checked={isSelectedFilter(CLIENTS_STATUS_FILTERS.ALL)}
      />
    </div>
  );
};

export default ClientStatusesFilter;
