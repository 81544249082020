import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import SelectCategory from './SelectCategory';
import Heading from 'grommet/components/Heading';
import CustomButton from '../../../components/CustomButton/CustomButton';
import classes from './EditCategory.module.scss';
import CustomToast from '../../../components/CustomToast/CustomToast';

function EditCategory({ open, onClose, categories, isAdmin = false }) {
  const [editCategories, setEditCategories] = useState([]);
  const [toast, setToast] = useState(null);

  const activeCategories = editCategories.filter((editCategory) => editCategory.active);
  const inActiveCategories = editCategories.filter((editCategory) => !editCategory.active);

  useEffect(() => {
    if (categories && categories.active && categories.inActive) {
      setEditCategories([
        ...categories.active.map((category) => ({ ...category, active: true })),
        ...categories.inActive.map((category) => ({ ...category, active: false }))
      ]);
    }
  }, [categories]);

  const onChange = (id) => {
    setEditCategories((editCategories) =>
      editCategories.map((editCategory) => {
        if (editCategory.id === id) {
          return {
            ...editCategory,
            active: !editCategory.active
          };
        }

        return editCategory;
      })
    );
  };

  return (
    <>
      <CustomToast toast={toast} />
      <Modal appElement={document.getElementById('root')} isOpen={open} onRequestClose={onClose} className={'ss-modal'}>
        {activeCategories.length ? (
          <>
            <Heading id="active-categories" tag="h3">
              Active Categories
            </Heading>
            <article aria-labelledby="active-categories" className={classes.categoriesContainer}>
              {activeCategories.map((activeCategory) => (
                <SelectCategory
                  category={activeCategory}
                  key={activeCategory.id}
                  isEditMode={isAdmin}
                  isActive
                  onChange={onChange}
                  setToast={setToast}
                />
              ))}
            </article>
          </>
        ) : (
          <Heading tag="h3">No Active Categories</Heading>
        )}
        {inActiveCategories.length ? (
          <>
            <Heading id="inactive-categories" tag="h3">
              Inactive Categories
            </Heading>
            <article aria-labelledby="inactive-categories" className={classes.categoriesContainer}>
              {inActiveCategories.map((inactiveCategory) => (
                <SelectCategory
                  category={inactiveCategory}
                  key={inactiveCategory.id}
                  isEditMode={isAdmin}
                  onChange={onChange}
                  setToast={setToast}
                />
              ))}
            </article>
          </>
        ) : (
          <Heading tag="h3">No Inactive Categories</Heading>
        )}
        <div className={classes.done}>
          <CustomButton primary label="Done" onClick={onClose} />
        </div>
      </Modal>
    </>
  );
}

EditCategory.propTypes = {
  categories: PropTypes.object,
  isAdmin: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func
};

export default EditCategory;
