import axios from '../axios-global';
import { queryClient } from '../SsApp';
import { updateUserData } from '../store/actions/index';
import { store } from '../store';
import { PRODUCT_TYPES } from '../constants';
import moment from 'moment';

export const delayFetchFunctions = (queryKeys, timeout = 1500) =>
  new Promise((resolve) => {
    setTimeout(() => {
      Promise.all(queryKeys.forEach((key) => queryClient.invalidateQueries(key)))
        .then(resolve)
        .catch(resolve); // if error occurs just continue
    }, timeout);
  });

/**
 *         CLASS TYPES / EVENTS
 */
export const fetchClassesByAdmin = (
  classType,
  startDate = moment().format('DD-MM-YYYY'),
  endDate = moment().format('DD-MM-YYYY').add(14, 'D')
) =>
  axios
    .get(`/class/all`, {
      params: {
        classType,
        startDate,
        endDate
      }
    })
    .then((resp) => resp.data);

export const fetchClassesPerScheduleId = (scheduleId) =>
  axios.get(`/class/scheduledClasses/${scheduleId}`).then((resp) => resp.data);

export const fetchSingleClass = (classId) => axios.get(`/class/${classId}`).then((resp) => resp.data);

export const fetchUpcomingClassesInAllGyms = (classType) =>
  axios.get(`/class/all`, { params: { classType } }).then((resp) => resp.data);

export const fetchClasses = (classType, gymId = 'all', dateFrom = null, dateTo = null) => {
  const params =
    dateFrom && dateTo
      ? {
          dateFrom,
          dateTo
        }
      : null;
  return axios.get(`/class/gym/${gymId}/${classType}`, { params }).then((resp) => resp.data);
};

export const fetchClassesHistory = (classType, gymId = 'all') =>
  axios.get(`/class/${classType}/gym/${gymId}/history`).then((resp) => resp.data);

export const fetchClientUpcomingClasses = (classType) =>
  axios.get(`/users/${classType}/upcoming`).then((resp) => resp.data);

export const joinClass = (classId, userId, isSinglePayment = false) =>
  axios
    .post(`/class/${classId}`, {
      userId: userId,
      isSinglePayment
    })
    .then((resp) => resp.data);

export const payForClass = (classId, stripePaymentId, method) =>
  axios
    .post(`/class/${classId}/pay`, {
      stripePaymentId,
      method
    })
    .then((resp) => resp.data);

export const payForAttendance = (attendanceId, stripeOrderId) =>
  axios
    .post(`/attendance/${attendanceId}/payment`, {
      stripeOrderId: stripeOrderId,
      method: 'web'
    })
    .then((resp) => resp.data);

export const moveClientsBetweenClasses = (attendanceId, classId) =>
  axios
    .patch(`/attendance/${attendanceId}/reschedule`, {
      classId
    })
    .then((resp) => resp.data);

export const signUpAndPayForClass = async (classId, userId, isSinglePayment = false) => {
  const joined = await joinClass(classId, userId, isSinglePayment);
  if (joined.stripeOrderId) {
    const payed = await payForAttendance(joined.attendanceId, joined.stripeOrderId);
    return payed;
  } else {
    return joined;
  }
};

export const changeAttendanceStatus = (attendanceId, status) =>
  axios.patch(`/attendance/${attendanceId}`, { status }).then((resp) => resp.data);

export const cancelAttendance = (attendanceId, userId = null, checkTiming = true) =>
  axios
    .patch(`/attendance/${attendanceId}/cancel?checkTiming=${checkTiming}`, userId && { userId })
    .then((resp) => resp.data);

export const buyProductAndUpdateAttendance = (attendanceId, method, userId, productId, isSinglePayment) =>
  axios
    .post(`/attendance/${attendanceId}/payByOtherMethod`, {
      method,
      userId,
      productId,
      isSinglePayment
    })
    .then((resp) => resp.data);

export const cancelClass = (classId, shouldAdminChargeClients = false) =>
  axios.delete(`/class/${classId}/checkTiming/${shouldAdminChargeClients}`).then((resp) => resp.data);

export const activateClass = (classId) => axios.patch(`/class/${classId}`, { active: true }).then((resp) => resp.data);

export const addClass = (gymId, classType, formData) =>
  axios.post(`/class/gym/${gymId}/create/${classType}`, formData).then((resp) => resp.data);

export const editClass = (classId, formData) => axios.patch(`/class/${classId}`, formData).then((resp) => resp.data);

export const createClassAndSignUpAllUsersToClass = async (gymId, classType, formData, usersList) => {
  await new Promise((resolve, reject) =>
    addClass(gymId, classType, formData)
      .then(async (newClass) => {
        for (const client of usersList) {
          try {
            await joinClass(newClass._id, client._id);
          } catch (error) {
            await cancelClass(newClass._id);
            return reject(new Error(`Error: ${client.name} ${client.last_name} cannot sign up`));
          }
        }
        return resolve('Successfully created');
      })
      .catch((error) => reject(new Error(error)))
  );
};
export const bulkClassEditing = (data) =>
  axios.patch(`/class/update/multipleClasses`, { data }).then((resp) => resp.data);

/**
 *          USER
 */
export const fetchUserData = () =>
  axios.get(`/users/me`).then((resp) => {
    store.dispatch(updateUserData(resp.data));
    return resp.data;
  });
export const fetchSingleClientsDetails = (clientId) => axios.get(`users/${clientId}`).then((resp) => resp.data);
export const fetchUsersListAllData = (gymId) => axios.get(`users/gym/${gymId}/all`).then((resp) => resp.data);
export const fetchUsersList = (gymId) => axios.get(`users/gym/${gymId}`).then((resp) => resp.data);
export const searchUsersByName = (name) => axios.get(`users/all?name=${name}`).then((resp) => resp.data);
/**
 *          TRAINER
 */
export const fetchTrainers = (gymId) => axios.get(`/users/gym/${gymId}/trainers`).then((resp) => resp.data);
export const searchTrainersByName = (name) => axios.get(`users/trainers?name=${name}`).then((resp) => resp.data);
/**
 *          ADMIN
 */
export const fetchAdmins = (gymId) => axios.get(`/users/admin/gym/${gymId}`).then((resp) => resp.data);
export const editAdmin = (adminId, data) => axios.get(`/users/admin/${adminId}`, data).then((resp) => resp.data);
export const deleteAdmin = (adminId) => axios.delete(`/users/${adminId}`).then((resp) => resp.data);
export const searchAdminsByName = (name) => axios.get(`users/admins?name=${name}`).then((resp) => resp.data);

/**
 *          PASSES
 */
export const fetchPasses = (classType, gymId) =>
  axios.get(`/products/passes/${classType}Pass/${gymId}/all`).then((resp) => resp.data);

export const fetchVideoPasses = ({ classType, gymId }) =>
  axios.get(`/products/passes/videoPass/${gymId}/all`).then(({ data }) =>
    data.filter((product) => {
      if (classType) {
        return product.__t === PRODUCT_TYPES.VIDEO && product.forClass === classType;
      }
      return product.__t === PRODUCT_TYPES.VIDEO;
    })
  );

export const orderPass = (passId, req) => axios.post(`/products/passes/${passId}/order`, req).then((resp) => resp.data);

export const payForPass = (productId, stripeOrderId, method = 'web', currency) =>
  axios.post(`/products/passes/pay`, { productId, stripeOrderId, method, currency }).then((resp) => resp.data);

export const orderAndPayForPass = async (passId, method = 'web', currency, promoCode) => {
  const req = {
    method: method
  };
  if (promoCode !== undefined && promoCode !== null && promoCode.trim().length !== 0) {
    req['coupon'] = promoCode;
  }
  const order = await orderPass(passId, req);
  if (order.stripeOrderId) {
    const payed = await payForPass(passId, order.stripeOrderId, method, currency);
    return payed;
  } else {
    return order;
  }
};

export const addPass = (classType, body = {}) =>
  axios.post(`/products/passes/${classType}`, body).then((resp) => resp.data);

export const editPass = (passId, body = {}) =>
  axios.patch(`/products/passes/${passId}`, body).then((resp) => resp.data);

export const getDiscountedPriceForCoupon = (passId, couponCode) =>
  axios.get(`/products/${passId}/discountedPrice?couponCode=${couponCode}`).then((resp) => resp.data);

export const addVideoToProducts = (videoId, products = []) => {
  return Promise.all(
    products.map((product) => {
      if (product.videos && product.videos.includes(videoId)) {
        return Promise.resolve();
      }
      return editPass(product._id, {
        videos: product.videos ? [...product.videos, videoId] : [videoId]
      });
    })
  );
};

export const removeVideoFromProducts = (videoId, products = []) => {
  return Promise.all(
    products.map((product) => {
      if (!product.videos || !product.videos.length || !product.videos.includes(videoId)) {
        return Promise.resolve();
      }
      return editPass(product._id, {
        videos: product.videos.filter((video) => {
          return video !== videoId;
        })
      });
    })
  );
};

/**
 *          TEMPLATES
 */

export const fetchTemplates = (gymId, classType = 'all') =>
  axios.get(`/class-template/gym/${gymId}?classType=${classType}`).then((resp) => resp.data);
export const addTemplate = (gymId, body) => axios.post(`/class-template/gym/${gymId}`, body).then((resp) => resp.data);
export const editTemplate = (templateId, body) =>
  axios.patch(`/class-template/${templateId}`, body).then((resp) => resp.data);
export const deleteTemplate = (templateId) => axios.delete(`/class-template/${templateId}`).then((resp) => resp.data);

/**
 *          SCHEDULED CLASSES
 */
export const fetchAllScheduledClasses = (gymId) => axios.get(`/scheduled-class/gym/${gymId}`).then((resp) => resp.data);
export const fetchScheduledClasses = (classType, gymId) =>
  axios.get(`/scheduled-class/gym/${gymId}/${classType}`).then((resp) => resp.data);
export const addScheduledClass = (gymId, body = {}) =>
  axios.post(`/scheduled-class/gym/${gymId}`, body).then((resp) => resp.data);
export const editScheduledClass = (scheduledId, body = {}) =>
  axios.patch(`/scheduled-class/${scheduledId}`, body).then((resp) => resp.data);
export const deleteScheduledClass = (scheduledId) =>
  axios.delete(`/scheduled-class/${scheduledId}`).then((resp) => resp.data);

/**
 *          FAQS
 */
export const fetchFAQs = (gymId) => axios.get(`/faq/all/gym/${gymId}`).then((resp) => resp.data);
export const addFAQ = (gymId, body = {}) => axios.post(`/faq/add/gym/${gymId}`, body).then((resp) => resp.data);
export const editFAQ = (faqId, body = {}) => axios.patch(`/faq/${faqId}`, body).then((resp) => resp.data);
export const deleteFAQ = (faqId) => axios.delete(`/faq/${faqId}`).then((resp) => resp.data);
export const uploadFAQJsonFile = (gymId, body = {}) =>
  axios.post(`faq/add/json/gym/${gymId}`, body).then((resp) => resp.data);

export const fetchUpcomingPts = () => axios.get(`/users/personalTraining/upcoming`).then((resp) => resp.data);

export const fetchDebtorsReport = (gymId = null) => {
  const url = gymId !== null ? `/reports/debtors/${gymId}` : '/reports/debtors';
  return axios.get(url).then((resp) => resp.data);
};

export const fetchPayrollReport = (data) => {
  let url = '/users/trainers/timesheet';
  url = `${url}?${new URLSearchParams(data).toString()}`;
  return axios.get(url, data).then((resp) => resp.data);
};

/**
 *          VIDEO
 */
export const fetchVideos = () => axios.get(`/videos`).then((resp) => resp.data);
export const fetchVideoById = (id) => axios.get(`/videos/${id}`).then((resp) => resp.data);
export const uploadVideo = async (formData, { config, products }) => {
  const { videoUrl: url } = await axios.post(`/videos`, formData).then((resp) => resp.data);

  const videoConfig = {
    ...config,
    file: {
      ...config.file,
      url
    }
  };

  const workoutTypeSelection = config.videoConfig.find((category) => category.name === 'Workout Type');
  const workoutType =
    workoutTypeSelection && workoutTypeSelection.options.length
      ? workoutTypeSelection.options.sort((a, b) => a.localeCompare(b)).join('-')
      : 'uncategorised';

  const { _id: savedVideoId } = await saveVideo(workoutType, videoConfig);

  await addVideoToProducts(savedVideoId, products);
};

export const saveVideo = (workoutType, body) => {
  const workoutSlug = workoutType.toLowerCase().replace(' ', '-');
  return axios.post(`/videos/${workoutSlug}`, body).then((resp) => resp.data);
};
export const editVideo = (videoId, body) => axios.patch(`/videos/editVideo/${videoId}`, body).then((resp) => resp.data);
export const deleteVideo = async (videoId, products) => {
  await markVideoAsDeleted(videoId);
  await removeVideoFromProducts(videoId, products);
};
const markVideoAsDeleted = (videoId) => axios.delete(`/videos/${videoId}`).then((resp) => resp.data);
export const deleteVideoFromS3 = (fileName) => axios.delete(`/videos/s3/${fileName}`).then((resp) => resp.data);
export const videoPassCompleted = (purchaseId) => {
  if (!purchaseId) return Promise.reject(new Error('Missing purchase id'));
  return axios.post(`/users/videos/${purchaseId}/completeConfirmation`);
};

/**
 *          VIDEO CATEGORIES
 */
export const fetchVideoCategories = () => axios.get(`/video-config/all`).then((resp) => resp.data);
export const addVideoCategory = (name, options) =>
  axios.post(`/video-config`, { name, options }).then((resp) => resp.data);
export const editVideoCategory = (categoryId, body) =>
  axios.patch(`/video-config/${categoryId}`, body).then((resp) => resp.data);
export const deleteVideoCategory = (categoryId) =>
  axios.delete(`/video-config/${categoryId}`).then((resp) => resp.data);

/**
 *          COUPONS
 */

export const fetchCoupons = (gymId) => axios.get(`/coupons/gym/${gymId}`).then((resp) => resp.data);
export const addCoupon = (gymId, body) =>
  axios.post(`/coupons/${body.couponType}/gym/${gymId}`, body).then((resp) => resp.data);
export const editCoupon = (couponId, body) => axios.patch(`/coupons/${couponId}`, body).then((resp) => resp.data);
export const deleteCoupon = (couponId) => axios.delete(`/coupons/${couponId}`).then((resp) => resp.data);
