import React, { useState, useEffect } from 'react';
import SingleClient from '../ManageUsers/ManageClients/SingleClient/SingleClient';
import Toast from 'grommet/components/Toast';
import axios from '../../../axios-global';
import Filters from '../../../components/ClientFilters';
import TableHeader from 'grommet/components/TableHeader';
import Table from 'grommet/components/Table';
import classes from './NeedsApproval.module.scss';

const NeedsApproval = (props) => {
  const [clientsList, setClientsList] = useState([]);
  const [filteredClientsList, setFilteredClientsList] = useState([]);
  const [status, setStatus] = useState('waitingForApproval');
  const [errorMessage, setErrorMessage] = useState();

  useEffect(() => {
    const getAllCategory = async () => {
      const response = await axios.get(`/users/status/${status}`);
      if (response.status === 200) {
        setClientsList(response.data);
        setFilteredClientsList(response.data);
      } else {
        showErrorToast('Failed to get users');
      }
    };
    getAllCategory();
  }, [status]);

  const showErrorToast = (message) => {
    setErrorMessage(message);
  };

  const handleToastClose = () => {
    setErrorMessage(false);
  };

  const renderMessage = () => {
    if (errorMessage) {
      return (
        <Toast status="critical" onClose={handleToastClose}>
          {errorMessage}
        </Toast>
      );
    }
  };

  const handleFilterClientTypes = (filter) => {
    setFilteredClientsList(clientsList);
    if (filter.statusFilter) {
      setStatus(filter.statusFilter);
    }

    if (filter.nameFilter) {
      let filteredClients = clientsList.filter(
        (x) =>
          x.name.toLowerCase().startsWith(filter.nameFilter.toLowerCase()) ||
          x.last_name.toLowerCase().startsWith(filter.nameFilter.toLowerCase())
      );
      setFilteredClientsList(filteredClients);
    }

    if (filter.gymNameFilter) {
      let filteredClients = clientsList.filter((x) =>
        x.gym.name.toLowerCase().includes(filter.gymNameFilter.toLowerCase())
      );
      setFilteredClientsList(filteredClients);
    }
  };

  const renderFilter = () => {
    return <Filters onFilter={handleFilterClientTypes} gymNameFilter={true} hideStatusFilter={true} />;
  };

  const renderClientList = () => {
    if (filteredClientsList.length > 0) {
      return filteredClientsList.map((client) => (
        <SingleClient
          isAdmin={props.isAdmin}
          showGymName={true}
          allClients={true}
          key={client._id}
          client={client}
          history={props.history}
          match={props.match}
        />
      ));
    } else {
      return 'No clients';
    }
  };

  return (
    <>
      {renderMessage()}
      {renderFilter()}
      {filteredClientsList && filteredClientsList.length > 0 ? (
        <Table responsive={false} className={classes.clientTable}>
          <TableHeader labels={['Clients', 'Gym', 'Account type', 'Membership Status', 'Marketing', 'Action']} />
          <tbody>{renderClientList()}</tbody>
        </Table>
      ) : (
        <h3>No clients</h3>
      )}
    </>
  );
};
export default NeedsApproval;
