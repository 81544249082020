import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { delayFetchFunctions, deleteCoupon } from '../../../apiFunctions/apiFunctions';
import { returnCurrencyAndCostString, returnErrorFromResponse } from '../../../shared/utility';
import CustomToast from '../../../components/CustomToast/CustomToast';
import { Table, TableRow, TableHeader } from 'grommet';
import Icons from '../../../components/UI/Icons/Icons';

const ShowCoupon = ({ data, onUpdateCoupon }) => {
  const [toast, setToast] = useState(null);
  const [selectedCouponIdToDelete, setSelectedCouponIdToDelete] = useState(null);

  const mutateDeleteCoupon = useMutation((couponId) => deleteCoupon(couponId), {
    onSuccess: () => {
      setToast({
        status: 'ok',
        msg: 'Coupon successfully deleted',
        show: true
      });
      delayFetchFunctions([['coupons']], 0);
    },
    onError: (err) => {
      setToast({
        status: 'critical',
        msg: returnErrorFromResponse(err),
        show: true
      });
    }
  });

  const removeCoupon = (couponId) => {
    if (selectedCouponIdToDelete === couponId) {
      mutateDeleteCoupon.mutate(couponId);
    } else {
      setSelectedCouponIdToDelete(couponId);
      setToast({
        status: 'ok',
        msg: 'To confirm deletion please click once again.',
        show: true
      });
    }
  };
  const tableHeader = ['Name', 'Description', 'Discount Value', 'Duration', 'Display Order', 'Action'];

  const renderTableBody = data.map((elem) => (
    <TableRow
      direction="column"
      align="start"
      separator="horizontal"
      key={elem._id}
      style={elem.active ? {} : inactiveRow}>
      <td>{elem.name}</td>
      <td style={{ fontSize: '12px' }}>{elem.description}</td>
      <td>
        {elem.discountType === 'amount'
          ? returnCurrencyAndCostString(elem.currency, elem.discountValue)
          : `${elem.discountValue} %`}
      </td>
      <td>{elem.duration}</td>
      <td>{elem.displayOrder}</td>
      <td>
        <Icons edit delete editClick={() => onUpdateCoupon(elem)} deleteClick={() => removeCoupon(elem._id)} />
      </td>
    </TableRow>
  ));

  return (
    <div>
      <CustomToast toast={toast} />
      <Table responsive={false}>
        <TableHeader labels={tableHeader} />
        <tbody>{renderTableBody}</tbody>
      </Table>
    </div>
  );
};

export default ShowCoupon;

const inactiveRow = {
  background: '#dddddd'
};
