import React from 'react';
import SingleClassValidator from './SingleClassValidator/SingleClassValidator';
import ScheduleValidator from './ScheduleValidator/ScheduleValidator';

const CheckClassDateAndTrainersShifts = ({
  selectedTrainer = null,
  classDateAndTime = null,
  classDuration = 60,
  setStaffShiftsSolution = null,
  isEditMode = false,
  isSchedule = false,
  schedule = null
}) => {
  return isSchedule ? (
    <ScheduleValidator
      selectedTrainer={selectedTrainer}
      schedule={schedule}
      setStaffShiftsSolution={setStaffShiftsSolution}
      isEditMode={isEditMode}
    />
  ) : (
    <SingleClassValidator
      selectedTrainer={selectedTrainer}
      classDateAndTime={classDateAndTime}
      classDuration={classDuration}
      setStaffShiftsSolution={setStaffShiftsSolution}
      isEditMode={isEditMode}
    />
  );
};

export default CheckClassDateAndTrainersShifts;
