import React, { Component } from 'react';

import Status from 'grommet/components/icons/Status';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import Modal from 'react-modal';
import moment from 'moment';

import classes from './CurrentStatus.module.scss';

import UserListPtBlocks from '../../../../components/UserListPtBlocks/UserListPtBlocks';

export default class CurrentStatus extends Component {
  state = {
    showConfirmationModal: false
  };

  handleModalOpen = () => {
    this.setState({
      showConfirmationModal: true
    });
  };

  handleModalClose = () => {
    this.setState({
      showConfirmationModal: false
    });
  };

  render() {
    let containerClasses = [classes.currentStatus];
    if (this.props.borders) {
      containerClasses.push(classes.currentStatusBorder);
    }

    let button = null;
    if (!this.props.dashboard) {
      button = (
        <div className={classes.currentStatus__button}>
          {this.props.hasUserSignUpMembershipPlan ? (
            <div className={classes.info}>
              <div>
                Your membership expired on{' '}
                {moment(this.props.userData.currentMembershipPlan.current_period_end).format('Do MMM')}.
              </div>
              <div>Please contact us to arrange restarting your membership.</div>
            </div>
          ) : (
            <CustomButton label="Sign up" secondary={true} onClick={() => this.props.openPlansList()} />
          )}
        </div>
      );
    }

    let modal = null;
    if (this.state.showConfirmationModal) {
      modal = (
        <Modal isOpen={this.state.showConfirmationModal} className="ss-modal" onRequestClose={this.handleModalClose}>
          <div className="grommet">
            <UserListPtBlocks PtsList={this.props.PtsLeft} />
          </div>
        </Modal>
      );
    }

    let status = (
      <div className={containerClasses.join(' ')}>
        {modal}
        <div className={classes.currentStatus__info}>
          <span className={classes.info__text}>Current status</span>

          <div>
            <span className={classes.info__notActive} data-cy="membershipStatus">
              {this.props.hasUserSignUpMembershipPlan ? 'Expired' : 'Not active'}
            </span>
            <Status value="critical" data-cy="membershipRedioCheck" />
          </div>
        </div>

        {button}
      </div>
    );

    if (this.props.currentStatus.active && !this.props.dashboard) {
      status = (
        <div className={containerClasses.join(' ')}>
          {modal}
          <div className={[classes.currentStatus__info, classes.currentStatus__infoActive].join(' ')}>
            <span className={classes.info__text}>Current status</span>

            <div className={classes.info__statusContainer}>
              <span className={classes.info__activeMobile} data-cy="membershipStatus">
                {this.props.currentStatus.planName}
              </span>

              <Status value="ok" data-cy="membershipRedioCheck" />

              <span className={classes.info__planName}>{this.props.currentStatus.ssPlanId.name}</span>
            </div>

            <span className={classes.info__activeMobile}>
              Renews on {moment(this.props.currentStatus.current_period_end).format('Do MMM h:mm A')}
            </span>

            <span className={classes.info__activeDesktop}>
              Membership renews on {moment(this.props.currentStatus.current_period_end).format('Do MMM h:mm A')}
            </span>
          </div>
        </div>
      );
    }

    if (this.props.currentStatus.active && this.props.dashboard) {
      status = (
        <div className={containerClasses.join(' ')}>
          {modal}
          <div className={[classes.currentStatus__info, classes.currentStatus__infoActive].join(' ')}>
            <span className={classes.info__text}>Current status</span>

            <div className={classes.info__statusContainer}>
              <span className={classes.info__activeMobile}>{this.props.currentStatus.planName}</span>

              <Status value="ok" />

              <span className={classes.info__planName}>{this.props.currentStatus.planName}</span>
            </div>

            <div className={classes.limitsContainer}>
              <span className={classes.info__activeMobile} style={{ marginBottom: '5px' }}>
                Classes left (this month): {this.props.classesLeft === true ? 'Unlimited' : this.props.classesLeft}
              </span>

              <span className={classes.info__activeDesktop} style={{ marginBottom: '5px' }}>
                Classes left (this month): {this.props.classesLeft === true ? 'Unlimited' : this.props.classesLeft}
              </span>

              <span className={classes.info__activeMobile} style={{ marginBottom: '5px' }}>
                Classes left (next month):{' '}
                {this.props.classesLeftNextMonth === true ? 'Unlimited' : this.props.classesLeftNextMonth}
              </span>

              <span className={classes.info__activeDesktop} style={{ marginBottom: '5px' }}>
                Classes left (next month):{' '}
                {this.props.classesLeftNextMonth === true ? 'Unlimited' : this.props.classesLeftNextMonth}
              </span>

              <span className={classes.info__activeMobile}>
                My PT Packages:
                <CustomButton
                  style={{
                    display: 'inline-block',
                    marginLeft: '5px'
                  }}
                  box
                  label="Show"
                  secondary
                  onClick={this.handleModalOpen}
                />
              </span>

              <span className={classes.info__activeDesktop}>
                My PT Packages:
                <CustomButton
                  style={{
                    display: 'inline-block',
                    marginLeft: '5px'
                  }}
                  box
                  label="Show"
                  secondary
                  onClick={this.handleModalOpen}
                />
              </span>
            </div>
          </div>
        </div>
      );
    }

    return status;
  }
}
