import React, { useState } from 'react';
import Label from 'grommet/components/Label';
import FormField from 'grommet/components/FormField';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import SingleTrainer from '../../ManageUsers/ManageTrainers/SingleTrainer/SingleTrainer';
import SingleAdmin from '../../ManageUsers/ManageAdmins/SingleTrainer/SingleAdmin';
import classes from './SearchAdminTrainers.module.scss';
import Loading from '../../../../components/Loading/Loading';
import withAdminDashboard from '../../../../store/hoc/withAdminDashboard';
import { useMutation } from 'react-query';
import { searchAdminsByName, searchTrainersByName } from '../../../../apiFunctions/apiFunctions';
import Notification from 'grommet/components/Notification';

const SearchAdminTrainers = ({ role }) => {
  const [name, setName] = useState('');
  const [showError, setShowError] = useState(false);
  const [searchData, setSearchData] = useState(null);

  const mutateSearch = useMutation(() => (role === 'admin' ? searchAdminsByName(name) : searchTrainersByName(name)), {
    onSuccess: (data) => setSearchData(data),
    onError: () => setShowError(true)
  });

  const search = (
    <div>
      <Label>Please enter name or last name</Label>
      <FormField>
        <input value={name} onChange={(e) => setName(e.target.value)} />
      </FormField>
      <CustomButton primary label="Search" onClick={mutateSearch.mutate} />
    </div>
  );

  const searchResult = () => {
    if (searchData) {
      if (searchData.length) {
        return searchData.map((user) => {
          if (role === 'admin') {
            return <SingleAdmin singleAdmin={user} key={user._id} refreshData={mutateSearch.mutate} />;
          } else {
            return <SingleTrainer key={user._id} singleTrainer={user} refreshData={mutateSearch.mutate} />;
          }
        });
      } else {
        return <h3>No {role === 'admin' ? 'admins' : 'trainers'}</h3>;
      }
    }
    return null;
  };
  const errorNotification = showError && (
    <Notification message="Something went wrong. Please try again" status="critical" />
  );
  return (
    <div className={classes.root}>
      {search}
      {errorNotification}
      {searchResult()}
      {mutateSearch.isLoading && <Loading />}
    </div>
  );
};

export default withAdminDashboard(SearchAdminTrainers);
