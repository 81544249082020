import React, { useState } from 'react';
import MoneyIcon from 'grommet/components/icons/base/Money';
import { Tab, Tabs, Notification, Select } from 'grommet';
import Modal from 'react-modal';
import HeaderWithBorder from '../../../components/UI/HeaderWithBorder/HeaderWithBorder';

import ClientProfilePaymentMethodSelection from '../../../components/ClientProfile/ClientProfilePaymentMethodSelection';
import { useMutation, useQuery } from 'react-query';
import { buyProductAndUpdateAttendance, delayFetchFunctions, fetchPasses } from '../../../apiFunctions/apiFunctions';
import { returnErrorFromResponse } from '../../../shared/utility';
import CustomToast from '../../../components/CustomToast/CustomToast';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Loading from '../../../components/Loading/Loading';
import useTabIndex from '../../../hooks/useTabIndex';

const SelectAndPayForProduct = ({ selectedUser, singleClass, attendanceId }) => {
  const isOnlySinglePaymentAvailable =
    (selectedUser.customerType === 'full-member' && singleClass.__t === 'class') || singleClass.__t === 'induction';

  const [toast, setToast] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [isSinglePayment, setIsSinglePayment] = useState(isOnlySinglePaymentAvailable);
  const [selectedPassProduct, setSelectedPassProduct] = useState(null);
  const { activeTabIndex, onTabChange } = useTabIndex();

  const { data, isLoading, error } = useQuery(
    ['passes', singleClass.__t],
    () => fetchPasses(singleClass.__t, singleClass.gymId._id),
    { enabled: showModal && !isOnlySinglePaymentAvailable }
  );

  const mutateBuyProductAndUpdateAttendance = useMutation(
    () =>
      buyProductAndUpdateAttendance(
        attendanceId,
        selectedPaymentMethod,
        selectedUser._id,
        !isSinglePayment ? selectedPassProduct.value : null,
        isSinglePayment
      ),
    {
      onSuccess: () => {
        setToast({
          msg: `Attendance successfully updated.`,
          status: 'ok',
          show: true
        });
        delayFetchFunctions(['singleClass'], 0);
      },
      onError: (err) => {
        setToast({
          msg: returnErrorFromResponse(err),
          status: 'critical',
          show: true
        });
      }
    }
  );

  const renderProducts = () => {
    if (data && data.length > 0) {
      const classPasses = data.map((elem) => {
        return {
          label: `${elem.name} (x${elem.classCount})`,
          value: elem._id
        };
      });
      if (selectedPassProduct === null) {
        setSelectedPassProduct(classPasses[0]);
      }
      return (
        <Select
          options={classPasses}
          value={selectedPassProduct ? selectedPassProduct.label : classPasses[0].label}
          onChange={(e) => setSelectedPassProduct(e.value)}
        />
      );
    }
    return <h1 style={{ color: 'red', margin: '10px', textAlign: 'center' }}>No passes</h1>;
  };

  const modal = () => {
    if (isLoading) {
      return <Loading />;
    }
    if (error) {
      return <Notification message="Something went wrong. Please try again" status="critical" />;
    }

    return (
      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)} className="ss-modal">
        <Tabs responsive={false} activeIndex={activeTabIndex} onActive={onTabChange}>
          {!isOnlySinglePaymentAvailable && (
            <Tab title="Choose pass product" onClick={() => setIsSinglePayment(false)}>
              {renderProducts()}
            </Tab>
          )}
          <Tab title="Make single payment" onClick={() => setIsSinglePayment(true)} />
        </Tabs>
        <HeaderWithBorder header={'Choose payment method'} />
        <ClientProfilePaymentMethodSelection onMethodSelected={setSelectedPaymentMethod} />

        <div className="ss-modal__buttons-container">
          <CustomButton box label="OK" secondary={true} onClick={mutateBuyProductAndUpdateAttendance.mutate} />
          <CustomButton box label="Cancel" primary onClick={() => setShowModal(false)} />
        </div>
      </Modal>
    );
  };

  return (
    <>
      <CustomToast toast={toast} />
      {modal()}
      <MoneyIcon style={{ cursor: 'pointer' }} onClick={() => setShowModal(true)} />
    </>
  );
};

export default SelectAndPayForProduct;
