import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Heading from 'grommet/components/Heading';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Loading from '../../../components/Loading/Loading';
import UploadVideo from './Upload';
import EditVideoMetaData from '../EditVideo/EditVideoMetaData';
import CustomToast from '../../../components/CustomToast/CustomToast';
import { returnErrorFromResponse } from '../../../shared/utility';
import { uploadVideo, delayFetchFunctions } from '../../../apiFunctions/apiFunctions';

import classes from './AddVideo.module.scss';

function AddVideo({ categories = { active: [], inActive: [] }, productList = [], open, onClose }) {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [videoMetaData, setVideoMetaData] = useState({
    title: '',
    selectedTrainer: '',
    videoConfig: []
  });
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [toast, setToast] = useState(null);
  const [loading, setLoading] = useState(false);

  const isValid = videoMetaData.title.length && selectedVideo;

  const clearSelection = () => {
    setSelectedProducts([]);
    setSelectedVideo(null);
    setVideoMetaData({
      title: '',
      selectedTrainer: '',
      videoConfig: []
    });
  };

  const upload = async () => {
    if (isValid) {
      const formData = new FormData();
      formData.append('video', selectedVideo);
      setLoading(true);
      setToast({
        msg: 'Uploading video...',
        show: true
      });

      try {
        const config = {
          ...videoMetaData,
          file: {
            title: videoMetaData.title,
            name: selectedVideo.name,
            size: selectedVideo.size,
            type: selectedVideo.type
          }
        };

        const selectedProductIds = selectedProducts.map((selectedProduct) => selectedProduct.value);
        await uploadVideo(formData, {
          config,
          products: productList.filter((product) => selectedProductIds.includes(product._id))
        });

        setLoading(false);
        setToast({
          msg: 'Uploaded.',
          show: true
        });

        delayFetchFunctions(['fetchVideos'], 1000);
      } catch (error) {
        setLoading(false);
        setToast({
          status: 'critical',
          msg: returnErrorFromResponse(error),
          show: true
        });
      }
      close();
    }
  };

  const close = () => {
    clearSelection();
    onClose();
  };

  return (
    <>
      <CustomToast toast={toast} />
      <Modal
        appElement={document.getElementById('root')}
        isOpen={open}
        onRequestClose={close}
        className={`ss-modal ${classes.modal}`}>
        {loading ? (
          <Loading size="large" />
        ) : (
          <>
            <div className={classes.content}>
              <Heading tag="h1">Upload Video</Heading>
              <div className={classes.container}>
                <EditVideoMetaData
                  productList={productList}
                  selectedProducts={selectedProducts}
                  setSelectedProducts={setSelectedProducts}
                  categories={categories.active}
                  videoMetaData={videoMetaData}
                  setVideoMetaData={setVideoMetaData}
                />
                <div>
                  <Heading tag="h3">File</Heading>
                  <UploadVideo selectedVideo={selectedVideo} onSelectVideo={(video) => setSelectedVideo(video)} />
                </div>
              </div>
            </div>
            <div className={classes.buttonBar}>
              <CustomButton label="Cancel" secondary={true} onClick={close} />
              {isValid ? <CustomButton label="Upload" primary onClick={upload} /> : null}
            </div>
          </>
        )}
      </Modal>
    </>
  );
}

AddVideo.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  categories: PropTypes.object,
  productList: PropTypes.array
};

export default AddVideo;
