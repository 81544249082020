import axios from '../../axios-global';
import * as actionTypes from './actionTypes';
import { createAction } from './actionTypes';
import { returnErrorFromResponse } from '../../shared/utility';

//manage admins
export const getAdmins = (gymId) => (dispatch) => {
  dispatch(createAction(actionTypes.GET_ADMINS_START));

  return axios
    .get(`users/admin/gym/${gymId}`)
    .then((resp) => {
      dispatch(
        createAction(actionTypes.GET_ADMINS_SUCCESS, {
          adminsList: resp.data
        })
      );
    })
    .catch((error) => {
      dispatch(createAction(actionTypes.GET_ADMINS_ERROR, { error }));
    });
};

export const addNewAdmin = (newAdmin) => (dispatch) => {
  dispatch(createAction(actionTypes.CREATE_ADMIN_START));

  return axios
    .post(`users/admin/`, newAdmin)
    .then((resp) =>
      dispatch(
        createAction(actionTypes.CREATE_ADMIN_SUCCESS, {
          newAdmin: resp.data
        })
      )
    )
    .catch((error) => dispatch(createAction(actionTypes.CREATE_ADMIN_ERROR, { error })));
};

export const editAdmin = (data, adminId) => (dispatch) => {
  dispatch(createAction(actionTypes.UPDATE_ADMIN_START));

  return axios
    .patch(`/users/admin/${adminId}`, data)
    .then((resp) => {
      dispatch(
        createAction(actionTypes.UPDATE_ADMIN_SUCCESS, {
          updatedAdmin: resp.data
        })
      );
    })
    .catch((error) => dispatch(createAction(actionTypes.UPDATE_ADMIN_ERROR, { error })));
};

export const deleteAdmin = (adminId) => (dispatch) => {
  dispatch(createAction(actionTypes.DELETE_ADMIN_START));

  return axios
    .delete(`/users/${adminId}`)
    .then((resp) =>
      dispatch(
        createAction(actionTypes.DELETE_ADMIN_SUCCESS, {
          deletedAdmin: resp.data
        })
      )
    )
    .catch((error) => dispatch(createAction(actionTypes.DELETE_ADMIN_ERROR, { error })));
};

// manage global QR reader
export const changeGlobalQrReader = (state) => {
  return {
    type: actionTypes.ADMIN_CHANGE_GLOBAL_QR_READER,
    state
  };
};

// gym details
export const fetchGymDetails = (gymId) => {
  return (dispatch) => {
    dispatch(setGymDataStart());

    axios
      .get(`/gym/${gymId}`)
      .then((response) => dispatch(setGymDataSuccess(response.data)))
      .catch((error) => dispatch(setGymDataFailed(returnErrorFromResponse(error))));
  };
};

const setGymDataStart = () => {
  return {
    type: actionTypes.ADMIN_SET_GYM_DATA_START
  };
};

const setGymDataSuccess = (selectedGym) => {
  return {
    type: actionTypes.ADMIN_SET_GYM_DATA_SUCCESS,
    selectedGym
  };
};

const setGymDataFailed = (error) => {
  return {
    type: actionTypes.ADMIN_SET_GYM_DATA_FAILED,
    error
  };
};

// plans list
export const fetchPlansList = (gymId) => {
  return (dispatch) => {
    dispatch(setPlansListStart());

    axios
      .get(`/membership-plan/gymPlans/${gymId}`)
      .then((response) => dispatch(setPlansListSuccess(response.data)))
      .catch((error) => dispatch(setPlansListFailed(returnErrorFromResponse(error))));
  };
};

const setPlansListStart = () => {
  return {
    type: actionTypes.ADMIN_FETCH_PLANS_START
  };
};

const setPlansListSuccess = (plansList) => {
  return {
    type: actionTypes.ADMIN_FETCH_PLANS_SUCCESS,
    plansList
  };
};

const setPlansListFailed = (error) => {
  return {
    type: actionTypes.ADMIN_FETCH_PLANS_FAILED,
    error
  };
};

// clients list from gym
export const fetchGymUsersList = (gymId) => {
  return (dispatch) => {
    dispatch(setGymUsersListStart());

    axios
      .get(`/users/gym/${gymId}`)
      .then((response) =>
        dispatch(
          setClientsListSuccess(
            response.data,
            response.data.filter((elem) => elem.role === 'user' && elem.status === 'active')
          )
        )
      )
      .catch((error) => dispatch(setGymUsersListFailed(returnErrorFromResponse(error))));
  };
};

const setGymUsersListStart = () => {
  return {
    type: actionTypes.ADMIN_FETCH_GYM_USERS_START
  };
};

const setClientsListSuccess = (allClientsList, clientsList) => {
  return {
    type: actionTypes.ADMIN_FETCH_GYM_USERS_SUCCESS,
    allClientsList,
    clientsList
  };
};

const setGymUsersListFailed = (error) => {
  return {
    type: actionTypes.ADMIN_FETCH_GYM_USERS_FAILED,
    error
  };
};

// trainer list
export const fetchTrainersList = (gymId) => {
  return (dispatch) => {
    dispatch(setTrainersListStart());

    axios
      .get(`/users/gym/${gymId}/trainers`)
      .then((response) => {
        if (gymId === 'all') {
          dispatch(setTrainersFromAllGymsSuccess(response.data.filter((trainer) => trainer.status === 'active')));
        } else {
          dispatch(setTrainersListSuccess(response.data.filter((trainer) => trainer.status === 'active')));
        }
      })
      .catch((error) => dispatch(setTrainersListFailed(returnErrorFromResponse(error))));
  };
};

const setTrainersListStart = () => {
  return {
    type: actionTypes.ADMIN_SET_TRAINERS_START
  };
};

const setTrainersListSuccess = (trainersList) => {
  return {
    type: actionTypes.ADMIN_SET_TRAINERS_SUCCESS,
    trainersList
  };
};

const setTrainersFromAllGymsSuccess = (trainersFromAllGyms) => {
  return {
    type: actionTypes.ADMIN_SET_TRAINERS_FROM_ALL_GYMS_SUCCESS,
    trainersFromAllGyms
  };
};

const setTrainersListFailed = (error) => {
  return {
    type: actionTypes.ADMIN_SET_TRAINERS_FAILED,
    error
  };
};

// remove trainer
export const removeSingleTrainer = (trainer, gymId, gymsList) => {
  return (dispatch, getState) => {
    dispatch(setTrainersListStart());

    if (gymId === true) {
      axios
        .delete(`/users/${trainer._id}`)
        .then(() => {
          const updatedList = [...getState().admin.trainersList];
          const trainerToRemoveIndex = updatedList.findIndex((e) => e._id === trainer._id);
          updatedList.splice(trainerToRemoveIndex, 1);

          dispatch(setTrainersListSuccess(updatedList));
        })
        .catch((error) => dispatch(setTrainersListFailed(returnErrorFromResponse(error))));
    } else {
      gymsList = Object.keys(gymsList);
      let newAccess =
        trainer['gymsFullAccess'] === undefined || trainer.gymsFullAccess
          ? gymsList.filter((elem) => elem !== gymId)
          : trainer.gymId.filter((elem) => elem !== gymId);

      axios
        .patch(`/users/${trainer._id}`, {
          gymId: newAccess,
          gymsFullAccess: false
        })
        .then(() => {
          const updatedList = [...getState().admin.trainersList];
          const trainerToRemoveIndex = updatedList.findIndex((e) => e._id === trainer._id);
          updatedList.splice(trainerToRemoveIndex, 1);

          dispatch(setTrainersListSuccess(updatedList));
        })
        .catch((error) => dispatch(setTrainersListFailed(returnErrorFromResponse(error))));
    }
  };
};

// single client
export const fetchSingleClient = (userId) => {
  return (dispatch) => {
    dispatch(setSingleClientStart());

    axios
      .get(`/users/${userId}`)
      .then((response) => {
        if (response.data.length && response.data.length > 0) {
          dispatch(setSingleClientSuccess(response.data[0]));
        } else {
          dispatch(setSingleClientSuccess(response.data));
        }
      })
      .catch((error) => dispatch(setSingleClientError(returnErrorFromResponse(error))));
  };
};

export const singleClientDataReset = () => {
  return {
    type: actionTypes.ADMIN_FETCH_SINGLE_CLIENT_RESET
  };
};

const setSingleClientStart = () => {
  return {
    type: actionTypes.ADMIN_FETCH_SINGLE_CLIENT_START
  };
};

const setSingleClientSuccess = (client) => {
  return {
    type: actionTypes.ADMIN_FETCH_SINGLE_CLIENT_SUCCESS,
    client
  };
};

const setSingleClientError = (error) => {
  return {
    type: actionTypes.ADMIN_FETCH_SINGLE_CLIENT_FAILED,
    error
  };
};

// classes templates list
export const fetchClassesTemplatesList = (gymId) => {
  return (dispatch) => {
    dispatch(setClassesTemplatesListStart());

    axios
      .get(`/class-template/gym/${gymId}?classType=all`)
      .then((response) => {
        //response.data.filter
        return dispatch(setClassesTemplatesSuccess(response.data));
      })
      .catch((error) => dispatch(setClassesTemplatesFailed(returnErrorFromResponse(error))));
  };
};

export const removeSingleClassTemplate = (classTemplate) => {
  return (dispatch, getState) => {
    dispatch(setClassesTemplatesListStart());

    axios
      .delete(`/class-template/${classTemplate._id}`)
      .then(() => {
        const updatedList = [...getState().admin.classesTemplatesList];
        const classTemplateToRemoveIndex = updatedList.findIndex((e) => e._id === classTemplate._id);
        updatedList.splice(classTemplateToRemoveIndex, 1);

        dispatch(setClassesTemplatesSuccess(updatedList));
      })
      .catch((error) => dispatch(setClassesTemplatesFailed(returnErrorFromResponse(error))));
  };
};

const setClassesTemplatesListStart = () => {
  return {
    type: actionTypes.ADMIN_FETCH_CLASSES_TEMPLATES_START
  };
};

const setClassesTemplatesSuccess = (classesTemplatesList) => {
  return {
    type: actionTypes.ADMIN_FETCH_CLASSES_TEMPLATES_SUCCESS,
    classesTemplatesList
  };
};

const setClassesTemplatesFailed = (error) => {
  return {
    type: actionTypes.ADMIN_FETCH_CLASSES_TEMPLATES_FAILED,
    error
  };
};

// upcoming PTs
export const adminFetchUpcomingPts = (gymId, params) => {
  return (dispatch) => {
    dispatch(setAdminFetchUpcomingPtsStart());

    let url = `/class/gym/${gymId}/personalTraining`;

    if (params && Object.keys(params).length > 0) {
      url += '?';
      for (const param in params) {
        if (params.hasOwnProperty(param)) {
          const element = params[param];
          url += `${params[param]}=${element}&`;
        }
      }
    }

    axios
      .get(url)
      .then((response) => dispatch(setAdminFetchUpcomingPtsListSuccess(response.data)))
      .catch((error) => dispatch(setAdminFetchUpcomingPtsFailed(returnErrorFromResponse(error))));
  };
};

const setAdminFetchUpcomingPtsStart = () => {
  return {
    type: actionTypes.ADMIN_FETCH_UPCOMING_PTS_START
  };
};

const setAdminFetchUpcomingPtsListSuccess = (upcomingPts) => {
  return {
    type: actionTypes.ADMIN_FETCH_UPCOMING_PTS_SUCCESS,
    upcomingPts
  };
};

const setAdminFetchUpcomingPtsFailed = (error) => {
  return {
    type: actionTypes.ADMIN_FETCH_UPCOMING_PTS_FAILED,
    error
  };
};

// inductions
export const cancelClass = (gymId, classId, checkTiming = false) => {
  return (dispatch) => {
    dispatch(setCancelInductionStart());

    axios
      .delete(`/class/${classId}/checkTiming/${checkTiming}`)
      .then(() => {
        dispatch(setCancelInductionSuccess());
      })
      .catch((error) => dispatch(setCancelInductionFailed(returnErrorFromResponse(error))));
  };
};

const setCancelInductionStart = () => {
  return {
    type: actionTypes.ADMIN_CANCEL_CLASS_START
  };
};

const setCancelInductionSuccess = () => {
  return {
    type: actionTypes.ADMIN_CANCEL_CLASS_SUCCESS
  };
};

const setCancelInductionFailed = (error) => {
  return {
    type: actionTypes.ADMIN_CANCEL_CLASS_FAILED,
    error
  };
};

export const fetchGymUsersFullData = (gymId) => {
  return (dispatch) => {
    dispatch(setFetchGymUsersFullDataStart());

    axios
      .get(`users/gym/${gymId}`)
      .then((response) => {
        dispatch(setFetchGymUsersFullDataSuccess(response.data, gymId));
      })
      .catch((error) => {
        dispatch(setFetchGymUsersFullDataFailed(returnErrorFromResponse(error)));
      });
  };
};

const setFetchGymUsersFullDataStart = () => {
  return {
    type: actionTypes.FETCH_GYM_USERS_FULL_DATA_START
  };
};

const setFetchGymUsersFullDataSuccess = (gymsUsersFullData, gymId) => {
  return {
    type: actionTypes.FETCH_GYM_USERS_FULL_DATA_SUCCESS,
    gymsUsersFullData,
    gymId
  };
};

const setFetchGymUsersFullDataFailed = (error) => {
  return {
    type: actionTypes.FETCH_GYM_USERS_FULL_DATA_FAILED,
    error
  };
};
