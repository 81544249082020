import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from '../../../axios-global';
import Notification from 'grommet/components/Notification';
import Toast from 'grommet/components/Toast';
import CustomButton from '../../../components/CustomButton/CustomButton';
import FormCreatorSmall from '../../../components/UI/FormCreatorSmall/FormCreatorSmall';
import ProfilePicture from '../../../components/UI/ProfilePicture/ProfilePicture';

import { updateObject, checkValidity, returnErrorTextForField } from '../../../shared/utility';

import * as actions from '../../../store/actions';

import classes from '../User/UserSettings.module.scss';
import Title from '../../../components/UI/Title/Title';
import Loading from '../../../components/Loading/Loading';

const cloneDeep = require('lodash.clonedeep');

const mapStateToProps = (state) => {
  return {
    userData: state.auth.userData
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFetchUserDetails: () => dispatch(actions.fetchUserDetails())
  };
};

class UserSettings extends Component {
  state = {
    personalDetails: {
      name: {
        label: 'Name',
        type: 'text',
        placeholder: 'Enter name',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      last_name: {
        label: 'Last name',
        type: 'text',
        placeholder: 'Enter last name',
        value: '',
        validation: {
          required: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      email: {
        label: 'E-mail',
        type: 'text',
        placeholder: 'Enter e-mail',
        value: '',
        validation: {
          required: true,
          isEmail: true
        },
        valid: false,
        touched: false,
        errorText: null
      },
      phone_number: {
        label: 'Phone number',
        type: 'text',
        placeholder: 'Enter telephone number',
        value: '',
        validation: {
          required: true,
          isUkTelephone: true
        },
        valid: false,
        touched: false,
        errorText: null
      }
    },

    formIsValid: true,
    notificationText: null,

    newDataSaving: false,

    profilePictureLoading: false,

    toast: null,
    toastText: null
  };

  componentDidMount() {
    this.assignExistingValues();
  }

  assignExistingValues = () => {
    const formNames = Object.keys(this.props.userData);
    const previousValues = Object.values(this.props.userData);
    const copiedPersonalDetailsForm = cloneDeep(this.state.personalDetails);

    formNames.forEach((element, index) => {
      if (copiedPersonalDetailsForm.hasOwnProperty(element) && copiedPersonalDetailsForm[element] !== undefined) {
        copiedPersonalDetailsForm[element] = {
          ...copiedPersonalDetailsForm[element],
          value: previousValues[index],
          valid: true,
          touched: true
        };
      }
    });

    this.setState({
      personalDetails: copiedPersonalDetailsForm
    });
  };

  personalDetailsValueChangedHandler = (newValue, identifier) => {
    const updatedPersonalDetails = updateObject(this.state.personalDetails, {
      [identifier]: updateObject(this.state.personalDetails[identifier], {
        value: newValue,
        valid: checkValidity(newValue, this.state.personalDetails[identifier].validation),
        errorText: returnErrorTextForField(this.state.personalDetails[identifier].validation),
        touched: true
      })
    });

    return this.setState({
      personalDetails: updatedPersonalDetails
    });
  };

  checkFormValidity = () => {
    this.setState({
      formIsValid: true,
      notificationText: null,
      newDataSaving: true
    });

    for (let input in this.state.personalDetails) {
      if (!this.state.personalDetails[input].valid) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        const updatedPersonalDetails = updateObject(this.state.personalDetails, {
          [input]: updateObject(this.state.personalDetails[input], {
            valid: checkValidity(this.state.personalDetails[input].value, this.state.personalDetails[input].validation),
            errorText: returnErrorTextForField(this.state.personalDetails[input].validation),
            touched: true
          })
        });
        return this.setState({
          personalDetails: updatedPersonalDetails,
          formIsValid: false,
          notificationText: 'Form is not filled in correctly.',
          newDataSaving: false
        });
      }
    }

    return this.setState(
      {
        formIsValid: true,
        notificationText: null
      },
      () => {
        if (this.state.formIsValid) {
          this.saveAccountDetails();
        }
      }
    );
  };

  saveAccountDetails = () => {
    const newData = {};
    for (let input in this.state.personalDetails) {
      newData[input] = this.state.personalDetails[input].value;
    }

    newData.gymId = this.state.chosenGymId;
    newData.marketing = this.state.marketing;

    axios
      .patch(`/users/${this.props.userData._id}`, newData)
      .then(() => {
        this.setState({
          newDataSaving: false,
          toast: true,
          toastText: 'Profile updated',
          toastStatus: 'ok'
        });
        this.props.onFetchUserDetails();
      })
      .catch((error) => this.passErrorMessagesToForm(error));
  };

  passErrorMessagesToForm = (error) => {
    if (error.response && error.response.status === 500) {
      return this.setState({
        formIsValid: false,
        newDataSaving: false,
        notificationText: 'Server error.'
      });
    }

    const copiedPersonalDetailsForm = cloneDeep(this.state.personalDetails);

    error.response.data.errors.forEach((element) => {
      if (
        this.state.personalDetails.hasOwnProperty(element.field) &&
        this.state.personalDetails[element.field] !== undefined
      ) {
        copiedPersonalDetailsForm[element.field] = {
          ...copiedPersonalDetailsForm[element.field],
          valid: false,
          errorText: element.message,
          touched: true
        };
      }
    });

    this.setState({
      personalDetails: copiedPersonalDetailsForm
    });
  };

  onNewPhotoUpload = (formData) => {
    this.setState({
      profilePictureLoading: true
    });

    axios
      .put(`/users/${this.props.userData._id}/image`, formData)
      .then((resp) => {
        axios
          .patch(`/users/${this.props.userData._id}/image`, {
            imageUrl: resp.data.imageUrl
          })
          .then(() => {
            this.setState({
              toast: true,
              toastText: 'Picture changed',
              toastStatus: 'ok',
              profilePictureLoading: false
            });
            this.props.onFetchUserDetails();
          });
      })
      .catch(() => {
        this.setState({
          profilePictureLoading: false
        });
      });
  };

  returnNotification = () => {
    if (!this.state.formIsValid) {
      return <Notification message={this.state.notificationText} status="warning" className="ss-top-notification" />;
    }
  };

  closeToast = () => {
    this.setState({
      toast: null,
      toastText: null,
      toastStatus: 'ok'
    });
  };

  returnToast = () => {
    if (this.state.toast) {
      return (
        <Toast status={this.state.toastStatus} onClose={this.closeToast}>
          {this.state.toastText}
        </Toast>
      );
    }

    return null;
  };

  render() {
    return (
      <div data-cy="settings" className="container" style={{ marginTop: '50px', maxWidth: '800px' }}>
        <Title header="Account settings" subHeader="Your account settings" />
        {this.returnNotification()}
        {this.returnToast()}

        <div className={classes.formPhotoContainer}>
          <div className={classes.profilePictureInfo}>
            <ProfilePicture
              editable={this.props.userData.image ? false : true}
              uploadNewPhoto={this.onNewPhotoUpload}
              src={this.props.userData.image ? this.props.userData.image : null}
              profilePictureLoading={this.state.profilePictureLoading}
            />
            {!this.props.userData.image && (
              <span>
                Upload a photo from your gallery or take a quick snap. We love to see your smiley face so make sure we
                can see your whole face. <br />
                You can only upload your photo once.
              </span>
            )}
          </div>

          <FormCreatorSmall
            formData={this.state.personalDetails}
            valueChanged={this.personalDetailsValueChangedHandler}
          />
        </div>

        {this.props.userData.role !== 'admin' && (
          <>
            {this.state.newDataSaving ? (
              <Loading />
            ) : (
              <CustomButton
                style={{
                  margin: '20px 0',
                  display: 'block',
                  width: '100%',
                  maxWidth: '100%'
                }}
                label="Save settings"
                secondary={true}
                onClick={this.checkFormValidity}
              />
            )}
          </>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
