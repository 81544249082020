import React, { useState } from 'react';

import Modal from 'react-modal';
import CustomButton from '../../../components/CustomButton/CustomButton';
import HealthStatement from '../../../../src/brandConfig/componentsTexts/HealthStatement';

import TermsOfService from '../../TermsOfService/TermsOfService';

import classes from './StepThird.module.scss';

const StepThird = ({ dataAccepted, termsUrl, history, locationHome }) => {
  const [showModal, setShowModal] = useState(true);
  const [termsError, setTermsError] = useState(true);
  const [healthStatementAccepted, setHealthStatementAccepted] = useState(false);
  const [termsOfServiceAccepted, setTermsOfServiceAccepted] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const handlePrivacyCheckbox = () => {
    setTermsOfServiceAccepted(!termsOfServiceAccepted);
  };

  const handleHealthStatementCheckbox = () => {
    setHealthStatementAccepted(!healthStatementAccepted);
  };

  const cancelTermsInModal = () => {
    setShowModal(false);
    setTermsOfServiceAccepted(false);
  };

  const acceptTermsInModal = () => {
    setShowModal(false);
    setTermsOfServiceAccepted(true);
  };

  const renderButtonOrInfo = () => {
    if (healthStatementAccepted && termsOfServiceAccepted) {
      return (
        <CustomButton
          dataCy="next_step_btn"
          className={classes.nextStepButton}
          box
          label="Next step"
          secondary={true}
          onClick={dataAccepted}
        />
      );
    }
  };
  return (
    <>
      <Modal appElement={document.getElementById('root')} isOpen={showModal} className="ss-modal">
        <div className={`grommet ${classes.textContainer}`} data-cy="signup_accept_tnc_modal">
          <TermsOfService termsUrl={termsUrl} termsLoadFailed={(error) => setTermsError(error)} />
        </div>
        {!termsError ? (
          <>
            <CustomButton
              className={classes.nextStepButton}
              box
              dataCy={'terms_of_service_agree'}
              label="Agree"
              secondary={true}
              onClick={acceptTermsInModal}
            />
            <CustomButton
              dataCy={'terms_of_service_cancel'}
              className={classes.nextStepButton}
              box
              label="Cancel"
              primary
              onClick={cancelTermsInModal}
            />
          </>
        ) : (
          <CustomButton
            className={classes.nextStepButton}
            box
            dataCy={'terms_of_service_ok'}
            label="Ok"
            secondary={true}
            onClick={() => history.push(locationHome)}
          />
        )}
      </Modal>

      <HealthStatement
        healthStatementAccepted={healthStatementAccepted}
        termsOfServiceAccepted={termsOfServiceAccepted}
        handleHealthStatementCheckbox={handleHealthStatementCheckbox}
        handlePrivacyCheckbox={handlePrivacyCheckbox}
        openModal={openModal}
      />

      {renderButtonOrInfo()}
    </>
  );
};
export default StepThird;
