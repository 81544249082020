import React from 'react';
import { Table, TableHeader, TableRow } from 'grommet';
import classes from './TablePassesView.module.scss';
import { getCurrencySymbol } from '../../../../shared/utility';
import RenderPassActionButtons from '../../RenderPassActionButtons/RenderPassActionButtons';
import { withRouter } from 'react-router-dom';

const TablePassesView = ({ data, location, classType, setShowAddEditPass, setSelectedPass, history }) => {
  const columnLabels =
    classType === 'personalTraining'
      ? ['Name', 'Places', 'Duration', 'Classes', 'Price', 'One Off', 'Active', 'Display Order', 'Action']
      : ['Name', 'Classes', 'Price', 'One Off', 'Active', 'Display Order', 'Action'];

  const goBackToPreviousPage = () =>
    location && location.state && location.state.goBackToPreviousPage ? history.goBack() : null;

  const renderPasses = () => {
    return data.map((element) => {
      return (
        <TableRow key={element._id} data-cy="singleClassPass" className={!element.active && classes.inactiveRow}>
          <td className={classes.nameColumnWidth}>{element.name}</td>
          {classType === 'personalTraining' && <td>{element.places}</td>}

          {classType === 'personalTraining' && <td>{element.duration}</td>}
          <td>{element.classCount}</td>
          <td>
            {getCurrencySymbol(element.currency)} {element.cost}
          </td>
          <td>{element.oneOff ? 'Yes' : 'No'}</td>
          <td>{element.active ? 'Yes' : 'No'}</td>
          <td>{element.displayOrder}</td>
          <td>
            <RenderPassActionButtons
              classPass={element}
              classType={classType}
              editPass={(element) => {
                setSelectedPass(element);
                setShowAddEditPass(true);
              }}
              goBackToPreviousPage={goBackToPreviousPage}
            />
          </td>
        </TableRow>
      );
    });
  };

  return (
    <Table responsive={false}>
      <TableHeader labels={columnLabels} />
      <tbody>{renderPasses()}</tbody>
    </Table>
  );
};

export default withRouter(TablePassesView);
