import { ALL_CURRENCIES } from '../../../constants';
import { getCurrencyDetail } from '../../../shared/utility';

const unEditableFields = ['currency', 'discountValue', 'duration', 'discountType', 'active', 'couponType', 'oneOff'];
export const returnCouponFields = (data = null) => {
  let result = {
    name: {
      label: 'Name',
      type: 'text',
      placeholder: 'Enter name',
      value: data !== null ? data.name : null,
      validation: {
        required: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    description: {
      label: 'Description',
      type: 'text',
      placeholder: 'Enter description',
      value: data && data.description ? data.description : null,
      validation: {
        required: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    discountType: {
      label: 'Discount Type',
      type: 'select',
      options: ['amount'],
      placeholder: 'Select',
      value: data && data.discountType ? data.discountType : 'amount',
      validation: {
        required: true
      },
      valid: true,
      touched: false,
      errorText: null
    },
    discountValue: {
      label: 'Discount Value',
      type: 'text',
      placeholder: 'Enter discount value',
      value: data && data.discountValue !== undefined ? data.discountValue : null,
      validation: {
        required: true,
        isNumericDecimal: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    currency: {
      label: 'Currency',
      type: 'select',
      options: ALL_CURRENCIES,
      placeholder: 'Select',
      value: getCurrencyDetail(data && data.currency),
      validation: {
        required: true
      },
      valid: true,
      touched: false,
      errorText: null
    },
    displayOrder: {
      label: 'Display order',
      type: 'text',
      placeholder: 'Display order',
      value: data ? data.displayOrder : null,
      validation: {
        required: true,
        isNumericWithLimit: true
      },
      valid: !!data,
      touched: false,
      errorText: null
    },
    couponType: {
      label: 'Coupon Type',
      type: 'select',
      options: ['all', 'classes', 'classPasses', 'membershipPlans'],
      placeholder: 'Select',
      value: data && data.couponType ? data.couponType : 'all',
      validation: {
        required: true
      },
      valid: true,
      touched: false,
      errorText: null
    },
    oneOff: {
      label: 'One Off',
      type: 'select',
      options: ['yes', 'no'],
      placeholder: 'Select',
      value: data && data.oneOff != null ? (data.oneOff ? 'yes' : 'no') : 'yes',
      validation: {
        required: true
      },
      valid: true,
      touched: false,
      errorText: null
    }
  };

  if (data && unEditableFields) {
    unEditableFields.forEach((elem) => delete result[elem]);
  }

  return result;
};
