import React, { Component } from 'react';
import classes from './StaffCalendar.module.scss';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Select from 'grommet/components/Select';
import DayView from './DayView/DayView';
import WeekView from './WeekView/WeekView';
import LinkNextIcon from 'grommet/components/icons/base/LinkNext';
import LinkPreviousIcon from 'grommet/components/icons/base/LinkPrevious';
import RefreshIcon from 'grommet/components/icons/base/Refresh';
import DateTime from 'grommet/components/DateTime';
import axios from '../../../axios-global';
import withUser from '../../../store/hoc/withUser';
import Loading from '../../../components/Loading/Loading';
import Modal from 'react-modal';
import ClassDetailModal from './ClassDetailModal/ClassDetailModal';
import UsersDayEventModal from './UsersDayEventModal/UsersDayEventModal';

export class StaffCalendar extends Component {
  state = {
    currentView: 'Day',
    loadingEvents: true,
    dayTimetable: {
      date: moment().format('DD.MM.yyyy')
    },
    weekTimeTable: {
      startDate: moment().startOf('isoWeek').format('DD.MM.YYYY'),
      endDate: moment().endOf('isoWeek').format('DD.MM.YYYY')
    },
    timetable: [],
    trainers: [],
    selectedTrainer: [],
    showClassDetail: null,
    showDayModal: false,
    dayEvents: []
  };

  componentDidMount() {
    this.getTrainers();
  }

  getEvents = async (silent) => {
    if (!silent) {
      this.setState({ loadingEvents: true });
    }
    let data = {
      type: this.state.currentView.toLowerCase(),
      date: moment(this.state.dayTimetable.date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      startDate: moment(this.state.weekTimeTable.startDate, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      endDate: moment(this.state.weekTimeTable.endDate, 'DD.MM.YYYY').format('YYYY-MM-DD')
    };

    if (this.state.selectedTrainer.length > 0) {
      data['trainers'] = this.state.selectedTrainer.map((trainer) => trainer.value);
    }

    const response = await axios.get(
      `/class/gym/${this.props.selectedGym._id}/calendar?${new URLSearchParams(data).toString()}`,
      data
    );

    let filteredTrainers = this.state.trainers.filter((trainer) => {
      return !response.data.some((timetable) => timetable.leader._id === trainer.value);
    });
    if (this.state.selectedTrainer.length > 0) {
      filteredTrainers = filteredTrainers.filter((selectedTrainer) => {
        return this.state.selectedTrainer.some((trainer) => trainer.value === selectedTrainer.value);
      });
    }
    const availableTrainers = filteredTrainers.map((trainer) => {
      return {
        leader: {
          _id: trainer.value,
          name: trainer.name,
          last_name: trainer.last_name
        },
        classes: []
      };
    });

    this.setState({
      timetable: response.data.concat(availableTrainers),
      loadingEvents: false
    });
  };

  getTrainers = async () => {
    const url = this.props.isAdmin ? `/users/trainers` : `/users/trainers?gymId=${this.props.selectedGym._id}`;
    const response = await axios.get(url);
    this.setState({
      trainers: response.data.map((trainer) => {
        return {
          value: trainer._id,
          name: trainer.name,
          last_name: trainer.last_name,
          label: `${trainer.name} ${trainer.last_name}`
        };
      })
    });
    this.getEvents();
  };

  changeView = (view) => {
    this.setState({ currentView: view }, () => {
      this.getEvents(true);
    });
  };

  dateChange = (date) => {
    if (this.state.currentView === 'Week') {
      const startDate = moment(date, 'DD.MM.YYYY').startOf('isoWeek').format('DD.MM.YYYY');
      const endDate = moment(date, 'DD.MM.YYYY').endOf('isoWeek').format('DD.MM.YYYY');
      const weekTimeTable = { ...this.state.weekTimeTable };
      weekTimeTable.startDate = startDate;
      weekTimeTable.endDate = endDate;
      this.setState({ weekTimeTable });
      this.getEvents(true);
    } else {
      const dayTimetable = { ...this.state.dayTimetable };
      dayTimetable.date = date;
      this.setState({ dayTimetable }, () => {
        this.getEvents(true);
      });
    }
  };

  nextPreviousClicked = (type) => {
    if (this.state.currentView === 'Week') {
      const startDate = moment(
        type === 'previous' ? this.state.weekTimeTable.startDate : this.state.weekTimeTable.endDate,
        'DD.MM.YYYY'
      )
        .add(type === 'previous' ? -1 : +1, 'days')
        .startOf('isoWeek')
        .format('DD.MM.YYYY');
      const endDate = moment(startDate, 'DD.MM.YYYY').endOf('isoWeek').format('DD.MM.YYYY');
      const weekTimeTable = { ...this.state.weekTimeTable };
      weekTimeTable.startDate = startDate;
      weekTimeTable.endDate = endDate;
      this.setState({ weekTimeTable }, () => {
        this.getEvents(true);
      });
    } else {
      const nextDate = moment(this.state.dayTimetable.date, 'DD.MM.YYYY')
        .add(type === 'previous' ? -1 : +1, 'days')
        .format('DD.MM.YYYY');
      const dayTimetable = { ...this.state.dayTimetable };
      dayTimetable.date = nextDate;
      this.setState({ dayTimetable }, () => {
        this.getEvents(true);
      });
    }
  };

  trainerSelected = (trainer) => {
    this.setState({ selectedTrainer: trainer.value }, () => {
      this.getEvents(true);
    });
  };

  searchTrainer = (e) => {
    const filter = e.target.value.toLowerCase();
    const filteredTrainerList = this.state.trainers.filter(
      (trainer) => trainer.name.toLowerCase().includes(filter) || trainer.last_name.toLowerCase().includes(filter)
    );
    this.setState({ filteredTrainerList });
  };

  calendarHeader = () => (
    <div className={`${classes.flexContainer} ${classes.calendarHeader}`}>
      <div className={classes.trainerContainer}>
        <Select
          placeHolder="Trainer: all"
          multiple={true}
          options={this.state.filteredTrainerList || this.state.trainers}
          label={'name'}
          value={this.state.selectedTrainer}
          onChange={this.trainerSelected}
          onSearch={this.searchTrainer}
        />
        {this.state.selectedTrainer.length > 0 && (
          <RefreshIcon className={classes.resetTrainer} onClick={() => this.trainerSelected({ value: [] })} />
        )}
      </div>
      <div className={classes.calendarDate}>
        <LinkPreviousIcon onClick={() => this.nextPreviousClicked('previous')} />
        <span className={classes.date}>
          {this.state.currentView === 'Day'
            ? this.state.dayTimetable.date
            : `${this.state.weekTimeTable.startDate.substring(0, 2)}-${this.state.weekTimeTable.endDate}`}
        </span>
        <div>
          <DateTime
            id="id"
            name="name"
            format="DD.MM.yyyy"
            value={this.state.timetable.date}
            className={classes.datePicker}
            onChange={this.dateChange}
          />
        </div>
        <LinkNextIcon onClick={() => this.nextPreviousClicked('next')} />
      </div>
      <div>
        <CustomButton label="Day" onClick={() => this.changeView('Day')} primary={this.state.currentView === 'Day'} />
        <CustomButton
          label="Week"
          onClick={() => this.changeView('Week')}
          primary={this.state.currentView === 'Week'}
        />
      </div>
    </div>
  );

  calendar = () => {
    if (this.state.loadingEvents) {
      return <Loading />;
    } else {
      return (
        <div className={classes.flexContainer}>
          {this.state.currentView === 'Day' && (
            <DayView timetable={this.state.timetable} showClassDetail={this.openClassDetailModal} />
          )}
          {this.state.currentView === 'Week' && (
            <WeekView timetable={this.state.timetable} openDayInModal={this.openDayInModal} />
          )}
        </div>
      );
    }
  };

  openClassDetailModal = (classObj) => this.setState({ showClassDetail: classObj });

  openDayInModal = (event) => this.setState({ showDayModal: true, dayEvents: event });

  dayViewInModal = () => (
    <Modal
      onRequestClose={() => this.setState({ showDayModal: false })}
      isOpen={this.state.showDayModal}
      className="ss-modal">
      <UsersDayEventModal
        timetable={this.state.dayEvents}
        showClassDetail={this.openClassDetailModal}
        onClose={() => this.setState({ showDayModal: false })}
      />
    </Modal>
  );

  classDetailModal = () => (
    <Modal
      onRequestClose={() => this.setState({ showClassDetail: null })}
      isOpen={this.state.showClassDetail !== null}
      className="ss-modal">
      <ClassDetailModal
        event={this.state.showClassDetail}
        history={this.props.history}
        onClose={() => this.setState({ showClassDetail: null })}
      />
    </Modal>
  );

  render() {
    return (
      <div data-cy={'staff_calendar'}>
        {this.dayViewInModal()}
        {this.classDetailModal()}
        {this.calendarHeader()}
        {this.calendar()}
      </div>
    );
  }
}

export default withRouter(withUser(StaffCalendar));
