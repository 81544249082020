import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { fetchClasses } from '../../../../apiFunctions/apiFunctions';
import { returnCombinedClassesList } from '../../../../shared/classFunctions';
import Loading from '../../../../components/Loading/Loading';
import classes from './StepTwo_ClassSelector.module.scss';
import { moveClientsTableData, moveClientsTableHeaderLabels } from '../table/table';
import CustomButton from '../../../../components/CustomButton/CustomButton';
import Notification from 'grommet/components/Notification';
import moment from 'moment';
import CalendarHeader from '../../../../components/CalendarHeader/CalendarHeader';

const daysJumpValue = 7;

const StepTwo_ClassSelector = ({ onSelect, currentClass, selectedClient, nextStep }) => {
  const [selectedClass, setSelectedClass] = useState(null);
  const [startDate, setStartDate] = useState(moment());

  const {
    data: classesList,
    error: classesListError,
    isLoading: classesListLoading
  } = useQuery(
    ['upcomingClasses', startDate],
    () =>
      fetchClasses(
        currentClass.__t,
        currentClass.gymId._id,
        moment(startDate).format('YYYY-MM-DD'),
        moment(startDate).add(daysJumpValue, 'd').format('YYYY-MM-DD')
      ),
    {}
  );

  const changeDay = (value) => {
    setStartDate((state) => moment(state, 'DD-MM-YYYY').add(value * daysJumpValue, 'd'));
  };

  const handleNextBtnClick = () => {
    nextStep(3);
    onSelect(selectedClass);
  };

  const returnNextBt = () => (
    <CustomButton
      label="Next step"
      secondary
      className={classes.nextBtn}
      disabled={selectedClass === null}
      onClick={handleNextBtnClick}
    />
  );
  const returnCurrentClassDetails = () => (
    <div>
      <div className={classes.title}>Current:</div>
      <div>{moveClientsTableHeaderLabels()}</div>
      <div className={classes.currentClass}>
        {moveClientsTableData(selectedClass, selectedClient, [currentClass], false)}
      </div>
    </div>
  );

  const returnUpcomingClassesDetails = () => {
    let classesData = <div className={classes.noData}>No bookings</div>;
    if (classesListLoading) {
      classesData = <Loading centered size="xlarge" />;
    }
    if (classesListError) {
      classesData = (
        <Notification message="Something went wrong, try again." status="critical" className="ss-top-notification" />
      );
    }
    if (classesList && classesList.length > 0) {
      const filteredClasses = returnCombinedClassesList(classesList).filter((elem) => elem._id !== currentClass._id);
      classesData =
        filteredClasses.length > 0 ? (
          <div>
            <div>{moveClientsTableHeaderLabels()}</div>
            {moveClientsTableData(selectedClass, selectedClient, filteredClasses, true, (value) =>
              setSelectedClass(value)
            )}
          </div>
        ) : (
          <div className={classes.noData}>No bookings</div>
        );
    }
    return (
      <div>
        <div className={classes.title}>Please select:</div>
        <CalendarHeader
          startDate={startDate}
          daysJumpValue={daysJumpValue}
          onLeftArrow={() => changeDay(-1)}
          onRightArrow={() => changeDay(1)}
        />
        {classesData}
      </div>
    );
  };

  return (
    <div>
      {returnNextBt()}
      {returnCurrentClassDetails()}
      {returnUpcomingClassesDetails()}
    </div>
  );
};

export default StepTwo_ClassSelector;
