import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from '../../axios-global';
import location from './location.module.scss';
import CustomButton from '../../components/CustomButton/CustomButton';
import Error404 from '../../components/Error404/Error404';
import { withRouter, Route, Switch } from 'react-router-dom';
import * as actions from '../../store/actions';
import PublicClassPage from '../PublicClassPage/PublicClassPage';
import Title from '../../components/UI/Title/Title';
import WhiteBackground from '../../hoc/WhiteBackground/WhiteBackground';
import ManageFAQ from '../FAQ/ManageFAQ';
import Loading from '../../components/Loading/Loading';
import classes from '../../../src/brandConfig/components/HomePage/Hero.module.scss';
import { Table, TableHeader, TableRow } from 'grommet';
import moment from 'moment';

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
    userData: state.auth.userData,
    selectedGym: state.admin.selectedGym,

    cityNames: state.location.cityNames,
    cityNamesLoading: state.location.cityNamesLoading,
    cityNamesError: state.location.cityNamesError
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    onFetchGymDetails: (gymId) => dispatch(actions.fetchGymDetails(gymId))
  };
};

class Location extends Component {
  state = {
    gym: null,
    loading: true,
    err: null,
    errCityName: false,
    eventsArray: []
  };

  componentDidMount() {
    if (this.props.match.params.city !== 'gym') {
      axios
        .get(`/gym/location/${this.props.match.params.city}/${this.props.match.params.urlName}`, {})
        .then((resp) => {
          this.props.onFetchGymDetails(resp.data.gymId);
          this.getAllEvents(resp.data.gymId);
          this.setState({
            loading: false,
            gym: resp.data
          });
        })
        .catch((err) => {
          this.setState({
            loading: false,
            err: err.response
          });
        });
    }
  }

  getAllEvents = (gymId) => {
    this.setState({
      loading: true
    });

    axios
      .get(`/class/public/all?locationId=${gymId}&fromDate=${moment().format('YYYY-MM-DD')}`, {})
      .then((respEvents) => {
        this.setState({
          loading: false
        });

        let dayViseEvents = respEvents.data;
        dayViseEvents.forEach((day) => {
          if (day.events.length !== 0) {
            day.events.forEach((event) => {
              this.setState({
                eventsArray: this.state.eventsArray.concat([event])
              });
            });
          }
        });
      })
      .catch((err) => {
        this.setState({
          loading: false,
          err: err.response
        });
      });
  };

  cityNameIsCorrect = () => {
    let smallLetters = this.props.cityNames.map((city) => city.toLowerCase());
    return smallLetters.includes(this.props.match.params.city);
  };

  renderRoutes = () => {
    return (
      <>
        <Switch>
          <Route path={this.props.match.url} exact render={() => this.showGymHomepage()} />

          {this.state.gym && (
            <>
              <Route
                path={`${this.props.match.url}/faq`}
                exact
                render={() => (
                  <WhiteBackground>
                    <Title
                      header="FAQ"
                      subHeader="See the FAQs below for further information about the services we offer."
                    />
                    <ManageFAQ selectedGymId={this.state.gym.gymId} />
                  </WhiteBackground>
                )}
              />

              <Route path={`${this.props.match.url}/class/:classId`} exact render={PublicClassPage} />
            </>
          )}
        </Switch>
      </>
    );
  };

  eventsTable = () => {
    const tableLabels = ['Name', 'Start Date', 'Start Time', 'End Date', 'Duration', 'Trainer'];
    const renderRows = () =>
      this.state.eventsArray.map((elem) => (
        <TableRow direction="column" align="start" separator="horizontal" key={elem.eventId}>
          <td>{elem.name}</td>
          <td> {moment(elem.startDate).format('DD-MM-YYYY')}</td>
          <td> {elem.startTime}</td>
          <td>{moment(elem.endDate).format('DD-MM-YYYY')}</td>
          <td>{elem.duration}</td>
          <td>{elem.staff && elem.staff.name ? elem.staff.name : ''}</td>
        </TableRow>
      ));

    return this.state.eventsArray.length > 0 ? (
      <div style={{ marginBottom: 10 }}>
        <h2>Upcoming events</h2>
        <Table responsive={true}>
          <TableHeader labels={tableLabels} />
          <tbody>{renderRows()}</tbody>
        </Table>
      </div>
    ) : (
      <></>
    );
  };

  showGymHomepage = () => {
    let gymNameClasses = [location.hero__header];
    if (this.state.gym) {
      return (
        <div>
          {!this.props.isAuthenticated && (
            <div>
              <div className={location.hero}>
                <div className="container">
                  <h1 className={gymNameClasses}>
                    Welcome to <br />
                    {this.state.gym.gymName}
                  </h1>

                  <div className={location.hero__box}>
                    <h2 className={classes.box__header}>Sign up</h2>

                    <p className={location.box__additionalInfo}>Sign up here.</p>

                    <div className={location.boxesContainer}>
                      <CustomButton
                        primary={true}
                        label="LOGIN"
                        className={`${location.visibleMobile} ${location.btnLogin}`}
                        onClick={() => this.props.history.push(`/login`)}
                      />
                      <CustomButton
                        label="SIGN UP"
                        onClick={() => this.props.history.push(`/gym/${this.state.gym.gymId}/signup`)}
                        primary={true}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">{this.eventsTable()}</div>
            </div>
          )}
        </div>
      );
    }

    if (this.cityNameIsCorrect()) {
      return (
        <div className={location.incorrectBackground}>
          <div className={location.hero__box}>
            <h2 className={location.box__header}>URL ADDRESS IS INCORRECT</h2>

            <p className={location.box__additionalInfo}>
              Please check the URL address or contact us for more information.
            </p>

            <div className={location.boxesContainer}>
              <CustomButton label="CONTACT US" onClick={() => this.props.history.push('/contact')} primary={true} />
            </div>
          </div>
        </div>
      );
    }
    return <Error404 />;
  };

  render() {
    if (this.props.cityNamesLoading || this.state.loading) {
      return <Loading size="medium" />;
    }

    return this.renderRoutes();
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Location));
