import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import Heading from 'grommet/components/Heading';
import List from 'grommet/components/List';
import ListItem from 'grommet/components/ListItem';
import Loading from '../Loading/Loading';
import Notification from 'grommet/components/Notification';
import TextInput from 'grommet/components/TextInput';

import classes from './GymList.module.scss';
import CustomButton from '../CustomButton/CustomButton';
import { isFullAccessAdminOrTrainer } from '../../shared/userFunctions';

const mapStateToProps = (state) => {
  return {
    loading: state.location.loading,
    gymLocations: state.location.locations,
    error: state.location.error,
    userData: state.auth.userData,
    isFullAccessAdmin:
      state.auth.userData && state.auth.userData.role === 'admin' && isFullAccessAdminOrTrainer(state.auth.userData)
  };
};

const GymList = ({ gymsList, loading, error, gymSelected, gymLocations, history, header, isFullAccessAdmin }) => {
  const [filteredList, setFilteredList] = useState([]);
  useEffect(() => {
    setFilteredList(gymsList);
  }, [gymsList]);
  let locations = <Loading />;
  if (!loading && error) {
    locations = <Notification message={error} status="critical" />;
  }

  if (!loading && !error && filteredList) {
    locations = filteredList.map((element) => (
      <ListItem
        key={element._id}
        direction="column"
        align="start"
        separator="horizontal"
        onClick={() => gymSelected(element._id)}>
        <Heading tag="h3" className={classes.tableListHeader}>
          {element.name}
        </Heading>
        <span>
          {element.city}, {element.address}
        </span>
      </ListItem>
    ));
  }
  if (!loading && gymLocations && gymLocations.length === 0) {
    locations = 'No gyms';
  }
  if (!loading && gymLocations && gymLocations.length === 1) {
    gymSelected(gymLocations[0]._id);
  }
  const onSearchChanged = (e) => {
    const searchText = e.target.value.toLowerCase();
    const filteredArray = gymsList.filter((location) => {
      return location.name.toLowerCase().includes(searchText);
    });
    setFilteredList(filteredArray);
  };
  const searchGym = () => {
    return (
      <div className={classes.filter}>
        <h4>Filter by name</h4>
        <TextInput placeHolder="Gym name" onDOMChange={onSearchChanged} />
      </div>
    );
  };

  const addNewGymButton = isFullAccessAdmin && (
    <CustomButton
      style={{ marginTop: '50px', display: 'inline-block' }}
      label="Add"
      secondary={true}
      onClick={() => history.push('/add-gym')}
    />
  );

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h2>{header}</h2>
        {addNewGymButton}
      </div>
      {searchGym()}
      <List data-cy="gymlist">{locations}</List>
    </div>
  );
};

export default connect(mapStateToProps)(GymList);
