import React, { useState } from 'react';
import axios from '../../../axios-global';
import ProfilePicture from '../../UI/ProfilePicture/ProfilePicture';
import ProfileTextWithLabel from '../../UI/ProfileTextWithLabel/ProfileTextWithLabel';
import './ClientProfileMainDetails.scss';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Modal from 'react-modal';
import Toast from 'grommet/components/Toast';
import { CLIENTS_TYPES } from '../../../constants';

const ClientProfileMainDetails = (props) => {
  const [profilePictureLoading, setProfilePictureLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [toastText, setToastText] = useState(null);
  const [toastStatus, setToastStatus] = useState(null);
  const [toast, setToast] = useState(null);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const onNewPhotoUpload = async (formData) => {
    setProfilePictureLoading(true);

    try {
      const imageUrlResp = await axios.put(`/users/${props.selectedClient._id}/image`, formData);
      const savedPictureResp = await axios.patch(`/users/${props.selectedClient._id}/image`, {
        imageUrl: imageUrlResp.data.imageUrl
      });
      props.onSuccessfulSave(savedPictureResp);
      props.fetchSingleClient();
    } catch (error) {
      props.onFailedSave(error);
    } finally {
      setProfilePictureLoading(false);
    }
  };

  const closeToast = () => {
    setToast(null);
    setToastText(null);
    setToastStatus('ok');
  };

  const returnToast = () => {
    if (toast) {
      return (
        <Toast status={toastStatus} onClose={closeToast}>
          {toastText}
        </Toast>
      );
    }

    return null;
  };

  const recordAttandance = () =>
    axios
      .post(`/gym-attendances`, {
        attendanceTime: new Date(),
        userId: props.selectedClient._id,
        gymId: props.selectedClient.gymId
      })
      .then(() => {
        setToast(true);
        setToastText('Attendance recorded successfully');
        setToastStatus('ok');
        setShowModal(false);
      })
      .catch((err) => {
        props.onFailedSave(err);
        setShowModal(false);
      });

  return (
    <>
      {returnToast()}
      <Modal onRequestClose={handleClose} isOpen={showModal} className="ss-modal">
        <div className="grommet">
          Record Attendance
          <div className="ss-modal__buttons-container">
            <CustomButton box label="Confirm" secondary onClick={recordAttandance} />
            <CustomButton box label="Cancel" primary onClick={handleClose} />
          </div>
        </div>
      </Modal>

      <div>
        <CustomButton
          className="admin-user-attandance-button"
          label="Record Attendance"
          secondary={true}
          onClick={handleShow}
        />
        <h2>{`${props.selectedClient.name} ${props.selectedClient.last_name}`}</h2>
      </div>
      <div className={'main-details-container ' + (props.isAdmin ? 'admin-user-profile-pic' : '')}>
        <ProfilePicture
          editable={props.editable}
          uploadNewPhoto={onNewPhotoUpload}
          src={props.selectedClient.image ? props.selectedClient.image : null}
          profilePictureLoading={profilePictureLoading}
        />
        <div className="main-details-container-phone">
          <h3 style={{ fontSize: '18px', marginBottom: '20px' }}>
            {props.selectedClient.customerType === CLIENTS_TYPES.CLASS_PASS_MEMBER
              ? 'PAYG (Class Pass)'
              : props.selectedClient.currentMembershipPlan
              ? `Membership - ${props.selectedClient.currentMembershipPlan.planName}`
              : ''}
          </h3>
          <ProfileTextWithLabel label={'Email'} text={`${props.selectedClient.email}`} />
          <ProfileTextWithLabel label={'Phone'} text={`${props.selectedClient.phone_number}`} />
          {props.selectedClient.gymSettings.qrCode && (
            <ProfileTextWithLabel
              label={'QR Code'}
              text={`${
                props.selectedClient.qrCode && props.selectedClient.qrCode !== ''
                  ? props.selectedClient.qrCode + ' - extra/external'
                  : 'Internal/no extra QR code set'
              }`}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default ClientProfileMainDetails;
