import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Select, Label } from 'grommet';
import classes from './ProductSelector.module.scss';
import { ALL_CLASS_WITH_CLASS_TYPES, CLASS } from '../../../constants';
import { fetchPasses, fetchClasses, fetchVideos } from '../../../apiFunctions/apiFunctions';
import { Notification } from 'grommet';
import Loading from '../../Loading/Loading';
import RefreshIcon from 'grommet/components/icons/base/Refresh';

const ProductSelector = ({
  gymId,
  selectedCoupon,
  onProductTypeSelection,
  onProductSelection,
  selectedCouponType,
  membershipPlans
}) => {
  let productTypeOptions = [];
  const classTypes = ALL_CLASS_WITH_CLASS_TYPES();

  const setProductTypeDropdown = () => {
    if (selectedCouponType === 'all' || selectedCouponType === 'classes') {
      classTypes.forEach((elem) => {
        productTypeOptions.push({
          label: elem.name,
          isClass: true,
          type: elem.type
        });
      });
    }

    if (selectedCouponType === 'all' || selectedCouponType === 'classPasses') {
      classTypes.forEach((elem) => {
        if (elem.productType !== 'inductionPass') {
          productTypeOptions.push({
            label: elem.productType,
            isClass: false,
            type: elem.productType,
            url: elem.type
          });
        }
      });
    }

    return selectedCoupon && selectedCoupon.productType
      ? productTypeOptions.filter((elem) => elem.type === selectedCoupon.productType)[0]
      : {};
  };
  const isNotEmptyObject = (object) => {
    return object && Object.keys(object).length > 0;
  };

  const clearData = () => {
    setSelectedProduct([]);
    setProductOptions([]);
    setFilteredProduct(null);
  };

  const [selectedProductType, setSelectedProductType] = useState(setProductTypeDropdown());

  const [selectedProduct, setSelectedProduct] = useState([]);
  const [productOption, setProductOptions] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState(null);
  const [filteredProductType, setFilteredProductType] = useState(null);

  const {
    data: videoList,
    error: videoListError,
    isLoading: videoListLoading
  } = useQuery(['fetchVideos'], () => fetchVideos(), {
    enabled:
      isNotEmptyObject(selectedProductType) &&
      selectedProductType.type === CLASS.VIDEO &&
      selectedProductType.isClass === true &&
      gymId !== null
  });

  const { data, isLoading, error } = useQuery(
    ['passes', selectedProductType.url],
    () => fetchPasses(selectedProductType.url, gymId),
    {
      enabled: isNotEmptyObject(selectedProductType) && selectedProductType.isClass === false && gymId !== null
    }
  );

  const {
    data: classesList,
    error: classesListError,
    isLoading: classesListLoading
  } = useQuery(['upcomingClasses', selectedProductType.type], () => fetchClasses(selectedProductType.type, gymId), {
    enabled:
      isNotEmptyObject(selectedProductType) &&
      selectedProductType.type !== CLASS.VIDEO &&
      selectedProductType.isClass === true &&
      gymId !== null
  });

  useEffect(() => {
    setProductDropdown(videoList, true);
    // eslint-disable-next-line
  }, [videoList]);

  useEffect(() => {
    setProductDropdown(data, false);
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    setProductDropdown(classesList, true);
    // eslint-disable-next-line
  }, [classesList]);

  useEffect(() => {
    if (!selectedCoupon) {
      setSelectedProductType({});
      setSelectedProduct([]);
      setFilteredProduct(null);
      setFilteredProductType(null);
      // eslint-disable-next-line
      productTypeOptions = [];
      setProductOptions([]);
    }
    if (selectedCouponType === 'membershipPlans') {
      setProductDropdown(membershipPlans, false);
    }
    // eslint-disable-next-line
  }, [selectedCouponType]);

  const setProductDropdown = (products, isClass) => {
    if (products && products.length > 0) {
      let productArray = isClass ? (products[0].classes ? products[0].classes : products) : products;
      let options = productArray.map((elem) => {
        return {
          label: elem.name ? elem.name : elem.title,
          value: elem._id
        };
      });
      setProductOptions(options);
      if (selectedCoupon && selectedCoupon.productIds) {
        let products = options.filter((elem) => selectedCoupon.productIds.includes(elem.value));
        setSelectedProduct(products);
      }
    }
  };

  if (isLoading || classesListLoading || videoListLoading) {
    return <Loading />;
  }
  if (error || classesListError || videoListError) {
    return <Notification message="Something went wrong. Please try again" status="critical" />;
  }
  const renderProductTypeOptions = () => {
    const chooseProductType = (event) => {
      clearData();
      let productTypeObj = event.value;
      setSelectedProductType(productTypeObj);
      if (productTypeObj.type === CLASS.VIDEO && productTypeObj.isClass === true) {
        setProductDropdown(videoList, true);
      }
      onProductTypeSelection(productTypeObj);
    };

    const refreshProductType = () => {
      setSelectedProductType({});
      setFilteredProductType(null);
      clearData();
      onProductTypeSelection({});
    };

    const onFilterProductTypes = (e) => {
      const filter = e.target.value.toLowerCase();
      const filteredProductList = productTypeOptions.filter((elem) => elem.label.toLowerCase().includes(filter));

      setFilteredProductType(
        filteredProductList.map((elem) => {
          return {
            label: elem.label,
            isClass: elem.isClass,
            type: elem.type,
            url: elem.url
          };
        })
      );
    };

    return (
      <>
        <Label>Product Type</Label>
        <div className={classes.inline}>
          <Select
            className={classes.select}
            placeHolder="Product: all"
            options={filteredProductType || productTypeOptions}
            value={selectedProductType}
            onChange={chooseProductType}
            onSearch={onFilterProductTypes}
          />
          <RefreshIcon className={classes.icon} onClick={refreshProductType} />
        </div>
      </>
    );
  };

  const onFilterProducts = (e) => {
    const filter = e.target.value.toLowerCase();
    const filteredProductList = productOption.filter((elem) => elem.label.toLowerCase().includes(filter));

    setFilteredProduct(
      filteredProductList.map((elem) => {
        return {
          label: elem.label,
          value: elem.value
        };
      })
    );
  };

  const handleProductSelection = (event) => {
    setSelectedProduct(event.value);
    let productIds = event.value.map((a) => a.value);
    onProductSelection(productIds);
  };

  return (
    <>
      <div className={classes.bottomSpace}>{renderProductTypeOptions()}</div>
      {productOption && (
        <div>
          <Label>Select product</Label>
          <div>
            <Select
              placeHolder="Product: all"
              multiple={true}
              options={filteredProduct || productOption}
              value={selectedProduct}
              onChange={handleProductSelection}
              onSearch={onFilterProducts}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ProductSelector;
