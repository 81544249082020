import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { delayFetchFunctions, orderAndPayForPass } from '../../../apiFunctions/apiFunctions';
import Icons from '../../../components/UI/Icons/Icons';
import { Link } from 'react-router-dom';
import CustomButton from '../../../components/CustomButton/CustomButton';
import withUser from '../../../store/hoc/withUser';
import { getCurrencyDetail, returnErrorFromResponse } from '../../../shared/utility';
import Modal from 'react-modal';
import CustomToast from '../../../components/CustomToast/CustomToast';
import Loading from '../../../components/Loading/Loading';
import GoogleApplePayModal from '../../ActionButtons/GoogleApplePayButton/GoogleApplePayModal';
import classes from './RenderPassActionButtons.module.scss';
import { useStripe } from '@stripe/react-stripe-js';
import CouponField from '../../ManageCoupons/CouponField/CouponField';

const RenderPassActionButtons = ({
  sourcesList,
  isAdmin,
  isUser,
  classPass,
  editPass,
  goBackToPreviousPage,
  buttonStyle = {},
  classType,
  label = 'Pay',
  successMessage = 'Successfully paid'
}) => {
  const [toast, setToast] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const currency = getCurrencyDetail(classPass.currency).value;
  const stripe = useStripe();
  const mutateOrderAndPay = useMutation(() => orderAndPayForPass(classPass._id, 'web', currency, coupon), {
    onSuccess: async ({ status, clientSecret }) => {
      if (status === 'requires_action') {
        const result = await stripe.confirmCardPayment(clientSecret, {});
        if (result.error) {
          return setToast({
            status: 'critical',
            msg: result.error?.message,
            show: true
          });
        }
      }
      setShowModal(false);
      setCoupon(null);
      setToast({
        status: 'ok',
        msg: successMessage,
        show: true
      });
      await delayFetchFunctions([['userData'], ['passes', classType], ['videoPasses']]);
      goBackToPreviousPage();
    },
    onError: (err) => {
      setToast({
        status: 'critical',
        msg:
          err.response.data.errors[0].param === 'coupon'
            ? err.response.data.errors[0].msg
            : returnErrorFromResponse(err),
        show: true
      });
    }
  });

  if (isAdmin) {
    return <Icons edit editClick={() => editPass(classPass)} data-cy={'edit_icon'} />;
  }

  if (isUser) {
    if (sourcesList && sourcesList.length === 0 && classPass.cost > 0) {
      return <GoogleApplePayModal product={classPass} productType={'pass'} classType={classType} />;
    }

    const renderModal = (
      <Modal
        appElement={document.getElementById('root')}
        isOpen={showModal}
        className="ss-modal"
        onRequestClose={() => setShowModal(false)}>
        <CouponField productId={classPass._id} setCoupon={setCoupon} type={'pass'} />

        <div className="grommet">
          Do you want to pay for the selected product? <br />
          This product will be paid for using your default card payment. You can change it{' '}
          <Link to="/gym/payment-methods">here</Link> if you prefer.
          <div className="ss-modal__buttons-container">
            {mutateOrderAndPay.isLoading ? (
              <Loading topOffset={'10px'} />
            ) : (
              <>
                <CustomButton dataCy="yes_btn" label="Yes" secondary={true} onClick={mutateOrderAndPay.mutate} />
                <CustomButton dataCy="cancel_btn" label="Cancel" primary onClick={() => setShowModal(false)} />
              </>
            )}
          </div>
        </div>
      </Modal>
    );

    const openModal = () => {
      if (classPass?.cost === 0) {
        return mutateOrderAndPay.mutate();
      }
      setShowModal(true);
    };

    return (
      <>
        {renderModal}
        <CustomToast toast={toast} />
        {mutateOrderAndPay.isLoading ? (
          <div className={classes.spinner}>
            <Loading />
          </div>
        ) : (
          <CustomButton
            style={{ width: '100%', height: '50px', margin: 'auto 0px', ...buttonStyle }}
            dataCy="payBtn"
            label={label}
            secondary={true}
            onClick={openModal}
          />
        )}
      </>
    );
  }

  return null;
};

export default withUser(RenderPassActionButtons);
