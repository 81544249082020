import React, { Component } from 'react';

import Modal from 'react-modal';
import CheckBox from 'grommet/components/CheckBox';
import Anchor from 'grommet/components/Anchor';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Paragraph from 'grommet/components/Paragraph';
import Loading from '../../../components/Loading/Loading';

import FormCreator from '../../../components/UI/FormCreator/FormCreator';
import TermsOfWebsite from '../../TermsOfWebsite/TermsOfWebsite';

import classes from './StepTwo.module.scss';

export class StepTwo extends Component {
  state = {
    showModal: false,
    termsOfWebsiteAccepted: false
  };

  handleTermsOfWebisteCheckbox = () => {
    this.setState({
      termsOfWebsiteAccepted: !this.state.termsOfWebsiteAccepted
    });
  };

  openModal = () => {
    this.setState({
      showModal: true
    });
  };

  cancelTermsInModal = () => {
    this.setState({
      showModal: false,
      termsOfWebsiteAccepted: false
    });
  };

  acceptTermsInModal = () => {
    this.setState({
      showModal: false,
      termsOfWebsiteAccepted: true
    });
  };

  renderButtonOrInfo = () => {
    if (!this.state.termsOfWebsiteAccepted) {
      return <Paragraph>Please accept Terms & Conditions of our Website to continue.</Paragraph>;
    }

    if (this.props.registrationLoading) {
      return <Loading />;
    }

    return (
      <CustomButton
        dataCy="next_step_btn"
        className={classes.nextStepButton}
        box
        label="Next step"
        secondary={true}
        onClick={this.props.checkFormValidity}
      />
    );
  };

  render() {
    return (
      <>
        <Modal appElement={document.getElementById('root')} isOpen={this.state.showModal} className="ss-modal">
          <div data-cy="terms_of_website_modal" className="grommet">
            <TermsOfWebsite inModal />
          </div>

          <CustomButton
            dataCy="agree_btn"
            className={classes.nextStepButton}
            box
            label="Agree"
            secondary
            onClick={this.acceptTermsInModal}
          />

          <CustomButton
            dataCy="cancel_btn"
            className={classes.nextStepButton}
            box
            label="Cancel"
            primary
            onClick={this.cancelTermsInModal}
          />
        </Modal>

        <div className={classes.termsOfWebsiteContainer}>
          <CheckBox
            checked={this.state.termsOfWebsiteAccepted}
            onChange={this.handleTermsOfWebisteCheckbox}
            label={
              <Anchor
                onClick={this.openModal}
                data-cy="signup_accept_tnc"
                label="Accept Terms & Conditions of our Website"
              />
            }
          />
        </div>

        <FormCreator formData={this.props.personalDetails} valueChanged={this.props.valueChangedHandler} />

        <div className={classes.checkboxContainer}>
          <CheckBox
            checked={this.props.marketing}
            onChange={() => {
              this.props.toggleCheckbox('marketing', !this.props.marketing);
            }}
            label="We'd love to keep you up to date with our news & offers, tick here if you'd like to stay in touch"
          />
        </div>

        {this.renderButtonOrInfo()}
      </>
    );
  }
}

export default StepTwo;
