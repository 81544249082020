import React, { useRef, useState, useEffect } from 'react';
import useCameraMedia from '../../../hooks/useCameraMedia';
import CustomButton from '../../../components/CustomButton/CustomButton';
import classes from './LiveStreamBroadcaster.module.scss';
import PlayIcon from 'grommet/components/icons/base/Play';
import StopIcon from 'grommet/components/icons/base/Stop';
import brandLogo from '../../../brandConfig/logo-icons/brand-logo.png';
import ViewIcon from 'grommet/components/icons/base/View';
import AlertIcon from 'grommet/components/icons/base/Alert';
import Toast from 'grommet/components/Toast';
import Messenger from '../../Messenger/Messenger';

const LiveStreamBroadcaster = (props) => {
  const videoRef = useRef();
  const mediaStream = useRef();
  const webSocket = useRef();
  const videoIsStreaming = useRef(false);
  const [showLiveIcon, setShowRecordingIcon] = useState(false);
  const [signalServerUnavailable, setSignalServerUnavailable] = useState(false);
  const [rtcPeerConnections, setRtcPeerConnections] = useState(new Map());
  const [viewerCount, setViewerCount] = useState(0);
  const [sessionType] = useState('broadcaster');

  useEffect(
    () => {
      webSocketAdapter();
      return function closeWebSocketSession() {
        webSocket.current.close();
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  mediaStream.current = useCameraMedia();

  const startVideo = async () => {
    if (!videoIsStreaming.current) {
      videoRef.current.srcObject = mediaStream.current;
      videoIsStreaming.current = true;
      setShowRecordingIcon(true);
      await videoRef.current.play();
    }
  };

  const stopVideo = () => {
    if (videoIsStreaming.current) {
      videoIsStreaming.current = false;
      setShowRecordingIcon(false);
      videoRef.current.pause();
      if (rtcPeerConnections.size > 0) {
        rtcPeerConnections.forEach((rtcPeerConnection, sessionId) => {
          rtcPeerConnection.close();
          rtcPeerConnections.delete(sessionId);
        });
      }
      mediaStream.current = null;
      setViewerCount(0);
    }
  };

  const handleSession = (serverResponse) => {
    const typeRequest = {
      signalType: 'type',
      sessionType: sessionType,
      sessionId: serverResponse.sessionId
    };
    webSocket.current.send(JSON.stringify(typeRequest));
  };

  const handleViewer = async (serverResponse) => {
    if (videoIsStreaming.current) {
      const config = {
        iceServers: [
          {
            urls: [process.env.REACT_APP_LIVE_STREAM_STUN_SERVERS]
          }
        ]
      };

      const rtcPeerConnection = new RTCPeerConnection(config);

      mediaStream.current.getTracks().forEach((track) => {
        rtcPeerConnection.addTrack(track, mediaStream.current);
      });

      const sdp = await rtcPeerConnection.createOffer();

      await rtcPeerConnection.setLocalDescription(sdp);

      const offerRequest = {
        signalType: 'offer',
        sessionType: sessionType,
        sessionId: serverResponse.sessionId,
        sdpOffer: rtcPeerConnection.localDescription
      };

      webSocket.current.send(JSON.stringify(offerRequest));

      rtcPeerConnection.onicecandidate = (event) => {
        if (event.candidate) {
          const candidateRequest = {
            signalType: 'candidate',
            sessionType: sessionType,
            sessionId: serverResponse.sessionId,
            iceCandidate: event.candidate
          };
          webSocket.current.send(JSON.stringify(candidateRequest));
        }
      };

      setRtcPeerConnections(rtcPeerConnections.set(serverResponse.sessionId, rtcPeerConnection));
      setViewerCount(rtcPeerConnections.size);
    } else {
      const messageRequest = {
        signalType: 'message',
        sessionType: sessionType,
        name: props.userData.name,
        message: "Broadcaster hasn't gone live yet"
      };
      webSocket.current.send(JSON.stringify(messageRequest));
    }
  };

  const handleCandidate = (serverResponse) => {
    const candidateSessionId = serverResponse.sessionId;
    const rtcPeerConnection = rtcPeerConnections.get(candidateSessionId);
    rtcPeerConnection.addIceCandidate(new RTCIceCandidate(serverResponse.iceCandidate));
  };

  const handleAnswer = (serverResponse) => {
    const answerSessionId = serverResponse.sessionId;
    const rtcPeerConnection = rtcPeerConnections.get(answerSessionId);
    rtcPeerConnection.setRemoteDescription(serverResponse.sdpAnswer);
  };

  const handleClosure = (serverResponse) => {
    const closedSessionId = serverResponse.sessionId;
    let rtcPeerConnectionToClose = rtcPeerConnections.get(closedSessionId);
    if (rtcPeerConnectionToClose) {
      rtcPeerConnectionToClose.close();
      rtcPeerConnectionToClose = null;
    }
    rtcPeerConnections.delete(closedSessionId);
    setViewerCount(rtcPeerConnections.size);
  };

  const webSocketAdapter = () => {
    if (!webSocket.current) {
      webSocket.current = new WebSocket(process.env.REACT_APP_LIVE_STREAM_WEBSOCKET_SERVER_URL);
    }

    webSocket.current.onopen = () => {
      const messageData = {
        type: 'welcome',
        message: 'Welcome',
        name: props.userData.name
      };
      addMessageToWindow(messageData);
    };

    webSocket.current.onmessage = (event) => {
      const serverResponse = JSON.parse(event.data);
      if (serverResponse.signalType === 'session') {
        handleSession(serverResponse);
      } else if (serverResponse.signalType === 'viewer') {
        handleViewer(serverResponse);
      } else if (serverResponse.signalType === 'answer') {
        handleAnswer(serverResponse);
      } else if (serverResponse.signalType === 'candidate') {
        handleCandidate(serverResponse);
      } else if (serverResponse.signalType === 'closed') {
        handleClosure(serverResponse);
      } else if (serverResponse.signalType === 'message') {
        addMessageToWindow(serverResponse);
      }
    };

    webSocket.current.onerror = () => {
      setSignalServerUnavailable(true);
    };

    webSocket.current.onclose = () => {
      videoRef.current = null;
    };
  };

  const addMessageToWindow = (messageData) => {
    const messageWindow = document.getElementById('messages');
    let message = `<div style="color:${messageData.color}">${messageData.name}: ${messageData.message}</div>`;
    if (messageData.type === 'welcome') {
      message = `<div>${messageData.message}&nbsp;${messageData.name}</div>`;
    }
    messageWindow.innerHTML += message;
    messageWindow.scrollTop = messageWindow.scrollHeight;
  };

  const renderNotification = () => {
    if (signalServerUnavailable) {
      return <Toast status="critical">Unable to connect to the live stream server</Toast>;
    }
  };

  return (
    <div data-cy={'live_stream'}>
      {renderNotification()}
      <div className={classes.cameraContainer}>
        <div className={classes.cameraText}>
          <p>
            When you're ready to start your live stream, hit the Start button below. Once you've finished your session
            hit Stop.
          </p>
        </div>
        <div className={classes.videoContainer}>
          <video id="live-stream" ref={videoRef} autoPlay muted poster={brandLogo}></video>
          {showLiveIcon ? (
            <div className={classes.liveIconContainer}>
              <div className={classes.liveIcon}>LIVE</div>
              {viewerCount > 0 ? (
                <div className={classes.viewerCount}>
                  <ViewIcon type="logo" />
                  &nbsp;{viewerCount}
                </div>
              ) : null}
            </div>
          ) : null}
          {signalServerUnavailable ? (
            <div className={classes.signalServerErrorMessage}>
              <AlertIcon />
              &nbsp;Live stream server is currently unavailable&nbsp;
              <AlertIcon />
            </div>
          ) : null}
        </div>
        <div className={classes.videoButtons}>
          <CustomButton
            className={classes.btnStart}
            label="Start"
            secondary={true}
            onClick={
              signalServerUnavailable ? null : videoIsStreaming.current || !webSocket.current ? null : startVideo
            }
            icon={<PlayIcon />}
          />
          <CustomButton
            className={classes.btnStop}
            label="Stop"
            secondary={false}
            onClick={videoIsStreaming.current ? stopVideo : null}
            icon={<StopIcon />}
          />
        </div>
        <Messenger
          webSocket={webSocket.current}
          userName={props.userData.name}
          sessionType={sessionType}
          signalServerUnavailable={signalServerUnavailable}
        />
      </div>
    </div>
  );
};

export default LiveStreamBroadcaster;
