import React from 'react';
import { useState } from 'react';
import CustomButton from '../../../components/CustomButton/CustomButton';
import Modal from 'react-modal';
import Loading from '../../../components/Loading/Loading';
import { useMutation } from 'react-query';
import { activateClass, delayFetchFunctions } from '../../../apiFunctions/apiFunctions';
import CustomToast from '../../../components/CustomToast/CustomToast';

const ActivateClass = ({ classId }) => {
  const [showModal, setShowModal] = useState(false);
  const [toast, setToast] = useState(null);
  const mutateActivateClass = useMutation(() => activateClass(classId), {
    onSuccess: () => {
      setShowModal(false);
      setToast({
        status: 'ok',
        msg: 'Successfully activated',
        show: true
      });
      delayFetchFunctions(['singleClass']);
    },
    onError: (response) => {
      setShowModal(false);
      setToast({
        status: 'critical',
        msg: response.data.message ? response.data.message : 'Something went wrong, try again',
        show: true
      });
    }
  });

  const confirmationModal = (
    <Modal onRequestClose={() => setShowModal(false)} isOpen={showModal} className="ss-modal">
      <div className="grommet">
        Do you want to activate this class?
        <div className="ss-modal__buttons-container">
          <CustomButton box label="Yes" secondary onClick={mutateActivateClass.mutate} />

          <CustomButton box label="Cancel" primary onClick={() => setShowModal(false)} />
        </div>
      </div>
    </Modal>
  );

  if (mutateActivateClass.isLoading) {
    return <Loading />;
  }

  return (
    <div>
      <div>
        <CustomToast toast={toast} />
        {confirmationModal}
        <CustomButton label="Activate" secondary={true} onClick={() => setShowModal(true)} data-cy="activateClass" />
      </div>
    </div>
  );
};

export default ActivateClass;
