import React, { useState, useEffect } from 'react';
import { Select, Label } from 'grommet';
import classes from './TrainerSelector.module.scss';
import withAdminDashboard from '../../../store/hoc/withAdminDashboard';
import Notification from 'grommet/components/Notification';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchTrainers } from '../../../apiFunctions/apiFunctions';
import Loading from '../../Loading/Loading';
import RefreshIcon from 'grommet/components/icons/base/Refresh';
import TrainersShifts from './TrainersShifts/TrainersShifts';
import { returnGymSettingsFromGymsList } from '../../../shared/gymFunctions';

const TrainerSelector = ({
  selectedTrainer = null,
  onSelectTrainer = () => {},
  gymId = null,
  validationErrors = [],
  setValidationErrors = () => {},
  setIsStaffShiftsValid = () => {},
  showLabel = true,
  multiple = false,
  placeHolder = 'Select trainer',
  classDateAndTime = '',
  classDuration = 60,
  showStaffShifts = false,
  onCloseEditClassModal = () => {},
  locations = [],
  schedule = null,
  isSchedule = false,
  isEditMode = false
}) => {
  const [filteredTrainers, setFilteredTrainers] = useState();
  const [selected, setSelected] = useState(
    selectedTrainer
      ? {
          value: selectedTrainer,
          label: `${selectedTrainer.name} ${selectedTrainer.last_name}`
        }
      : null
  );
  const [staffShiftsSolution, setStaffShiftsSolution] = useState(null);
  const showShifts =
    gymId === null
      ? false
      : multiple
      ? false
      : showStaffShifts
      ? returnGymSettingsFromGymsList(gymId, locations).staffShifts
      : false;

  const {
    data: trainers,
    isLoading,
    error
  } = useQuery(['trainersList', gymId], () => fetchTrainers(gymId), {
    enabled: gymId !== null
  });

  useEffect(
    () => {
      if (isSchedule && selectedTrainer && trainers) {
        const trainer = trainers.find((elm) => elm._id === selectedTrainer._id);
        setSelected({
          value: trainer,
          label: `${trainer.name} ${trainer.last_name}`
        });
      } else {
        setSelected(
          selectedTrainer
            ? {
                value: selectedTrainer,
                label: `${selectedTrainer.name} ${selectedTrainer.last_name}`
              }
            : null
        );
      }
    },
    // eslint-disable-next-line
    [selectedTrainer, trainers]
  );

  useEffect(
    () => {
      if (showShifts) {
        setValidationErrors(staffShiftsSolution === 'cancel' ? ['staffShifts'] : []);
        setIsStaffShiftsValid(staffShiftsSolution === 'save');

        if (staffShiftsSolution === 'cancel' && selectedTrainer !== null) {
          onCloseEditClassModal();
        }
      }
    }, // eslint-disable-next-line
    [selected, staffShiftsSolution]
  );

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <Notification message="Unable to show trainer's list. Please try again" status="critical" />;
  }

  if (!trainers || trainers?.length === 0) {
    return (
      <Link className={classes.noData} to={`/gym/${gymId}/trainers`}>
        Please add at least one trainer first
      </Link>
    );
  }

  if (trainers && trainers.length > 0) {
    const list = trainers.map((elem) => {
      return {
        label: `${elem.name} ${elem.last_name}`,
        value: elem
      };
    });

    const onFilterTrainerList = (e) => {
      const filter = e.target.value.toLowerCase();
      const filteredTrainerList = list.filter(
        (trainer) =>
          trainer.value.name.toLowerCase().includes(filter) || trainer.value.last_name.toLowerCase().includes(filter)
      );
      setFilteredTrainers(filteredTrainerList);
    };

    const handleRefreshSelection = () => {
      setSelected(null);
      onSelectTrainer(null);
    };

    const handleTrainerSelection = ({ value }) => {
      setSelected(value);
      onSelectTrainer(multiple ? value.map((elm) => elm.value) : value.value);
    };

    return (
      <div className={classes.root}>
        <div
          className={classes.container}
          style={multiple ? { gridTemplateColumns: '1fr 30px' } : { gridTemplateColumns: '100%' }}>
          <div>
            {showLabel && <Label id="trainer">Trainer</Label>}
            <Select
              data-cy="trainer_selector"
              aria-labelledby="trainer"
              options={filteredTrainers || list}
              value={selected}
              onChange={handleTrainerSelection}
              onSearch={onFilterTrainerList}
              placeHolder={placeHolder}
              multiple={multiple}
            />
          </div>
          {multiple && <RefreshIcon onClick={handleRefreshSelection} />}
        </div>
        {validationErrors.includes('trainer') && <div className={classes.trainerError}>Please select a trainer</div>}
        {validationErrors.includes('staffShifts') && (
          <div className={classes.trainerError}>
            Selected trainer is not available at that date & time. Please change trainer or date & time.
          </div>
        )}

        <TrainersShifts
          show={showShifts}
          selectedTrainer={selected ? selected.value : null}
          classDateAndTime={classDateAndTime}
          classDuration={classDuration}
          setStaffShiftsSolution={setStaffShiftsSolution}
          isSchedule={isSchedule}
          schedule={schedule}
          isEditMode={isEditMode}
        />
      </div>
    );
  }
};

export default withAdminDashboard(TrainerSelector);
