import React from 'react';
import moment from 'moment';
import classes from './ShowData.module.scss';

const DebtorsReportsMembership = ({ data }) => {
  const header = ['Name', 'Type', 'Failed on', 'Plan', 'Expired', 'Amount'];
  const item = ({ name, lastName, userType, date, planName, currentPeriodEnd, cost, userId }) => (
    <div key={userId} className={[classes.membershipGrid, classes.item].join(' ')}>
      <div className={classes.name}>
        {name} {lastName}
      </div>
      <div>
        <span className={classes.label}>Type:</span>
        {userType}
      </div>
      <div>
        <span className={classes.label}>Failed on:</span>
        {moment(date).format('DD/MM/YYYY')}
      </div>
      <div>
        <span className={classes.label}>Plan:</span>
        {planName ? planName : '-'}
      </div>
      <div>
        <span className={classes.label}>Expired:</span>
        {currentPeriodEnd ? moment(currentPeriodEnd).format('DD/MM/YYYY') : '-'}
      </div>
      <div>
        <span className={classes.label}>Amount:</span>
        {cost}
      </div>
    </div>
  );

  if (data.length === 0) {
    return <h3>No history</h3>;
  }

  return (
    <div>
      <div className={classes.header}>
        <div className={['table-header', classes.membershipGrid].join(' ')}>
          {header.map((elem) => (
            <div key={elem}>{elem}</div>
          ))}
        </div>
      </div>
      <div className={classes.dataContainer}>{data.map((elm) => item(elm))}</div>
    </div>
  );
};

export default DebtorsReportsMembership;
