import { useState } from 'react';
import moment from 'moment';

const useSelectDateFilter = (initialState, onFilter) => {
  const [selectedFilter, setSelectedFilter] = useState(initialState);

  const onSelectedDateFilterChanged = (dateString) => {
    onFilter(moment(dateString, 'DD/MM/YYYY').format('YYYY-MM-DD'));
    setSelectedFilter(dateString);
  };

  const isSelectedFilter = (filter) => {
    return filter === selectedFilter;
  };

  return {
    onSelectedDateFilterChanged,
    isSelectedFilter,
    selectedFilter
  };
};

export default useSelectDateFilter;
