import axios from '../../../../axios-global';
import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Tabs, Tab, List, ListItem, Toast } from 'grommet';
import ViewIcon from 'grommet/components/icons/base/View';
import { returnErrorFromResponse } from '../../../../shared/utility';
import ProfilePaymentHistory from '../../../../components/UI/ProfilePaymentHistory/ProfilePaymentHistory';
import Modal from 'react-modal';
import moment from 'moment';
import Loading from '../../../../components/Loading/Loading';
import './ClassPassReport.scss';
import Select from 'grommet/components/Select';
import ExportToCSV from '../../ExportToCSV/ExportToCSV';
import withAdminDashboard from '../../../../store/hoc/withAdminDashboard';
import RefreshIcon from 'grommet/components/icons/base/Refresh';
import { Notification } from 'grommet';
import { ALL_CLASS_TYPES_WITH_DETAILS, PASS_PAYMENT_TYPE } from '../../../../constants';
import Filter from '../../../../components/Filter';
import { fetchPasses } from '../../../../apiFunctions/apiFunctions';
import useTabIndex from '../../../../hooks/useTabIndex';

export const ClassPassesReports = (props) => {
  const [reportResult, setReportResult] = useState([]);
  const [overallTotal, setOverallTotal] = useState([]);
  const [filteredResult, setFilteredResult] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [bookingHistory, setBookingHistory] = useState([]);
  const [toastMsg, setToastMsg] = useState(false);
  const [filteredReport, setFilteredReport] = useState([]);
  const [selectedGym, setSelectedGym] = useState({
    label: props.selectedGym.name,
    id: props.selectedGym._id
  });
  const [loading, setLoading] = useState(false);
  const [filteredGym, setFilteredGym] = useState(null);
  const [filteredPass, setFilteredPass] = useState(null);
  const [selectedPassType, setSelectedPassType] = useState({});
  const classTypes = ALL_CLASS_TYPES_WITH_DETAILS();
  const [passOptions, setPassOptions] = useState({});
  const [selectedPass, setSelectedPass] = useState({});
  const { data, isLoading, error } = useQuery(
    ['passes', selectedPassType.type],
    () => fetchPasses(selectedPassType.type, selectedGym.id),
    {
      enabled: selectedPassType && Object.keys(selectedPassType).length > 0 && selectedGym.id !== null
    }
  );
  const { activeTabIndex, onTabChange } = useTabIndex();

  useEffect(() => {
    if (data && data.length > 0) {
      let options = data.map((elem) => {
        return {
          label: elem.name,
          id: elem._id
        };
      });
      setPassOptions(options);
    }
  }, [data]);

  useEffect(() => {
    setSelectedPass({});
  }, [selectedPassType]);

  useEffect(() => {
    const getReport = async () => {
      let url = `reports/${selectedGym.id}/classpass/remaining`;
      if (selectedPassType && Object.keys(selectedPassType).length > 0) {
        url += `?productPassType=${selectedPassType.value}`;
      }
      if (selectedPass && Object.keys(selectedPass).length > 0) {
        url += `&productPassId=${selectedPass.value}`;
      }
      setLoading(true);
      await axios
        .get(url)
        .then((response) => {
          setLoading(false);
          setFilteredReport(response.data);
          formatReportArray(response.data.userTotals);
          setOverallTotal(response.data.overallTotal);
        })
        .catch(() => setLoading(false));
    };
    getReport();
  }, [selectedGym, selectedPassType, selectedPass]);

  if (error) {
    return <Notification message="Something went wrong. Please try again" status="critical" />;
  }

  const formatReportArray = (report) => {
    let results = [];
    Object.keys(report).forEach((key) => {
      results.push(report[key]);
    });

    setReportResult(results);
    setFilteredResult(results);
  };

  const onSelectedFilterChanged = (event) => {
    const searchText = event.target.value.toLowerCase();
    const filteredArray = reportResult.filter((result) => {
      return (
        result.user.name.toLowerCase().includes(searchText) || result.user.last_name.toLowerCase().includes(searchText)
      );
    });
    setFilteredResult(filteredArray);
  };

  const viewDetail = (classObj) => {
    axios
      .get(`/users/${classObj.user._id}`)
      .then((response) => {
        selectedPassType && Object.keys(selectedPassType).length > 0
          ? setPaymentHistory(
              response.data.paymentHistory.filter((payment) => payment.type === selectedPassType.value + '_payment')
            )
          : setPaymentHistory(
              response.data.paymentHistory.filter((payment) => PASS_PAYMENT_TYPE.includes(payment.type))
            );
        selectedPassType && Object.keys(selectedPassType).length > 0
          ? setBookingHistory(
              response.data.fitnessClassHistory.filter((booking) => booking.class.__t === selectedPassType.type)
            )
          : setBookingHistory(response.data.fitnessClassHistory);

        setShowModal(true);
      })
      .catch((error) => {
        setToastMsg(returnErrorFromResponse(error));
      });
  };

  const renderModal = () => {
    const renderPaymentHistory = () => {
      return paymentHistory.map((payment, index) => {
        return (
          <ListItem justify="between" separator="horizontal" key={index}>
            <span>{payment.type}</span>
            <span className="secondary">
              <ProfilePaymentHistory lastPaymentInfo={payment} />
            </span>
          </ListItem>
        );
      });
    };

    const renderBookingHistory = () => {
      return bookingHistory.map((booking, index) => {
        return (
          <ListItem justify="between" separator="horizontal" key={index}>
            <span className="secondary">
              {booking.class.name} - {moment(booking.classDate).format('Do MMM YYYY')}
            </span>
            <span>{booking.status}</span>
          </ListItem>
        );
      });
    };
    return (
      <Modal onRequestClose={onHideModal} isOpen={showModal} className="ss-modal">
        <Tabs responsive={false} justify="start" activeIndex={activeTabIndex} onActive={onTabChange}>
          <Tab title="Payment History">
            <List>{renderPaymentHistory()}</List>
          </Tab>
          <Tab title="Booking History">
            <List>{renderBookingHistory()}</List>
          </Tab>
        </Tabs>
      </Modal>
    );
  };

  const onHideModal = () => {
    return setShowModal(false);
  };

  const renderToast = () => {
    if (toastMsg) {
      return (
        <Toast status="critical" onClose={() => setToastMsg(false)}>
          {toastMsg}
        </Toast>
      );
    }
  };
  const renderGymSelection = () => {
    let options = props.locations.map((elem) => {
      return {
        label: elem.name,
        id: elem._id
      };
    });
    const onFilterGymList = (e) => {
      const filter = e.target.value.toLowerCase();
      const filteredGymList = props.locations.filter((gym) => gym.name.toLowerCase().includes(filter));

      setFilteredGym(
        filteredGymList.map((elem) => {
          return {
            label: elem.name,
            id: elem._id
          };
        })
      );
    };
    return (
      <div>
        <h4>Select gym</h4>
        <Select
          placeHolder="Select gym name"
          options={filteredGym || options}
          value={selectedGym}
          onChange={(event) => setSelectedGym(event.option)}
          onSearch={onFilterGymList}
        />
      </div>
    );
  };

  const renderPassSelection = () => {
    let passesOptions = [];

    classTypes.forEach((elem) => {
      if (elem.productType) {
        passesOptions.push({
          label: elem.title,
          value: elem.productType,
          type: elem.type
        });
      }
    });

    const onFilterPassList = (e) => {
      const filter = e.target.value.toLowerCase();
      const filteredPassesList = passesOptions.filter((pass) => pass.label.toLowerCase().includes(filter));

      setFilteredPass(
        filteredPassesList.map((elem) => {
          return {
            label: elem.label,
            value: elem.value,
            type: elem.type
          };
        })
      );
    };

    const choosePassType = (event) => {
      setSelectedPassType({ label: event.option.label, value: event.option.value, type: event.option.type });
    };

    const choosePass = (event) => {
      setSelectedPass({ label: event.option.label, value: event.option.id });
    };

    const resetSelection = () => {
      setSelectedPassType({});
      setSelectedPass({});
    };

    return (
      <>
        <div>
          <h4>Select pass type</h4>
          <div className="filterPass">
            <Select
              placeHolder="PassType: all"
              options={filteredPass || passesOptions}
              value={selectedPassType ? selectedPassType.label : ''}
              onChange={choosePassType}
              onSearch={onFilterPassList}
            />
            <RefreshIcon className="refreshIcon" onClick={resetSelection} />
          </div>
        </div>
        {selectedPassType && Object.keys(selectedPassType).length > 0 && (
          <div>
            <h4>Select pass</h4>
            <div className="filterPass">
              <Select
                placeHolder="Pass: all"
                options={passOptions}
                value={selectedPass ? selectedPass.label : ''}
                onChange={choosePass}
              />
              <RefreshIcon className="refreshIcon" onClick={() => setSelectedPass({})} />
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {renderModal()}
      {renderToast()}
      <ExportToCSV data={filteredResult} type={'classPassesReport'} gymName={selectedGym.label} />
      <div className="ClassPassReport">
        <div className="ClassPassReport__filter">
          <Filter onFilter={onSelectedFilterChanged} placeHolder="Client name" title="Filter by name" />
          {renderGymSelection()}
          {renderPassSelection()}
        </div>

        <div className="ClassPassReport__total__label">Total Passes Remaining: {overallTotal}</div>

        {loading || isLoading ? (
          <Loading />
        ) : (
          <List className="ClassPassReport__list">
            {filteredReport !== null && filteredReport.length === 0 && <div>No client found</div>}
            {filteredReport !== null && filteredReport.length > 0 && (
              <ListItem className="ClassPassReport__list__header">
                <span>Client</span>
                <span>Class Passes Remaining</span>
              </ListItem>
            )}
            {filteredResult.map((result, index) => (
              <ListItem className="ClassPassReport__list__item" key={index}>
                <div>
                  {result.user.name} {result.user.last_name}
                </div>
                <div>{result.total}</div>
                <ViewIcon onClick={() => viewDetail(result)} />
              </ListItem>
            ))}
          </List>
        )}
      </div>
    </>
  );
};

export default withAdminDashboard(ClassPassesReports);
